import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/page-loader.json";

export default function PageLoader({ size }) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: size ? size : "unset",
				height: "100vh",
			}}
		>
			<Lottie options={defaultOptions} height={100} width={100} />
		</div>
	);
}
