import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import claimBg from "../../../assets/images/bidding/claim-banner.png";
import liveIcon from "../../../assets/icons/live.svg";
import clock from "../../../assets/icons/sand-clock.svg";

export default function BidTimerBanner({ upcomingBiddingList }) {
	const [shortestTime, setShortestTime] = useState(null);
	const [days, setDays] = useState(1);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const getTimeDifference = (startTime) => {
		const now = new Date();
		const diff = new Date(startTime) - now;
		return Math.abs(diff);
	};

	const handleShortestPath = () => {
		const shortest = upcomingBiddingList.reduce((prev, current) => {
			const prevDiff = getTimeDifference(prev.start_time_stamp);
			const currentDiff = getTimeDifference(current.start_time_stamp);
			return prevDiff < currentDiff ? prev : current;
		});
		setShortestTime(shortest);
	};
	useEffect(() => {
		if (upcomingBiddingList.length > 0) {
			handleShortestPath();
			if (shortestTime) {
				getTimeDifference(shortestTime.start_time_stamp);
			}
		}
	}, [upcomingBiddingList, shortestTime]);

	useEffect(() => {
		if (shortestTime) {
			const endDate = new Date(shortestTime.start_time_stamp);
			const intervalId = setInterval(() => {
				const timeRemaining = Math.max(endDate - Date.now(), 0);
				const remainingSeconds = Math.floor(timeRemaining / 1000);

				const newDays = Math.floor(remainingSeconds / (60 * 60 * 24));
				const newHours = Math.floor(
					(remainingSeconds / (60 * 60)) % 24
				);
				const newMinutes = Math.floor((remainingSeconds / 60) % 60);
				const newSeconds = Math.floor(remainingSeconds % 60);

				setDays(newDays);
				setHours(newHours);
				setMinutes(newMinutes);
				setSeconds(newSeconds);
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [shortestTime]);

	return (
		upcomingBiddingList.length > 0 && (
			<BannerContainer>
				<Wrapper
					className="wrapper"
					style={{
						background: `url(${claimBg})`,
					}}
					isPadding={
						days === 0 &&
						hours === 0 &&
						minutes === 0 &&
						seconds === 0
					}
				>
					{shortestTime && (
						<Left>
							<ImageBox>
								<Image
									style={{
										width: "100%",
										height: "0",
										paddingBottom: "100%", // creates the 1:1 ratio
										position: "relative",
									}}
								>
									<img
										fetchpriority="low"
										className="img-class"
										alt="Image"
										src={shortestTime.product_image}
										style={{
											position: "absolute",
											width: "100%",
											height: "100%",
											objectFit: "cover",
											left: 0,
											top: 0,
											borderRadius: "5px",
										}}
									/>
								</Image>
							</ImageBox>
							<Content>
								<Title>
									Grab your chance to <span>Win</span>
								</Title>
								<ProductName>
									{shortestTime.product_name}
								</ProductName>
								<SubTitle>{shortestTime.title}</SubTitle>
								<SubTitle>
									Worth{" "}
									<span>₹{shortestTime.actual_cost}</span>
								</SubTitle>
								{days === 0 &&
								hours === 0 &&
								minutes === 0 &&
								seconds === 0 ? (
									<Box>
										<TextContainer className="mobile">
											<IconBox>
												<img
													className="img-class"
													alt="Image"
													src={liveIcon}
												/>
											</IconBox>
											<TimerText>
												Jackpot event is live now
											</TimerText>
										</TextContainer>
										<Button
											className="mobile"
											to={`bidding/live-bidding/${shortestTime.id}`}
										>
											<BtText>Start Now</BtText>
										</Button>
									</Box>
								) : (
									<Box className="timer">
										<SubTitle>Event starts in</SubTitle>
										<TimerBoxWrapper>
											<TimerBox>
												<TimerCell>
													<Text>{days}</Text>
												</TimerCell>
												<Time>Days</Time>
											</TimerBox>
											<TimerBox>
												<TimerCell>
													<Text>{hours}</Text>
												</TimerCell>
												<Time>Hour</Time>
											</TimerBox>
											<TimerBox>
												<TimerCell>
													<Text>{minutes}</Text>
												</TimerCell>
												<Time>Min</Time>
											</TimerBox>
											<TimerBox>
												<TimerCell>
													<Text>{seconds}</Text>
												</TimerCell>
												<Time>Sec</Time>
											</TimerBox>
										</TimerBoxWrapper>
									</Box>
								)}
							</Content>
						</Left>
					)}

					{shortestTime && (
						<Right>
							{days === 0 &&
							hours === 0 &&
							minutes === 0 &&
							seconds === 0 ? (
								<ButtonContainer>
									<TextContainer>
										<IconBox>
											<img
												
												className="img-class"
												alt="Image"
												src={liveIcon}
											/>
										</IconBox>
										<TimerText>
											Jackpot Event is live now
										</TimerText>
									</TextContainer>
									<Button
										to={`bidding/live-bidding/${shortestTime.id}`}
									>
										<BtText>Start</BtText>
									</Button>
									<TextContainer>
										<IconBox>
											<img
												
												className="img-class"
												alt="Image"
												src={clock}
											/>
										</IconBox>
										<TimerText>
											End’s in : 2hr 32min
										</TimerText>
									</TextContainer>
								</ButtonContainer>
							) : (
								<TimerContainer>
									<JackpotImage>
										<img src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/web/Jackpot-logo2.png" alt="Image" className="img-class"/>
									</JackpotImage>
									<SubTitle>Event starts in</SubTitle>
									<TimerBoxWrapper>
										<TimerBox>
											<TimerCell>
												<Text>{days}</Text>
											</TimerCell>
											<Time>Days</Time>
										</TimerBox>
										<TimerBox>
											<TimerCell>
												<Text>{hours}</Text>
											</TimerCell>
											<Time>Hour</Time>
										</TimerBox>
										<TimerBox>
											<TimerCell>
												<Text>{minutes}</Text>
											</TimerCell>
											<Time>Min</Time>
										</TimerBox>
										<TimerBox>
											<TimerCell>
												<Text>{seconds}</Text>
											</TimerCell>
											<Time>Sec</Time>
										</TimerBox>
									</TimerBoxWrapper>
								</TimerContainer>
							)}
						</Right>
					)}
					{days === 0 &&
						hours === 0 &&
						minutes === 0 &&
						seconds === 0 && (
							<TextContainer className="mobile-second">
								<IconBox>
									<img
										
										className="img-class"
										alt="Image"
										src={clock}
									/>
								</IconBox>
								<TimerText>End’s in : 2hr 32min</TimerText>
							</TextContainer>
						)}
				</Wrapper>
			</BannerContainer>
		)
	);
}

const BannerContainer = styled.div`
	background-color: #fff;
	padding-bottom: 3%;
	@media all and (max-width: 640px) {
		padding-bottom: 7%;
	}
`;
const Wrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2%;
	@media all and (max-width: 640px) {
		padding: 3%;
		flex-wrap: wrap;
		padding-bottom: ${({ isPadding }) => (isPadding ? "14%" : "3%")};
	}
	position: relative;
`;
const Left = styled.div`
	width: 60%;
	display: flex;
	align-items: center;

	justify-content: space-between;
	@media all and (max-width: 980px) {
		width: 100%;
	}
	@media all and (max-width: 640px) {
		align-items: flex-start;
	}
`;
const Content = styled.div`
	width: 68%;
	@media all and (max-width: 980px) {
		width: 58%;
	}
`;
const Title = styled.h2`
	font-size: 35px;
	color: #fff;
	margin-bottom: 10%;
	font-family: regular;
	span {
		color: #feda00;
		font-family: bold;
	}
	@media all and (max-width: 1280px) {
		font-size: 25px;
	}
	@media all and (max-width: 980px) {
		margin-bottom: 5%;
	}
	@media all and (max-width: 768px) {
		font-size: 20px;
	}
	@media all and (max-width: 640px) {
		font-size: 17px;
		margin-bottom: 3%;
	}
	@media all and (max-width: 460px) {
		font-size: 14px;
		margin-bottom: 6%;
	}
`;
const ProductName = styled.h3`
	font-size: 30px;
	color: #fff;
	margin-bottom: 4%;
	font-family: medium;
	span {
		color: #feda00;
		font-family: bold;
	}
	@media all and (max-width: 1280px) {
		font-size: 20px;
	}
	@media all and (max-width: 768px) {
		font-size: 18px;
	}
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 3%;
	}
	@media all and (max-width: 460px) {
		font-size: 11px;
	}
`;

const JackpotImage = styled.div`
	width: 90%;
	margin : 0 auto;
`;
const SubTitle = styled.h3`
	font-size: 18px;
	color: #fff;
	margin-bottom: 2%;
	font-family: regular;
	span {
		color: #feda00;
	}
	@media all and (max-width: 1080px) {
		font-size: 14px;
	}
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
const Right = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media all and (max-width: 980px) {
		display: none;
	}
`;
const TimerContainer = styled.div`
	text-align: center;
	width: 60%;
	@media all and (max-width: 640px) {
		width: 40%;
	}
	@media all and (max-width: 460px) {
		width: 46%;
	}
`;
const TimerBoxWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 5%;
`;
const TimerBox = styled.div`
	text-align: center;
`;
const TimerCell = styled.div`
	border: 1px solid #feda00;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	margin-bottom: 8%;
	@media all and (max-width: 1280px) {
		width: 50px;
		height: 50px;
	}
	@media all and (max-width: 980px) {
		width: 40px;
		height: 40px;
	}
	@media all and (max-width: 640px) {
		width: 40px;
		height: 40px;
	}
	@media all and (max-width: 460px) {
		width: 35px;
		height: 35px;
	}
`;
const Text = styled.span`
	font-size: 25px;
	color: #fff;
	font-family: medium;
	@media all and (max-width: 1280px) {
		font-size: 16px;
	}
	@media all and (max-width: 980px) {
		font-size: 15px;
	}
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
	@media all and (max-width: 460px) {
		font-size: 12px;
	}
`;
const Time = styled.span`
	font-size: 15px;
	color: #fff;
	font-family: medium;
	@media all and (max-width: 980px) {
		font-size: 11px;
	}
`;
const Image = styled.div`
	border-radius: 5px;
	overflow: hidden;
	width: 20%;
`;
const Button = styled(Link)`
	background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
	height: 50px;
	font-size: 18px;
	font-family: medium;
	color: #747474;
	margin: 5% 0;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	&.mobile {
		margin-bottom: 0;

		@media all and (max-width: 640px) {
			height: 35px;
		}
		@media all and (max-width: 460px) {
			height: 30px;
		}
		span {
			@media all and (max-width: 640px) {
				font-size: 13px;
			}
			@media all and (max-width: 460px) {
				font-size: 11px;
			}
		}
	}
`;
const BtText = styled.span`
	font-size: 18px;
	font-family: medium;
	color: #747474;
`;
const ImageBox = styled.div`
	width: 30%;
	@media all and (max-width: 980px) {
		width: 40%;
	}
`;

const ButtonContainer = styled.div`
	width: 65%;
	text-align: center;
`;
const IconBox = styled.div`
	width: 20px;
	margin-right: 2%;
	@media all and (max-width: 460px) {
		width: 15px;
	}
`;
const TextContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	&.mobile {
		justify-content: flex-start;
		margin-top: 4%;
		@media all and (max-width: 640px) {
			margin-top: 7%;
		}
	}
	&.mobile-second {
		display: none;
		@media all and (max-width: 640px) {
			display: flex;
			position: absolute;
			bottom: 3%;
			left: 50%;
			right: 50%;
			transform: translateX(-50%);
		}
		div {
			@media all and (max-width: 640px) {
				width: 10px;
			}
		}
	}
`;
const TimerText = styled.span`
	font-size: 18px;
	color: #fff;
	font-family: regular;
	span {
		color: #feda00;
	}
	@media all and (max-width: 1080px) {
		font-size: 14px;
	}
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
const Box = styled.div`
	display: none;
	@media all and (max-width: 980px) {
		display: block;
		margin-top: 5%;
	}

	&.timer {
		width: 31%;
		@media all and (max-width: 980px) {
			width: 50%;
		}
		@media all and (max-width: 640px) {
			width: 70%;
		}
		@media all and (max-width: 460px) {
			width: 85%;
		}
		@media all and (max-width: 360px) {
			width: 90%;
		}
	}
`;
