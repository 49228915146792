import React from "react";
import styled from "styled-components";
import HeaderPush from "../general/HeaderPush";
import Products from "./Products";

export default function Seller() {
  return (
    <>
      <HeaderPush />
      <SellerContainer>
        <Wrapper className="wrapper">
          <TopBanner>
            <GiftBox>
              <GiftBoxImg alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/seller.svg" className="img-class" />
            </GiftBox>
            <BannerBg
              className="img-class"
              alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/seller/seller-top-bg.png"
            />
          </TopBanner>
          <SellerDetails>
            <SellerName>NEW GEN stepping to future</SellerName>
            <DetailsMiddle>
              <RatingBox>
                <Rating>
                  <IconBox>
                    <StarIcon className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/star.svg" />
                  </IconBox>
                  <RatingText>4.5</RatingText>
                </Rating>
                <Followers>72,65777 Ratings</Followers>
              </RatingBox>
              <Followers
                style={{
                  margin: "0 40px",
                }}
              >
                <BoldText>1430</BoldText>
                {"  "}Followers
              </Followers>
              <Followers className="third">
                <BoldText>1430</BoldText>
                {"  "}Products
              </Followers>
            </DetailsMiddle>
            <FollowButton>Follow</FollowButton>
          </SellerDetails>
        </Wrapper>
        <Products pageTitle={"seller"} />
      </SellerContainer>
    </>
  );
}

const SellerContainer = styled.section`
  padding-top: 3%;
`;
const Wrapper = styled.section`
  margin-bottom: 100px;
  @media all and (max-width: 980px) {
    margin-bottom: 60px;
  }
`;
const TopBanner = styled.div`
  width: 100%;
  position: relative;
  background: transparent;
  @media all and (max-width: 768px) {
    height: 180px;
  }
`;
const GiftBox = styled.div`
  padding: 7px;
  border: 1px solid #fffbe7;
  position: absolute;
  bottom: -15%;
  left: 50%;
  right: 50%;
  border-radius: 5px;
  background: rgba(255, 250, 233, 0.6);
  width: 100px;
  transform: translateX(-50%);
  -webkit-box-shadow: 2px 15px 42px -21px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 15px 42px -21px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 15px 42px -21px rgba(0, 0, 0, 0.75);
  @media all and (max-width: 1280px) {
    width: 75px;
    padding: 3px;
  }
  @media all and (max-width: 768px) {
    width: 60px;
  }
`;
const GiftBoxImg = styled.img`
  border-radius: 5px;
  background-color: #fffbe7;
  border: 1px solid #eab600;
  padding: 5px;
`;
const BannerBg = styled.img``;
const SellerDetails = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SellerName = styled.h1`
  font-family: medium;
  color: #191919;
  font-size: 30px;
  @media all and (max-width: 1080px) {
    font-size: 25px;
  }
  @media all and (max-width: 768px) {
    font-size: 20px;
  }
`;
const DetailsMiddle = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const RatingBox = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: 640px) {
    margin-bottom: 10px;
  }
`;
const Rating = styled.div`
  display: flex;
  align-items: center;
  background-color: #32c997;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 10px;
`;
const IconBox = styled.div`
  width: 12px;
  margin-right: 5px;
`;
const StarIcon = styled.img``;
const RatingText = styled.span`
  font-size: 13px;
  font-family: medium;
  color: #fff;
`;
const Followers = styled.h2`
  font-family: regular;
  font-size: 16px;
  color: #c5bdbd;
  @media all and (max-width: 640px) {
    font-size: 13px;
    &.third {
      margin-top: 10px;
    }
  }
`;
const BoldText = styled.span`
  font-family: medium;
  color: #191919;
  font-size: 18px;
  @media all and (max-width: 768px) {
    font-size: 15px;
  }
  @media all and (max-width: 420px) {
    font-size: 13px;
  }
`;
const FollowButton = styled.button`
  background-color: #fffbe7;
  border: 1px solid #eab600;
  color: #eab600;
  font-family: medium;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 35px;
`;
