import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GreenRatingBox, ProductPriceContainer } from "../includes/constants";
import HeaderPush from "../general/HeaderPush";
import {
	Link,
	useNavigate,
	useParams,
} from "react-router-dom";
import { shopConfig, wishlistConfig } from "../../apiConfig";
import Header from "../general/Header";
import Footer from "../general/Footer";
import { Context } from "../context/store";
import Toast from "../general/includes/Toast";
import PageLoader from "./PageLoader";
import EmptyPage from "./EmptyPage";

export default function FeaturedItem({
	// pageTitle,
	// headerPush,
	// reswidth,
	searchValue,
	setSearchValue,
}) {
	const { state } = useContext(Context);
	const [wishlistId, setWishlistId] = useState([]);
	const [products, setProducts] = useState([]);
	const params = useParams();
	const [isToast, setToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [itemPk, setItemPk] = useState(params.item_pk);
	// const location = useLocation();
	// const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setLoading] = useState(true);

	const navigate = useNavigate();

	function fetchFeatured() {
		shopConfig
			.get(
				`featured-item/${itemPk}`,
				state.user_data.is_verified && {
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				}
			)
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setProducts(data);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	}
	// fetch featured item);

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchFeatured();
	}, []);

	//add to wishlist
	const addToWishList = (product_pk, is_wishlisted) => {
		if (state.user_data.is_verified) {
			if (!is_wishlisted) {
				wishlistConfig
					.post(
						`add-to-wishlist/${product_pk}/`,
						{ quantity: 1 },
						{
							headers: {
								Authorization: `Bearer ${state.user_data.access_token}`,
							},
						}
					)
					.then((res) => {
						if (res.data.StatusCode === 6000) {
							fetchFeatured();
							setToast(true);
							setToastText(res.data.data.message);
						} else {
							setToast(true);
							setToastText(res.data.data.message);
						}
					})
					.catch((err) => {
						setToast(true);
						setToastText(err.message);
					});
			} else {
				wishlistConfig
					.post(`remove-from-wishlist/${product_pk}/`, null, {
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					})
					.then((res) => {
						const { StatusCode, data } = res.data;
						if (StatusCode === 6000) {
							fetchFeatured();
						}
						setToastText(data.message);
						setToast(true);
					})
					.catch((err) => {
						setToast(true);
						setToastText(err.message);
					});
			}
		} else {
			navigate("/login");
		}
	};

	const renderProducts = () => {
		return products.map((item, index) => (
			<ProductItem
				key={item.id}
				// to={{
				//   pathname: `/product/${item.id}`,
				// }}
			>
				<ProductImageBox
				// to={{
				// 	pathname: `/product/${item.product_code}`,
				// }}
				>
					<HeartIconBox
						onClick={() =>
							addToWishList(item.product_code, item.is_wishlisted)
						}
					>
						<Heart
							alt="Image" src={
								item.is_wishlisted ||
								wishlistId.includes(item.id)
									? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/addWishlist.svg"
									: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/heart.svg"
							}
						/>
					</HeartIconBox>
					<ProductImage
						to={{
							pathname: `/product/${item.product_code}`,
						}}
					>
						<img
							alt="Image" src={item.featured_image}
							
							className="img-class"
						/>
					</ProductImage>
				</ProductImageBox>
				<Link
					to={{
						pathname: `/product/${item.product_code}`,
					}}
				>
					<ProductBrand>Rolex</ProductBrand>
					<ProductName>{item.name}</ProductName>
					<ProductPriceContainer
						margin="10px 0 15px 0"
						actualPrice={item.actual_cost}
						displayPrice={item.display_cost}
					/>
					<GreenRatingBox
						rating={item.rating}
						review={item.review_count}
					/>
				</Link>
			</ProductItem>
		));
	};
	return (
		<>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush />
			<Toast isToast={isToast} setToast={setToast} text={toastText} />
			{!isLoading && products.length > 0 ? (
				<ProductContainer>
					<Wrapper className="wrapper">
						<ProductHeader>
							<Title className="search-sub-title">
								Featured products
							</Title>
						</ProductHeader>
						<ProductMiddle>
							{/* render product list  */}
							<ProductList>{renderProducts()}</ProductList>
						</ProductMiddle>
					</Wrapper>
				</ProductContainer>
			) : !isLoading && products.length === 0 ? (
				<EmptyPage text="No items found" />
			) : (
				<PageLoader />
			)}
			<Footer />
		</>
	);
}

const ProductContainer = styled.div`
	padding: 3% 0;
`;
const Wrapper = styled.div`
	@media all and (max-width: 640px) {
		width: 100%;
	}
`;
const ProductHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media all and (max-width: 640px) {
		width: 90%;
		margin: 0 auto;
	}
	@media all and (max-width: 420px) {
		align-items: flex-start;
		flex-direction: column;
	}
`;
const Title = styled.span`
	color: #747474;
	font-size: 30px;
	font-family: medium;
	@media all and (max-width: 1280px) {
		font-size: 20px;
	}
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
	@media all and (max-width: 420px) {
		width: 100%;
		/* margin-bottom: 10px; */
	}
	&.search-title {
		display: flex;
		@media all and (max-width: 420px) {
			flex-direction: column;
		}
	}
	&.search-sub-title {
		margin-left: 8px;
		color: #191919;
		@media all and (max-width: 420px) {
			margin-left: 0;
		}
	}
`;
// const Arrow = styled.img`
// 	display: inline-block;
// 	width: 100%;
// 	height: 100%;
// `;
// product list

const ProductList = styled.div`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	/* width: 74%; */
	/* justify-content: space-between; */
	/* margin-top: 30px; */
	/* @media all and (max-width: 768px) { */
	width: 100%;
	/* } */
`;
const ProductItem = styled.div`
	transition: 0.3s all ease-in-out;
	padding: 5px;
	border-radius: 5px;
	margin-right: 1.3%;
	width: 18%;
	margin-bottom: 25px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:hover {
		box-shadow: 0px 1px 12px -2px rgb(176 176 176);
	}
	&:nth-child(5n) {
		margin-right: 0;
	}
	@media all and (max-width: 1080px) {
		width: 24%;

		&:nth-child(5n) {
			margin-right: 1.3%;
		}
		&:nth-child(4n) {
			margin-right: 0%;
		}
	}
	@media all and (max-width: 768px) {
		width: 32%;

		&:nth-child(5n) {
			margin-right: 1.3%;
		}
		&:nth-child(4n) {
			margin-right: 1.3%;
		}
		&:nth-child(3n) {
			margin-right: 0%;
		}
	}
	@media all and (max-width: 640px) {
		width: 50%;

		&:nth-child(5n) {
			margin-right: 0;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		margin-right: 0;
	}

	@media all and (max-width: 640px) {
		/* width: 49.5%;
		padding: 0;
		margin: 0;
		border: 0.5px solid #f5f5f5; */
	}
`;
const ProductImageBox = styled.div`
	width: 200px;
	height: 200px;
	overflow: hidden;
	min-width: 100%;
	margin-bottom: 7px;
	img {
		max-width: 100%;
	}

	@media all and (max-width: 1280px) {
		width: 170px;
		height: 170px;
	}
	@media all and (max-width: 1080px) {
		width: 200px;
		height: 200px;
	}
	@media all and (max-width: 980px) {
		width: 155px;
		height: 155px;
	}
	@media all and (max-width: 768px) {
		width: 175px;
		height: 175px;
	}
	@media all and (max-width: 640px) {
		width: 250px;
		height: 250px;
	}
	@media all and (max-width: 520px) {
		width: 200px;
		height: 200px;
	}
	@media all and (max-width: 420px) {
		width: 170px;
		height: 170px;
	}
	@media all and (max-width: 360px) {
		width: 150px;
		height: 150px;
	}
`;
const HeartIconBox = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	cursor: pointer;
	right: 10px;
	top: 10px;
	z-index: 5;
`;
const Heart = styled.img`
	width: 100%;
	height: 100%;
	display: inline-block;
`;
const ProductImage = styled(Link)`
	width: 100%;
	height: 100%;
`;
const ProductBrand = styled.span`
	font-size: 13px;
	font-family: medium;

	color: #747474;
`;
const ProductName = styled.h3`
	font-size: 14px;
	font-family: regular;
	color: #191919;
	margin: 7px 0;
`;

// product footer

// const ProductFooter = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	margin-top: 30px;
// 	@media all and (max-width: 640px) {
// 		justify-content: center;
// 	}
// `;
// const FooterLeft = styled.div`
// 	@media all and (max-width: 640px) {
// 		display: none;
// 	}
// `;
// const ResultCount = styled.div`
// 	font-size: 14px;
// 	font-family: medium;
// 	color: #747474;
// `;
// const FooterRight = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const Button = styled.div`
// 	display: flex;
// 	align-items: center;
// 	cursor: pointer;
// `;
// const ButtonIcon = styled.div`
// 	width: 20px;
// 	height: 20px;
// `;
// const ButtonText = styled.div`
// 	font-size: 14px;
// 	font-family: medium;
// 	color: #747474;
// `;
// const PaginationButtons = styled.div`
// 	display: flex;
// 	align-items: center;
// 	margin: 0 15px;
// `;
// const PaginationButton = styled.div`
// 	background-color: ${({ nextPage }) =>
// 		nextPage ? "#EAC901" : "transparent"};
// 	border-radius: 5px;
// 	width: 30px;
// 	height: 30px;
// 	display: flex;
// 	align-items: center;
// 	cursor: pointer;
// 	justify-content: center;
// 	margin-right: 10px;
// 	&:last-child {
// 		margin-right: 0;
// 	}
// `;
// const PaginationButtonText = styled.div`
// 	font-size: 14px;
// 	font-family: medium;
// 	color: #747474;
// `;

// updated filter

const ProductMiddle = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	justify-content: space-between;
	margin-top: 3%;
`;
