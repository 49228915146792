import React from "react";
import styled from "styled-components";

export default function FlagTooltip({ setFocus }) {
  return (
    <Container>
      <SearchBox>
        <IconBox>
          <Image   className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/search.svg" />
        </IconBox>
        <Input placeholder="Search Country" />
      </SearchBox>
      <CountryBox>
        <CountryItem onClick={() => setFocus("phone")}>
          <Flag>
            <Image  
              className="img-class"
              alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/flag.png"
            />
          </Flag>
          <Text>India (+91)</Text>
        </CountryItem>
      </CountryBox>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  background: #fff;
  width: 320px;
  height: 350px;
  top:100px;
  left: 0;
  border-radius: 5px;
  -webkit-box-shadow: -1px 10px 23px -7px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: -1px 10px 23px -7px rgba(0, 0, 0, 0.61);
  box-shadow: -1px 10px 23px -7px rgba(0, 0, 0, 0.61);
  padding: 20px;
  z-index: 10;
  @media all and (max-width: 420px) {
    height: 280px;
    width: 300px;
  }
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #f2efe9;
`;
const IconBox = styled.div`
  width: 25px;
  margin-right: 10px;
`;
const Image = styled.img``;
const Input = styled.input`
  font-size: 18px;
  font-family: "medium";
  color: #747474;
  @media all and (max-width: 420px) {
    font-size: 16px;
  }
`;
const CountryBox = styled.div`
  margin-top: 20px;
`;
const CountryItem = styled.div`
  display: flex;
  align-items: center;
  background: #fff7ea;
  border-radius: 5px;
  padding: 7px;
`;
const Flag = styled.div`
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
`;
const Text = styled.span`
  font-size: 20px;
  font-family: "medium";
  color: #010101;
  @media all and (max-width: 420px) {
    font-size: 17px;
  }
`;
