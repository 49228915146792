import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ordersConfig } from "../../../../apiConfig";
import { Context } from "../../../context/store";
import ButtonLoader from "../../../general/includes/ButtonLoader";
import { useNavigate } from "react-router-dom";

export default function AddToCart({
  isCartModal,
  setCartModal,
  price,
  productId,
  setToastText,
  setToast,
  // productName,
  counterVal,
  setCounterVal,
  selectedAttr,
  setRefresh,
  setSelectedAttr,
  productAttr,
}) {
  const { state } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const navigate = useNavigate();

  //add to cart
  const addToCart = () => {
    if (state.user_data.is_verified) {
      if (productAttr === null || selectedAttr) {
        setError(false);
        setLoading(true);
        ordersConfig
          .post(
            `cart/`,
            { quantity: counterVal, product_code: productId },
            {
              headers: {
                Authorization: `Bearer ${state.user_data.access_token}`,
              },
            }
          )
          .then((res) => {
            const { StatusCode, data } = res.data;
            setToast(true);
            setToastText(data.message);
            if (StatusCode === 6000) {
              setRefresh(Math.random());
              setLoading(false);
              setCartModal(false);
            } else {
              setLoading(false);
              setCartModal(false);
            }
          })
          .catch((err) => {
            setToast(true);
            setToastText(err.message);
            setLoading(false);
            setCartModal(false);
          });
      } else {
        setError(true);
      }
    } else {
      navigate("/login");
    }
  };

  // handle attribute maping
  function handleAttr() {
    const data = productAttr;
    const attList = data[Object.keys(data)[0]].map((item, index) => {
      return (
        <SizeBox
          key={index}
          onClick={() => setSelectedAttr(item)}
          style={{
            background: selectedAttr == item ? "#fffae9" : "#fff",
            cursor: "pointer",
          }}
        >
          <Text
            style={{
              color: selectedAttr == item ? "#eab600" : "#747474",
            }}
          >
            {item}
          </Text>
        </SizeBox>
      );
    });
    return attList;
  }

  return (
    <ModalContainer isCartModal={isCartModal}>
      <ModalFade
        onClick={() => setCartModal(false)}
        isCartModal={isCartModal}
      ></ModalFade>
      <ModalBox>
        <Top>
          <TitleBox>
            <Cart>
              <Image
                className="img-class"
                alt="Image"
                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/cart.svg"
              />
            </Cart>
            <Title>Add to Cart</Title>
          </TitleBox>
          <Close onClick={() => setCartModal(false)}>
            <Image
              className="img-class"
              alt="Image"
              src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
            />
          </Close>
        </Top>

        <Middle>
          {productAttr && (
            <Size>
              {productAttr && <SizeTitle>{Object.keys(productAttr)}</SizeTitle>}
              <SizeBoxs>{handleAttr()}</SizeBoxs>
              {isError && (
                <ErrorText>Select {Object.keys(productAttr)}</ErrorText>
              )}
            </Size>
          )}
          <Quantity>
            <SizeTitle>Select Quantity</SizeTitle>
            <Counter>
              <Button
                className="left"
                onClick={() =>
                  counterVal === 1 ? 1 : setCounterVal(counterVal - 1)
                }
              >
                <Image
                  className="img-class"
                  alt="Image"
                  src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-left-gold.svg"
                />
              </Button>
              <Number>{counterVal}</Number>
              <Button onClick={() => setCounterVal(counterVal + 1)}>
                <Image
                  className="img-class"
                  alt="Image"
                  src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-left-gold.svg"
                />
              </Button>
            </Counter>
          </Quantity>
          <Price>
            <SizeTitle>Total Price</SizeTitle>
            <Title>₹{price * counterVal}</Title>
          </Price>
          <div
            style={{
              textAlign: "right",
              width: "100%",
            }}
          >
            {!loading ? (
              <MainButton onClick={addToCart}>Add to Cart</MainButton>
            ) : (
              <MainButton className="loader" onClick={addToCart}>
                <ButtonLoader />
              </MainButton>
            )}
          </div>
        </Middle>
      </ModalBox>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out;
  transform: scale(1.1);
  background: transparent;
  transform: ${({ isCartModal }) => (isCartModal ? "scale(1)" : "scale(0)")};
`;
const ModalFade = styled.div`
  background: rgba(0, 0, 0, 0.16);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ isCartModal }) => (isCartModal ? "block" : "none")};
`;
const ModalBox = styled.div`
  background: #fffcf7;
  padding: 40px;
  border-radius: 5px;
  width: 600px;
  position: relative;
  z-index: 1001;
  @media all and (max-width: 768px) {
    width: 500px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
    padding: 20px;
  }
  @media all and (max-width: 420px) {
    width: 300px;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #e7e7e7;
  padding-bottom: 20px;
  margin-bottom: 30px;
`;
const Title = styled.h3`
  font-size: 22px;
  font-family: medium;
  color: #003c3c;
  @media all and (max-width: 640px) {
    font-size: 18px;
  }
`;
const Close = styled.div`
  width: 15px;
  cursor: pointer;
`;
const Image = styled.img``;
// const Description = styled.p`
// 	font-size: 18px;
// 	font-family: regular;
// 	color: #003c3c;
// 	line-height: 25px;
// 	@media all and (max-width: 640px) {
// 		font-size: 15px;
// 	}
// `;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;
const Cart = styled.div`
  width: 23px;
  margin-right: 7px;
`;
const Middle = styled.div``;
const Size = styled.div`
  padding: 20px 10px;
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
`;
const SizeTitle = styled.h3`
  font-size: 20px;
  font-family: "medium";
  color: #747474;
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;
const SizeBoxs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;
const SizeBox = styled.div`
  width: 100px;
  height: 28px;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  @media all and (max-width: 640px) {
    width: 85px;
  }
  @media all and (max-width: 420px) {
    width: 65px;
  }
`;
const Text = styled.span`
  font-size: 16px;
  font-family: "medium";
  color: #747474;
  height: 13px;
  @media all and (max-width: 640px) {
    font-size: 14px;
    height: 11px;
  }
  @media all and (max-width: 420px) {
    font-size: 12px;
  }
`;
const Quantity = styled.div`
  padding: 20px 10px;
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Counter = styled.div`
  display: flex;
  align-items: center;
`;
const Button = styled.button`
  height: 28px;
  width: 28px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  &.left {
    transform: rotate(180deg);
  }
  @media all and (max-width: 420px) {
    padding: 7px;
  }
`;
const Number = styled.span`
  background: #fffae9;
  border-radius: 5px;
  border: 1px solid #eab600;
  padding: 3px 35px;
  font-size: 20px;
  color: #eab600;
  height: 28px;
  margin: 0 10px;
  font-family: medium;
  @media all and (max-width: 640px) {
    padding: 6px 25px;
    font-size: 16px;
  }
  @media all and (max-width: 420px) {
    margin: 0 5px;
    padding: 6px 18px;
    font-size: 14px;
  }
`;
const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  background: #fff;
  margin-bottom: 20px;
`;

const MainButton = styled.span`
  font-size: 16px;
  font-family: medium;
  width: 45%;
  color: #747474;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
  padding: 10px 0;
  &.loader {
    padding: 0;
  }
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;

const ErrorText = styled.span`
  font-size: 13px;
  font-family: "regular";
  color: #e93a22;
`;
