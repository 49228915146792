import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "../../general/Header";
import HeaderPush from "../../general/HeaderPush";
import { paymentsConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import ButtonLoader from "../../general/includes/ButtonLoader";

export default function PurchaseCoin() {
	const [coins, setCoins] = useState("");
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState("Enter your amount");

	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	// handle payment url
	function handlePayment() {
		// if (coins >= 100) {
		setLoading(true);
		paymentsConfig
			.post(
				"buy-coins/",
				{
					// purchase_type: "coin_purchase",
					coins: coins,
				},
				{
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				}
			)
			.then((res) => {
				if (res.data.StatusCode === 6000) {
					window.open(res.data.data.payment_url, "_self");
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
		// } else {
		// 	setErrMsg("Minimum 100 coins required");
		// }
	}

	return (
		<>
			<Header />
			<HeaderPush />
			<Container>
				<Wrapper>
					<Top>
						<Coin>
							<img
								
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
								className="img-class"
							/>
						</Coin>
						<Title>Purchase Coin</Title>
						<Span>1 Coin represents 1 Rupee</Span>
					</Top>
					<Bottom>
						<CoinCount
							placeholder="0"
							ref={inputRef}
							autoFocus={true}
							value={coins}
							type={"number"}
							onChange={(e) =>
								setCoins(e.target.value < 0 ? 0 : e.target.value)
							}
						/>
						<Span
							style={{
								margin: "7px 0",
								color:
									errMsg === "Enter your amount"
										? "#747474"
										: " #E02B1D",
							}}
						>
							{errMsg}
						</Span>
						<InputBox>
							<IconBox>
								<img
									
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/ticket.svg"
									className="img-class"
								/>
							</IconBox>
							<Input
								placeholder="Have a coupon code? Insert"
								autoFocus
							/>
						</InputBox>
					</Bottom>
				</Wrapper>
				<Button onClick={handlePayment}>
					{isLoading ? (
						<ButtonLoader />
					) : (
						<ButtonText>Purchase Coin</ButtonText>
					)}
				</Button>
			</Container>
		</>
	);
}

const Container = styled.div`
	padding: 5% 0;
	height: calc(100vh - 80px);
`;
const Wrapper = styled.div`
	width: 60%;
	margin: 0 auto;
	max-width: 1350px;
	background: #fff7ea;
	border-radius: 6px;
	padding: 50px;
	margin-bottom: 40px;
	@media all and (max-width: 1280px) {
		width: 75%;
	}
	@media all and (max-width: 420px) {
		width: 90%;
		margin-bottom: 20px;
	}
`;

const Top = styled.div`
	text-align: center;
`;
const Coin = styled.div`
	width: 70px;
	height: 70px;
	margin: 0 auto;
`;
const Title = styled.h3`
	font-size: 20px;
	font-family: medium;
	color: #191919;
	margin-bottom: 10px;
`;
const Span = styled.span`
	font-size: 16px;
	font-family: regular;
	color: #747474;
`;
const Bottom = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 60px;
`;
const CoinCount = styled.input`
	min-width: 15px;
	max-width: 150px;
	color: #191919;
	font-size: 35px;
	font-family: "medium";
	text-align: center;
`;
const InputBox = styled.div`
	display: flex;
	border: 1px solid #feda00;
	align-items: center;
`;
const IconBox = styled.div`
	margin-right: 10px;
	padding: 10px;
	background: #feda00;
	@media all and (max-width: 640px) {
		padding: 7px;
	}
`;
const Input = styled.input`
	/* text-transform: uppercase; */
`;
const Button = styled.span`
	height: 40px;
	width: 13%;
	background: #feda00;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	@media all and (max-width: 980px) {
		width: 20%;
	}
	@media all and (max-width: 640px) {
		width: 35%;
	}
`;
const ButtonText = styled.div`
	font-size: 16px;
	font-family: regular;
	color: #191919;
	@media all and (max-width: 980px) {
		font-size: 14px;
	}
`;
