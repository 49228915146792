import React, { useEffect } from "react";
import styled from "styled-components";

export default function Cancellation() {
	//when onload the page ,scroll top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Wrapper>
				<Title>Cancellation & Refund Policy</Title>
				<Content>
					Thank you for choosing to shop with us at Oneruppee. We
					appreciate your business and are committed to providing you
					with quality products and services. If for any reason you
					need to cancel your order or seek a refund, we are here to
					assist you. Please read our Cancellation & Refund Policy
					below for more information.
				</Content>
				<SubTitle>Order Cancellation</SubTitle>
				<Content>
					You have the right to cancel your order within 24 hours of
					placing it. If you decide to cancel your order, please
					contact us as soon as possible at info@oneruppee.com with
					your order details and reason for cancellation. We will
					process your cancellation and issue a refund as per our
					refund policy below.
				</Content>
				<SubTitle>Refund Policy</SubTitle>
				<Content>
					If you are not completely satisfied with your purchase, you
					may request a refund within 7 days of receiving your order.
					To request a refund, please contact us at info@oneruppee.com
					with your order details and reason for requesting a refund.
				</Content>
				<SubTitle>
					Please note that the following conditions must be met for a
					refund to be processed:
				</SubTitle>
				<List>
					<ListItem>
						The item must be in its original packaging and in the
						same condition as when you received it.
					</ListItem>
					<ListItem>
						The item must be returned within 14 days of your request
						for a refund.
					</ListItem>
					<ListItem>Shipping charges are non-refundable.</ListItem>
				</List>
				<Content>
					Once we receive the returned item, we will inspect it and
					notify you of the status of your refund. If your return is
					approved, we will initiate a refund to your original method
					of payment. Refunds typically take 5-7 business days to
					process.
				</Content>
				<Content>
					If your item arrived damaged, please contact us immediately
					at info@oneruppee.com with photos of the damage and your
					order details. We will work with you to resolve the issue
					and arrange for a replacement or refund as necessary.
				</Content>
				<Content>
					Please note that we reserve the right to refuse a refund or
					exchange if the item is not returned in its original
					condition or if it does not meet our return policy
					guidelines.
				</Content>
				<SubTitle>Contact Us</SubTitle>
				<Content>
					If you have any questions or concerns about our Cancellation
					& Refund Policy, please do not hesitate to contact us at
					info@oneruppee.com. We are here to assist you and ensure
					that you are completely satisfied with your shopping
					experience at Oneruppee.
				</Content>
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	padding: 3% 0;
	min-height: calc(100vh - 420px);
	@media all and (max-width: 640px) {
		padding: 8% 0;
	}
`;
const Wrapper = styled.div`
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	text-align: left;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #191919;
	padding-bottom: 20px;
	border-bottom: 1px solid #c4c4c4;
	margin-bottom: 30px;
	text-align: center;
	@media all and (max-width: 640px) {
		font-size: 19px;
		margin-bottom: 25px;
		padding-bottom: 15px;
	}
`;
const Content = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	line-height: 1.2em;
	letter-spacing: 0.3px;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 25px;
	}
`;
const SubTitle = styled.h3`
	font-size: 20px;
	font-family: regular;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;
const List = styled.ul`
	padding-left: 3%;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		margin-bottom: 25px;
	}
`;
const ListItem = styled.li`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	list-style: disc;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 15px;
	}
`;
