import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { GreenRatingBox, ProductPriceContainer } from "../includes/constants";
import HeaderPush from "../general/HeaderPush";
import {
  Link,
  // Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { shopConfig, wishlistConfig } from "../../apiConfig";
import Header from "../general/Header";
import Footer from "../general/Footer";
import { Context } from "../context/store";
import Toast from "../general/includes/Toast";
import PageLoader from "./PageLoader";
import EmptyPage from "./EmptyPage";
import FilterModal from "../includes/modal/filter/Filter";
import CategoryHeader from "../general/CategoryHeader";
import PaginationLoader from "../general/includes/PaginationLoader";
import axios from "axios";

export default function Products({
  pageTitle,
  // headerPush,
  componentLoc,
  reswidth,
  searchValue,
  setSearchValue,
  // searchRefresh,
  pageNum,
  setPageNum,
}) {
  const { state } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [paginationData, setPagination] = useState({});
  const [isToast, setToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isNestedLoading, setNestedLoading] = useState(true);
  const [isRefresh, setRefresh] = useState(0);
  const params = useParams();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [priceFilterId, setPriceFilterId] = useState(null);
  const [ratingFilterId, setRatingFilterId] = useState(null);
  const location = useLocation();
  const [categoryFilterId, setCategoryFilterId] = useState(
    location.state?.category_name
  );
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [nestedCategoryId, setNestedCategoryId] = useState("");
  const [outerSubCategory, setOuterSubCategory] = useState([]);
  const [isFilterApply, setIsFilterApply] = useState(Math.random());
  const [categories, setCategories] = useState([]);

  const [isModal, setModal] = useState(false);
  const listInnerRef = useRef();
  const navigate = useNavigate();
  //   const [pageNum, setPageNum] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isNetworkFree, setIsNetworkFree] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const randomString = Math.random().toString(36).substring(0.2);
  const randomString2 = Math.random().toString(36).substring(0.1);

  // disable html scroll when modal is active
  useEffect(() => {
    if (isModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isModal]);

  // scroll to top when page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // for filter maping
  const priceList = [
    {
      id: 1,
      text: "₹0 to ₹499",
      initial: 0,
      end: 499,
    },
    {
      id: 2,
      text: "₹499 to ₹1999",
      initial: 499,
      end: 1999,
    },
    {
      id: 3,
      text: "₹1999 to ₹2999",
      initial: 1999,
      end: 2999,
    },
    {
      id: 4,
      text: "₹2999 to ₹3999",
      initial: 2999,
      end: 3999,
    },
    {
      id: 5,
      text: "₹4999 & above",
      initial: 4999,
      end: "",
    },
  ];
  const ratingList = [
    {
      id: 1,
      text: "2.0 and above",
      rating: 2.0,
    },
    {
      id: 2,
      text: "3.0 and above",
      rating: 2.0,
    },
    {
      id: 3,
      text: "3.5 and above",
      rating: 2.0,
    },
    {
      id: 4,
      text: "4.0 and above",
      rating: 2.0,
    },
  ];

  const productUrl = () => {
    const queryParams = [];

    if (location?.state?.name) {
      queryParams.push(`name=${location.state?.name}`);
    } else if (searchValue) {
      queryParams.push(`name=${searchValue}`);
    }

    if (
      location.state?.category_id ||
      categoryFilterId ||
      params?.filter_details !== "all-products"
    ) {
      queryParams.push(
        `category=${
          location.state?.category_name ||
          categoryFilterId ||
          params?.filter_details
        }`
      );
    }

    if (priceFilterId) {
      const priceParam = `initial_range=${
        priceList[priceFilterId - 1].initial
      }&end_range=${priceList[priceFilterId - 1].end}`;
      queryParams.push(priceParam);
    }

    if (ratingFilterId) {
      queryParams.push(`rating=${ratingList[ratingFilterId - 1].rating}`);
    }

    if (subCategory) {
      queryParams.push(`sub_category=${nestedCategoryId || subCategoryId}`);
    }

    if ((queryParams.length > 0 && location.state) || categoryFilterId) {
      return `products/all?${queryParams.join("&")}`;
    } else {
      return "products/all";
    }
  };

  // clearing subcategories when main category is changed
  useEffect(() => {
    setSubCategory("");
    setSubCategoryId("");
    setNestedCategoryId("");
  }, [categoryFilterId, location.state]);

  // fetch all products
  function fetchProducts() {
    setSearchTitle(
      searchValue && location.state?.name
        ? location.state?.name
        : location.state?.name && !searchValue
        ? location.state?.name
        : searchValue
    );
    if (location?.state?.url) {
      axios
        .get(
          location?.state?.url,
          state.user_data.is_verified && {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          }
        )
        .then((res) => {
          const { StatusCode, data, pagination_data } = res.data;
          if (StatusCode === 6000) {
            setPagination(pagination_data);
            // let concat = products.concat(data);
            setProducts(data);
            // setLoading(false);
            setPageNum(pagination_data.current_page);
            setTotalPage(pagination_data.total_pages);
          } else {
            setLoading(false);
          }
          setNestedLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setNestedLoading(false);
        });
    } else {
      shopConfig
        .get(
          productUrl(),
          state.user_data.is_verified && {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          }
        )
        .then((res) => {
          const { StatusCode, data, pagination_data } = res.data;
          if (StatusCode === 6000) {
            setPagination(pagination_data);
            // let concat = products.concat(data);
            setProducts(data);
            // setLoading(false);
            setPageNum(pagination_data.current_page);
            setTotalPage(pagination_data.total_pages);
          } else {
            setLoading(false);
          }
          setNestedLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setNestedLoading(false);
        });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (products.length > 0) {
        setNestedLoading(true);
      }

      // Fetch products
      await fetchProducts();

      try {
        const res = await shopConfig.get("categories/");
        const { StatusCode, data } = res.data;

        if (StatusCode === 6000) {
          setCategories(data);

          // Filter categories and set filterCategory
          const filteredCategory = data.filter((item) => {
            return (
              location.state?.category_name === item.slug ||
              params?.filter_details === item.slug
            );
          });

          if (filteredCategory.length > 0) {
            setOuterSubCategory(filteredCategory[0].sub_categories);
          }

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [
    isRefresh,
    priceFilterId,
    ratingFilterId,
    categoryFilterId,
    isFilterApply,
    subCategory,
    location.state?.category_name,
    params.filter_details,
    // categoryFilterId
  ]);

  //
  useLayoutEffect(() => {
    //set outer sub category
    if (!isLoading) {
      categories.filter((item) => {
        if (location.state?.category_name === item.slug) {
          let filterCategory = item.sub_categories;
          setOuterSubCategory(filterCategory);
        }
      });
    }
    return () => {
      setSearchValue("");
    };
  }, [isLoading]);

  //add to wishlist
  const addToWishList = (product_pk, is_wishlisted) => {
    if (state.user_data.is_verified) {
      if (!is_wishlisted) {
        wishlistConfig
          .post(
            `add-to-wishlist/${product_pk}/`,
            { quantity: 1 },
            {
              headers: {
                Authorization: `Bearer ${state.user_data.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.StatusCode === 6000) {
              fetchProducts();
              setToast(true);
              setToastText(res.data.data.message);
            } else {
              setToast(true);
              setToastText(res.data.data.message);
            }
          })
          .catch((err) => {
            setToast(true);
            setToastText(err.message);
          });
      } else {
        wishlistConfig
          .post(`remove-from-wishlist/${product_pk}/`, null, {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          })
          .then((res) => {
            const { StatusCode, data } = res.data;
            if (StatusCode === 6000) {
              fetchProducts();
            }
            setToastText(data.message);
            setToast(true);
          })
          .catch((err) => {
            setToast(true);
            setToastText(err.message);
          });
      }
    } else {
      navigate("/login");
    }
  };

  const paginationRequest = () => {
    
    if (paginationData.current_page > 0 && paginationData.has_next_page && isNetworkFree) {
      
      setIsNetworkFree(false);
      setPaginationLoading(true);
      shopConfig
        .get(
          productUrl() === "products/all"
            ? `${productUrl()}?page=${paginationData?.next_page_number}`
            : `${productUrl()}&page=${paginationData?.next_page_number}`
        )
        .then((res) => {
          const { StatusCode, data, pagination_data } = res.data;
          if (StatusCode === 6000) {
            setPagination(pagination_data);
            let concat = products.concat(data);
            setProducts(concat);
            setPaginationLoading(false);
            setPageNum(pagination_data.current_page);
            setTotalPage(pagination_data.total_pages);
            setPageNum(pagination_data?.next_page_number);
          } else {
            setPaginationLoading(false);
          }
          setIsNetworkFree(true);
        })
        .catch((err) => {
          setPaginationLoading(false);
          setIsNetworkFree(true);
        });
    }else{
    }
  };

  useEffect(()=>{
	if(componentLoc === "home"){
		paginationRequest();
	}
  },[pageNum])

  const onScroll = () => {
    if (listInnerRef.current && componentLoc !== "home") {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const threshold = 100; // Adjust this threshold as needed

      if (scrollHeight - scrollTop - threshold <= clientHeight) {
        // Trigger your pagination here.
        paginationRequest();
      }
    }
  };

  //product items
  const renderProducts = () => {
    return products.map((item, index) => (
      <ProductItem key={index}>
        <Helmet>
          <meta
            property="og:image"
            content="https://example.com/my-image.jpg"
          />
        </Helmet>
        <HeartIconBox
          onClick={() => addToWishList(item.product_code, item.is_wishlisted)}
          // onClick={() => handleShare(item.featured_image)}
        >
          <Heart
            alt="Image"
            src={
              item.is_wishlisted
                ? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/addWishlist.svg"
                : "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/heart.svg"
            }
          />
        </HeartIconBox>
        <ProductImageBox
          to={{
            pathname: `/product/${item.id}?pid=${randomString2}&pn=${item.name}&pb=${item.brand}&pr-nds=${randomString}`,
          }}
        >
          <ProductImage alt="Image" src={item.featured_image} />
        </ProductImageBox>
        <ProductBottom
          className="productBottom"
          to={{
            pathname: `/product/${item.id}?pid=${randomString2}&pn=${item.name}&pb=${item.brand}&pr-nds=${randomString}`,
          }}
        >
          <ProductBrand>{item.brand}</ProductBrand>
          <ProductName>{item.name}</ProductName>
          <View>
            <ProductPriceContainer
              margin="10px 0 15px"
              actualPrice={item.actual_cost}
              displayPrice={item.display_cost}
            />
          </View>
          <GreenRatingBox
            rating={item.average_product_rating}
            review={item.review_count}
          />
        </ProductBottom>
      </ProductItem>
    ));
  };

  // main component
  return (
    <div
      onScroll={onScroll}
      ref={listInnerRef}
      style={{
        overflowY: componentLoc === "home" ? "unset" : "auto",
        maxHeight: componentLoc === "home" ? "unset" : "99.99vh",
      }}
    >
      {/* <Helmet>
				<meta charSet="utf-8" />
				<title>One ruppee | Shop now</title>
			</Helmet> */}
      {componentLoc !== "home" && (
        <Header
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isRefresh={isRefresh}
          setRefresh={setRefresh}
        />
      )}
      {componentLoc !== "home" && <HeaderPush />}
      <Toast isToast={isToast} setToast={setToast} text={toastText} />
      <FilterModal
        isModal={isModal}
        setModal={setModal}
        categories={categories}
        setCategoryFilterId={setCategoryFilterId}
        setSelectedCategory={setSelectedCategory}
        categoryFilterId={categoryFilterId}
        setRatingFilterId={setRatingFilterId}
        setPriceFilterId={setPriceFilterId}
        priceList={priceList}
        ratingList={ratingList}
        priceFilterId={priceFilterId}
        ratingFilterId={ratingFilterId}
        setIsFilterApply={setIsFilterApply}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
        outerSubCategory={outerSubCategory}
        setOuterSubCategory={setOuterSubCategory}
        subCategoryId={subCategoryId}
        setSubCategoryId={setSubCategoryId}
        nestedCategoryId={nestedCategoryId}
        setNestedCategoryId={setNestedCategoryId}
      />
      {!isLoading && componentLoc !== "home" && (
        <CategoryHeader categories={categories} />
      )}
      {!isLoading ? (
        <ProductContainer>
          <Wrapper className="wrapper">
            <ProductHeader>
              {pageTitle === "seller" ||
              (params.filter_details == "all-products" && searchTitle === "") ||
              componentLoc === "home" ? (
                <Title className="sub-title">All Products</Title>
              ) : (
                <Title className="search-title">
                  {searchTitle &&
                    // `results for ${(
                    // 	<Title className="search-sub-title">
                    // 		{searchValue}
                    // 	</Title>
                    // )}`}
                    `Showing results for${" "}${searchTitle}`}
                </Title>
              )}
              <Title className="item-count" style={{ marginLeft: 0 }}>
                {products.length} items
              </Title>
            </ProductHeader>
            <FilterHead>
              <SelectedBoxs>
                <MainFilterButton>
                  <FilterIcon>
                    <Image
                      className="img-class"
                      alt="Image"
                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/filter.svg"
                    />
                  </FilterIcon>
                  <FilterButtonText>FILTER</FilterButtonText>
                </MainFilterButton>
                <MobileFilterButton onClick={() => setModal(true)}>
                  <FilterIcon>
                    <Image
                      className="img-class"
                      alt="Image"
                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/filter.svg"
                    />
                  </FilterIcon>
                  <FilterButtonText>FILTER</FilterButtonText>
                </MobileFilterButton>
                {selectedCategory ||
                  (params.filter_details && (
                    <SelectedBox>
                      <SelectedText>
                        Category : {selectedCategory || params.filter_details}
                      </SelectedText>
                    </SelectedBox>
                  ))}
                {subCategory && (
                  <SelectedBox>
                    <SelectedText>{subCategory}</SelectedText>
                    <FilterCloseIcon
                      onClick={() => (
                        setSubCategory(""),
                        setSubCategoryId(""),
                        setNestedCategoryId("")
                      )}
                    >
                      <Image
                        className="img-class"
                        alt="Image"
                        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
                      />
                    </FilterCloseIcon>
                  </SelectedBox>
                )}
                {priceFilterId && (
                  <SelectedBox>
                    <SelectedText>
                      Price : {priceList[priceFilterId - 1].text}
                    </SelectedText>
                    <FilterCloseIcon onClick={() => setPriceFilterId(null)}>
                      <Image
                        className="img-class"
                        alt="Image"
                        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
                      />
                    </FilterCloseIcon>
                  </SelectedBox>
                )}
                {ratingFilterId && (
                  <SelectedBox>
                    <SelectedText>
                      Rating : {ratingList[ratingFilterId - 1].text}
                    </SelectedText>
                    <FilterCloseIcon onClick={() => setRatingFilterId(null)}>
                      <Image
                        className="img-class"
                        alt="Image"
                        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
                      />
                    </FilterCloseIcon>
                  </SelectedBox>
                )}
                {
                  // categoryFilterId ||
                  subCategory || ratingFilterId || priceFilterId ? (
                    <ClearButton
                      onClick={() => (
                        // setCategoryFilterId(""),
                        setRatingFilterId(null),
                        setPriceFilterId(null),
                        setSubCategory(""),
                        setSubCategoryId(""),
                        setNestedCategoryId(""),
                        setRefresh(Math.random())
                      )}
                    >
                      Clear All
                    </ClearButton>
                  ) : (
                    ""
                  )
                }
              </SelectedBoxs>
            </FilterHead>

            <ProductMiddle>
              {/* filter aside */}
              <ProductFilterAside>
                {/* Category */}
                {outerSubCategory.length > 0 && (
                  <LeftFilterBox>
                    <FilterTop>
                      <FilterTitle>Category</FilterTitle>
                    </FilterTop>

                    <FilterItemBox>
                      {outerSubCategory.map((item, index) => {
                        return (
                          item.parent === null && (
                            <FilterItem className="sub-cat" key={index}>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <AddIconBox
                                  onClick={() => {
                                    setSubCategory(
                                      subCategory ? "" : item.title
                                    );
                                    setNestedCategoryId(
                                      subCategory ? "" : subCategoryId
                                    );
                                    setSubCategoryId(
                                      subCategoryId ? "" : item.slug
                                    );
                                  }}
                                >
                                  {item.slug !== subCategoryId &&
                                    item.id !== nestedCategoryId && (
                                      <AddIcon>
                                        <img
                                          alt="Image"
                                          src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/vertical-line.svg"
                                          className="img-class"
                                        />
                                      </AddIcon>
                                    )}
                                  <AddIcon>
                                    <img
                                      alt="Image"
                                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/horizontal-line.svg"
                                      className="img-class"
                                    />
                                  </AddIcon>
                                </AddIconBox>
                                <CheckText
                                  onClick={() => {
                                    setSubCategory(
                                      subCategory ? "" : item.title
                                    );
                                    setNestedCategoryId(
                                      subCategory ? "" : subCategoryId
                                    );
                                    setSubCategoryId(
                                      subCategoryId ? "" : item.id
                                    );
                                  }}
                                >
                                  {item.title}
                                </CheckText>
                              </div>
                              {subCategory && (
                                <SubCategoryBox>
                                  {outerSubCategory.map((sub_item, index) => {
                                    return (
                                      subCategoryId === sub_item.parent &&
                                      item.id === sub_item.parent && (
                                        <SubCategoryItem
                                          key={index}
                                          onClick={() => {
                                            setSubCategory(
                                              subCategory === item.title
                                                ? sub_item.title
                                                : item.title
                                            );
                                            setNestedCategoryId(sub_item.id);
                                          }}
                                        >
                                          {nestedCategoryId === sub_item.id ? (
                                            <Check
                                              style={{
                                                border: "none",
                                              }}
                                            >
                                              <Image
                                                className="img-class"
                                                alt="Image"
                                                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
                                              />
                                            </Check>
                                          ) : (
                                            <Check></Check>
                                          )}

                                          <CheckText className="sub-cat">
                                            {sub_item.title}
                                          </CheckText>
                                        </SubCategoryItem>
                                      )
                                    );
                                  })}
                                </SubCategoryBox>
                              )}
                            </FilterItem>
                          )
                        );
                      })}
                    </FilterItemBox>
                  </LeftFilterBox>
                )}
                {/* Price */}
                <LeftFilterBox>
                  <FilterTop>
                    <FilterTitle>Price</FilterTitle>
                  </FilterTop>
                  <FilterItemBox>
                    {priceList.map((item, index) => (
                      <FilterItem
                        key={index}
                        onClick={() => setPriceFilterId(item.id)}
                      >
                        {item.id === priceFilterId ? (
                          <Check
                            style={{
                              border: "none",
                            }}
                          >
                            <Image
                              className="img-class"
                              alt="Image"
                              src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
                            />
                          </Check>
                        ) : (
                          <Check></Check>
                        )}
                        <CheckText htmlFor="Topwear">{item.text}</CheckText>
                      </FilterItem>
                    ))}
                  </FilterItemBox>
                </LeftFilterBox>
                {/* rating*/}
                <LeftFilterBox>
                  <FilterTop>
                    <FilterTitle>Rating</FilterTitle>
                  </FilterTop>
                  <FilterItemBox>
                    {ratingList.map((item, index) => (
                      <FilterItem
                        key={index}
                        onClick={() => setRatingFilterId(item.id)}
                      >
                        {item.id === ratingFilterId ? (
                          <Check
                            style={{
                              border: "none",
                            }}
                          >
                            <Image
                              className="img-class"
                              alt="Image"
                              src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
                            />
                          </Check>
                        ) : (
                          <Check></Check>
                        )}
                        <CheckText htmlFor="Topwear">{item.text}</CheckText>
                      </FilterItem>
                    ))}
                  </FilterItemBox>
                </LeftFilterBox>
                {/* home page catogry */}
                {categories.length > 0 && (
                  <LeftFilterBox>
                    <FilterTop>
                      <FilterTitle>Category</FilterTitle>
                    </FilterTop>

                    <FilterItemBox>
                      {categories.map((item, index) => {
                        return (
                          <FilterItem className="sub-cat" key={index}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <AddIconBox
                                onClick={() => {
                                  setCategoryFilterId(item.slug);
                                }}
                              >
                                {item.slug !== categoryFilterId && (
                                  <AddIcon>
                                    <img
                                      alt="Image"
                                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/vertical-line.svg"
                                      className="img-class"
                                    />
                                  </AddIcon>
                                )}
                                <AddIcon>
                                  <img
                                    alt="Image"
                                    src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/horizontal-line.svg"
                                    className="img-class"
                                  />
                                </AddIcon>
                              </AddIconBox>
                              <CheckText
                                onClick={() => {
                                  setCategoryFilterId(item.slug);
                                }}
                              >
                                {item.title}
                              </CheckText>
                            </div>
                          </FilterItem>
                        );
                      })}
                    </FilterItemBox>
                  </LeftFilterBox>
                )}
              </ProductFilterAside>
              {/* render product list  */}
              {!isNestedLoading ? (
                <ProductList
                // className={
                // 	products.length > 0 ? null : "noCenter"
                // }
                >
                  {products.length > 0 ? (
                    renderProducts()
                  ) : (
                    <EmptyPage text="No items found" inside={true} />
                  )}
                </ProductList>
              ) : (
                <PageLoader size="100%" />
              )}
              {/* <paginationLoading> */}
              {/* </paginationLoading> */}
            </ProductMiddle>

            {paginationLoading && <PaginationLoader />}
            {/* <PaginationLoader /> */}
          </Wrapper>
        </ProductContainer>
      ) : (
        <PageLoader />
      )}

      {componentLoc !== "home" && <Footer />}
    </div>
  );
}

const ProductContainer = styled.div`
  padding: 3% 0 5%;
  @media all and (max-width: 1280px) {
    padding-bottom: 10%;
  }
  @media all and (max-width: 640px) {
    padding-bottom: 20%;
  }
`;
const Wrapper = styled.div`
  position: relative;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* @media all and (max-width: 420px) { */
  align-items: flex-start;
  flex-direction: column;
  @media all and (max-width: 640px) {
    width: 90%;
    margin: 0 auto;
  }
  /* } */
`;
const Title = styled.span`
  color: #747474;
  font-size: 30px;
  font-family: regular;
  @media all and (max-width: 1280px) {
    font-size: 20px;
  }
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
  &.search-title {
    display: flex;
    @media all and (max-width: 420px) {
      font-size: 15px;
    }
  }
  &.search-sub-title {
    margin-left: 8px;
    color: #191919;
  }
  &.item-count {
    display: flex;
    font-size: 20px;
    @media all and (max-width: 420px) {
      font-size: 12px;
    }
  }
  &.sub-title {
    color: #191919;
  }
`;
// product list

const ProductList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 74%;
  /* margin-top: 30px; */
  @media all and (max-width: 768px) {
    width: 100%;
  }
  /* &.noCenter {
		justify-content: center;
	} */
`;
const ProductItem = styled.div`
  transition: 0.3s all ease-in-out;
  padding: 5px;
  border-radius: 5px;
  margin-right: 1%;
  width: 24%;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &:hover {
    box-shadow: 0px 1px 12px -2px rgb(176 176 176);
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
  @media all and (max-width: 1280px) {
    /* width: 19.2%; */
    &:nth-child(6n) {
      /* margin-right: 1%; */
    }
    &:nth-child(5n) {
      /* margin-right: 0; */
    }
  }
  /* @media all and (max-width: 1080px) {
    width: 24.2%;

    &:nth-child(5n) {
      margin-right: 1%;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  } */
  @media all and (max-width: 1080px) {
    width: 32.2%;

    &:nth-child(4n) {
      margin-right: 1%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  /* @media all and (max-width: 768px) {
    width: 32.2%;

    &:nth-child(4n) {
      margin-right: 1%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  } */
  @media all and (max-width: 640px) {
    width: 49.5%;
    padding: 0;
    margin: 0;
    border: 0.5px solid #f5f5f5;
  }
`;
const ProductBottom = styled(Link)`
  @media all and (max-width: 640px) {
    &.productBottom {
      padding: 10px;
    }
  }
`;
const ProductImageBox = styled(Link)`
  width: 200px;
  height: 200px;
  overflow: hidden;
  /* border-radius: 5px; */
  min-width: 100%;
  margin-bottom: 7px;

  /* @media all and (max-width: 1440px) {
		width: 200px;
		height: 200px;
	} */
  @media all and (max-width: 1280px) {
    width: 165px;
    height: 165px;
  }
  @media all and (max-width: 1080px) {
    width: 200px;
    height: 200px;
  }
  @media all and (max-width: 980px) {
    width: 155px;
    height: 155px;
  }
  @media all and (max-width: 768px) {
    width: 212px;
    height: 212px;
  }
  @media all and (max-width: 640px) {
    width: 315px;
    height: 315px;
  }
  @media all and (max-width: 520px) {
    width: 215px;
    height: 215px;
  }
  @media all and (max-width: 420px) {
    width: 185px;
    height: 185px;
  }
  /* @media all and (max-width: 360px) {
		width: 130px;
		height: 130px;
	} */
`;
const View = styled.div`
  @media all and (max-width: 640px) {
    padding: 0px 10px;
  }
`;
const HeartIconBox = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  z-index: 999;
`;
const Heart = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  min-width: 100%;

  display: inline-block;
  /* @media all and (max-width: 1440px) {
		width: 200px;
		height: 200px;
	} */
  @media all and (max-width: 1280px) {
    width: 165px;
    height: 165px;
  }
  @media all and (max-width: 1080px) {
    width: 200px;
    height: 200px;
  }
  @media all and (max-width: 980px) {
    width: 155px;
    height: 155px;
  }
  @media all and (max-width: 768px) {
    width: 212px;
    height: 212px;
  }
  @media all and (max-width: 640px) {
    width: 315px;
    height: 315px;
  }
  @media all and (max-width: 520px) {
    width: 215px;
    height: 215px;
  }
  @media all and (max-width: 420px) {
    width: 185px;
    height: 185px;
  }
  @media all and (max-width: 360px) {
    width: 150px;
    height: 150px;
  }
`;
const ProductBrand = styled.span`
  font-size: 13px;
  font-family: medium;

  color: #747474;
`;
const ProductName = styled.h3`
  font-size: 14px;
  font-family: regular;
  color: #191919;
  margin: 7px 0;
  @media all and (max-width: 640px) {
    margin: 7px 10px;
  }
`;

// product footer

// const ProductFooter = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	margin-top: 30px;
// 	@media all and (max-width: 640px) {
// 		justify-content: center;
// 	}
// `;
// const FooterLeft = styled.div`
// 	@media all and (max-width: 640px) {
// 		display: none;
// 	}
// `;
// const ResultCount = styled.div`
// 	font-size: 14px;
// 	font-family: medium;
// 	color: #747474;
// `;
// const FooterRight = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const Button = styled.div`
// 	display: flex;
// 	align-items: center;
// 	cursor: pointer;
// `;
// const ButtonIcon = styled.div`
// 	width: 20px;
// 	height: 20px;
// `;
// const ButtonText = styled.div`
// 	font-size: 14px;
// 	font-family: medium;
// 	color: #747474;
// `;
// const PaginationButtons = styled.div`
// 	display: flex;
// 	align-items: center;
// 	margin: 0 15px;
// `;
// const PaginationButton = styled.div`
// 	background-color: ${({ nextPage }) =>
// 		nextPage ? "#EAC901" : "transparent"};
// 	border-radius: 5px;
// 	width: 30px;
// 	height: 30px;
// 	display: flex;
// 	align-items: center;
// 	cursor: pointer;
// 	justify-content: center;
// 	margin-right: 10px;
// 	&:last-child {
// 		margin-right: 0;
// 	}
// `;
// const PaginationButtonText = styled.div`
// 	font-size: 14px;
// 	font-family: medium;
// 	color: #747474;
// `;

// updated filter

const ProductMiddle = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  background: #fff;
  position: relative;
  z-index: 1;
`;
const ProductFilterAside = styled.div`
  width: 24%;
  background-color: #fcfcfc;
  padding: 10px;
  border-radius: 5px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const LeftFilterBox = styled.div`
  margin-bottom: 35px;
`;
const FilterTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const FilterTitle = styled.h3`
  font-family: medium;
  font-size: 20px;
  @media all and (max-width: 980px) {
    font-size: 18px;
  }
`;
// const FilterIconBox = styled.div`
// 	width: 15px;
// 	/* height: 15px; */
// 	@media all and (max-width: 980px) {
// 		width: 10px;
// 		/* height: 10px; */
// 	}
// `;
const FilterItemBox = styled.div``;
const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 13px;
  &.sub-cat {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Check = styled.div`
  background: #fff;
  border: 1px solid #adadad;
  overflow: hidden;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  @media all and (max-width: 980px) {
    font-size: 16px;
    width: 14px;
    height: 14px;
  }
`;
const CheckText = styled.span`
  font-family: regular;
  color: #191919;
  font-size: 18px;
  &.sub-cat {
    font-size: 16px;
    @media all and (max-width: 980px) {
      font-size: 14px;
    }
  }
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`;
// const GreyText = styled.span`
// 	font-family: regular;
// 	color: #747474;
// 	font-size: 13px;
// 	margin-left: 7px;
// 	@media all and (max-width: 980px) {
// 		font-size: 11px;
// 	}
// `;
const Image = styled.img``;
// const FilterBrandTitle = styled.h3`
// 	font-family: regular;
// 	color: #191919;
// 	font-size: 20px;
// 	@media all and (max-width: 980px) {
// 		font-size: 17px;
// 	}
// `;
// const ColorBullet = styled.div`
// 	background: #000000;
// 	border-radius: 50%;
// 	width: 12px;
// 	height: 12px;
// 	margin: 0 6px 0 10px;
// 	@media all and (max-width: 980px) {
// 		width: 10px;
// 		height: 10px;
// 		margin: 0 4px 0 4px;
// 	}
// `;
const MoreButton = styled.span`
  font-family: medium;
  color: #f5b528;
  font-size: 14px;
  border-bottom: 2px solid #f5b528;
  margin: 20px 0 0px 30px;
  text-align: center;
  display: inline-block;
  @media all and (max-width: 980px) {
    font-size: 13px;
  }
`;

// filter selected head

const FilterHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  @media all and (max-width: 640px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }
`;
const MainFilterButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const MobileFilterButton = styled.div`
  display: none;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  @media all and (max-width: 768px) {
    display: flex;
  }
`;
const FilterIcon = styled.div`
  width: 17px;
  margin-right: 6px;
  @media all and (max-width: 420px) {
    width: 12px;
  }
`;
const FilterButtonText = styled.span`
  font-size: 18px;
  font-family: medium;
  color: #191919;
  @media all and (max-width: 420px) {
    font-size: 15px;
  }
`;
const SelectedBoxs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const SelectedBox = styled.div`
  border-radius: 5px;
  border: 1px solid #747474;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 7px;
  width: max-content;
  margin-right: 10px;
  margin-bottom: 10px;
`;
const SelectedText = styled.span`
  font-size: 16px;
  font-family: regular;
  color: #747474;
  text-transform: capitalize;
  @media all and (max-width: 420px) {
    font-size: 13px;
  }
`;
const FilterCloseIcon = styled.div`
  width: 12px;
  margin-left: 8px;
  cursor: pointer;
  @media all and (max-width: 420px) {
    width: 8px;
    height: 16px;
  }
`;
const ClearButton = styled.span`
  border-radius: 5px;
  border: 1px solid #ffdd0d;
  cursor: pointer;
  color: #ffdd0d;
  font-size: 16px;
  font-family: medium;
  padding: 5px 7px;
  margin-bottom: 10px;
  @media all and (max-width: 420px) {
    font-size: 13px;
  }
`;
const SubCategoryBox = styled.div`
  padding-left: 25px;
  margin-top: 10px;
  transition: all 0.3s ease-in;
`;
const SubCategoryItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;
const AddIconBox = styled.div`
  position: relative;
  height: 18px;
  width: 18px;
  margin-right: 7px;
`;
const AddIcon = styled.div`
  height: 18px;
  width: 18px;
  position: absolute;
`;
