import React from "react";
import CartList from "../includes/cart/CartList";
import { Helmet } from "react-helmet";


export default function Cart({ reswidth }) {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>My Cart</title>
			</Helmet>
			

			<CartList reswidth={reswidth} />
		</>
	);
}
