import React from "react";
import styled from "styled-components";
import { GradientButton } from "../../constants";
import { useNavigate } from "react-router-dom";

export default function CheckoutModal({
	isCheckout,
	orderResponse,
	status,
	orderId,
	type,
}) {
	const navigate = useNavigate();
	return (
		<ModalContainer isCheckout={isCheckout}>
			<ModalFade isCheckout={isCheckout}></ModalFade>
			<ModalBox>
				<Top>
					<IconBox
						style={{
							width: "60px",
						}}
					>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/success-tick.svg"
						/>
					</IconBox>
					{type === "bid_redeem" ? (
						<Title>Thank you for shopping with us!</Title>
					) : type === "claim_your_product" ? (
						<Title>Successfully redeemed!</Title>
					) : (
						<Title>
							{status === "success"
								? "Thank you for shopping with us!"
								: "Order Failed"}
						</Title>
					)}
					<Id>{orderId ? orderId : ""}</Id>
				</Top>
				{/* <Time>
					<IconBox
						style={{
							marginRight: "10px",
						}}
					>
						<Image   className="img-class" alt="Image" src={box} />
					</IconBox>
					<TimeText>Estimated delivery by Friday, 27th Mar</TimeText>
				</Time> */}
				<GradientButton
					margin={"20px 0 0 0"}
					fontColor={"#747474"}
					fontSize={"15px"}
					fontFamily={"medium"}
					br={"5px"}
					text={"Continue"}
					width={"100%"}
					height={"45px"}
					onClick={() => navigate("/orders")}
				/>
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s ease-out;
	transform: scale(1.1);
	background: transparent;
	transform: ${({ isCheckout }) => (isCheckout ? "scale(1)" : "scale(0)")};
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: ${({ isCheckout }) => (isCheckout ? "block" : "none")};
`;
const ModalBox = styled.div`
	background: #fff;
	padding: 40px;
	border-radius: 5px;
	width: 600px;
	position: relative;
	z-index: 1001;
`;
const Top = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
`;
const IconBox = styled.div`
	width: 30px;
`;
const Image = styled.img``;
const Title = styled.div`
	color: #000000;
	font-size: 20px;
	font-family: "medium";
	margin: 20px 0;
`;
const Id = styled.div`
	color: #000000;
	font-size: 18px;
	font-family: "regular";
`;
// const Time = styled.div`
// 	display: flex;
// 	align-items: center;
// 	background-color: #fffdfa;
// 	border: 1px solid #f2f0ed;
// 	border-radius: 5px;
// 	padding: 25px;
// 	width: 100%;
// `;
// const TimeText = styled.div`
// 	color: #000000;
// 	font-size: 20px;
// 	font-family: "regular";
// `;
