import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { adsConfig } from "../../../apiConfig";
import HomeLoader from "../content-loader/trend-of-the-week/HomeLoader";
// import { shopConfig } from "../../../apiConfig";

export default function Featured() {
	const [isLoading, setLoading] = useState(true);
	const [featuredList, setFeaturedList] = useState([]);
	const navigate   = useNavigate()

	useEffect(() => {
		adsConfig
			.get("featured-products/")
			.then((res) => {
				const { StatusCode } = res.data;
				setFeaturedList(res.data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	}, []);

	const handleUrl = (url, id) => {
		if (url.includes("products")) {
		  navigate("/products/all-products", {
			state: {
			  url: url,
			},
		  });
		} else if (
		  url.includes("products") === false &&
		  url.includes("product") === false
		) {
		  window.open(`${url}`, "_self");
		} else {
		  navigate(`/product/${id}`, {
			state: {
			  url: url,
			},
		  });
		}
	  };

	function handleTrendList() {
		return featuredList.map((item, index) => {
			return (
				<ButtonPage
					// to={{
					// 	pathname: `/featured/${item.slug}`,
					// }}
					onClick={() => {
						// window.open(`${item.url}`, "_self");
						handleUrl(item.url, item.id);
					  }}
					key={index}
				>
					<Imagebox>
						<Image
							
							alt="Image" src={item.item_image}
							className="img-class"
						/>
					</Imagebox>
					<Name>{item.title}</Name>
					{item.offer_percentage && (
						<Off>{item.offer_percentage}%</Off>
					)}
				</ButtonPage>
			);
		});
	}
	return (
		<>
			{featuredList.length > 0 && (
				<FeaturedContainet>
					<Wrapper className="wrapper">
						<Top>
							<Title>Featured</Title>
							{/* <Text>View More</Text> */}
						</Top>
						{!isLoading ? (
							<TrendList>{handleTrendList()}</TrendList>
						) : (
							<HomeLoader />
						)}
					</Wrapper>
				</FeaturedContainet>
			)}
		</>
	);
}

const ButtonPage = styled.div`
	width: 15%;
	text-align: center;
	border: 1px solid #eeeeee;
	padding: 5px;
	cursor: pointer;
	margin-right: 2%;
	border-radius: 5px;
	@media all and (max-width: 980px) {
		width: unset;
	}
`;
const FeaturedContainet = styled.div`
	padding-bottom: 3%;
	@media all and (max-width: 640px) {
		padding-bottom: 4%;
	}
`;
const Wrapper = styled.div``;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin-bottom: 30px; */
	@media all and (max-width: 768px) {
		/* margin-bottom: 10px; */
	}
`;
const Title = styled.h3`
	font-family: medium;
	font-size: 24px;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 980px) {
		/* font-size: 18px; */
		margin-bottom: 10px;
	}
	@media all and (max-width: 768px) {
		font-size: 18px;
	}
	@media all and (max-width: 420px) {
		font-size: 15px;
		margin-bottom: 5px;
	}
`;
// const Text = styled.h3`
// 	font-family: medium;
// 	font-size: 20px;
// 	color: #d6b114;
// 	cursor: pointer;
// 	@media all and (max-width: 768px) {
// 		font-size: 15px;
// 	}
// `;
const TrendList = styled.div`
	align-items: center;
	display: flex;
	max-width: 1350px;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	@media all and (max-width: 768px) {
		/* margin-top: 10px; */
	}
`;
// const TrendBox = styled.div`
// 	width: 15%;
// 	text-align: center;
// 	border: 1px solid #eeeeee;
// 	padding: 5px;
// 	cursor: pointer;
// 	margin-right: 3%;
// 	border-radius: 5px;
// 	@media all and (max-width: 980px) {
// 		min-width: 23%;
// 	}
// 	@media all and (max-width: 768px) {
// 		min-width: 32%;
// 	}
// 	@media all and (max-width: 640px) {
// 		min-width: 48%;
// 	}
// `;
const Imagebox = styled.div`
	border-radius: 5px 5px 0 0;
	overflow-x: hidden;
	background-repeat: no-repeat;
	background-size: fixed;
`;
const Image = styled.img`
	width: 190px;
	height: 190px;
	min-width: 100%;
	@media all and (max-width: 1280px) {
		width: 135px;
		height: 135px;
	}
	@media all and (max-width: 1080px) {
		width: 120px;
		height: 120px;
	}
`;

const Name = styled.h3`
	font-family: medium;
	font-size: 18px;
	color: #191919;
	padding: 8px 0;
	@media all and (max-width: 1280px) {
		font-size: 18px;
		padding: 5px 0;
	}
	@media all and (max-width: 640px) {
		font-size: 12px;
	}
`;
const Off = styled.h2`
	font-family: regular;
	font-size: 14px;
	color: #219a75;
	@media all and (max-width: 1280px) {
		font-size: 13px;
	}
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
