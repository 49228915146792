import React, { useContext, useState } from "react";
import styled from "styled-components";
import { GreenRatingBox, ProductPriceContainer } from "../includes/constants";
import HeaderPush from "../general/HeaderPush";
import { Link, useNavigate } from "react-router-dom";
import { wishlistConfig } from "../../apiConfig";
import Header from "../general/Header";
import Footer from "../general/Footer";
import { Context } from "../context/store";
import PageLoader from "./PageLoader";

export default function WeeklyTrendsProducts({
  pageTitle,
  searchValue,
  setSearchValue,
}) {
  const { state } = useContext(Context);
  const [products, setProducts] = useState([]);

  const [wishlistId, setWishlistId] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  //add to wishlist
  const addToWishList = (product_pk) => {
    if (state.user_data.is_verified) {
      wishlistConfig
        .post(
          `add-to-wishlist/${product_pk}/`,
          { quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.StatusCode === 6000) {
            setWishlistId([...wishlistId, product_pk]);
          }
        })
        .catch((err) => {});
    } else {
      navigate("/login");
    }
  };

  const handleUrl = (url, id) => {
    if (url.includes("products")) {
      navigate("/products/all-products", {
        state: {
          url: url,
        },
      });
    } else if (
      url.includes("products") === false &&
      url.includes("product") === false
    ) {
      window.open(`${url}`, "_self");
    } else {
      navigate(`/product/${id}`, {
        state: {
          url: url,
        },
      });
    }
  };

  const renderProducts = () => {
    return products.map((item, index) => (
      <ProductItem
        key={item.id}
        to={{
        	pathname: `/product/${item.id}`,
        }}
        
      >
        <ProductImageBox>
          <HeartIconBox onClick={() => addToWishList(item.id)}>
            <Heart
              alt="Image"
              src={
                item.is_wishlisted || wishlistId.includes(item.id)
                  ? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/addWishlist.svg"
                  : "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/heart.svg"
              }
            />
          </HeartIconBox>
          <ProductImage alt="Image" src={item.featured_image} />
        </ProductImageBox>
        <Link
          to={{
            pathname: `/product/${item.id}`,
          }}
        >
          <ProductBrand>Rolex</ProductBrand>
          <ProductName>{item.name}</ProductName>
          <ProductPriceContainer
            margin="10px 0 15px 0"
            actualPrice={item.actual_cost}
            displayPrice={item.display_cost}
          />
          <GreenRatingBox rating={item.rating} review={item.review_count} />
        </Link>
      </ProductItem>
    ));
  };
  return (
    <>
      <Header searchValue={searchValue} setSearchValue={setSearchValue} />
      <HeaderPush />
      {!isLoading ? (
        <ProductContainer>
          <Wrapper className="wrapper">
            <ProductHeader>
              {pageTitle === "seller" ? (
                <Title>All Products</Title>
              ) : (
                <Title className="search-title">
                  Showing results for{" "}
                  <Title className="search-sub-title">Watches for Men</Title>
                  <br />
                  {/* <h3 style={{ color: "#adadad" }}> 58720 items</h3> */}
                </Title>
              )}
            </ProductHeader>
            <ProductMiddle>
              {/* render product list  */}
              <ProductList>{renderProducts()}</ProductList>
            </ProductMiddle>
          </Wrapper>
        </ProductContainer>
      ) : (
        <PageLoader />
      )}
      <Footer />
    </>
  );
}

const ProductContainer = styled.div`
  padding: 3% 0;
`;
const Wrapper = styled.div``;
const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media all and (max-width: 420px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
const Title = styled.span`
  color: #747474;
  font-size: 30px;
  font-family: regular;
  @media all and (max-width: 1280px) {
    font-size: 20px;
  }
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
  @media all and (max-width: 420px) {
    width: 100%;
  }
  &.search-title {
    display: flex;
    @media all and (max-width: 420px) {
      flex-direction: column;
    }
  }
  &.search-sub-title {
    margin-left: 8px;
    color: #191919;
    @media all and (max-width: 420px) {
      margin-left: 0;
    }
  }
`;

const ProductList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;
const ProductItem = styled.div`
  transition: 0.3s all ease-in-out;
  padding: 5px;
  border-radius: 5px;
  margin-right: 1%;
  width: 19%;
  margin-bottom: 25px;
  &:hover {
    box-shadow: 0px 1px 12px -2px rgb(176 176 176);
  }
  &:nth-child(5n) {
    margin-right: 0;
  }
  @media all and (max-width: 1280px) {
    width: 24%;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:nth-child(5n) {
      margin-right: 1%;
    }
  }
  @media all and (max-width: 1080px) {
    width: 32.2%;

    &:nth-child(4n) {
      margin-right: 1%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media all and (max-width: 640px) {
    width: 49.2%;

    &:nth-child(3n) {
      margin-right: 1%;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;
const ProductImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 7px;
  @media all and (max-width: 420px) {
    height: 150px;
  }
`;
const HeartIconBox = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
`;
const Heart = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const ProductBrand = styled.span`
  font-size: 13px;
  font-family: medium;

  color: #747474;
`;
const ProductName = styled.h3`
  font-size: 14px;
  font-family: regular;
  color: #191919;
  margin: 7px 0;
`;

const ProductMiddle = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
`;
