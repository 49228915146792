import React, { useEffect } from "react";
import styled from "styled-components";

export default function DeliveryAndReturns() {
    //when onload the page ,scroll top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Wrapper>
				<Title>Delivery and Returns</Title>
				<Content>
					The process for delivery and returns of products,are safe
					and secured with the extensive network of Delivery team for
					your delightful shopping experience.Delivery charge varies
					with each Seller. Returns is a scheme provided by respective
					sellers directly under this policy in terms of which the
					option of exchange, replacement and/ or refund is offered by
					the respective sellers to you. All products listed under a
					particular category may not have the same returns policy.
					For all products, the returns/replacement policy provided on
					the product page shall prevail over the general returns
					policy.
				</Content>
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	padding: 3% 0;
	min-height: calc(100vh - 420px);
	@media all and (max-width: 640px) {
		padding: 8% 0;
	}
`;
const Wrapper = styled.div`
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	text-align: left;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #191919;
	padding-bottom: 20px;
	border-bottom: 1px solid #c4c4c4;
	margin-bottom: 30px;
	text-align: center;
	@media all and (max-width: 640px) {
		font-size: 19px;
		margin-bottom: 25px;
		padding-bottom: 15px;
	}
`;
const Content = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	line-height: 1.2em;
	letter-spacing: 0.3px;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 25px;
	}
`;
const SubTitle = styled.h3`
	font-size: 20px;
	font-family: regular;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;
