import React from "react";
import ContentLoader from "react-content-loader";

const HomeLoader = (props) => (
	<ContentLoader
		speed={2}
		width={"100%"}
		height={"100%"}
		viewBox="0 0 1920 100%"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
        {/* header */}
		<rect x="0" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="150" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="300" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="450" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="600" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="750" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="900" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1050" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1200" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1350" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1500" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1650" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1800" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="1950" y="15" rx="100" ry="100" width="110" height="110" />
		<rect x="2100" y="15" rx="100" ry="100" width="110" height="110" />
	</ContentLoader>
);

export default HomeLoader;
