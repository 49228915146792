import React, { useContext } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context/store";

export default function PrivateRoute() {
	const { state } = useContext(Context);
	const location = useLocation();
	// const history = useNavigate();

	return state.user_data.is_verified ? (
		<Outlet />
	) : (
		<Navigate to="/login" state={{ from: location }} replace={"/"} />
	);
}
