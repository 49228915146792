import React, { useState } from "react";
import styled from "styled-components";

export default function RatingViewMore({ isModal, setModal, reviewImages }) {
	const [imgIndex, setImgIndex] = useState(0);
	const handlePrev = () => {
		if (imgIndex <= 0) {
			setImgIndex(reviewImages.length - 1);
		} else {
			setImgIndex(imgIndex - 1);
		}
	};
	const handleNext = () => {
		if (reviewImages.length - 1 === imgIndex) {
			setImgIndex(0);
		} else {
			setImgIndex(imgIndex + 1);
		}
	};
	return (
		<ModalContainer isModal={isModal}>
			<ModalFade
				onClick={() => setModal(false)}
				isModal={isModal}
			></ModalFade>
			<ModalBox>
				<Top>
					<Title>User Images</Title>

					<Close onClick={() => setModal(false)}>
						<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg" />
					</Close>
				</Top>
				<Middle>
					<Button onClick={handlePrev}>
						<img
							
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-down.svg"
							className="img-class"
						/>
					</Button>
					<SliderImage>
						<img
							
							alt="Image" src={reviewImages[imgIndex]?.image}
							className="img-class"
						/>
					</SliderImage>
					<Button className="right" onClick={handleNext}>
						<img
							
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-down.svg"
							className="img-class"
						/>
					</Button>
				</Middle>
				<ImageBox>
					{reviewImages.map((item, index) => {
						return (
							<ImageItem
								onClick={() => setImgIndex(index)}
								key={index}
								style={{
									border:
										imgIndex === index
											? "1px solid #FEDA00"
											: "unset",
								}}
							>
								<img
									
									alt="Image" src={item.image}
									className="img-class"
								/>
							</ImageItem>
						);
					})}
				</ImageBox>
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s ease-out;
	transform: scale(1.1);
	background: transparent;
	transform: ${({ isModal }) => (isModal ? "scale(1)" : "scale(0)")};
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: ${({ isModal }) => (isModal ? "block" : "none")};
`;
const ModalBox = styled.div`
	background: #fff;
	padding: 30px;
	border-radius: 5px;
	width: 800px;
	height: max-content;
	position: relative;
	z-index: 1001;
	@media all and (max-width: 980px) {
		width: 650px;
	}
	@media all and (max-width: 768px) {
		width: 550px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 420px) {
		width: 300px;
		padding: 15px;
	}
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #003c3c;
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
	&.subTitle {
		font-size: 14px;
		color: #747474;
		font-family: regular;
		margin-top: 5px;
	}
	@media all and (max-width: 420px) {
		font-size: 12px;
	}
`;

const Close = styled.div`
	width: 15px;
	cursor: pointer;
	@media all and (max-width: 420px) {
		width: 8px;
	}
`;
const Image = styled.img``;
const ImageBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-height: 500px;
	margin-top: 15px;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
`;
const ImageItem = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 5px;
	margin-bottom: 10px;
	margin-right: 10px;
	overflow: hidden;
	border-radius: 5px;
	padding: 2px;
	cursor: pointer;
	img {
		border-radius: 5px;
	}

	@media all and (max-width: 640px) {
		width: 60px;
		height: 60px;
		margin-right: 5px;
	}
`;

const SliderImage = styled.div`
	width: 100%;
`;
const Middle = styled.div`
	position: relative;
`;
const Button = styled.div`
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 2%;
	top: 50%;
	bottom: 50%;
	z-index: 3;
	background-color: #fff;
	opacity: .4;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	&.right {
		right: 2%;
		left: unset;
		img {
			transform: rotate(-90deg);
		}
	}
	img {
		width: 50%;
		height: 50%;
		display: inline-block;
		transform: rotate(90deg);
	}
	@media all and (max-width: 640px) {
		width: 30px;
		height: 30px;
	}
`;
