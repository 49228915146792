import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Store from "./components/context/store";
import MainRouter from "./components/routing/MainRouter";
import Offline from "./components/screens/Offline";

export default function App() {
	const [reswidth, setResWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener("resize", () => setResWidth(window.innerWidth));
	}, []);
	return (
		<Store>
			<MainRouter reswidth={reswidth} />
			{/* <Offline /> */}
		</Store>
	);
}
