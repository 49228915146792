import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { paymentsConfig } from "../../../apiConfig";
import PageLoader from "../../screens/PageLoader";
import { Context } from "../../context/store";

export default function Coins() {
	const { state } = useContext(Context);
	const [coinBalance, setCoinBalance] = useState("");
	const [transaction, setTransaction] = useState([]);
	const [isLoading, setLoading] = useState(true);
	// const location = useLocation();

	useEffect(() => {
		// coin and wallet ballence
		paymentsConfig
			.get("view-balance", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				if (res.data.StatusCode === 6000) {
					setCoinBalance(res.data.data[0].coin_balance);
				}
			})
			.catch((err) => {});

		// for view transaction history
		paymentsConfig
			.get(
				"view-transaction-history/?transaction_type=coin_transaction",
				{
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				}
			)
			.then((res) => {
				const { data, StatusCode } = res.data;

				if (StatusCode === 6000) {
					// let newArray = data.coin_transaction_data.concat(
					// 	data.cash_transaction_data,
					// 	data.cash_transaction_data
					// );
					// const sortedItems = newArray.sort((a, b) => {
					// 	const dateA = new Date(a.date_added);
					// 	const dateB = new Date(b.date_added);
					// 	return dateB - dateA;
					// });
					// setTransaction(sortedItems);
					setTransaction(data.coin_transaction_data);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [state.user_data.access_token]);

	return !isLoading ? (
		<>
			{/* <PurchaseCoin isModal={coinModal} setModal={setCoinModal} /> */}
			<Main>
				<Container>
					<Top>
						<Title>My Coins</Title>
						<CoinCount>
							<CoinBigBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
								/>
							</CoinBigBox>
							{coinBalance ? coinBalance : "0"}
						</CoinCount>
						<Descritpion>1 Coin represents 1 Rupee</Descritpion>
					</Top>
					{/* <Button onClick={() => setCoinModal(true)}>Purchase Coin</Button> */}
					<Button to="/coins/purchase-coins">Purchase Coin</Button>
					<Button className="transfer" to="/coins/transfer-to-wallet">
						Transfer to wallet
					</Button>
					<Bottom>
						<BottomTitle>Transactions</BottomTitle>
						{transaction.length > 0 ? (
							<BottomList>
								{transaction.map((item) => {
									return (
										item.payment_status !== "pending" && (
											<ListItem>
												<Left>
													<BankImg>
														<Image
															
															className="img-class"
															// alt="Image" src={item.bank_logo}
															alt="Image" src={
																item.payment_type ===
																"coin_purchase"
																	? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-arrow.svg"
																	: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/red-arrow.svg"
															}
														/>
													</BankImg>
													<Box>
														<Name>
															{item.payment_type ===
															"coin_purchase"
																? "Credited"
																: item.payment_type ===
																  "bidding_deduction"
																? "Debited for bidding"
																: item.payment_type ===
																  "credited to wallet"
																? "Debited to wallet"
																: "Debited"}
														</Name>
														<Time>
															{new Date(
																item.date_added
															).toDateString()}
														</Time>
													</Box>
												</Left>
												<Right>
													<CoinBox>
														<Count>
															<CoinIconBox>
																<Image
																	
																	className="img-class"
																	alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
																/>
															</CoinIconBox>
															<Text
																className="coin"
																style={{
																	color:
																		item.payment_type ===
																		"bidding_deduction"
																			? "#E02B1D"
																			: "#009262",
																}}
															>
																{item.payment_type ===
																"bidding_deduction"
																	? item.transfer_amount.slice(
																			0,
																			1
																	  )
																	: item.transfer_amount}
															</Text>
														</Count>
														{/* <Status>
														<IconBox>
															<Image
																
																className="img-class"
																alt="Image" src={
																	item.payment_type ===
																	"bidding_deduction"
																		? red
																		: green
																}
															/>
														</IconBox>
														<SpendText>
															{item.payment_type ===
															"bidding_deduction"
																? "Bidding"
																: "Purchase"}
														</SpendText>
													</Status> */}
													</CoinBox>
												</Right>
											</ListItem>
										)
									);
								})}
							</BottomList>
						) : (
							<NoTransaction>
								<NoIcon>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/no-events.gif"
									/>
								</NoIcon>
								<NoText>
									Sorry, You have <br />
									no transaction found
								</NoText>
							</NoTransaction>
						)}
					</Bottom>
				</Container>
				<CoinRight>
					<RightTitle>View All</RightTitle>
					<Tab to="/bidding/my-biddings">
						<TabIcon>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/events.svg"
							/>
						</TabIcon>
						<TabText>Your Jackpot Events</TabText>
					</Tab>
					<Tab to="/bidding/winnings">
						<TabIcon>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/winning.svg"
							/>
						</TabIcon>
						<TabText>Your Winning Events</TabText>
					</Tab>
					<Tab to="/bidding/know-more">
						<TabIcon>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/know-more.svg"
							/>
						</TabIcon>
						<TabText>Know More Biddings</TabText>
					</Tab>
				</CoinRight>
			</Main>
		</>
	) : (
		<PageLoader />
	);
}

const Main = styled.section`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@media all and (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

const Container = styled.div`
	width: 68%;
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const Top = styled.div`
	background: linear-gradient(109.04deg, #ff9239 0.63%, #cc752d 100%);
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 40px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media all and (max-width: 640px) {
		padding: 40px 30px;
	}
`;
const Title = styled.h3`
	font-family: bold;
	font-size: 20px;
	color: #fff;
`;
const CoinBigBox = styled.div`
	width: 60px;
	margin-right: 10px;
	@media all and (max-width: 640px) {
		width: 50px;
	}
`;
const CoinCount = styled.h3`
	font-family: bold;
	margin: 25px 0;
	font-size: 60px;
	align-items: center;
	display: flex;
	color: #fff;
	@media all and (max-width: 640px) {
		font-size: 50px;
		margin: 20px 0;
	}
`;
const Descritpion = styled.p`
	font-family: regular;
	font-size: 18px;
	color: #fff;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
const Bottom = styled.div``;
const BottomTitle = styled.h3`
	font-size: 22px;
	font-family: regular;
	color: #191919;
	margin-bottom: 20px;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
`;
const BottomList = styled.div``;
const ListItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
`;
const BankImg = styled.div`
	width: 50px;
	height: 50px;
	margin-right: 15px;
	border-radius: 50%;
	background: #f7f7f7;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	img {
		width: 40%;
		height: 40%;
	}
	@media all and (max-width: 640px) {
		width: 40px;
		height: 40px;
	}
`;
const Image = styled.img``;
const Box = styled.div``;
const Name = styled.h3`
	font-size: 20px;
	font-family: medium;
	color: #191919;
	margin-bottom: 5px;
	@media all and (max-width: 640px) {
		margin-bottom: 2px;
		font-size: 16px;
	}
`;
const Time = styled.span`
	font-size: 16px;
	font-family: regular;
	color: #747474;
	margin-top: 5px;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
const Right = styled.div``;
const CoinBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;
const Count = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
`;
// const IconBox = styled.div`
// 	width: 13px;
// 	margin-right: 8px;
// 	@media all and (max-width: 640px) {
// 		width: 10px;
// 		margin-right: 5px;
// 	}
// `;
const CoinIconBox = styled.div`
	width: 25px;
	margin-right: 8px;
	@media all and (max-width: 640px) {
		width: 18px;
		margin-right: 5px;
	}
`;
const Text = styled.span`
	font-family: medium;
	font-size: 18px;
	margin-bottom: 6px;
`;
// const Status = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const SpendText = styled.span`
// 	font-family: regular;
// 	font-size: 16px;
// 	color: #747474;
// 	@media all and (max-width: 640px) {
// 		font-size: 14px;
// 	}
// `;

const NoTransaction = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: 60px 20px;
`;
const NoIcon = styled.div`
	width: 40%;
	margin-bottom: 20px;
`;
const NoText = styled.p`
	font-size: 20px;
	font-family: "medium";
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
`;
const CoinRight = styled.div`
	width: 30%;
	@media all and (max-width: 768px) {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`;

const RightTitle = styled.h3`
	margin-bottom: 15px;
	font-size: 15px;
	font-family: medium;
	color: #191919;
	@media all and (max-width: 768px) {
		display: none;
	}
`;
const Tab = styled(Link)`
	display: flex;
	align-items: center;
	border-radius: 5px;
	background-color: #f5f5f5;
	margin-bottom: 15px;
	padding: 10px;
	cursor: pointer;
	@media all and (max-width: 640px) {
		padding: 20px;
		width: 30%;
	}
`;
const TabIcon = styled.div`
	width: 20px;
	margin-right: 10px;
	@media all and (max-width: 640px) {
		margin: 0;
		width: 100%;
	}
`;
const TabText = styled.span`
	font-size: 15px;
	font-family: "medium";
	color: #747474;
	@media all and (max-width: 640px) {
		display: none;
	}
`;

const Button = styled(Link)`
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	padding: 15px;
	font-size: 18px;
	font-family: medium;
	color: #191919;
	display: inline-block;
	width: 100%;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	&.transfer {
		margin-bottom: 30px;
		background: #fffcf3;
		color: #191919;
		margin-top: 2%;
		border: 1px solid #feda00;
	}
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
