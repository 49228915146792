import React, { useState } from "react";
import styled from "styled-components";
import { ordersConfig, shippingsConfig } from "../../../../apiConfig";
import ButtonLoader from "../../../general/includes/ButtonLoader";
import { useContext } from "react";
import { Context } from "../../../context/store";

export default function AddresChange({
	addressModal,
	setAddressModal,
	fullAddress,
}) {
	const { state } = useContext(Context);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [pincode, setPincode] = useState("");
	const [pincodeError, setPincodeError] = useState("");

	// checking delivery availability
	function checkPincode() {
		if (pincode.length === 6) {
			setButtonLoader(true);
			shippingsConfig
				.get(`check-delivery-availability/${pincode}/`)
				.then((res) => {
					const { StatusCode } = res.data;
					if (StatusCode === 6000) {
						setPincodeError("Delivery available");
					} else {
						setPincodeError(
							"Delivery not available on this pincode"
						);
					}
					setButtonLoader(false);
				})
				.catch((err) => {
					setButtonLoader(false);
				});
		} else if (!pincode) {
			setPincodeError("Pls Enter Pincode");
		} else {
			setPincodeError("Not a valid pnicode");
		}
	}

	// change address
	function changeDefault(item) {
		const data = {
			state: item.state,
			phone_no: item.phone_no,
			pincode: item.pincode,
			city: item.city,
			address_line1: item.address_line1,
			address_line2: item.address_line1,
			near_landmark: item.near_landmark,
			address_type: item.address_type,
			default: true,
		};
		ordersConfig
			.post(`update-address/${item.id}/`, data, {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {})
			.catch((err) => {
			});
	}

	return (
		<ModalContainer addressModal={addressModal}>
			<ModalFade
				onClick={() => setAddressModal(false)}
				addressModal={addressModal}
			></ModalFade>
			<ModalBox>
				<Title>Choose Delivery Address</Title>
				<ItemBox>
					{fullAddress?.map((item, index) => {
						return (
							<Items
								onClick={() => changeDefault(item)}
								key={index}
							>
								<Radio>
									<img  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg" />
								</Radio>
								<ItemTop>
									<AddressTitleBox>
										{item.customer}, {item.pincode}{" "}
										<Label>{item.address_type}</Label>
									</AddressTitleBox>
									<AdressSpan>
										{item.address_line1},{" "}
										{item.near_landmark}, {item.city},
										{item.state}
									</AdressSpan>
								</ItemTop>
							</Items>
						);
					})}
				</ItemBox>

				<Title
					style={{
						borderTop: "1px solid #c4c4c4",
						paddingTop: "15px",
					}}
				>
					Use pincode to check delivery info
				</Title>
				<Bottom>
					<PinCodeRight>
						<PinCodeIconBox>
							<Image   className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/location-yellow.svg" />
						</PinCodeIconBox>
						<input
							placeholder="Enter delivery pincode"
							value={pincode}
							onChange={(e) => setPincode(e.target.value)}
						/>
					</PinCodeRight>
					<ChangeButton onClick={checkPincode}>
						{buttonLoader ? (
							<ButtonLoader />
						) : (
							<ChangeText>
								{pincode ? "Submit" : "Change"}
							</ChangeText>
						)}
					</ChangeButton>
				</Bottom>
				<Error
					style={{
						color:
							pincodeError === "Delivery available"
								? "#32C997"
								: "#E02B1D",
					}}
				>
					{pincodeError}
				</Error>
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s ease-out;
	transform: scale(1.1);
	background: transparent;
	transform: ${({ addressModal }) =>
		addressModal ? "scale(1)" : "scale(0)"};
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: ${({ addressModal }) => (addressModal ? "block" : "none")};
`;
const ModalBox = styled.div`
	background: #fff;
	padding: 30px;
	border-radius: 5px;
	width: 50%;
	position: relative;
	z-index: 1001;
	@media all and (max-width: 980px) {
		width: 75%;
	}
	@media all and (max-width: 640px) {
		width: 90%;
	}
	/* @media all and (max-width: 420px) {
		width: 300px;
	}  */
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #003c3c;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
const AddressTitleBox = styled.h3`
	display: flex;
	font-size: 16px;
	text-transform: capitalize;
	font-family: medium;
	align-items: center;
	/* margin: 0px 10px 0px; */
	color: #0a0a0a;
	@media all and (max-width: 420px) {
		font-size: 12px;
	}
`;
const Label = styled.div`
	background: #f2f2f2;
	color: #0a0a0a;
	text-transform: capitalize;
	padding: 3px 8px;
	border-radius: 4px;
	margin-left: 8px;
	font-family: medium;
	font-size: 12px;
	@media all and (max-width: 640px) {
		font-size: 10px;
	}
`;
const AdressSpan = styled.p`
	color: #747474;
	font-size: 13px;
	font-family: regular;
	/* margin: 6px 0 0 20px; */
	margin-top: 6px;
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
const PinCodeRight = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e9ce22;
	padding-bottom: 5px;
	input {
		margin: 0 8px;
		color: #747474;
		font-size: 14px;
		font-family: regular;
	}
	width: -webkit-fill-available;
`;
const PinCodeIconBox = styled.div`
	width: 15px;
`;
const Check = styled.span`
	font-size: 15px;
	font-family: medium;
	color: #e9ce22;
	margin-left: 40px;
`;
const Items = styled.div`
	margin-bottom: 30px;
	display: flex;
	align-items: flex-start;
	cursor: pointer;
`;
const ItemTop = styled.div`
	/* display: flex;
	align-items: center; */
`;
const Radio = styled.div`
	width: 18px;
	height: 18px;
	margin-right: 10px;
`;
const Image = styled.img``;

const Bottom = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
`;
const ChangeButton = styled.div`
	border: 1px solid #eab600;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	margin-left: 20px;
	width: 100px;
	cursor: pointer;
	@media all and (max-width: 640px) {
		/* margin-top: 15px; */
	}
`;

const ChangeText = styled.span`
	color: #0a0a0a;
	font-size: 13px;
	font-family: regular;
`;
const ItemBox = styled.div`
	max-height: 110px;
	overflow-y: scroll;
	margin: 20px 0 10px;
	&::-webkit-scrollbar {
		display: none;
	}
`;
const Error = styled.span`
	color: #e02b1d;
	font-size: 13px;
	font-family: regular;
	@media all and (max-width: 420px) {
		font-size: 11px;
	}
`;
