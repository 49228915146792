import React, { useState } from "react";
import styled from "styled-components";
import HeaderPush from "../general/HeaderPush";
import EnterPhone from "../includes/auth/EnterPhone";
import EnterOtp from "../includes/auth/EnterOtp";
import Header from "../general/Header";
import SetName from "../includes/auth/SetName";

export default function AuthScreen({ reswidth, searchValue, setSearchValue }) {
  const [isOtpMode, setOtpMode] = useState(false);
  const [isLogin, setLogin] = useState(true);
  const [isName, setIsName] = useState(false);
  return (
    <>
      <Header searchValue={searchValue} setSearchValue={setSearchValue} />
      <HeaderPush height="80px" />
      <AuthContainer>
        <LeftBg
          style={{
            background: "#2d2d2d",
          }}
        ></LeftBg>
        <Wrapper className="wrapper">
          <Left>
            <Top>
              <LogoBox>
                <Logo
                  alt="Image"
                  src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/logo.svg"
                />
              </LogoBox>
              <BrandName>onerupee.com</BrandName>
            </Top>
            <Description>
              Provide you with a seamless and enjoyable{" "}
              <GoldText>shopping experience.</GoldText>
            </Description>
            <Bottom>
              <BottomImage>
                <img
                  className="img-class"
                  alt="Image"
                  src={require("../../assets/images/auth/auth-left-image.png")}
                />
              </BottomImage>
            </Bottom>
          </Left>
          <Right>
            {isOtpMode && !isName ? (
              <EnterOtp
                reswidth={reswidth}
                isLogin={isLogin}
                setLogin={setLogin}
                setOtpMode={setOtpMode}
                setIsName={setIsName}
              />
            ) : isOtpMode && isName ? (
              <SetName
                setOtpMode={setOtpMode}
                isLogin={isLogin}
                setLogin={setLogin}
              />
            ) : (
              <EnterPhone
                setOtpMode={setOtpMode}
                isLogin={isLogin}
                setLogin={setLogin}
              />
            )}
          </Right>
        </Wrapper>
      </AuthContainer>
    </>
  );
}

const AuthContainer = styled.div`
  height: calc(100vh - 80px);
  position: relative;
  padding: 10% 0 8%;
`;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  @media all and (max-width: 980px) {
    align-items: center;
    justify-content: center;
  }
  @media all and (max-width: 640px) {
    align-items: center;
    /* margin-top: 30%; */
  }
`;
const LeftBg = styled.div`
  /* height: calc(100vh - 80px); */
  height: 100%;
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media all and (max-width: 980px) {
    display: none;
  }
`;
const Left = styled.div`
  width: 50%;
  display: flex;
  transform: translateX(-18%);
  flex-direction: column;
  /* height: calc(100vh - 80px); */
  justify-content: center;
  @media all and (max-width: 1600px) {
    transform: translateX(-9%);
  }
  @media all and (max-width: 1440px) {
    transform: translateX(-5%);
  }
  /* @media all and (max-width: 1280px) {
		transform: translateX(-4%);
	} */
  @media all and (max-width: 980px) {
    display: none;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const LogoBox = styled.div`
  width: 35px;
  width: 35px;
  margin-right: 10px;
`;
const Logo = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const BrandName = styled.h1`
  font-size: 35px;
  font-family: medium;
  color: #fff;
  @media all and (max-width: 1080px) {
    font-size: 25px;
  }
`;
const Description = styled.p`
  font-size: 40px;
  font-family: regular;
  color: #fff;
  margin: 20px 0;
  line-height: 50px;
  @media all and (max-width: 1080px) {
    font-size: 30px;
    line-height: 35px;
    width: 75%;
  }
`;
const GoldText = styled.span`
  color: #dac85a;
  font-family: medium;
`;
const Bottom = styled.div``;
const BottomImage = styled.div``;

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 80px); */
  /* justify-content: center; */
  padding: 8% 0 0 10%;
  /* align-items: center; */
  @media all and (max-width: 980px) {
    width: 80%;
    padding: 0;
  }
  @media all and (max-width: 420px) {
    width: 100%;
  }
`;
