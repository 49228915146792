import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function Toast({ isToast, setToast, text }) {
	const [showToast, setShowToast] = useState(true);
	useEffect(() => {
		setShowToast(true);
		if (isToast) {
			setTimeout(() => {
				setToast(false);
			}, 2000);
		} else {
			setTimeout(() => {
				setShowToast(false);
			}, 3000);
		}
	}, [isToast]);
	return (
		<Main style={{ display: showToast ? "flex" : "none" }}>
			<Container isToast={isToast}>
				{/* <IconBox>
					<Image   className="img-class" />
				</IconBox> */}
				<Text>{text}</Text>
			</Container>
		</Main>
	);
}

const Main = styled.div`
	position: fixed;
	top: 80px;
	left: 0;
	width: 100%;
	align-items: center;
	justify-content: center;
	z-index: 1000;
`;
const Container = styled.div`
	padding: 12px 20px;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	box-shadow: 0px 0px 13px rgb(0 0 0 / 22%);
	width: max-content;

	border-radius: 5.77726px;
	transition: 0.3s all;
	transform: ${({ isToast }) => (isToast ? "scale(1)" : "scale(0)")};
	display: flex;
	align-items: center;
	justify-content: center;
`;
// const IconBox = styled.div``;
// const Image = styled.div``;
const Text = styled.div`
	font-size: 20px;
	color: #747474;
	font-family: regular;
	text-transform: capitalize;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
`;
