import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MyOrdersCancelModal from "../modal/orders/MyOrdersModal";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/store";
import { ordersConfig, ratingConfig } from "../../../apiConfig";
import PageLoader from "../../screens/PageLoader";

export default function OrderSingle() {
	const [isModal, setModal] = useState(false);
	const params = useParams();
	const { state } = useContext(Context);
	const [orderData, setOrderData] = useState([]);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		ordersConfig
			.get(`view-order/${params.order_pk}/`, {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setOrderData(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);


	return !isLoading ? (
		<>
			<MyOrdersCancelModal
				isModal={isModal}
				setModal={setModal}
				orderData={orderData}
			/>
			<Title>
				{orderData?.order_details?.brand &&
					`${orderData?.order_details[0]?.brand}, `}
				<Grey>
					{orderData.order_details[0].product_name ||
						orderData.order_details[0].bid_product_name}
				</Grey>
			</Title>
			<ListBox>
				<ListItem>
					<FlexBox>
						<Left>
							<ImageBox>
								<Image
									
									className="img-class"
									alt="Image" src={
										orderData.order_details[0]
											.featured_image ||
										orderData.order_details[0]
											.bid_product_image
									}
								/>
							</ImageBox>
							<Details>
								<Brand>
									{orderData.order_details[0].brand}
								</Brand>
								<Name>
									{orderData.order_details[0].product_name}
								</Name>
								<Price>
									₹{orderData.order_details[0].total_cost}
								</Price>
								{orderData.status && (
									<DeliveryStatus>
										Delivery Status :{" "}
										<GreenStatus>
											{orderData.status}
										</GreenStatus>
									</DeliveryStatus>
								)}
								{/* <DeliveryStatusBig>
                  Delivery Status : <GreenStatus>Delivered</GreenStatus>
                </DeliveryStatusBig> */}
							</Details>
						</Left>
						<Right>
							{orderData.status === "payment_failed" ||
							orderData.status === "cancelled" ? (
								<ProgressBar
									percent={100}
									filledBackground="linear-gradient(90deg, #009262 2.2%, #E02B1D 100%)"
									height={5}
								>
									<Step
										transition="scale"
										style={{ flexDirection: "column" }}
									>
										{({ accomplished }) => (
											<>
												<StatusTitle>
													Ordered
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
												<StatusDate>
													{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
												</StatusDate>
											</>
										)}
									</Step>

									<Step transition="scale">
										{({ accomplished }) => (
											<>
												<StatusTitle>
													{orderData.status ===
													"cancelled"
														? orderData.status
														: "Failed"}
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
												<StatusDate>
													{/* {orderData.date_added
														? new Date(
																orderData.date_added
														  ).toDateString()
														: "--"} */}
												</StatusDate>
											</>
										)}
									</Step>
								</ProgressBar>
							) : (
								<ProgressBar
									percent={
										orderData.status === "pending"
											? 0
											: orderData.status === "ordered"
											? 25
											: orderData.status === "packed"
											? 50
											: orderData.status === "shipped"
											? 75
											: orderData.status === "delivered"
											? 100
											: 0
									}
									filledBackground="#009262"
									height={5}
								>
									{/* <Step
										transition="scale"
										style={{ flexDirection: "column" }}
									>
										{({ accomplished }) => (
											<>
												<StatusTitle>
													Pending
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
											</>
										)}
									</Step> */}

									<Step
										transition="scale"
										style={{ flexDirection: "column" }}
									>
										{({ accomplished }) => (
											<>
												<StatusTitle>
													Ordered
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
												<StatusDate>
													{/* {orderData.date_added
														? new Date(
																orderData.date_added
														  ).toDateString()
														: "--"} */}
												</StatusDate>
											</>
										)}
									</Step>
									<Step
										transition="scale"
										style={{ flexDirection: "column" }}
									>
										{({ accomplished }) => (
											<>
												<StatusTitle>
													Packed
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
												<StatusDate>
													{/* {orderData.date_added
														? new Date(
																orderData.date_added
														  ).toDateString()
														: "--"} */}
												</StatusDate>
											</>
										)}
									</Step>
									<Step transition="scale">
										{({ accomplished }) => (
											<>
												<StatusTitle>
													Shipped
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
												<StatusDate>
													{/* {orderData.date_added
														? new Date(
																orderData.date_added
														  ).toDateString()
														: "--"} */}
												</StatusDate>
											</>
										)}
									</Step>
									<Step transition="scale">
										{({ accomplished }) => (
											<>
												<StatusTitle>
													Delivered
												</StatusTitle>
												<img
													className="pImg"
													width="20"
													alt="Image" src={
														accomplished
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
													}
												/>
												<StatusDate>
													{/* {orderData.date_added
														? new Date(
																orderData.date_added
														  ).toDateString()
														: "--"} */}
												</StatusDate>
											</>
										)}
									</Step>
								</ProgressBar>
							)}
						</Right>
						{/* -= */}
					</FlexBox>
					<ButtonBox>
						<Button
							style={{
								marginRight: "15px",
							}}
						>
							Need help?
						</Button>

						{orderData.order_details.bid_product_name === null && (
							<>
								{orderData.status === "delivered" ? (
									<Button
									// onClick={() => setModal(true)}
									>
										Return Order
									</Button>
								) : (
									<Button onClick={() => setModal(true)}>
										Cancel Order
									</Button>
								)}
							</>
						)}
					</ButtonBox>
				</ListItem>
			</ListBox>
			{/* address */}

			<AddressBox>
				<AddLeft>
					<TitleBox>
						<RecName>
							{orderData?.customer_address?.customer}
						</RecName>
						<Label>
							{orderData?.customer_address?.address_type}
						</Label>
					</TitleBox>
					<Address>
						{orderData?.customer_address?.address_line1},{"  "}
						{orderData?.customer_address?.near_landmark}
					</Address>
					<Address>
						{orderData?.customer_address?.city},{"  "}
						{orderData?.customer_address?.state}
					</Address>
					<Address>{orderData?.customer_address?.pincode}</Address>
					<Phone>
						<IconBox>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/phone.svg"
							/>
						</IconBox>
						<Number>{orderData?.customer_address?.phone_no}</Number>
					</Phone>
				</AddLeft>
				{orderData.status === "delivered" && (
					<AddRight>
						<RightTitle>Invoice</RightTitle>
						<RightButton>
							<IconBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/paper.svg"
								/>
							</IconBox>
							<ButtonText>Download Invoice</ButtonText>
						</RightButton>
					</AddRight>
				)}
			</AddressBox>

			{/* review box */}
			{orderData.status === "delivered" && (
				<ReviewBox>
					<ReviewTop>
						<ReviewLeft>
							<ReviewTitle>
								Your Rating & Review product
							</ReviewTitle>
							<StarBox>
								<Star>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
									/>
								</Star>
								<Star>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
									/>
								</Star>
								<Star>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
									/>
								</Star>
								<Star>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
									/>
								</Star>
								<Star>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
									/>
								</Star>
							</StarBox>
						</ReviewLeft>
						{orderData.order_details[0]?.rating_data !== null && (
							<ReviewRight>
								<EditReviewButton
									to={{
										pathname: `/orders/order/edit-review/${orderData.order_details[0].product_code}?${orderData.pk}`,
									}}
									state={{
										type: "edit-review",
										order_pk: orderData.pk,
									}}
								>
									<IconBox>
										<Image
											
											className="img-class"
											alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/edit-2.svg"
										/>
									</IconBox>
									<EditButtonText>Edit</EditButtonText>
								</EditReviewButton>
							</ReviewRight>
						)}
					</ReviewTop>
					<ReviewBottom>
						{orderData.order_details[0]?.rating_data === null && (
							<AddReview
								to={{
									pathname: `/orders/order/add-review/${orderData.order_details[0].product_code}?${orderData.pk}`,
								}}
								state={{
									type: "add-review",
									order_pk: orderData.pk,
								}}
							>
								<IconBox>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/plus.svg"
									/>
								</IconBox>
								<AddReviewText>Add review</AddReviewText>
							</AddReview>
						)}
						{orderData.order_details[0]?.rating_data?.id && (
							<div>
								<BottomTitle>Review</BottomTitle>
								<ReviewText>
									{
										orderData.order_details[0]?.rating_data
											?.review
									}
								</ReviewText>
								<ReviewImages>
									{orderData.order_details[0]?.rating_data?.customer_review_images.map(
										(item) => {
											return (
												<ImageItem>
													<Image
														
														alt="Image" src={item.image}
														className="img-class"
													/>
												</ImageItem>
											);
										}
									)}
								</ReviewImages>
							</div>
						)}
					</ReviewBottom>
				</ReviewBox>
			)}
		</>
	) : (
		<PageLoader />
	);
}

// const Wrapper = styled.div``;
const Title = styled.h3`
	font-family: medium;
	font-size: 22px;
	color: #747474;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
	@media all and (max-width: 420px) {
		font-size: 18px;
	}
	@media all and (max-width: 420px) {
		margin-bottom: 5px;
	}
`;
const Grey = styled.span`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;

const IconBox = styled.div`
	width: 15px;
`;
const Image = styled.img``;
const ListBox = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	@media all and (max-width: 420px) {
		margin-top: 10px;
	}
`;
const ListItem = styled.div`
	padding: 30px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	/* cursor: pointer; */
	background: #fffdfa;
	@media all and (max-width: 420px) {
		padding: 15px;
	}
`;
const FlexBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media all and (max-width: 1080px) {
		flex-direction: column;
	}
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	width: 30%;
	@media all and (max-width: 1080px) {
		width: 100%;
	}
	@media all and (max-width: 420px) {
		align-items: flex-start;
	}
`;
const ImageBox = styled.div`
	width: 30%;
	margin-right: 20px;
	border-radius: 5px;
	overflow: hidden;
	@media all and (max-width: 1080px) {
		width: 10%;
	}
	@media all and (max-width: 768px) {
		width: 20%;
	}
	@media all and (max-width: 420px) {
		width: 35%;
		margin-right: 10px;
	}
`;
const Details = styled.div``;
const Brand = styled.h3`
	font-size: 20px;
	font-family: "medium";
	color: #191919;
	@media all and (max-width: 420px) {
		font-size: 16px;
	}
`;
const Name = styled.h3`
	color: #747474;
	font-size: 18px;
	font-family: "regular";
	margin: 10px 0;
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const Price = styled.span`
	color: #191919;
	font-family: "medium";
	font-size: 16px;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const Right = styled.div`
	.RSPBprogressBar {
		transform: translateX(-8%);
		width: 500px;
		@media all and (max-width: 1080px) {
			/* transform: unset; */
			margin-bottom: 40px;
			transform: translateX(8%);
		}
		@media all and (max-width: 768px) {
			width: 400px;
		}
		@media all and (max-width: 640px) {
			display: none;
		}
	}
	@media all and (max-width: 1080px) {
		transform: unset;
		width: 100%;
		margin-top: 70px;
	}
	@media all and (max-width: 640px) {
		margin-top: 20px;
	}
`;
// const OrderDetails = styled.div`
// 	text-align: right;
// 	@media all and (max-width: 1080px) {
// 		text-align: left;
// 	}
// `;
// const StatusBox = styled.div`
// 	display: flex;
// 	align-items: center;
// 	margin-bottom: 10px;
// 	@media all and (max-width: 420px) {
// 		margin: 0;
// 	}
// `;
// const StatusText = styled.h3`
// 	color: #009262;
// 	font-family: "regular";
// 	font-size: 20px;
// 	margin-left: 10px;
// 	@media all and (max-width: 420px) {
// 		font-size: 16px;
// 	}
// `;
// const Descritpion = styled.span`
// 	color: #747474;
// 	font-family: "regular";
// 	font-size: 18px;
// 	@media all and (max-width: 420px) {
// 		font-size: 14px;
// 	}
// `;
// const RateProduct = styled.span`
// 	display: flex;
// 	align-items: center;
// 	text-align: right;
// 	color: #747474;
// 	font-family: "regular";
// 	font-size: 18px;
// 	justify-content: end;
// 	margin-top: 10px;
// 	text-decoration: underline;
// 	color: #feda00;
// 	cursor: pointer;
// 	@media all and (max-width: 1080px) {
// 		justify-content: start;
// 	}
// 	@media all and (max-width: 420px) {
// 		margin-top: 5px;
// 		font-size: 14px;
// 	}
// `;

// green bar

const StatusTitle = styled.h3`
	color: #191919;
	font-size: 16px;
	font-family: "regular";
	position: absolute;
	top: -20px;
	white-space: nowrap;
	left: -150%;
	@media all and (max-width: 1080px) {
		font-size: 15px;
	}
`;

// const Tick = styled.div``;
const StatusDate = styled.span`
	color: #747474;
	font-size: 15px;
	font-family: "regular";
	position: absolute;
	white-space: nowrap;
	top: 30px;
	left: -150%;
	@media all and (max-width: 1080px) {
		font-size: 13px;
	}
`;
const DeliveryStatus = styled.h3`
	color: #747474;
	font-size: 15px;
	font-family: "regular";
	margin-top: 20px;
	display: none;
	@media all and (max-width: 420px) {
		display: block;
		font-size: 13px;
	}
`;
// const DeliveryStatusBig = styled.h3`
// 	color: #747474;
// 	font-size: 15px;
// 	font-family: "regular";
// 	margin-top: 20px;
// 	display: none;
// 	@media all and (max-width: 768px) {
// 		display: block;
// 	}
// 	@media all and (max-width: 420px) {
// 		font-size: 13px;
// 	}
// `;
const GreenStatus = styled.span`
	color: #009262;
	font-size: 15px;
	font-family: "medium";
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;

// address

const AddressBox = styled.div`
	padding: 30px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	background: #fffdfa;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@media all and (max-width: 768px) {
		flex-direction: column;
	}
	@media all and (max-width: 420px) {
		padding: 15px;
	}
`;
const TitleBox = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;
const RecName = styled.h3`
	color: #191919;
	font-size: 18px;
	font-family: "regular";
`;
const Label = styled.span`
	color: #191919;
	font-size: 14px;
	font-family: "regular";
	padding: 5px 10px;
	border-radius: 5px;
	background: #d4d4d4;
	margin-left: 10px;
`;
const Address = styled.h4`
	color: #747474;
	font-size: 16px;
	font-family: "regular";
	margin-bottom: 5px;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const Phone = styled.div`
	display: flex;
	align-items: center;
	margin-top: 15px;
`;
const Number = styled.span`
	color: #747474;
	font-size: 16px;
	font-family: "regular";
	margin-left: 6px;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;

const ButtonBox = styled.div`
	display: flex;
	align-items: center;
	margin-left: 100px;
	@media all and (max-width: 980px) {
		margin-left: 0;
	}
`;
const Button = styled.span`
	font-family: "regular";
	font-size: 16px;
	margin-top: 20px;
	text-decoration: underline;
	color: #feda00;
	cursor: pointer;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;

// address
const AddLeft = styled.div``;
const AddRight = styled.div`
	text-align: right;
	@media all and (max-width: 768px) {
		text-align: left;
		margin-top: 30px;
	}
`;
const RightTitle = styled.h3`
	font-size: 18px;
	font-family: "medium";
	color: #191919;
`;
const ButtonText = styled.span`
	font-size: 18px;
	font-family: "regular";
	color: #191919;
	margin-left: 10px;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const RightButton = styled.div`
	width: 100%;
	padding: 20px;
	margin-top: 10px;
	height: 40px;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	@media all and (max-width: 420px) {
		padding: 15px;
		height: 35px;
	}
`;
// review
const ReviewBox = styled.div`
	padding: 30px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	background: #fffdfa;
	/* display: flex; */
	margin-top: 30px;
	align-items: flex-start;
	justify-content: space-between;
	@media all and (max-width: 420px) {
		padding: 15px;
	}
`;
const ReviewTitle = styled.h3`
	color: #191919;
	font-size: 18px;
	font-family: "regular";
	margin-bottom: 20px;
	@media all and (max-width: 420px) {
		font-size: 16px;
		margin-bottom: 30px;
	}
`;
const StarBox = styled.div`
	display: flex;
	align-items: center;
`;
const Star = styled.div`
	width: 50px;
	margin-right: 35px;
	:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 768px) {
		width: 30px;
		margin-right: 25px;
	}
	@media all and (max-width: 420px) {
		margin-right: 15px;
	}
`;
const AddReview = styled(Link)`
	display: flex;
	align-items: center;
`;
const AddReviewText = styled.span`
	font-size: 16px;
	font-family: "regular";
	color: #feda00;
	margin-left: 10px;
`;

const ReviewTop = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 20px;
	@media all and (max-width: 640px) {
		/* align-items: center; */
	}
`;
const ReviewLeft = styled.div``;
const ReviewRight = styled.div``;
const EditReviewButton = styled(Link)`
	display: flex;
	align-items: center;
	border: 1px solid #feda00;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	@media all and (max-width: 640px) {
		padding: 2px 8px;
	}
`;
const EditButtonText = styled.span`
	color: #191919;
	font-size: 18px;
	font-family: "regular";
	margin-left: 10px;
	@media all and (max-width: 768px) {
		font-size: 15px;
	}
	@media all and (max-width: 640px) {
		display: none;
	}
`;
const ReviewBottom = styled.div`
	border-top: 1px solid #e4e4e4;
	padding-top: 20px;
`;
const BottomTitle = styled.h3`
	color: #191919;
	font-size: 15px;
	font-family: "regular";
	margin-top: 20px;
`;
const ReviewText = styled.p`
	color: #747474;
	font-size: 17px;
	font-family: "regular";
	margin: 10px 0 20px;
`;
const ReviewImages = styled.div`
	display: flex;
	align-items: center;
`;
const ImageItem = styled.div`
	width: 10%;
	margin-right: 5px;
	border-radius: 5px;
	overflow: hidden;
	:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 640px) {
		width: 18%;
	}
	@media all and (max-width: 420px) {
		width: 25%;
	}
`;
