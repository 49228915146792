import React, { useContext } from "react";
import HeaderPush from "../general/HeaderPush";
// import Footer from "../general/Footer";
import { Context } from "../context/store";
import styled from "styled-components";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import UserProfile from "../includes/profile/UserProfile";
import Coins from "../includes/profile/Coins";
import Wallet from "../includes/profile/Wallet";
import MyOrders from "./MyOrders";
import Header from "../general/Header";

export default function Profile({ searchValue, setSearchValue }) {
	const { state, dispatch } = useContext(Context);

	const profileAsideLinks = [
		{
			id: 1,
			link: "/profile/",
			text: "Profile",
			icon: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/user.svg",
		},
		{
			id: 2,
			link: "/profile/coins",
			text: "Coins",
			icon: 'https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg',
		},
		{
			id: 3,
			link: "/profile/wallet",
			text: "Wallet",
			icon: 'https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/wallet.svg',
		},
		{
			id: 4,
			link: "/orders",
			text: "My Orders",
			icon: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/orders.svg",
		},
	];
	const logout = () => {
		dispatch({
			type: "UPDATE_USER_DATA",
			user_data: {
				...state.user_data,
				is_verified: false,
				access_token: "",
				phone: "",
				country: "",
				name: "",
				email: "",
				gender: "",
				photo: "",
				uid: "",
				user_pk: null,
			},
		});
	};
	return (
		<ProfleMain>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush />
			<Wrapper className="wrapper">
				<Aside>
					<AsideTop>
						{profileAsideLinks.map((item) => {
							return (
								<ProfileTab to={item.link} key={item.id}>
									<IconBox>
										<Image
											
											className="img-class"
											alt="Image" src={item.icon}
										/>
									</IconBox>
									<TabText>{item.text}</TabText>
								</ProfileTab>
							);
						})}
					</AsideTop>
					<AsideBottom>
						{/* <LinkText to="/about-us">About us</LinkText>
						<LinkText to="/about-us">Privacy policy</LinkText>
						<LinkText to="/about-us">Terms of use</LinkText> */}
						<ProfileTabLog onClick={logout}>
							<IconBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/logout.svg"
								/>
							</IconBox>
							<TabText>Logout</TabText>
						</ProfileTabLog>
					</AsideBottom>
				</Aside>
				<Main>
					<Routes>
						<Route element={<UserProfile />} path="/" />
						<Route element={<Coins />} path="/coins" />
						<Route element={<Wallet />} path="/wallet" />
						<Route element={<MyOrders />} path="/my-orders" />
					</Routes>
				</Main>
			</Wrapper>
		</ProfleMain>
	);
}

const ProfleMain = styled.section`
	padding: 2% 0;
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 1000px;
	@media all and (max-width: 980px) {
		flex-direction: column-reverse;
	}
`;

const Aside = styled.aside`
	background: #fffdfa;
	padding: 20px;
	width: 27%;
	@media all and (max-width: 980px) {
		display: none;
	}
`;
const AsideTop = styled.div`
	position: relative;
	padding-bottom: 40px;
	&::after {
		display: block;
		content: "";
		height: 3px;
		width: 75%;
		position: absolute;
		bottom: 0;
		left: 50%;
		right: 50%;
		transform: translateX(-50%);
		background-color: #fff7ea;
	}
`;
const ProfileTab = styled(NavLink)`
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 10px;
	&.active {
		background: #fff7ea;
	}
	&:last-child {
		margin-bottom: 0;
	}
`;
const ProfileTabLog = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 20px;
`;
const IconBox = styled.div`
	width: 20px;
	height: 20px;
	margin-right: 10px;
`;
const Image = styled.img``;
const TabText = styled.span`
	font-size: 16px;
	font-family: "medium";
	color: #747474;
`;
const AsideBottom = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`;
const LinkText = styled(Link)`
	font-size: 15px;
	font-family: "medium";
	color: #747474;
	margin-bottom: 40px;
`;
const Main = styled.main`
	width: 70%;
	@media all and (max-width: 980px) {
		width: 100%;
	}
`;
