import React, { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../context/store";

export default function ProductSummary({
	selectedAddress,
	productData,
	type,
	quantity,
	attr,
}) {
	const { state } = useContext(Context);
	return (
		<>
			<Title>Product Details</Title>
			{type === "cart_products" ? (
				productData?.cart_items?.map((item) => {
					return (
						<ProductDetailCard key={item.id}>
							<ProductImage>
								<Image
									
									className="img-class"
									alt="Image" src={item.product_image}
								/>
							</ProductImage>
							<CardRight>
								<ProductName>{item.product_name}</ProductName>
								<Size>
									{item.product_attribute?.attribute} :{" "}
									{item.product_attribute?.attribute}, Qty :{" "}
									{item.quantity}
								</Size>
								<Price>₹{item.product_cost}</Price>
							</CardRight>
						</ProductDetailCard>
					);
				})
			) : (
				<ProductDetailCard>
					<ProductImage>
						<Image
							
							className="img-class"
							alt="Image" src={
								type === "bid_redeem"
									? productData.bid_product_image
									: productData.featured_image
							}
						/>
					</ProductImage>
					<CardRight>
						<ProductName>
							{type === "bid_redeem"
								? productData.bid_product_name
								: productData.name}
						</ProductName>
						{type !== "bid_redeem" && (
							<Size>
								{attr}, Quantity : {quantity}
							</Size>
						)}
						<Price
							style={{
								marginTop:
									type === "bid_redeem" ? "10px" : "unset",
							}}
						>
							₹
							{type === "bid_redeem"
								? productData.total_bid_amount
								: productData.display_cost}
						</Price>
					</CardRight>
				</ProductDetailCard>
			)}
			<Title>Delivery Address</Title>
			<AddressCardContainer key={selectedAddress.id}>
				<Top>
					<Name>{state.user_data.name}</Name>
					<Box>
						<IconBox>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/phone.svg"
							/>
						</IconBox>
						<PhoneNumber>{selectedAddress.phone_no}</PhoneNumber>
					</Box>
				</Top>
				<Middle>
					<Address>
						{selectedAddress.address_line1}
						<br />
						{selectedAddress.near_landmark}
						<br />
						{selectedAddress.pincode},{selectedAddress.city}
						<br />
						{selectedAddress.state}
					</Address>
				</Middle>
			</AddressCardContainer>
			{/* <PaymentMethod marginTop={true} /> */}
		</>
	);
}

const Title = styled.div`
	color: #747474;
	font-size: 22px;
	font-family: "medium";
	margin-bottom: 25px;
	@media all and (max-width: 768px) {
		margin-bottom: 10px;
		font-size: 20px;
	}
`;

const ProductDetailCard = styled.div`
	background-color: #fffdfa;
	border: 1px solid #f2f0ed;
	border-radius: 5px;
	display: flex;
	align-items: flex-start;
	padding: 40px;
	@media all and (max-width: 420px) {
		padding: 15px;
	}
	margin-bottom: 40px;
`;
const ProductImage = styled.div`
	width: 40%;
	border-radius: 5px;
	overflow: hidden;
	margin-right: 20px;
	@media all and (max-width: 980px) {
		width: 30%;
	}
	@media all and (max-width: 420px) {
		width: 110px;
		height: 110px;
	}
`;
const Image = styled.img``;
const CardRight = styled.div``;
const ProductName = styled.h3`
	font-family: regular;
	font-size: 22px;
	color: #191919;
	@media all and (max-width: 980px) {
		flex-direction: column-reverse;
	}
	@media all and (max-width: 768px) {
		font-size: 18px;
	}
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const Size = styled.h3`
	font-family: regular;
	font-size: 18px;
	color: #747474;
	margin: 15px 0;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const Price = styled.h3`
	font-family: medium;
	font-size: 18px;
	color: #191919;

	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;

// address card

// const Title = styled.h3`
// 	color: #747474;
// 	font-size: 22px;
// 	font-family: "medium";
// 	margin-bottom: 25px;
// 	@media all and (max-width: 768px) {
// 		margin-bottom: 10px;
// 		font-size: 20px;
// 	}
// `;
const AddressCardContainer = styled.div`
	background-color: #fffdfa;
	border: 1px solid #f2f0ed;
	border-radius: 5px;
	padding: 40px;
	margin-bottom: 25px;
	@media all and (max-width: 420px) {
		padding: 15px;
	}
`;
const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
`;
const Name = styled.h3`
	color: #000000;
	font-size: 20px;
	font-family: "medium";
	@media all and (max-width: 420px) {
		font-size: 18px;
	}
`;
const Box = styled.div`
	display: flex;
	align-items: center;
`;
const IconBox = styled.div`
	width: 17px;
	margin-right: 5px;
`;
// const Image = styled.img``;
const PhoneNumber = styled.span`
	color: #747474;
	font-size: 18px;
	font-family: "regular";
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const Middle = styled.div``;
const Address = styled.h3`
	color: #747474;
	font-size: 18px;
	font-family: "regular";
	line-height: 25px;
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
// const EditBox = styled.div`
// 	display: flex;
// 	align-items: center;
// 	margin-top: 20px;
// 	cursor: pointer;
// `;
// const EditButton = styled.span`
// 	color: #fedc0a;
// 	font-size: 18px;
// 	font-family: "medium";
// `;
// const AddAddressButton = styled.span`
// 	color: #fedc0a;
// 	font-size: 20px;
// 	font-family: "medium";
// 	display: inline-block;
// 	text-align: right;
// 	width: 100%;
// 	cursor: pointer;
// 	@media all and (max-width: 420px) {
// 		font-size: 18px;
// 		padding-bottom: 30px;
// 	}
// `;
