import React, { useEffect, useState } from "react";
import styled from "styled-components";

export function BiddingMainCountDown({
	bidStatus,
	initialTime,
	timeEnd,
	setTimeEnd
}) {
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	// const [timeEnd, setTimeEnd] = useState(false);
	const x = new Date(initialTime).getTime();
	const getTime = () => {
		// const time = Date.parse(deadline) - Date.now();
		const time = x - Date.now();
		setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
		setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
		setMinutes(Math.floor((time / 1000 / 60) % 60));
		setSeconds(Math.floor((time / 1000) % 60));
	};
	let interval;
	useEffect(() => {
		if (
			days < 0 ||
			hours < 0 ||
			minutes < 0 ||
			seconds < 0 ||
			bidStatus === "ended"
		) {
			clearInterval(interval);
			setTimeEnd(true);
			setDays(0);
			setHours(0);
			setMinutes(0);
			setSeconds(0);
			// handleEndBid();
		} else {
			interval = setInterval(() => getTime(x), 1000);
			// setTimeEnd(false);
		}
		return () => clearInterval(interval);
	}, [days, hours, minutes, seconds]);

	return (
		<TimeStatusBox timeEnd={timeEnd}>
			{!timeEnd ? (
				<>
					<Box>
						<Text className="bid-time">
							{hours < 10 ? `0${hours}` : hours}
						</Text>
						<Text className="bid-time-type">Hour</Text>
					</Box>
					<Box>
						<Text className="bid-time">
							{minutes < 10 ? `0${minutes}` : minutes}
						</Text>
						<Text className="bid-time-type">Minutes</Text>
					</Box>
					<Box>
						<Text className="bid-time">
							{seconds < 10 ? `0${seconds}` : seconds}
						</Text>
						<Text className="bid-time-type">Seconds</Text>
					</Box>
				</>
			) : (
				<Text style={{ color: "#E02B1D" }} className="bid-time">
					Bid Closed
				</Text>
			)}
		</TimeStatusBox>
	);
}

const TimeStatusBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 7%;
	@media all and (max-width: 768px) {
		margin-top: 0;
		width: 23%;
		margin-left: 5%;
	}
	@media (max-width: 640px) {
		width: ${({ timeEnd }) => (timeEnd ? "unset" : "45%")};
	}
	@media all and (max-width: 420px) {
		/* width: 50%; */
	}
`;
const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Text = styled.span`
	font-family: medium;

	&.bid-time {
		color: #ffdf17;
		font-family: bold;
		font-size: 35px;
		margin-bottom: 5px;
		@media all and (max-width: 1080px) {
			font-size: 28px;
		}
		@media all and (max-width: 768px) {
			font-size: 20px;
			margin-bottom: 0;
		}
		@media all and (max-width: 420px) {
			font-size: 17px;
		}
	}
	&.bid-time-type {
		color: #efefef;
		font-family: medium;
		font-size: 20px;
		@media all and (max-width: 1080px) {
			font-size: 16px;
		}
		@media all and (max-width: 768px) {
			font-size: 11px;
			font-family: regular;
		}
	}
`;
