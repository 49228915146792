import React from "react";
import styled from "styled-components";
import { GradientButton } from "../../constants";
import { useNavigate } from "react-router-dom";

export default function InsufficientCoins({ isModal, setModal }) {
	const navigate = useNavigate();
	return (
		<ModalContainer onClick={() => setModal(false)} isModal={isModal}>
			<ModalFade isModal={isModal}></ModalFade>
			<ModalBox>
				<Top>
					<Title>Insufficient coins</Title>
					<Close onClick={() => setModal(false)}>
						<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg" />
					</Close>
				</Top>
				<Description>
					You have insufficient amount of coins to continue bidding,
					purachase coins to continue bidding.
				</Description>
				<GradientButton
					onClick={() => {
						navigate("/coins/purchase-coins");
					}}
					fontColor={"#747474"}
					fontSize={"18px"}
					fontFamily={"medium"}
					br={"5px"}
					text="Purchase coins"
					width={"230px"}
					height={"45px"}
					margin={"70px 0 0 auto"}
				/>
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s ease-out;
	transform: scale(1.1);
	background: transparent;
	transform: ${({ isModal }) => (isModal ? "scale(1)" : "scale(0)")};
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: ${({ isModal }) => (isModal ? "block" : "none")};
`;
const ModalBox = styled.div`
	background: #fff;
	padding: 40px;
	border-radius: 5px;
	width: 600px;
	position: relative;
	z-index: 1001;
	@media all and (max-width: 768px) {
		width: 500px;
	}
	@media all and (max-width: 640px) {
		width: 350px;
		padding: 20px;
	}
	@media all and (max-width: 420px) {
		width: 300px;
	}
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #e7e7e7;
	padding-bottom: 20px;
	margin-bottom: 30px;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #003c3c;
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
`;
const Close = styled.div`
	width: 15px;
	cursor: pointer;
`;
const Image = styled.img``;
const Description = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #003c3c;
	line-height: 25px;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
