import React, { useContext, useState } from "react";
import styled from "styled-components";
import { GradientButton } from "../../constants";
import close from "../../../../assets/images/bidding/winner-medal.png";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/winning.json";
import { Context } from "../../../context/store";

export default function WinnerModal({
	winnerDetails,
	winningModal,
	setWinningModal,
}) {
	const { state } = useContext(Context);
	const navigate = useNavigate();
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<ModalContainer
			onClick={() => setWinningModal(false)}
			winningModal={winningModal}
		>
			<ModalFade winningModal={winningModal}></ModalFade>
			<ModalBox onClick={() => setWinningModal(true)}>
				<Animation>
					<Lottie
						options={defaultOptions}
						height={"100%"}
						width={"100%"}
					/>
				</Animation>
				<Title>Winner</Title>
				<Middle>
					<Left>
						<ProfileBox>
							<Medal>
								<img
									alt="Image" src={require("../../../../assets/images/bidding/winner-medal.png")}
									
									className="img-class"
								/>
							</Medal>
							<ProfileImage
								style={{
									background: winnerDetails?.profileImage
										? "transparent"
										: "#FFDF17",
								}}
							>
								{winnerDetails?.profileImage ? (
									<img
										alt="Image" src={winnerDetails?.profileImage}
										
										className="img-class"
									/>
								) : (
									<ProfileLetter>
										{/* {winnerDetails?.name?.charAt(0)} */}yz
									</ProfileLetter>
								)}
							</ProfileImage>
						</ProfileBox>
					</Left>
					<Right>
						<TextBox>
							<Name>{winnerDetails?.name} </Name>
							<SubTitle>Won the bid for</SubTitle>
							<Bold> ₹300</Bold>
						</TextBox>

						<Box>
							<ProductImage>
								<img
									alt="Image" src={winnerDetails?.productImage}
									
									className="img-class"
								/>
							</ProductImage>
							<ProductName>
								{winnerDetails?.productName}
							</ProductName>
						</Box>
					</Right>
				</Middle>
				{state.user_data.user_pk === winnerDetails?.userPk && (
					<Button to="/bidding/winnings">
						<Text>Continue</Text>
					</Button>
				)}
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s ease-out;
	transform: scale(1.1);
	background: transparent;
	transform: ${({ winningModal }) =>
		winningModal ? "scale(1)" : "scale(0)"};
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: ${({ winningModal }) => (winningModal ? "block" : "none")};
`;
const ModalBox = styled.div`
	background: #fff;
	padding: 3% 10%;
	border-radius: 5px;
	width: 50%;
	position: relative;
	z-index: 1001;
	@media all and (max-width: 1080px) {
		width: 70%;
	}
	@media all and (max-width: 980px) {
		padding: 3% 6%;
	}
	@media all and (max-width: 768px) {
		width: 80%;
	}
	@media all and (max-width: 640px) {
		padding: 5%;
		width: 85%;
	}
`;

const Title = styled.h3`
	font-size: 30px;
	font-family: medium;
	color: #191919;
	text-align: center;
	margin-bottom: 10%;
	@media all and (max-width: 768px) {
		font-size: 23px;
	}
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
`;
const Middle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media all and (max-width: 640px) {
		flex-direction: column;
	}
`;
const Left = styled.div`
	width: 35%;
`;
const ProfileBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Medal = styled.div`
	width: 100%;
	/* position: absolute;
	top: 0;
	left: 0; */
`;
const ProfileImage = styled.div`
	width: 65%;
	height: 65%;
	border-radius: 50%;
	position: absolute;
	top: 44%;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 50%;
	left: 50%;
	right: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	/* border: 1px solid #c4c4c4; */
`;
const Right = styled.div`
	width: 58%;
	@media all and (max-width: 640px) {
		width: 100%;
		margin-top: 5%;
	}
`;
const Name = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #d8be62;
	@media all and (max-width: 768px) {
		font-size: 18px;
	}
	@media all and (max-width: 640px) {
		font-size: 13px;
	}
`;
const SubTitle = styled.h4`
	color: #747474;
	font-size: 16px;
	font-family: regular;
	margin: 0 1%;
	@media all and (max-width: 768px) {
		font-size: 14px;
	}
	@media all and (max-width: 640px) {
		font-size: 10px;
	}
`;
const Bold = styled.span`
	color: #191919;
	font-size: 18px;
	font-family: regular;
	@media all and (max-width: 768px) {
		font-size: 14px;
	}
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
const Box = styled.div`
	display: flex;
	align-items: center;
	margin-top: 5%;
	@media all and (max-width: 640px) {
		justify-content: center;
	}
`;
const ProductImage = styled.div`
	width: 13%;
	border-radius: 6px;
`;
const ProductName = styled.h3`
	color: #747474;
	font-size: 16px;
	font-family: regular;
	margin-left: 2%;
	@media all and (max-width: 768px) {
		font-size: 13px;
	}
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
const Button = styled(Link)`
	background: rgb(252, 245, 157);
	background: linear-gradient(
		90deg,
		rgba(252, 245, 157, 1) 11%,
		rgba(251, 219, 3, 1) 100%
	);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 20;
	border-radius: 5px;
	cursor: pointer;
	margin: 10% auto 0;
	width: 63%;
	height: 40px;
	@media all and (max-width: 420px) {
		height: 30px;
	}
`;
const Text = styled.h3`
	color: rgb(116, 116, 116);
	font-family: medium;
	font-size: 18px;
	@media all and (max-width: 768px) {
		font-size: 14px;
	}
	@media all and (max-width: 640px) {
		font-size: 12px;
	}
`;
const Animation = styled.h3`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
const TextBox = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
`;
const ProfileLetter = styled.span`
	color: #747474;
	font-size: 55px;
	font-family: bold;
	@media all and (max-width: 768px) {
		font-size: 35px;
	}
`;
