import React, { useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AuthScreen from "../screens/AuthScreen";
import Cart from "../screens/Cart";
import Home from "../screens/Home";
import ProductSingle from "../screens/ProductSingle";
import PageNotFound from "../screens/PageNotFound";
import Products from "../screens/Products";
import Seller from "../screens/Seller";
import PrivateRoute from "./PrivateRoute";
import BiddingRouter from "./routes/BiddingRouter";
import CheckoutRouter from "./routes/CheckoutRouter";
import { Context } from "../context/store";
import PageLoader from "../screens/PageLoader";
import Profile from "../screens/Profile";
import WishList from "../screens/WishList";
import MyOrders from "../screens/MyOrders";
import NoEvents from "../includes/bidding/NoEvents";
import { accountConfig } from "../../apiConfig";
import FeaturedItem from "../screens/FeaturedItem";
import WeeklyTrendsProducts from "../screens/weeklyTrendsProducts";
import Legal from "../screens/Legal";
import CoinRouter from "./routes/CoinRouter";
import PaymentStatus from "../includes/checkout/PaymentStatus";
import CoinPurchaseStatus from "../includes/bidding/CoinPurchaseStatus";
import ContactUs from "../includes/legal/ContactUs";
import About from "../includes/legal/About";

export default function MainRouter({ reswidth }) {
	const { state, dispatch } = useContext(Context);
	const [searchValue, setSearchValue] = useState("");
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchUserData() {
			let promise = new Promise((resolve, reject) => {
				let user_data = localStorage.getItem("user_data");
				user_data = JSON.parse(user_data);
				if (user_data?.access_token) {
					userVerify(user_data.access_token);
				}
				dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
				setTimeout(() => {
					resolve("done!");
					setLoading(false);
				}, 500);
			});

			let result = await promise;
		}
		fetchUserData();

		// user verification
		function userVerify(token) {
			accountConfig
				.get("verify/profile/", {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					setLoading(false);
				})
				.catch((err) => {
					dispatch({
						type: "UPDATE_USER_DATA",
						user_data: {
							...state.user_data,
							is_verified: false,
							access_token: "",
							phone: "",
							country: "",
							name: "",
							email: "",
							gender: "",
							photo: "",
							uid: "",
							user_pk: null,
						},
					});
					setLoading(false);
				});
		}
	}, []);

	return (
		<>
			{isLoading ? (
				<PageLoader />
			) : (
				<Router>
					<Routes>
						{/* <Route
							path="*"
							element={<Navigate to="/page-not-found" />}
						/> */}
						<Route path="*" element={<PageNotFound />} />

						<Route
							path="/page-not-found"
							element={<PageNotFound />}
						/>
						<Route
							element={
								<Home
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/"
						/>
						<Route
							element={
								<Products
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/products/:filter_details"
						/>
						<Route
							element={
								<FeaturedItem
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/featured/:item_pk"
						/>
						<Route
							element={
								<WeeklyTrendsProducts
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/trend-of-the-week"
						/>
						<Route
							element={
								<ProductSingle
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/product/:product_id"
						/>
						<Route
							element={
								<Seller
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/seller"
						/>
						<Route
							element={
								<Legal
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/legal-aspects/*"
						/>
						<Route
							element={
								<ContactUs
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/contact"
						/>
						<Route
							element={
								<About
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/about"
						/>
						<Route
							element={
								<AuthScreen
									reswidth={reswidth}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							}
							path="/login"
						/>
						{/* private route  */}
						<Route element={<PrivateRoute />}>
							<Route
								element={
									<Profile
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/profile/*"
							/>
							{/* after payment */}
							{/* <Route
								element={
									<PaymentRouter
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/purchases/*"
							/> */}
							<Route
								element={<CheckoutRouter reswidth={reswidth} />}
								path="/checkout/*"
							/>
							<Route
								element={
									<MyOrders
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/orders/*"
							/>
							<Route
								element={
									<Cart
										reswidth={reswidth}
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/cart"
							/>
							<Route
								element={
									<WishList
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/wishlist"
							/>
							<Route
								element={
									<NoEvents
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/no-events"
							/>

							<Route
								element={
									<CoinRouter
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/coins/*"
							/>
							<Route
								element={
									<BiddingRouter
										reswidth={reswidth}
										searchValue={searchValue}
										setSearchValue={setSearchValue}
									/>
								}
								path="/bidding/*"
							/>
							{/* payment status */}
							<Route
								element={<CoinPurchaseStatus />}
								path="/purchases/coins/"
							/>
							<Route
								element={<PaymentStatus />}
								path="purchases/product/"
							/>
						</Route>
					</Routes>
				</Router>
			)}
		</>
	);
}
