import React from "react";
import styled from "styled-components";

export default function ProductSingleStars({ ratingData }) {
	return (
		<SummuryRight>
			<StartLineItem>
				<ReviewStatus>Excellent</ReviewStatus>
				<Stars>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-start.svg"
						/>
					</StarStatus>
				</Stars>
				<StarCount>
					{
						ratingData?.filter((item) => {
							return parseInt(item.stars) === 5;
						}).length
					}
				</StarCount>
			</StartLineItem>
			<StartLineItem>
				<ReviewStatus>Very good</ReviewStatus>
				<Stars>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/light-green.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/light-green.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/light-green.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/light-green.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
				</Stars>
				<StarCount>
					{
						ratingData?.filter((item) => {
							return (
								parseInt(item.stars) === 4 &&
								parseInt(item.stars) < 5
							);
						}).length
					}
				</StarCount>
			</StartLineItem>
			<StartLineItem>
				<ReviewStatus>Good</ReviewStatus>
				<Stars>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/dark-golden.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/dark-golden.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/dark-golden.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
				</Stars>
				<StarCount>
					{
						ratingData?.filter((item) => {
							return (
								parseInt(item.stars) === 3 &&
								parseInt(item.stars) < 4
							);
						}).length
					}
				</StarCount>
			</StartLineItem>
			<StartLineItem>
				<ReviewStatus>Average</ReviewStatus>
				<Stars>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
				</Stars>
				<StarCount>
					{
						ratingData?.filter((item) => {
							return (
								parseInt(item.stars) === 2 &&
								parseInt(item.stars) < 3
							);
						}).length
					}
				</StarCount>
			</StartLineItem>
			<StartLineItem>
				<ReviewStatus>Poor</ReviewStatus>
				<Stars>
					<StarStatus>
						<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/red-start.svg" />
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
					<StarStatus>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
						/>
					</StarStatus>
				</Stars>
				<StarCount>
					{
						ratingData?.filter((item) => {
							return (
								parseInt(item.stars) === 1 &&
								parseInt(item.stars) < 2
							);
						}).length
					}
				</StarCount>
			</StartLineItem>
		</SummuryRight>
	);
}

const SummuryRight = styled.div`
	width: 53%;
	flex-direction: column;
	display: flex;
	align-items: end;
	padding-right: 20px;
	border-left: 2px solid #c4c4c4;
	@media all and (max-width: 1280px) {
		padding-left: 20px;
		width: 60%;
	}
	@media all and (max-width: 980px) {
		padding-right: 0;
	}
`;
const StartLineItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
	width: 80%;
	@media all and (max-width: 1280px) {
		width: 100%;
	}
`;
const ReviewStatus = styled.span`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	width: 90px;
	@media all and (max-width: 980px) {
		font-size: 12px;
		width: 65px;
	}
	@media all and (max-width: 420px) {
		font-size: 10px;
		width: 50px;
	}
`;
const Stars = styled.div`
	display: flex;
	align-items: center;
`;
const StarStatus = styled.div`
	width: 20px;
	height: 20px;
	margin-right: 5px;
	:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 980px) {
		width: 15px;
		height: 15px;
		margin-right: 4px;
	}
	@media all and (max-width: 420px) {
		width: 12px;
		height: unset;
	}
`;
const StarCount = styled.div`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	@media all and (max-width: 980px) {
		font-size: 12px;
	}
	@media all and (max-width: 420px) {
		font-size: 10px;
	}
`;

const Image = styled.img``;
