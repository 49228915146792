import React, { useEffect } from "react";
import styled from "styled-components";

export default function TermsAndConditions() {
	//when onload the page ,scroll top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Wrapper>
				<Title>Terms & conditions</Title>
				<Content>
					Terms and conditions (also known as terms of service or
					terms of use) are a legal agreement between the company that
					operates an Oneruppee shopping application and the users of
					the application. These terms outline the rights and
					responsibilities of both parties and can include a variety
					of information, such as the rights and responsibilities of
					the users, the types of products and services that are
					offered, the payment terms, the return and refund policy,
					and any limitations of liability.It is important for users
					to carefully review and understand the terms of use before
					using an online shopping app to ensure that they are aware
					of their rights and responsibilities. Here are some common
					provisions that may be included in the terms and conditions
					for an Oneruppee shopping application:
				</Content>
				<SubTitle>Collection and use of personal information:</SubTitle>
				<Content>
					The terms of use may outline how the company collects and
					uses personal information, including for the purposes of
					processing orders, delivering products, and improving the
					user experience. The terms may also explain the steps that
					the company takes to protect personal information and may
					provide information on how users can access and control the
					use of their personal information.
				</Content>
				<SubTitle>Account creation:</SubTitle>
				<Content>
					The terms may require users to create an account in order to
					use certain features of the application, and may specify the
					types of information that the user is required to
					provide.Your mobile phone number and/or e-mail address is
					treated as your primary identifier on the Platform. It is
					your responsibility to ensure that Your mobile phone number
					and your email address is up to date on the Platform at all
					times.
				</Content>
				<SubTitle>Payment:</SubTitle>
				<Content>
					The terms of use may outline the payment options available
					to users and may include provisions related to refunds,
					returns, and disputes.Your online transaction on Oneruppee
					is secure with the highest levels of transaction security
					currently available on the Internet. Oneruppee uses 256-bit
					encryption technology to protect your card information while
					securely transmitting it to the respective banks for payment
					processing. All credit card and debit card payments on
					Oneruppee are processed through secure and trusted payment
					gateways managed by leading banks. Banks now use the 3D
					Secure password service for online transactions, providing
					an additional layer of security through identity
					verification. If you are not comfortable making an online
					payment on Oneruppee.com, you can opt for the Cash on
					Delivery (C-o-D) payment method instead. With C-o-D you can
					pay in cash at the time of actual delivery of the product at
					your doorstep, without requiring you to make any advance
					payment online. The maximum order value for a Cash on
					Delivery (C-o-D) payment is ₹50,000. It is strictly a
					cash-only payment method. Gift Cards or store credit cannot
					be used for C-o-D orders. Foreign currency cannot be used to
					make a C-o-D payment. Only Indian Rupees accepted.
				</Content>
				<SubTitle>Disclaimer of warranties:</SubTitle>
				<Content>
					The terms may include a disclaimer of warranties, which
					means that the company is not responsible for any errors or
					omissions on the app or for any problems with the products
					or services that are purchased through the app.
				</Content>
				<SubTitle>Intellectual property:</SubTitle>
				<Content>
					The terms of use may include provisions related to the
					ownership and use of intellectual property, such as
					trademarks, copyrights, and patents. These provisions may
					outline the rights of the Oneruppee and may prohibit users
					from using the company's intellectual property without
					permission.
				</Content>
				<SubTitle>Limitation of liability:</SubTitle>
				<Content>
					The terms may limit the company's liability for any damages
					or losses that may arise from using the app or purchasing
					products through the app. Users should carefully review and
					understand the terms of use before using Oneruppee online
					shopping application to ensure that they are aware of their
					rights and responsibilities.
				</Content>
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	padding: 3% 0;
	min-height: calc(100vh - 420px);
	@media all and (max-width: 640px) {
		padding: 8% 0;
	}
`;
const Wrapper = styled.div`
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	text-align: left;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #191919;
	padding-bottom: 20px;
	border-bottom: 1px solid #c4c4c4;
	margin-bottom: 30px;
	text-align: center;
	@media all and (max-width: 640px) {
		font-size: 19px;
		margin-bottom: 25px;
		padding-bottom: 15px;
	}
`;
const Content = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	line-height: 1.2em;
	letter-spacing: 0.3px;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 25px;
	}
`;
const SubTitle = styled.h3`
	font-size: 20px;
	font-family: regular;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;
