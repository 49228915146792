import React, { useEffect } from "react";
import styled from "styled-components";

export default function Shipping() {
	//when onload the page ,scroll top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Wrapper>
				<Title>Shipping policy</Title>
				<SubTitle>Delivery locations:</SubTitle>
				<Content>
					We are delighted to ship in all States & UT of Indian
					Country. We ensures quick and hassle-free delivery of all
					your products across India.
				</Content>
				<SubTitle>Shipping costs:</SubTitle>
				<Content>
					Shipping charges vary from seller to seller. Delivery
					Charges will be procured from the buyer which will be
					affordable and a basic cost. Courier partners, like Ecom
					Express, Delhivery, Bluedart, FedEx, and others work to
					deliver items.
				</Content>
				<SubTitle>Delivery time:</SubTitle>
				<Content>
					Orders will be shipped within 2-3 business days and
					delivered within 4-5 business days. Usually the Delivery
					time varies from seller to seller and product to product.
				</Content>
				<SubTitle>Tracking information:</SubTitle>
				<Content>
					Tracking number enables to track the package.Customers can
					get instant updates of their ordered product status,from the
					time of dispatch with the seller, through the many
					checkpoints and through multiple handlers, until your
					Oneruppee parcel is delivered to your home.
				</Content>

				<Title>Delivery and Returns</Title>
				<Content>
					The process for delivery and returns of products,are safe
					and secured with the extensive network of Delivery team for
					your delightful shopping experience.Delivery charge varies
					with each Seller. Returns is a scheme provided by respective
					sellers directly under this policy in terms of which the
					option of exchange, replacement and/ or refund is offered by
					the respective sellers to you. All products listed under a
					particular category may not have the same returns policy.
					For all products, the returns/replacement policy provided on
					the product page shall prevail over the general returns
					policy.
				</Content>
				<SubTitle>Returns and exchanges:</SubTitle>
				<Content>
					If a customer is not satisfied with the order, one can
					easily return/exchange the product using our application
					itself. Product has to be in original & unused state.
					Request has to be raised within 7 days of delivery. We
					accept the request if there is a mismatch in quality, size,
					color or design or in case an item is missing/wrong in a
					combo order.
				</Content>
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	padding: 3% 0;
	min-height: calc(100vh - 420px);
	@media all and (max-width: 640px) {
		padding: 8% 0;
	}
`;
const Wrapper = styled.div`
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	text-align: left;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #191919;
	padding-bottom: 20px;
	border-bottom: 1px solid #c4c4c4;
	margin-bottom: 30px;
	text-align: center;
	@media all and (max-width: 640px) {
		font-size: 19px;
		margin-bottom: 25px;
		padding-bottom: 15px;
	}
`;
const Content = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	line-height: 1.2em;
	letter-spacing: 0.3px;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 25px;
	}
`;
const SubTitle = styled.h3`
	font-size: 20px;
	font-family: regular;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;
