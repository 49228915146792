import React from "react";
import { Route, Routes } from "react-router-dom";
// import Header from "../../general/Header";
// import Bidding from "../../screens/Bidding";
import LiveBidding from "../../includes/bidding/LiveBidding";
import BiddingRemind from "../../includes/bidding/BiddingRemind";
import PageNotFound from "../../screens/PageNotFound";
import LiveBiddingList from "../../screens/LiveBiddingList";
import UpcommingBidding from "../../screens/UpcommingBidding";
import KnowMore from "../../includes/bidding/KnowMore";
import WinningEvents from "../../includes/bidding/WinningEvents";
import BiddenEvents from "../../includes/bidding/BiddenEvents";
import ClaimRewards from "../../includes/bidding/ClaimRewards";
// import Footer from "../../general/Footer";

export default function BiddingRouter({
	reswidth,
	searchValue,
	setSearchValue,
}) {
	// const location = useLocation();
	return (
		<>
			{/* <Header /> */}
			<Routes>
				<Route path="*" element={<PageNotFound />} />
				<Route path="/" element={<PageNotFound />} />
				<Route
					element={
						<LiveBiddingList
							reswidth={reswidth}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/live-biddings"
				/>
				<Route
					element={
						<UpcommingBidding
							reswidth={reswidth}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/upcoming-biddings"
				/>
				{/* <Route
					element={
						<RedeemBid
							reswidth={reswidth}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/redeem-your-bid"
				/> */}
				<Route
					element={
						<WinningEvents
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/redeem-your-bid"
				/>
				<Route
					element={<LiveBidding reswidth={reswidth} />}
					path="/live-bidding/:bid_id"
				/>
				<Route
					element={
						<BiddingRemind
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/remind-me"
				/>
				<Route
					element={
						<BiddenEvents
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/my-biddings"
				/>
				<Route
					element={
						<WinningEvents
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/winnings"
				/>
				<Route
					element={
						<KnowMore
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/know-more"
				/>
				<Route
					element={
						<ClaimRewards
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					}
					path="/claim-wallet"
				/>
			</Routes>
			{/* <Footer /> */}
		</>
	);
}
