import React from "react";
import styled from "styled-components";
import offlineIcon from "../../assets/icons/offline.svg";

export default function Offline() {
	
	return (
		<>
			<OfflineContainer>
				<Image>
					<img alt="Image" src={offlineIcon}  className="img-class" />
				</Image>
				<Title>No Connection</Title>
				<Description>
					Please check your internet connectivity and try again
				</Description>
				<Button>Retry</Button>
			</OfflineContainer>
		</>
	);
}

const OfflineContainer = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 80px);
`;
const Image = styled.div`
	width: 20%;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #191919;
	margin: 2% 0;
`;
const Description = styled.p`
	font-size: 18px;
	font-family: medium;
	color: #747474;
	margin-bottom: 2%;
`;
const Button = styled.span`
	background-color: #ffdf17;
	font-size: 14px;
	font-family: medium;
	color: #191919;
	padding: 10px 15px;
	border-radius: 5px;
	cursor: pointer;
`;
