import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

//router dom
import { Link, useLocation, useNavigate } from "react-router-dom";

//context and configs
import { ordersConfig, shopConfig } from "../../apiConfig";
import { Context } from "../context/store";

//components and hooks
import MobileMenu from "./MobileMenu";
import useComponentVisible from "../includes/constants";

export default function Header({
	searchValue,
	setSearchValue,
	setRefresh,
	isRefresh,
}) {
	const { state } = useContext(Context);
	const [isMenu, setMenu] = useState(false);
	const [isMobileSearch, setMobileSearch] = useState(false);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);
	const [cartCount, setCartCount] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [subCategoryId, setSubCategoryId] = useState("");
	const [name, setName] = useState("");
	const [subCategory, setSubCategory] = useState("");

	//
	const navigate = useNavigate();
	const location = useLocation();
	

	//diable scroll when modal is activated
	useEffect(() => {
		if (isMenu) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "scroll";
		}
		if (state.user_data.is_verified) {
			ordersConfig
				.get("cart/", {
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				})
				.then((res) => {
					setCartCount(res.data.data.cart_items);
				})
				.catch((error) => {});
		}
	}, [isMenu, isRefresh]);

	// when search enter
	function handleKeyDown(e) {
		if (e.key === "Enter") {
			setIsComponentVisible(false);
			setMobileSearch(false);
			if (location.pathname === "/products/all-products") {
				navigate({ replace: true });
				setRefresh(Math.random());
			} else {
				navigate("/products/all-products");
			}
			if (!searchValue) {
				navigate("/products/all-products", { replace: true });
			}
			if (subCategoryId && subCategory && name) {
				navigate(location.pathname, { replace: true });
			}
		}
	}

	//in responsive search enter is when click on icon
	function mobileKeyDown() {
		setIsComponentVisible(false);
		setMobileSearch(false);
		if (location.pathname === "/products/all-products") {
			navigate({ replace: true });
			setRefresh(Math.random());

			// setSearchValue("")
		} else {
			// setSearchValue("");
			navigate("/products/all-products");
		}
		if (!searchValue) {
			navigate("/products/all-products", { replace: true });
		}
		if (subCategoryId && subCategory && name) {
			navigate(location.pathname, { replace: true });
		}
	}

	//handle search suggetion enter
	function handleSuggestions(item) {
		setIsComponentVisible(false);
		setSubCategoryId(item.sub_category_id);
		setName(item.name);
		setSubCategory(item.sub_category);

		navigate("/products/all-products", {
			state: {
				// sub_category_id: subCategoryId,
				name: item.name,
				// sub_category: subCategory,
			},
		});
		setRefresh(Math.random());
	}

	//for search suggestions
	useEffect(() => {
		shopConfig
			.get(`search-suggestions/?query=${searchValue}`)
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setSuggestions(data);
				} else {
					setSuggestions([]);
				}
			})
			.catch((err) => {
				setSuggestions([]);
			});
	}, [searchValue]);

	//when click 'become a seller' button
	const openLink = () => {
		window.open('https://seller.oneruppee.com/home');
	}

	return (
		<>
			<HeaderContainer>
				<Wrapper className="wrapper">
					<MainHeader>
						<Left>
							<LogoBox to="/">
								<LogoImage alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/logo.svg" />
							</LogoBox>
							<SearchBox
								onClick={() =>
									setIsComponentVisible(!isComponentVisible)
								}
								isComponentVisible={isComponentVisible}
							>
								<IconBox>
									<HeaderIcon alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/search.svg" />
								</IconBox>
								<SearchInput
									placeholder="Search for products and brands etc"
									value={searchValue}
									onChange={(e) => (
										setSearchValue(e.target.value),
										setSubCategoryId(""),
										setSubCategory(""),
										setName("")
									)}
									onKeyDown={(e) => handleKeyDown(e)}
								/>
								{/* tooltip */}
								{suggestions.length > 0 && (
									<SearchToolTip
										className="web"
										ref={ref}
										isComponentVisible={isComponentVisible}
									>
										{suggestions.map((item) => {
											return (
												<ToolTipItems
													key={item.id}
													onClick={() =>
														handleSuggestions(item)
													}
													// to={{
													// 	pathname: `/products/${item.name}`,
													// }}
													// state={{
													// 	sub_category_id:
													// 		item.sub_category_id,
													// 	name: item.name,
													// 	sub_category:
													// 		item.sub_category,
													// }}
												>
													<ToolTipLeft>
														<ToolTipName>
															{item.name}
														</ToolTipName>
														<ToolTipCategory>
															In{" "}
															{item.sub_category}
														</ToolTipCategory>
													</ToolTipLeft>
													<Close
														style={{
															width: "10px",
														}}
													>
														<Image
															
															className="img-class"
															alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-up.svg"
														/>
													</Close>
												</ToolTipItems>
											);
										})}
									</SearchToolTip>
								)}
							</SearchBox>
						</Left>
						<Right>
							<DownloadText onClick={openLink} >Become a Seller</DownloadText>
							<IconBoxRight to="/profile">
								<LinkBox>
									<HeaderIcon alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/user.svg" />
								</LinkBox>
							</IconBoxRight>
							<IconBoxRight to="/wishlist">
								<LinkBox>
									<HeaderIcon alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/black-heart.svg" />
								</LinkBox>
							</IconBoxRight>
							<IconBoxRight to="/cart">
								{cartCount?.length > 0 && (
									<ItemText>{cartCount.length}</ItemText>
								)}
								<LinkBox>
									<HeaderIcon alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/cart.svg" />
								</LinkBox>
							</IconBoxRight>
						</Right>
					</MainHeader>
				</Wrapper>
			</HeaderContainer>
			<MobileHeader>
				<SearchToolTip
					// ref={ref}
					style={{
						height: isMobileSearch ? "100px" : 0,
						maxHeight: isMobileSearch ? "100px" : 0,
					}}
					className="mobile"
					isMobileSearch={isMobileSearch}
					// onClick={() => setMobileSearch(true)}
				>
					<ToolTipTOp>
						<div
							style={{
								width: "90%",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								margin: "0 auto",
								maxWidth: "1350px",
							}}
						>
							<Close onClick={() => setMobileSearch(false)}>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-left.svg"
								/>
							</Close>
							<ToolTipSearchBox>
								<SearchInput
									placeholder="What are you looking for?"
									autoFocus
									value={searchValue}
									onChange={(e) =>
										setSearchValue(e.target.value)
									}
									// onKeyDown={(e) => handleKeyDown(e)}
									inputMode={isMobileSearch ? "text" : "none"}
								/>
								<ToolTipSearchIcon onClick={mobileKeyDown}>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/search.svg"
									/>
								</ToolTipSearchIcon>
							</ToolTipSearchBox>
						</div>
					</ToolTipTOp>
					<div
						style={{
							width: "90%",
							margin: "0 auto",
							maxWidth: "1350px",
						}}
					>
						<RecentItems>
							{/* <RecentItemsTitle>Recent Searches</RecentItemsTitle> */}
							{suggestions.map((item) => (
								<RecentItem
									key={item.id}
									onClick={() => (
										handleSuggestions(item),
										setMobileSearch(false),
										setSearchValue("")
									)}
								>
									<RecentItemIcon>
										<Image
											
											className="img-class"
											alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/recent.svg"
										/>
									</RecentItemIcon>
									<RecentItemText>{item.name}</RecentItemText>
								</RecentItem>
							))}
						</RecentItems>
						{/* <RecentItems>
							<RecentItemsTitle>
								Popular Searches
							</RecentItemsTitle>
							<PopularSearch>
								<PopularSearchBox>
									Iphone 14 Pro
								</PopularSearchBox>
							</PopularSearch>
						</RecentItems> */}
					</div>
				</SearchToolTip>
				<Wrapper className="wrapper mobile">
					<MobileLeft>
						<IconBox onClick={() => setMenu(true)}>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/mobile-menu.svg" />
						</IconBox>
					</MobileLeft>
					<MobileMiddle>
						<MobileLogo to="/">
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/mobile-header-logo.png"
							/>
						</MobileLogo>
					</MobileMiddle>
					<MobileRight>
						<IconBoxSecondSearch
							className="mobile-mr"
							onClick={() => setMobileSearch(true)}
						>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/search.svg" />
						</IconBoxSecondSearch>
						<IconBoxSecond to="/cart">
							{cartCount?.length > 0 && (
								<ItemText>{cartCount.length}</ItemText>
							)}
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/cart.svg" />
						</IconBoxSecond>
					</MobileRight>
				</Wrapper>
			</MobileHeader>
			<MobileMenu
				isMenu={isMenu}
				setMenu={setMenu}
				setRefresh={setRefresh}
			/>
		</>
	);
}

const HeaderContainer = styled.header`
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	/* height: 115px; */
	z-index: 999;
	background: #fff;
	border-bottom: 1px solid #d3d3cf;
	@media all and (max-width: 980px) {
		display: none;
	}
`;

const Wrapper = styled.section`
	height: 80px;
	&.mobile {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}
	@media all and (max-width: 420px) {
		height: 60px;
	}
`;
const MainHeader = styled.div`
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Left = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	@media all and (max-width: 980px) {
		width: 47%;
	}
`;
const LogoBox = styled(Link)`
	width: 55px;
	margin-right: 50px;
	@media all and (max-width: 980px) {
		margin-right: 20px;
	}
`;
const LogoImage = styled.img`
	display: inline-block;
	width: 100%;
	height: 100%;
`;
const SearchBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 60%;
	height: 45px;
	padding: 0 20px;
	border: 1px solid #d3d3cf;
	border-radius: ${({ isComponentVisible }) =>
		isComponentVisible ? "5px 5px 0 0" : "5px"};
`;
const IconBox = styled.div`
	width: 15px;
	&.mobile-mr {
		margin-right: 20px;
	}
	@media all and (max-width: 420px) {
		width: 20px;
	}
`;
const IconBoxSecond = styled(Link)`
	width: 25px;
	position: relative;
	&.mobile-mr {
		margin-right: 20px;
	}
	@media all and (max-width: 420px) {
		width: 20px;
	}
`;
const IconBoxSecondSearch = styled.div`
	width: 25px;
	position: relative;
	&.mobile-mr {
		margin-right: 20px;
	}
	@media all and (max-width: 420px) {
		width: 20px;
	}
`;
const SearchInput = styled.input`
	width: 100%;
	color: #747474;
	font-size: 15px;
	margin-left: 5px;
	font-family: "medium";
	text-transform: capitalize;
	&::placeholder {
		color: #d4d4d4;
		font-size: 15px;
	}
`;

const LinkBox = styled.span`
	display: inline-block;
	width: 50%;
	height: 50%;
`;

const HeaderIcon = styled.img`
	display: inline-block;
	width: 100%;
	height: 100%;
`;
const Right = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@media all and (max-width: 980px) {
		width: 53%;
	}
`;
const IconBoxRight = styled(Link)`
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #d3d3cf;
	border-radius: 5px;
	margin-left: 20px;
	cursor: pointer;
	position: relative;
`;
const DownloadText = styled.span`
	font-size: 15px;
	font-family: "medium";
	color: #747474;
	cursor: pointer;
`;

// mobile header

const MobileHeader = styled.div`
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	/* height: 115px; */
	display: none;
	z-index: 999;
	background: #fff;
	border-bottom: 1px solid #d3d3cf;
	@media all and (max-width: 980px) {
		display: block;
	}
`;
const MobileLeft = styled.div``;
const Image = styled.img``;
const MobileMiddle = styled.div`
	justify-content: center;
	display: flex;
`;
const MobileLogo = styled(Link)`
	display: inline-block;
	width: 60%;
	@media all and (max-width: 420px) {
		width: 45%;
	}
`;
const MobileRight = styled.div`
	display: flex;
	align-items: center;
`;
const ItemText = styled.h3`
	position: absolute;
	top: -5px;
	right: -5px;
	color: #747474;
	font-size: 10px;
	padding: 5px 7px;
	text-align: center;
	color: #fff;
	border-radius: 50px;
	background: #e02b1d;
	font-family: "regular";
	@media all and (max-width: 768px) {
		font-size: 8px;
	}
	@media all and (max-width: 420px) {
		font-size: 6px;
	}
`;

const SearchToolTip = styled.div`
	&.web {
		display: block;
		position: absolute;
		width: 100%;
		min-height: ${({ isComponentVisible }) =>
			isComponentVisible ? "100%" : 0};
		border-radius: 0 0 5px 5px;
		left: 0;
		top: 100%;
		transition: all 0.3s ease-in;
		background: #fff;
		border: 1px solid #d3d3cf;
		border-top: none;
		opacity: ${({ isComponentVisible }) => (isComponentVisible ? 1 : 0)};
		overflow-y: ${({ isComponentVisible }) =>
			isComponentVisible ? "scroll" : "hidden"};
		height: ${({ isComponentVisible }) =>
			isComponentVisible ? "max-content" : 0};
		max-height: ${({ isComponentVisible }) =>
			isComponentVisible ? "150px" : 0};
		&::-webkit-scrollbar {
			display: none;
		}
		@media all and (max-width: 980px) {
			display: none !important;
		}
	}
	&.mobile {
		display: none;
		position: absolute;
		width: 100%;
		min-height: ${({ isMobileSearch }) => (isMobileSearch ? "100%" : 0)};
		border-radius: 0 0 5px 5px;
		left: 0;
		top: 100%;
		transition: all 0.1s ease-in;
		background: #fff;
		border: 1px solid #d3d3cf;
		border-top: none;
		opacity: ${({ isMobileSearch }) => (isMobileSearch ? 1 : 0)};
		overflow-y: ${({ isMobileSearch }) =>
			isMobileSearch ? "scroll" : "hidden"};
		height: ${({ isMobileSearch }) => (isMobileSearch ? "150px" : 0)};
		max-height: ${({ isMobileSearch }) => (isMobileSearch ? "150px" : 0)};
		&::-webkit-scrollbar {
			display: none;
		}
		@media all and (max-width: 980px) {
			display: block !important;
			width: 0;
			top: 0;
			padding-top: 20px;
			z-index: 1000;
			height: 100vh !important;
			max-height: unset !important;
			right: 0;
			left: unset;
			width: ${({ isMobileSearch }) => (isMobileSearch ? "100%" : 0)};
			border: none;
		}
	}
	/* @media all and (max-width: 980px) {
		width: 0;
		top: 0;
		padding-top: 20px;
		z-index: 1000;
		height: 100vh !important;
		max-height: unset !important;
		right: 0;
		left: unset;
		width: ${({ isComponentVisible }) => (isComponentVisible ? "100%" : 0)};
		border: none;
	} */
`;
// const ToolTipItems = styled(Link)`
const ToolTipItems = styled.div`
	padding: 16px 10px;
	background: #fffdf3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	margin-bottom: 8px;
	&:last-child {
		margin-bottom: 0;
	}
	@media all and (max-width: 980px) {
		display: none;
	}
`;
const ToolTipLeft = styled.div`
	display: flex;
	flex-direction: column;
`;
const ToolTipName = styled.span`
	font-size: 15px;
	font-family: regular;
	color: #191919;
`;
const ToolTipCategory = styled.span`
	font-size: 15px;
	font-family: regular;
	color: #eab600;
	margin-top: 6px;
	&:last-child {
		margin-bottom: 0;
	}
`;
const Close = styled.div`
	width: 20px;
`;
const ToolTipTOp = styled.div`
	display: none;
	@media all and (max-width: 980px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 10px;
	}
`;
const ToolTipSearchBox = styled.div`
	align-items: center;
	display: flex;
	background: #f6f6f6;
	border-radius: 5px;
	padding: 10px;
	width: 90%;
`;
const ToolTipSearchIcon = styled.div`
	width: 15px;
`;

const RecentItems = styled.div`
	margin-bottom: 20px;
`;
// const RecentItemsTitle = styled.div`
// 	font-size: 16px;
// 	font-family: regular;
// 	color: #747474;
// 	margin-bottom: 20px;
// `;
const RecentItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;
const RecentItemIcon = styled.div`
	width: 11px;
	margin-right: 4px;
`;
const RecentItemText = styled.div`
	font-size: 13px;
	font-family: regular;
	color: #adadad;
`;
// const PopularSearch = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// `;
// const PopularSearchBox = styled.div`
// 	font-size: 13px;
// 	font-family: regular;
// 	color: #adadad;
// 	padding: 5px;
// 	border-radius: 5px;
// 	margin: 0 10px 10px 0;
// 	background: #fffdf3;
// 	border: 1px solid #eee;
// `;
