import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../general/Header";
import HeaderPush from "../general/HeaderPush";
import OrderCancelletion from "../includes/myOrders/OrderCancelletion";
import OrderSingle from "../includes/myOrders/OrderSingle";
import OrderList from "../includes/myOrders/OrderList";
import OrderReturn from "../includes/myOrders/OrderReturn";
import styled from "styled-components";
import EditReview from "../includes/myOrders/EditReview";

export default function MyOrders({ searchValue, setSearchValue }) {
	return (
		<>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush />
			<Container>
				<Wrapper className="wrapper">
					<Routes>
						<Route
							path="*"
							element={<Navigate to="/page-not-found" replace />}
						/>
						<Route element={<OrderList />} path="/" />
						<Route
							element={<OrderSingle />}
							path="/order/:order_pk"
						/>
						<Route
							element={<OrderCancelletion />}
							path="/cancel/:product_details"
						/>
						<Route element={<OrderReturn />} path="/return" />
						<Route
							element={<EditReview />}
							path="/order/add-review/:product_pk"
						/>
						<Route
							element={<EditReview />}
							path="/order/edit-review/:product_pk"
						/>
					</Routes>
				</Wrapper>
			</Container>
		</>
	);
}

const Container = styled.div`
	padding: 4% 0;
`;
const Wrapper = styled.div`
	max-width: 1000px;
`;
