import React from "react";
import { useLocation } from "react-router-dom";
import CheckoutModal from "../modal/checkout/CheckoutModal";

export default function PaymentStatus() {
	const { search } = useLocation();
	const parameters = new URLSearchParams(search);
	const status = parameters.get("status");
	const orderId = parameters.get("id");

	return (
		<div>
			<CheckoutModal
				status={status}
				orderId={orderId ? orderId : ""}
				isCheckout={true}
			/>
		</div>
	);
}
