import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function MyOrdersCancelModal({ isModal, setModal, orderData }) {
	return (
		<ModalContainer onClick={() => setModal(false)} isModal={isModal}>
			<ModalFade isModal={isModal}></ModalFade>
			<ModalBox>
				<Top>
					<Title>Cancellation</Title>
					<Close onClick={() => setModal(false)}>
						<Image   className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg" />
					</Close>
				</Top>
				<Left>
					<ImageBox>
						<Image  
							className="img-class"
							alt="Image" src={orderData.order_details[0].featured_image}
						/>
					</ImageBox>
					<Details>
						{/* <Brand>Rolex</Brand> */}
						<Name>{orderData.order_details[0].product_name} </Name>
						<Price>₹{orderData.order_details[0].total_cost}</Price>
					</Details>
				</Left>
				<Description>
					Are you sure you want to cancel this order?
				</Description>
				<ButtonBox>
					<Button
						onClick={() => setModal(false)}
						to=""
						style={{
							marginRight: "10px",
							border: "1px solid #191919",
							background: "#fff",
						}}
					>
						<Text>Don’t Cancel</Text>
					</Button>
					<Button to={`/orders/cancel/${orderData.order_details[0].product_name}?${orderData.order_details[0].pk}`}>
						<Text>Cancel</Text>
					</Button>
				</ButtonBox>
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s ease-out;
	transform: scale(1.1);
	background: transparent;
	transform: ${({ isModal }) => (isModal ? "scale(1)" : "scale(0)")};
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: ${({ isModal }) => (isModal ? "block" : "none")};
`;
const ModalBox = styled.div`
	background: #fff;
	padding: 40px;
	border-radius: 5px;
	width: 600px;
	position: relative;
	z-index: 1001;
	@media all and (max-width: 768px) {
		padding: 20px;
		width: 450px;
	}
	@media all and (max-width: 640px) {
		width: 350px;
	}
	@media all and (max-width: 420px) {
		width: 310px;
	}
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #e7e7e7;
	padding-bottom: 20px;
	margin-bottom: 30px;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #003c3c;
	@media all and (max-width: 768px) {
		font-size: 20px;
	}
`;
const Close = styled.div`
	width: 15px;
	cursor: pointer;
	@media all and (max-width: 768px) {
		width: 10px;
	}
`;
const Image = styled.img``;
const Description = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #003c3c;
	line-height: 25px;
	margin-top: 30px;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;

const Left = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	@media all and (max-width: 768px) {
		align-items: flex-start;
	}
`;
const ImageBox = styled.div`
	width: 17%;
	margin-right: 20px;
	border-radius: 5px;
	overflow: hidden;
	@media all and (max-width: 768px) {
		width: 30%;
	}
`;
const Details = styled.div``;
// const Brand = styled.h3`
// 	font-size: 20px;
// 	font-family: "medium";
// 	color: #191919;
// 	@media all and (max-width: 768px) {
// 		font-size: 18px;
// 	}
// `;
const Name = styled.h3`
	color: #747474;
	font-size: 18px;
	font-family: "regular";
	margin: 10px 0;
	@media all and (max-width: 768px) {
		font-size: 16px;
	}
`;
const Price = styled.span`
	color: #191919;
	font-family: "medium";
	font-size: 16px;
	@media all and (max-width: 768px) {
		font-size: 14px;
	}
`;

const ButtonBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: end;
	margin-top: 30px;
`;
const Button = styled(Link)`
	width: 30%;
	height: 40px;
	border-radius: 5px;
	display: inline-block;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	@media all and (max-width: 640px) {
		height: 30px;
	}
	@media all and (max-width: 420px) {
		width: 35%;
	}
`;
const Text = styled.span`
	color: #191919;
	font-family: "medium";
	font-size: 16px;
	@media all and (max-width: 640px) {
		font-size: 12px;
	}
`;
