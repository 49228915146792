import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
    bidConfig,
    ordersConfig,
    paymentsConfig,
    shippingsConfig,
} from "../../../apiConfig";
import { Context } from "../../context/store";
import { GradientButton } from "../constants";
import CheckoutModal from "../modal/checkout/CheckoutModal";
import AddressCard from "./AddressCard";
import AddressForm from "./AddressForm";
import ProductSummary from "./ProductSummary";
import ButtonLoader from "../../general/includes/ButtonLoader";
import Toast from "../../general/includes/Toast";
import { cashfree } from "../../../utils";
import { load } from "@cashfreepayments/cashfree-js";

export default function Address({
    isAddress,
    isCheckout,
    isAddressConfirm,
    setAddress,
    setPayment,
    setAddressData,
    setCheckout,
    setAddressConfirm,
    addressData,
    productData,
    setSizeSelectId,
    type,
    selectAddId,
    setRefresh,
    quantity,
    attr,
    walletBalance,
    setSelectedPincode,
    shippingDetails,
}) {
    const { state } = useContext(Context);
    const [toastText, setToastText] = useState("");
    const [isToast, setToast] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [formData, setFormData] = useState({
        customer_name: "",
        state: "",
        phone_no: "",
        pincode: null,
        city: "",
        address_line1: "",
        address_line2: "",
        near_landmark: "",
        address_type: "",
        is_default: true,
    });
    const [errMsg, setErrMsg] = useState("");
    const [coupenCode, setCoupenCode] = useState("");

    const [orderResponse, serOrderResponse] = useState({});
    const [selectedAddress, setSelectedAddress] = useState(addressData[0]);
    const [orderId, setOrderId] = useState("");
    const [paymentSessionId, setPaymentSessionId] = useState("");
    const [cashfree, setCashfree] = useState(null);
    const [isWalletUsed, setIsWalletUsed] = useState(
        type === "claim_your_product" ? true : false
    );

    async function loadCashfree() {
        try {
            const cashfreeInstance = await load({
                mode: "sandbox", // or production
                // mode: "production", // or production
            });

            setCashfree(cashfreeInstance);
            console.log("Cashfree loaded");
        } catch (error) {
            console.error("Error loading Cashfree:", error);
        }
    }

    // cashfree initialization
    useEffect(() => {
        loadCashfree();
    }, []);

    const cashFreeRedirect = (session_id) => {
        console.log(
            "paymentSessionId : ",
            paymentSessionId,
            " , orderId : ",
            orderId
        );
        if (cashfree) {
            let checkoutOptions = {
                paymentSessionId: session_id,
                redirectTarget: "_self",
                // returnUrl: `https://sandbox.cashfree.com/pgappsdemos/v3success.php?myorder=${orderId}`,
                returnUrl: "http://localhost:3000",
            };

            console.log("CASHFREE", typeof checkoutOptions.paymentSessionId);
            cashfree.checkout(checkoutOptions).then(function (result) {
                console.log("RESLUT", result);
                if (result.error) {
                    alert(result.error.message);
                }
                if (result.redirect) {
                    console.log("Redirection");
                }
            });
        } else {
            console.error("Cashfree not loaded yet.");
        }
    };

    const location = useLocation();

    // for payment button action
    function buttonAction() {
        if (isAddress && isAddressConfirm) {
            setLoading(true);
            if (type === "cart_products") {
                ordersConfig
                    .get(
                        "order-from-cart/",
                        {
                            courier_id:
                                shippingDetails?.channel?.courier_company_id,
                            redeemed_amount: isWalletUsed
                                ? walletBalance >=
                                  productData?.pice_data?.total_product_cost
                                    ? Math.floor(
                                          productData?.pice_data
                                              ?.total_product_cost
                                      )
                                    : Math.floor(walletBalance)
                                : 0,
                            address_pk: selectedAddress.id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${state.user_data.access_token}`,
                            },
                        }
                    )
                    .then((res) => {
                        const { data, StatusCode } = res.data;
                        if (StatusCode === 6000) {
                            serOrderResponse(data);
                            // setLoading(false);
                            // setCheckout(true);
                            createOrder(data.order_id);
                        } else {
                            setLoading(false);
                            setToastText("Something went wrong 001");
                            setToast(true);
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        setToastText("Something went wrong 002");
                        setToast(true);
                    });
                // createOrder(orderResponse.order_id);
            } else {
                if (type === "bid_redeem") {
                    bidConfig
                        .post(
                            `convert-to-order/${productData.bid_product_id}/`,
                            {
                                address_pk: selectedAddress.id,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${state.user_data.access_token}`,
                                },
                            }
                        )
                        .then((res) => {
                            const { data, StatusCode } = res.data;
                            if (StatusCode === 6000) {
                                serOrderResponse(data);
                                // createOrder(data.order_id);
                                setCheckout(true);
                                setLoading(false);
                            } else {
                                setLoading(false);
                                setToastText("Something went wrong 003");
                                setToast(true);
                            }
                        })
                        .catch((err) => {
                            setLoading(false);
                            setToastText("Something went wrong 004");
                            setToast(true);
                        });
                } else if (type === "claim_your_product") {
                    ordersConfig
                        .post(
                            `buy-now/${productData.product_code}/`,
                            {
                                redeemed_amount:
                                    isWalletUsed && walletBalance > 0
                                        ? walletBalance >=
                                          productData.display_cost
                                            ? parseInt(productData.display_cost)
                                            : walletBalance
                                        : 0,
                                quantity: location.state?.quantity,
                                address_pk: selectAddId.id,
                                courier_id:
                                    shippingDetails?.channel
                                        ?.courier_company_id,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${state.user_data.access_token}`,
                                },
                            }
                        )
                        .then((res) => {
                            const { data, StatusCode } = res.data;
                            if (StatusCode === 6000) {
                                serOrderResponse(data);
                                if (data.has_completed_redeem) {
                                    setCheckout(true);
                                } else {
                                    setOrderId(data.order_id);
                                    createOrder(data.order_id);
                                }
                                setLoading(false);
                            } else {
                                setLoading(false);
                                setToastText("Something went wrong 005");
                                setToast(true);
                            }
                        })
                        .catch((err) => {
                            setToastText("Something went wrong 006");
                            setToast(true);
                            setLoading(false);
                        });
                } else {
                    ordersConfig
                        .post(
                            `buy-now/${productData.product_code}/`,
                            {
                                redeemed_amount:
                                    isWalletUsed && walletBalance > 0
                                        ? walletBalance >=
                                          productData.display_cost
                                            ? parseInt(productData.display_cost)
                                            : walletBalance
                                        : 0,
                                quantity: location.state?.quantity,
                                address_pk: selectAddId.id,
                                courier_id:
                                    shippingDetails?.channel
                                        ?.courier_company_id,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${state.user_data.access_token}`,
                                },
                            }
                        )
                        .then((res) => {
                            const { data, StatusCode } = res.data;
                            console.log(data);
                            if (StatusCode === 6000) {
                                serOrderResponse(data);
                                setOrderId(data.order_id);

                                createOrder(data.order_id);
                            } else {
                                setLoading(false);
                                setToastText("Something went wrong 007");
                                setToast(true);
                            }
                        })
                        .catch((err) => {
                            setToastText("Something went wrong 008");
                            setToast(true);
                            setLoading(false);
                        });
                }
            }
        } else {
            if (selectedAddress) {
                setAddressConfirm(true);
                setPayment(true);
            } else {
                setToastText("Please select your address");
                setToast(true);
            }
        }
    }

    // for generating payment session id
    function createOrder(order_id) {
        paymentsConfig
            .post(
                "create-order/",
                {
                    order_id: order_id,
                    purchase_type:
                        type === "claim_your_product"
                            ? "product_purchase"
                            : "product_purchase",
                },
                {
                    headers: {
                        Authorization: `Bearer ${state.user_data.access_token}`,
                    },
                }
            )
            .then((res) => {
                const { data, StatusCode } = res.data;
                console.log("DATA", data);
                if (StatusCode === 6000) {
                    // setCheckout(true);
                    // window.open(`${data.payment_url}`, "_self");
                    setPaymentSessionId(data.payment_session_id);
                    cashFreeRedirect(data.payment_session_id);
                } else {
                    setToastText("Something went wrong 009");
                    setToast(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setToastText("Something went wrong 010");
                setToast(true);
            });
    }

    // check the coupen code is valid
    function applyCoupenCode() {
        if (coupenCode) {
            setButtonLoading(true);
            paymentsConfig
                .post(
                    `validate-coupon/${coupenCode}/`,
                    { product_code: productData.product_code },
                    {
                        headers: {
                            Authorization: `Bearer ${state.user_data.access_token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.StatusCode === 6000) {
                        setErrMsg("Coupon code valid");
                        applyCoupen();
                    } else {
                        setErrMsg("Coupon code not valid");
                    }
                    setButtonLoading(false);
                })
                .catch((err) => {
                    setButtonLoading(false);
                });
        } else {
            setErrMsg("Enter a coupon code");
        }
    }

    // apply coupen
    function applyCoupen() {
        paymentsConfig
            .post(
                `apply-coupon/${coupenCode}/`,
                { order_id: productData.product_code },
                {
                    headers: {
                        Authorization: `Bearer ${state.user_data.access_token}`,
                    },
                }
            )
            .then((res) => {
                const { StatusCode, data } = res.data;
                setButtonLoading(false);
            })
            .catch((err) => {
                setButtonLoading(false);
            });
    }

    // Order total text condtion checking
    const OrderTotalText = () => {
        let productCost = 0;
        if (type === "cart_products" || type === "claim_your_product") {
            productCost = productData?.pice_data?.total_product_cost;
        } else {
            productCost = parseInt(productData?.display_cost) || 0;
        }
        const shippingCost = parseInt(shippingDetails?.channel?.rate) || 0;

        let totalCost = 0;

        if (isWalletUsed) {
            totalCost = productCost + shippingCost - walletBalance;
            totalCost = totalCost < 0 ? "0.00" : totalCost.toFixed(0);
        } else {
            totalCost = productCost + shippingCost;
            totalCost = totalCost.toFixed(2);
        }

        return <Text>₹{totalCost}</Text>;
    };

    //for render left components
    function renderCheckoutSection() {
        if (isAddress && !isAddressConfirm) {
            return (
                <AddressCard
                    setSizeSelectId={setSizeSelectId}
                    hasButton={true}
                    setAddressConfirm={setAddressConfirm}
                    addressData={addressData}
                    setRefresh={setRefresh}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    setAddressData={setAddressData}
                    setSelectedPincode={setSelectedPincode}
                    formData={formData}
                    setFormData={setFormData}
                />
            );
        }
        // else if (isAddress && isAddressConfirm && !isPayment) {
        // 	return <PaymentMethod />;
        // }
        else if (
            isAddress &&
            isAddressConfirm
            // && isPayment
        ) {
            return (
                <ProductSummary
                    selectedAddress={selectedAddress}
                    productData={productData}
                    type={type}
                    quantity={quantity}
                    attr={attr}
                />
            );
        } else {
            return (
                <AddressForm
                    setAddress={setAddress}
                    setAddressData={setAddressData}
                    formData={formData}
                    setFormData={setFormData}
                />
            );
        }
    }
    console.log("paymentSessionId : ", paymentSessionId);

    return (
        <AddressFormContainer>
            <Toast isToast={isToast} setToast={setToast} text={toastText} />
            <Wrapper className="wrapper">
                <Left>{renderCheckoutSection()}</Left>
                <Right>
                    <Title>Payment</Title>
                    <PaymentBox>
                        <Box>
                            <IconBox>
                                <Image
                                    className="img-class"
                                    alt="Image"
                                    src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/phone.svg"
                                />
                            </IconBox>
                            <FormTitle>Payment Details</FormTitle>
                        </Box>
                        {type !== "bid_redeem" && (
                            <CoupenBox>
                                <InputBox>
                                    <IconBox className="coupen">
                                        <img
                                            alt="Image"
                                            src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/ticket.svg"
                                            className="img-class"
                                        />
                                    </IconBox>
                                    <Input
                                        placeholder="Have a coupon code? Insert"
                                        value={coupenCode}
                                        onChange={(e) =>
                                            setCoupenCode(e.target.value)
                                        }
                                    />
                                    {/* <Input placeholder="" /> */}
                                    {isButtonLoading ? (
                                        <Apply>
                                            <ButtonLoader size={30} />
                                        </Apply>
                                    ) : (
                                        // </Apply>
                                        <Apply onClick={applyCoupenCode}>
                                            Apply
                                        </Apply>
                                    )}
                                </InputBox>
                                <ErrorMessage
                                    style={{
                                        color:
                                            errMsg === "Coupon code valid"
                                                ? "#219a75"
                                                : "#e02b1d",
                                    }}
                                >
                                    {errMsg}
                                </ErrorMessage>
                            </CoupenBox>
                        )}
                        {type !== "cart_products" ? (
                            <>
                                <PriceBox>
                                    <Text
                                        style={{
                                            color: "#747474",
                                        }}
                                    >
                                        Product price
                                    </Text>
                                    <Text
                                        style={{
                                            color: "#747474",
                                        }}
                                    >
                                        ₹
                                        {type === "bid_redeem"
                                            ? productData.bid_product_actual_cost
                                            : productData.actual_cost}
                                    </Text>
                                </PriceBox>
                                {type !== "bid_redeem" && (
                                    <PriceBox>
                                        <Text>Discount</Text>
                                        <Text>
                                            ₹
                                            {productData
                                                ? productData.actual_cost -
                                                  productData.display_cost
                                                : "---"}
                                        </Text>
                                    </PriceBox>
                                )}
                                {type !== "cart_product" && (
                                    <PriceBox>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Check
                                                onClick={() =>
                                                    setIsWalletUsed(
                                                        !isWalletUsed
                                                    )
                                                }
                                                style={{
                                                    border: !isWalletUsed
                                                        ? "1px solid #adadad"
                                                        : "none",
                                                }}
                                            >
                                                {isWalletUsed && (
                                                    <Image
                                                        className="img-class"
                                                        alt="Image"
                                                        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
                                                    />
                                                )}
                                            </Check>
                                            <Text>Wallet Discount</Text>
                                        </div>
                                        <Text>{walletBalance}</Text>
                                    </PriceBox>
                                )}
                            </>
                        ) : (
                            <>
                                <PriceBox>
                                    <Text
                                        style={{
                                            color: "#747474",
                                        }}
                                    >
                                        Product price
                                    </Text>
                                    <Text
                                        style={{
                                            color: "#747474",
                                        }}
                                    >
                                        ₹
                                        {
                                            productData?.pice_data
                                                ?.total_actual_cost
                                        }
                                    </Text>
                                </PriceBox>
                                <PriceBox>
                                    <Text>Discount</Text>
                                    <Text>
                                        ₹
                                        {productData?.pice_data
                                            ? productData?.pice_data?.discount
                                            : "---"}
                                    </Text>
                                </PriceBox>
                            </>
                        )}
                        {/* wallet money */}
                        {type === "cart_products" && (
                            <PriceBox>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Check
                                        onClick={() =>
                                            setIsWalletUsed(!isWalletUsed)
                                        }
                                        style={{
                                            border: !isWalletUsed
                                                ? "1px solid #adadad"
                                                : "none",
                                        }}
                                    >
                                        {isWalletUsed && (
                                            <Image
                                                className="img-class"
                                                alt="Image"
                                                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
                                            />
                                        )}
                                    </Check>
                                    <Text>Wallet Discount</Text>
                                </div>
                                <Text>{walletBalance}</Text>
                            </PriceBox>
                        )}
                        {/* shipping charge */}
                        {shippingDetails && (
                            <PriceBox>
                                <Text>Delivery charges</Text>
                                {type === "cart_products" ? (
                                    <Text>
                                        ₹
                                        {
                                            productData?.pice_data
                                                ?.total_shipping_cost
                                        }
                                    </Text>
                                ) : (
                                    <Text>
                                        ₹
                                        {shippingDetails?.channel?.rate ||
                                            "0.00"}
                                    </Text>
                                )}
                            </PriceBox>
                        )}
                        <PriceBox>
                            <Text
                                style={{
                                    color: "#191919",
                                }}
                            >
                                Order Total
                            </Text>

                            {OrderTotalText()}
                        </PriceBox>
                    </PaymentBox>
                    {isAddress && (
                        <ButtonBox>
                            <GradientButton
                                margin={"20px 0 0 0"}
                                fontColor={"#747474"}
                                fontSize={"15px"}
                                fontFamily={"medium"}
                                br={"5px"}
                                text={"Continue"}
                                width={"100%"}
                                height={"45px"}
                                isLoading={isLoading}
                                onClick={buttonAction}
                                grey={false}
                            />
                        </ButtonBox>
                    )}
                </Right>
            </Wrapper>
            <CheckoutModal
                type={type}
                isCheckout={isCheckout}
                orderResponse={orderResponse}
            />
        </AddressFormContainer>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    @media all and (max-width: 980px) {
        flex-direction: column-reverse;
    }
`;
const AddressFormContainer = styled.div`
    padding-bottom: 70px;
    @media all and (max-width: 980px) {
        margin-top: 50px;
    }
    @media all and (max-width: 640px) {
        padding-bottom: 70px;
    }
`;
const Left = styled.div`
    width: 58%;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const Title = styled.h3`
    color: #747474;
    font-size: 22px;
    font-family: "medium";
    margin-bottom: 25px;
    @media all and (max-width: 768px) {
        margin-bottom: 10px;
        font-size: 20px;
    }
`;
const IconBox = styled.div`
    width: 20px;
    margin-right: 10px;
    &.coupen {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        padding: 10px;
        background: #feda00;

        @media all and (max-width: 640px) {
            padding: 7px;
        }
    }
`;
const Image = styled.img``;
const FormTitle = styled.h3`
    color: #000000;
    font-size: 20px;
    font-family: "medium";
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
`;
const Right = styled.div`
    width: 40%;
    @media all and (max-width: 980px) {
        width: 100%;
        margin-top: 50px;
        padding-bottom: 30px;
    }
`;
const PaymentBox = styled.div`
    background-color: #fffdfa;
    border: 1px solid #f2f0ed;
    border-radius: 5px;
    padding: 40px;
    @media all and (max-width: 420px) {
        padding: 15px;
    }
`;
const Box = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
`;
const PriceBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
        padding-top: 30px;
        border-top: 1px solid #d4d4d4;
        margin-top: 40px;
    }
`;
const Text = styled.h3`
    font-size: 16px;
    font-family: medium;
    color: #219a75;
`;
const ButtonBox = styled.div`
    @media all and (max-width: 640px) {
        position: fixed;
        left: 50%;
        width: 90%;
        bottom: 0;
        right: 50%;
        transform: translateX(-50%);
        padding: 5px 0 15px;
        background-color: #fff;
    }
`;
const CoupenBox = styled.div`
    margin-bottom: 25px;
`;

const InputBox = styled.div`
    display: flex;
    border: 1px solid #feda00;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 5px;
    justify-content: space-between;
`;
const Input = styled.input`
    text-transform: capitalize;
    width: 100%;
    text-transform: uppercase;
    &::placeholder {
        text-transform: capitalize;
    }
`;
const Apply = styled.span`
    font-size: 13px;
    font-family: bold;
    color: #e02b1d;
    cursor: pointer;
    margin-right: 10px;
`;
const ErrorMessage = styled.span`
    font-size: 15px;
    font-family: regular;
    color: #e02b1d;
`;
const Check = styled.div`
    background: #fff;
    border: 1px solid #adadad;
    overflow: hidden;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    @media all and (max-width: 980px) {
        font-size: 16px;
        width: 14px;
        height: 14px;
    }
`;
