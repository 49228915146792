import React from "react";
import styled from "styled-components";
import Footer from "../../general/Footer";
import Header from "../../general/Header";
import HeaderPush from "../../general/HeaderPush";

export default function KnowMore(searchValue, setSearchValue) {
	return (
		<>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush />
			<Container>
				<Wrapper>
					<Title>Know more About Bidding Events</Title>
					<Para>
						Bidding can be performed by a person under influence of
						a product or service based on the context of the
						situation. In the context of auctions, stock exchange,
						or real estate, the price offer a business or individual
						is willing to pay is called a bid. In the context of
						corporate or government procurement initiatives, in
						Business and Law school students actively bid for high
						demand elective courses that have a maximum seat
						capacity though a course bidding process using pre
						allocated bidding points or e-bidding currency on course
						bidding systems.[2] The price offer a business or
						individual is willing to sell is also called a bid. The
						term "bidding" is also used when placing a bet in card
						games. Bidding is used by various economic niches for
						determining the demand and hence the value of the
						article or property, in today's world of advanced
						technology, the Internet is a favoured platform for
						providing bidding facilities; it is a natural way of
						determining the price of a commodity in a free market
						economy. Many similar terms that may or may not use the
						similar concept have been evolved in the recent past in
						connection to bidding, such as reverse auction, social
						bidding, or many other game-class ideas that promote
						themselves as bidding. Bidding is also sometimes used as
						ethical gambling in which the prize money is not
						determined solely by luck but also by the total demand
						that the prize has attracted towards itself.
					</Para>
				</Wrapper>
			</Container>
			<Footer />
		</>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const Wrapper = styled.div`
	max-width: 1000px;
	margin: 100px 0;
	@media all and (max-width: 1080px) {
		max-width: 800px;
	}
	@media all and (max-width: 768px) {
		max-width: 500px;
	}
	@media all and (max-width: 640px) {
		max-width: 300px;
	}
	@media all and (max-width: 420px) {
		max-width: 250px;
	}
`;
const Title = styled.h3`
	color: #191919;
	font-size: 20px;
	font-family: regular;
	margin-bottom: 20px;
	border-bottom: 1px solid #e4e4e4;
	padding-bottom: 20px;
	@media all and (max-width: 640px) {
		font-size: 17px;
	}
`;
const Para = styled.p`
	color: #747474;
	font-size: 18px;
	font-family: regular;
	line-height: 27px;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
	@media all and (max-width: 420px) {
		font-size: 13;
	}
`;
