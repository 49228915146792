import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HomeLoader from "../includes/content-loader/header/HomeLoader";
import { shopConfig } from "../../apiConfig";

export default function CategoryHeader() {
	const [categories, setCategories] = useState([]);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		shopConfig
			.get("categories/")
			.then((res) => {
				const { StatusCode } = res.data;
				if (StatusCode === 6000) {
					setCategories(res.data.data);
				}
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	}, []);


	const renderCategory = () => {
		return categories?.map((item, index) => (
			<CategoryBox
				key={index}
				to={{
					pathname: `/products/${item.title.toLowerCase()}`,
				}}
				state={{
					category_id: item.id,
					category_name: item.slug,
				}}
			>
				<CategoryImage>
					{item.thumbnail && (
						<Image
							
							className="img-class"
							alt="Image" src={item.thumbnail}
						/>
					)}
				</CategoryImage>
				<CatagoryLink>{item.title}</CatagoryLink>
			</CategoryBox>
		));
	};
	return !isLoading ? (
		<>
			{categories.length > 0 && (
				<CatagoryHeaderContainer>
					<Wrapper className="category-wrapper">
						<CategoryContent>
							{categories ? renderCategory() : "loading"}
						</CategoryContent>
					</Wrapper>
				</CatagoryHeaderContainer>
			)}
		</>
	) : (
		<HomeLoader />
	);
}

const CatagoryHeaderContainer = styled.div`
	padding: 10px 0;
	background-color: #fffdf3;
	/* display: none; */
	/* height: 40px; */
	border-bottom: 1px solid #e1e1dd;
	/* margin-bottom: 3%; */
	.category-wrapper {
		height: 100%;
	}
	@media all and (max-width: 640px) {
		margin-bottom: 4%;
	}
`;
const Wrapper = styled.section`
	height: 80px;
`;
const CategoryContent = styled.div`
	display: flex;
	background-color: #fffdf3;
	margin: 0 auto;
	max-width: 1350px;
	overflow-x: scroll;
	align-items: flex-start;
	&::-webkit-scrollbar {
		display: none;
	}
`;
const CategoryBox = styled(Link)`
	display: flex;
	background-color: #fffdf3;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-right: 20px;
	/* white-space: nowrap; */
	cursor: pointer;
	width: 9.09%;
	/* &:first-child {
		margin-left: 30px;
	} */
	&:last-child {
		margin-right: 10px;
	}
	&:first-child {

		@media all and (max-width: 640px) {
			margin-left: 4.5%;
		}
		@media all and (max-width: 420px) {
			margin-left: 7%;
		}
	}
	@media all and (max-width: 980px) {
		margin-right: 50px;
	}
`;
const CatagoryLink = styled.span`
	font-family: "regular";
	color: #747474;
	cursor: pointer;
	display: block;
	font-size: 18px;
	text-align: center;
	/* white-space: break-spaces; */
	@media all and (max-width: 640px) {
		font-size: 11px;
	}
`;
const CategoryImage = styled.div`
	width: 80px;
	height: 80px;
	border: 1px solid #feda00;
	border-radius: 50%;
	background-color: #efefef;
	overflow: hidden;
	margin-bottom: 10px;
	padding: 2%;
	img {
		border-radius: 50%;
	}
	@media all and (max-width: 980px) {
		width: 60px;
		height: 60px;
	}
	@media all and (max-width: 640px) {
		width: 50px;
		height: 50px;
		margin-bottom: 6px;
	}
`;
const Image = styled.img``;
