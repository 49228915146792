import React from "react";
import ContentLoader from "react-content-loader";

const HomeLoader = (props) => (
	<ContentLoader
		speed={2}
		width={"100%"}
		height={"100%"}
		viewBox="0 0 1920 400"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
        {/* spotlight */}
		<rect x="1" y="0" rx="0" ry="0" width="100%" height="100%" />
	</ContentLoader>
);

export default HomeLoader;
