import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ordersConfig, ratingConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
// import axios from "axios";
import Toast from "../../general/includes/Toast";
import ButtonLoader from "../../general/includes/ButtonLoader";
import PageLoader from "../../screens/PageLoader";

export default function EditReview() {
	const [img, setImg] = useState([]);
	const location = useLocation();
	const [orderData, setOrderData] = useState([]);
	const [starCount, setStarCount] = useState("");
	const [isToast, setToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [isPageLoading, setPageLoading] = useState(true);
	const [reviewText, setReview] = useState("");
	const [isLoading, setLoading] = useState(false);
	const { state } = useContext(Context);

	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		ordersConfig
			.get(`view-order/${location?.state?.order_pk}/`, {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setOrderData(data);
					setPageLoading(false);
				} else {
					setPageLoading(false);
				}
			})
			.catch((err) => {
				setPageLoading(false);
			});
	}, []);

	//for start maping
	const stars = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
		{
			id: 4,
		},
		{
			id: 5,
		},
	];

	//for image selection
	const onImageChange = (e) => {
		const [file] = e.target.files;
		let url;
		url = URL.createObjectURL(file);
		setImg([...img, { images: file, url: url }]);
	};
	const removeImage = (url) => {
		let newImg = img.filter((item) => {
			return item.url !== url;
		});
		setImg(newImg);
	};
	function hadleFormData() {
		const formData = new FormData();
		formData.append("stars", starCount);
		formData.append("review", reviewText);
		for (let i = 0; i < img.length; i++) {
			formData.append("images", img[i].images);
		}
		return formData;
	}
	function addEditReview() {
		if (orderData.order_details[0]?.rating_data === null) {
			if (starCount > 0 && reviewText) {
				setLoading(true);
				ratingConfig
					.post(`add-rating/${params.product_pk}/`, hadleFormData(), {
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					})
					.then((res) => {
						setToastText(res.data.data.message);
						setToast(true);
						setTimeout(() => {
							setLoading(false);
							if (res.data.StatusCode === 6000) {
								navigate(
									`/orders/order/${location.search.slice(1)}`
								);
							}
						}, 2000);
					})
					.catch((e) => {
						setToastText(e.message);
						setToast(true);
						setTimeout(() => {
							setLoading(false);
						}, 2000);
					});
			}
		} else {
			setLoading(true);
			ratingConfig
				.post(
					`edit/customer-rating/${orderData?.order_details[0]?.rating_data?.id}/`,
					hadleFormData(),
					{
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					}
				)
				.then((res) => {
					setToastText(res.data[0]?.data?.message);
					setToast(true);
					setTimeout(() => {
						setLoading(false);
						if (res.data[0]?.StatusCode === 6000) {
							navigate(
								`/orders/order/${location.search.slice(1)}`
							);
						}
					}, 2000);
				})
				.catch((e) => {
					setToastText(e.message);
					setToast(true);
					setTimeout(() => {
						setLoading(false);
					}, 2000);
				});
		}
	}

	return !isPageLoading ? (
		<>
			<Toast isToast={isToast} setToast={setToast} text={toastText} />
			<Title>Rate & review the product</Title>
			<ReviewBox>
				<ReviewTop>
					<ReviewLeft>
						<ReviewTitle>Rate this product</ReviewTitle>
						<StarBox>
							{stars.map((item) => (
								<Star
									key={item.id}
									onClick={() => setStarCount(item.id)}
								>
									<Image
										
										className="img-class"
										alt="Image" src={
											item.id <= starCount
												? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-start.svg"
												: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-start.svg"
										}
									/>
								</Star>
							))}
						</StarBox>
					</ReviewLeft>
					<ReviewRight>
						{/* <EditReviewButton>
	          <IconBox>
	            <Image   className="img-class" />
	          </IconBox>
	          <EditButtonText>Edit</EditButtonText>
	        </EditReviewButton> */}
					</ReviewRight>
				</ReviewTop>
				<ReviewBottom>
					{/* <AddReview>
	        <IconBox>
	          <Image   className="img-class" alt="Image" src={plus} />
	        </IconBox>
	        <AddReviewText>Add review</AddReviewText>
	      </AddReview> */}
					<CommentBox>
						<SelectText>Review this product</SelectText>
						<TextArea
							placeholder="Write here"
							rows={5}
							value={
								reviewText === ""
									? orderData?.order_details[0]?.rating_data
											?.review
									: reviewText
							}
							onChange={(e) => setReview(e.target.value)}
						/>
					</CommentBox>
					<ReviewImages>
						{img
							.filter((item) => {
								return item.url !== "";
							})
							.map((item, index) => {
								return (
									<ImageItem>
										<Close
											onClick={() =>
												removeImage(item.url)
											}
										>
											<Image
												
												style={{
													width: "70%",
													height: "70%",
													borderRadius: "5px",
												}}
												className="img-class"
												alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
											/>
										</Close>
										{item.url !== "" && (
											<Image
												
												className="img-class"
												alt="Image" src={item.url}
											/>
										)}
									</ImageItem>
								);
							})}
						<TakeImage>
							<Image
								
								style={{
									width: "50%",
									height: "50%",
								}}
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/add-image.svg"
								htmlFor="img"
							/>
							<input
								type="file"
								id="img"
								name="img"
								accept="image/*"
								disabled={img.length === 4 ? true : false}
								onChange={onImageChange}
							/>
						</TakeImage>
					</ReviewImages>
				</ReviewBottom>
				<div
					style={{
						display: "flex",
					}}
				>
					<Button onClick={addEditReview}>
						{isLoading ? <ButtonLoader /> : "Submit"}
					</Button>
				</div>
			</ReviewBox>
		</>
	) : (
		<PageLoader />
	);
}

const Title = styled.h3`
	font-family: medium;
	font-size: 22px;
	color: #747474;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
	@media all and (max-width: 420px) {
		font-size: 18px;
	}
`;
const ReviewBox = styled.div`
	padding: 30px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	background: #fffdfa;
	/* display: flex; */
	margin-top: 30px;
	align-items: flex-start;
	justify-content: space-between;
`;
const ReviewTitle = styled.h3`
	color: #747474;
	font-size: 18px;
	font-family: "regular";
	margin-bottom: 20px;
`;
const StarBox = styled.div`
	display: flex;
	align-items: center;
`;
const Star = styled.div`
	width: 50px;
	margin-right: 35px;
	:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 768px) {
		width: 30px;
		margin-right: 25px;
	}
	@media all and (max-width: 420px) {
		margin-right: 15px;
	}
`;
// const AddReview = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const AddReviewText = styled.span`
// 	font-size: 16px;
// 	font-family: "regular";
// 	color: #feda00;
// 	margin-left: 10px;
// `;

const ReviewTop = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 20px;
`;
const ReviewLeft = styled.div``;
const Image = styled.img``;
// const IconBox = styled.div`
// 	width: 15px;
// `;
const ReviewRight = styled.div``;
const EditReviewButton = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #feda00;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
`;
// const EditButtonText = styled.span`
// 	color: #191919;
// 	font-size: 18px;
// 	font-family: "regular";
// `;
const ReviewBottom = styled.div`
	border-top: 1px solid #e4e4e4;
	padding-top: 20px;
`;
// const BottomTitle = styled.h3`
// 	color: #191919;
// 	font-size: 15px;
// 	font-family: "regular";
// 	margin-top: 20px;
// `;
// const ReviewText = styled.p`
// 	color: #747474;
// 	font-size: 17px;
// 	font-family: "regular";
// 	margin: 10px 0 20px;
// `;
const ReviewImages = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
`;
const ImageItem = styled.div`
	width: 80px;
	height: 80px;
	margin-right: 10px;
	border-radius: 10px;
	position: relative;
	:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 640px) {
		width: 50px;
		height: 50px;
	}
`;
const CommentBox = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
`;
const TextArea = styled.textarea`
	border: 1px solid #e4e4e4;
	padding: 10px;
	margin-top: 10px;
	resize: vertical;
	color: #747474;
	font-size: 18px;
	font-family: regular;
	border-radius: 5px;
`;
const SelectText = styled.span`
	font-size: 16px;
	font-family: regular;
	color: #747474;
`;
const TakeImage = styled.label`
	background: #faf7e7;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	border-radius: 5px;
	overflow: hidden;
	input {
		display: none;
	}
	@media all and (max-width: 640px) {
		width: 50px;
		height: 50px;
	}
`;

const Close = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 50%;
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -10px;
	z-index: 2;
`;

const Button = styled.span`
	width: 20%;
	display: inline-block;
	padding: 15px 20px;
	margin-top: 20px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	text-align: center;
	font-family: "medium";
	border-radius: 5px;
	max-height: 50px;
	cursor: pointer;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	@media all and (max-width: 640px) {
		width: 40%;
		padding: 10px 20px;
		font-size: 14px;
	}
`;
