import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { adsConfig } from "../../../apiConfig";
import HomeLoader from "../content-loader/spotlight/HomeLoader";
import { useNavigate } from "react-router-dom";

export default function SpotlightBanner() {
  const [spotlightSlider, setSpotlightSlider] = useState([]);
  const [collectionSlider, setCollectionSlider] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
  };
  const navigate = useNavigate();

  useEffect(() => {
    adsConfig
      .get("sliders/")
      .then((res) => {
        if (res.data.length > 0) {
          res.data.filter((item) => {
            if (item.slider_order_id === 1) {
              setSpotlightSlider(item.slider_items);
            } else if (item.slider_order_id === 2) {
              setCollectionSlider(item.slider_items);
            }
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handleUrl = (url, id) => {
    if (url.includes("products")) {
      navigate("/products/all-products", {
        state: {
          url: url,
        },
      });
    } else if (
      url.includes("products") === false &&
      url.includes("product") === false
    ) {
      window.open(`${url}`, "_self");
    } else {
      navigate(`/product/${id}`, {
        state: {
          url: url,
        },
      });
    }
  };

  return (
    <BannerContainer>
      {!isLoading && spotlightSlider.length > 0 ? (
        <Slider {...settings}>
          {spotlightSlider.map((item, index) => {
            return (
              <img
                fetchpriority="high"
                key={index}
                className="img-class"
                alt="Image"
                src={item.item_image}
                onClick={() => {
                  // window.open(`${item.url}`, "_self");
                  handleUrl(item.url, item.id);
                }}
              />
            );
          })}
        </Slider>
      ) : (
        <HomeLoader />
      )}
    </BannerContainer>
  );
}

const BannerContainer = styled.section`
  margin-bottom: 3%;
  cursor: pointer;
  @media all and (max-width: 640px) {
    margin-bottom: 0;
  }
  overflow-x: hidden;
  /* height: 400px; */
  .slick-track {
    display: flex;
    /* width: 100vw !important; */
  }
  .slick-slide {
    /* width: 100vw !important; */
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    display: flex !important;
    margin: 0 auto;
    width: max-content;
    transform: translateY(-40px);
    @media all and (max-width: 640px) {
      transform: translateY(-15px);
    }
    li {
      width: 27px;
      height: 4px;
      margin-right: 6px;
      cursor: pointer;
      background-color: #c1c1c1;
      @media all and (max-width: 640px) {
        width: 10px;
        height: 1px;
      }
      button {
        display: none !important;
      }
    }
    .slick-active {
      background-color: #0796d8;
    }
  }
`;
