import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AddEditModal from "../modal/checkout/AddEditModal";
import { Context } from "../../context/store";

export default function AddressCard({
  hasButton,
  setAddressConfirm,
  addressData,
  setSizeSelectId,
  setSelectedPincode,
  setRefresh,
  setAddressData,
  selectedAddress,
  setSelectedAddress,
  formData,
  setFormData,
}) {
  const [isModal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const { state } = useContext(Context);
  //   useEffect(() => {
  //     if (isModal) {
  //       document.body.style.overflowY = "hidden";
  //     } else {
  //       document.body.style.overflowY = "scroll";
  //     }
  //   }, [isModal]);

  function buttonAction(addId) {
    setSizeSelectId(addId);
    // setAddressConfirm(true);
  }

  function modalAction(type) {
    setModalType(type);
    setModal(true);
  }

  return (
    <>
      <Title>Select Delivery Address</Title>
      {addressData.map((item) => {
        // setSelectedAddress(item.)
        return (
          <AddressCardContainer
            style={{
              border:
                selectedAddress === item
                  ? "1px solid #EAB600"
                  : "1px solid #f2f0ed",
            }}
            key={item.id}
            onClick={() => (
              setSelectedAddress(item),
              buttonAction(item),
              setSelectedPincode(item.pincode)
            )}
          >
            <AddEditModal
              modalType={modalType}
              isModal={isModal}
              setModal={setModal}
              addId={item.id}
              setRefresh={setRefresh}
              setAddressData={setAddressData}
              formData={formData}
              setFormData={setFormData}
            />
            <Top>
              <Name>{item.customer_name}</Name>
              <Box>
                <IconBox>
                  <Image
                    className="img-class"
                    alt="Image"
                    src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/phone.svg"
                  />
                </IconBox>
                <PhoneNumber>{item.phone_no}</PhoneNumber>
              </Box>
            </Top>
            <Middle>
              <Address>
                {item.address_line1}
                <br />
                {item.near_landmark}
                <br />
                {item.pincode},{item.city}
                <br />
                {item.state}
              </Address>
              <EditBox onClick={() => setModal(true)}>
                <IconBox>
                  <Image
                    className="img-class"
                    alt="Image"
                    src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/phone.svg"
                  />
                </IconBox>
                <EditButton
                  onClick={() => (
                    setFormData({
                      ...formData,
                      edit_id : item.id,
                      address_line1: item.address_line1,
                      address_line2: item.address_line2,
                      address_type: item.address_type,
                      city: item.city,
                      customer_name: item.customer_name,
                      near_landmark: item.near_landmark,
                      phone_no: item.phone_no,
                      pincode: item.pincode,
                      state: item.state,
                    }),
                    modalAction("edit")
                  )}
                >
                  Edit
                </EditButton>
              </EditBox>
            </Middle>
            {/* {hasButton && (
						<GradientButton
							margin={"30px 0 0 0"}
							fontColor={"#747474"}
							fontSize={"15px"}
							fontFamily={"medium"}
							br={"5px"}
							text={"Save Address & Continue"}
							width={"100%"}
							height={"45px"}
							onClick={() => buttonAction(item)}
						/>
					)} */}
          </AddressCardContainer>
        );
      })}
      {hasButton && (
        <AddAddressButton onClick={() => modalAction("add")}>
          + ADD NEW ADDRESS
        </AddAddressButton>
      )}
    </>
  );
}

const Title = styled.h3`
  color: #747474;
  font-size: 22px;
  font-family: "medium";
  margin-bottom: 25px;
  @media all and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 20px;
  }
`;
const AddressCardContainer = styled.div`
  background-color: #fffdfa;
  /* border: 1px solid #f2f0ed; */
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 25px;
  @media all and (max-width: 420px) {
    padding: 15px;
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;
const Name = styled.h3`
  color: #000000;
  font-size: 20px;
  font-family: "medium";
  @media all and (max-width: 420px) {
    font-size: 18px;
  }
`;
const Box = styled.div`
  display: flex;
  align-items: center;
`;
const IconBox = styled.div`
  width: 17px;
  margin-right: 5px;
`;
const Image = styled.img``;
const PhoneNumber = styled.span`
  color: #747474;
  font-size: 18px;
  font-family: "regular";
  @media all and (max-width: 420px) {
    font-size: 15px;
  }
`;
const Middle = styled.div``;
const Address = styled.h3`
  color: #747474;
  font-size: 18px;
  font-family: "regular";
  line-height: 25px;
  @media all and (max-width: 420px) {
    font-size: 15px;
  }
`;
const EditBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;
const EditButton = styled.span`
  color: #fedc0a;
  font-size: 18px;
  font-family: "medium";
`;
const AddAddressButton = styled.span`
  color: #fedc0a;
  font-size: 20px;
  font-family: "medium";
  display: inline-block;
  text-align: right;
  width: 100%;
  cursor: pointer;
  @media all and (max-width: 420px) {
    font-size: 18px;
    padding-bottom: 30px;
  }
`;
