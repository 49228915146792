import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { bidConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import Header from "../../general/Header";
import HeaderPush from "../../general/HeaderPush";
import EmptyPage from "../../screens/EmptyPage";
import PageLoader from "../../screens/PageLoader";

export default function WinningEvents({ searchValue, setSearchValue }) {
	const { state } = useContext(Context);
	const [events, setEvents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		bidConfig
			.get("my-biddings/", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setEvents(data.bidding_data);
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, []);
	return (
		<>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush />
			{!isLoading && events.length > 0 ? (
				<Container>
					<Wrapper className="wrapper">
						<Top>
							<Title>Your Winning Events</Title>
							<Sub>
								{
									events.filter((item) => {
										return item.has_claimed && item;
									}).length
								}{" "}
								Items
							</Sub>
						</Top>
						<List>
							{events.map((item) => {
								return (
									item.has_claimed && (
										<ListItem>
											<ProductImage>
												{item.bid_product_image && (
													<Image
														
														className="img-class"
														alt="Image" src={
															item.bid_product_image
														}
													/>
												)}
											</ProductImage>
											<Name>{item.bid_product_name}</Name>
											<Button
												onClick={() =>
													item.product_has_converted_to_order
														? null
														: navigate(
																`/checkout/${item.bid_product_id}?type=bid_redeem`
														  )
												}
												style={{
													color: item.product_has_converted_to_order
														? "#c4c4c4"
														: "#eab600",
													borderColor:
														item.product_has_converted_to_order
															? "#c4c4c4"
															: "#eab600",
													cursor: item.product_has_converted_to_order
														? "default"
														: "pointer",
												}}
											>
												{item.product_has_converted_to_order
													? "Prizzed"
													: "Redeem"}{" "}
												{item.bidding_start_time_stamp.slice(
													0,
													10
												)}
											</Button>
										</ListItem>
									)
								);
							})}
						</List>
					</Wrapper>
				</Container>
			) : !isLoading && events.length === 0 ? (
				<EmptyPage text="No items found" />
			) : (
				<PageLoader />
			)}
		</>
	);
}

const Container = styled.div`
	padding: 4% 0;
`;
const Wrapper = styled.div``;
const Top = styled.div``;
const Title = styled.h3`
	font-size: 20px;
	font-family: "medium";
	color: #191919;
`;
const Sub = styled.span`
	font-size: 16px;
	font-family: "regular";
	color: #747474;
`;
const List = styled.div`
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
`;
const ListItem = styled.div`
	text-align: center;
	padding: 15px;
	border-radius: 6px;
	border: 1px solid #e4e4e4;
	margin-right: 1%;
	margin-bottom: 1%;
	width: 19.2%;
	&:nth-child(5n) {
		margin-right: 0;
	}
	@media all and (max-width: 1280px) {
		width: 24.2%;
		&:nth-child(5n) {
			margin-right: 1%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 980px) {
		width: 32.2%;
		&:nth-child(4n) {
			margin-right: 1%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 768px) {
		width: 48.2%;
		margin-bottom: 2%;
		margin-right: 2%;
		&:nth-child(3n) {
			margin-right: 2%;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 420px) {
		padding: 6px;
	}
`;
const ProductImage = styled.div`
	width: 100%;
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	position: relative;
`;
const Image = styled.img`
	border-radius: 6px;
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	left: 0;
	top: 0;
	border-radius: 5px;
`;
const Name = styled.h3`
	font-size: 16px;
	font-family: "medium";
	color: #191919;
	margin: 20px 0 10px;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const Button = styled.span`
	padding: 10px;
	border-radius: 5px;
	border: 2px solid #eab600;
	color: #eab600;
	font-family: medium;
	font-size: 15px;
	width: 100%;
	cursor: pointer;
	display: inline-block;
	@media all and (max-width: 640px) {
		font-size: 10px;
		padding: 6px;
	}
`;
