import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { GradientButton } from "../constants";
import { ordersConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import Toast from "../../general/includes/Toast";
import EmptyPage from "../../screens/EmptyPage";
import { Link, useNavigate } from "react-router-dom";
import PageLoader from "../../screens/PageLoader";
import CheckoutContainerHeader from "../checkout/CheckoutContainerHeader";
import Header from "../../general/Header";

export default function CartList({ reswidth }) {
	const [isModal, setModal] = useState(false);
	const { state } = useContext(Context);
	const [orders, setOrders] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isToast, setToast] = useState(false);
	const [deleted, setDeleted] = useState(false);

	const navigate = useNavigate();

	const cartLoadData = () => {
		ordersConfig
			.get("cart/", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				setOrders(res.data.data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		cartLoadData();
	}, [isToast, deleted]);
	//
	const removeCart = (product_pk) => {
		ordersConfig
			.delete(
				`cart/${product_pk}/`,
				
				{
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				}
			)
			.then((res) => {
				const { StatusCode } = res.data;
				if (StatusCode === 6000) {
					setModal(false);
					setToast(true);
					setDeleted(true);
					cartLoadData();
				}
			})
			.catch((err) => {});
	};

	function buttonAction() {
		navigate("/products/all-products");
	}

	const renderProducts = () => {
		return orders.cart_items.length > 0
			? orders.cart_items?.map((item, index) => (
					<ProductDetailCard key={index}>
						<CardTop>
							<Close onClick={() => removeCart(item.id)}>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
								/>
							</Close>
							<ProductImage to={`/product/${item.product_id}`}>
								<Image
									
									className="img-class"
									alt="Image" src={item.product_image}
								/>
							</ProductImage>
							<CardRight to={`/product/${item.product_id}`}>
								<ProductName>
									{item?.product_name}
									{item?.product_attribute?.value}
								</ProductName>
								<Size>
									{item?.product_attribu?.attribute} :{" "}
									{item?.product_attribute?.value},Qty:{" "}
									{item?.quantity}
								</Size>
								<Price>{item.product_cost}</Price>
							</CardRight>
						</CardTop>
						
					</ProductDetailCard>
			  ))
			: null;
	};

	return (
		<>
			{orders?.cart_items?.length > 0 ? (
				<CheckoutContainerHeader cart={true} reswidth={reswidth} />
			) : (
				<Header />
			)}
			{orders?.cart_items?.length > 0 ? (
				<CartListContainer>
					<Toast
						isToast={isToast}
						setToast={setToast}
						text="Item removed from  the cart"
					/>
					<Wrapper className="wrapper">
						<Left>
							<Top>
								<Title>Cart</Title>
								<ItemText>
									{orders.cart_items.length} Items
								</ItemText>
							</Top>
							{/* // <EmptyTitle>Cart empty !</EmptyTitle> : */}
							{renderProducts()}
						</Left>
						<Right>
							<Title
								style={{
									marginBottom: "25px",
								}}
							>
								Payment
							</Title>
							<PaymentBox>
								<Box>
									<IconBox>
										<Image
											
											className="img-class"
											alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/payment.svg"
										/>
									</IconBox>
									<FormTitle>Payment Details</FormTitle>
								</Box>
								<PriceBox>
									<Text
										style={{
											color: "#747474",
										}}
									>
										Product price
									</Text>
									<Text
										style={{
											color: "#747474",
										}}
									>
										₹{orders?.pice_data?.total_actual_cost}
									</Text>
								</PriceBox>
								<PriceBox>
									<Text>Supplier Discount</Text>
									<Text>-₹{orders?.pice_data?.discount}</Text>
								</PriceBox>
								{/* <PriceBox>
									<Text>Product price</Text>
									<Text>-₹12</Text>
								</PriceBox> */}
								<PriceBox>
									<Text
										style={{
											color: "#191919",
										}}
									>
										Order Total
									</Text>
									<Text>
										₹{orders?.pice_data?.total_product_cost}
									</Text>
								</PriceBox>
							</PaymentBox>
							<GradientButton
								margin={"20px 0 0 0"}
								fontColor={"#747474"}
								fontSize={"15px"}
								fontFamily={"medium"}
								br={"5px"}
								text={"Continue"}
								width={"100%"}
								height={"45px"}
								onClick={() =>
									navigate(
										`/checkout/${orders.cart_items[0].id}?type=cart_products`
									)
								}
							/>
						</Right>
					</Wrapper>
				</CartListContainer>
			) : isLoading ? (
				<PageLoader />
			) : (
				<EmptyPage
					text="Cart is Empty"
					buttonText="Shop now"
					onClick={buttonAction}
				/>
			)}
			{orders?.cart_items?.length > 0 && (
				<CartMobileBottom>
					<div
						style={{
							width: "90%",
							margin: "0 auto",
							maxWidth: "1350px",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<MobileLeft>
							<BottomText>
								{orders?.cart_items?.length} Items
							</BottomText>
							<BottomText>
								₹{orders?.pice_data?.total_product_cost}
							</BottomText>
						</MobileLeft>
						<MobileRight
							onClick={() =>
								navigate(
									`/checkout/${orders.cart_items[0].id}?type=cart_products`
								)
							}
						>
							Place order
						</MobileRight>
					</div>
				</CartMobileBottom>
			)}
		</>
	);
}
const CartListContainer = styled.div`
	padding: 3% 0 1.5%;
	@media all and (max-width: 980px) {
		margin-top: 170px;
		max-height: calc(100vh - 160px);
		overflow-y: scroll;
	}
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 1000px;
	@media all and (max-width: 980px) {
		flex-direction: column-reverse;
	}
`;
const Left = styled.div`
	width: 58%;
	@media all and (max-width: 980px) {
		width: 100%;
	}
`;
const Top = styled.div`
	margin-bottom: 25px;
	display: flex;
	justify-content: space-between;
`;
const Title = styled.div`
	color: #747474;
	font-size: 22px;
	font-family: "medium";
	@media all and (max-width: 768px) {
		margin-bottom: 10px;
		font-size: 20px;
	}
`;

const ItemText = styled.h3`
	color: #747474;
	font-size: 22px;
	font-family: "regular";
	@media all and (max-width: 768px) {
		margin-bottom: 10px;
		font-size: 20px;
	}
	@media all and (max-width: 420px) {
		font-size: 17px;
	}
`;
const ProductDetailCard = styled.div`
	background-color: #fffdfa;
	border: 1px solid #f2f0ed;
	border-radius: 5px;
	display: block;
	padding: 40px;
	margin-bottom: 20px;
	@media all and (max-width: 640px) {
		padding: 25px 15px 15px;
	}
`;
const CardTop = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
`;

const ProductImage = styled(Link)`
	width: 130px;
	height: 130px;
	border-radius: 5px;
	overflow: hidden;
	margin-right: 20px;
	@media all and (max-width: 640px) {
		width: 100px;
		height: 100px;
	}
	@media all and (max-width: 360px) {
		width: 80px;
		height: 80px;
	}
`;
const Close = styled.div`
	position: absolute;
	top: 7%;
	right: 2%;
	width: 15px;
	cursor: pointer;
	@media all and (max-width: 640px) {
		width: 10px;
	}
	@media all and (max-width: 420px) {
		top: 4px;
	}
`;
const Image = styled.img``;
const CardRight = styled(Link)``;
const ProductName = styled.h3`
	font-family: regular;
	font-size: 22px;
	color: #191919;
	@media all and (max-width: 980px) {
		flex-direction: column-reverse;
	}
	@media all and (max-width: 768px) {
		font-size: 18px;
	}
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const Size = styled.h3`
	font-family: regular;
	font-size: 18px;
	color: #747474;
	margin: 15px 0;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const Price = styled.h3`
	font-family: medium;
	font-size: 18px;
	color: #191919;
`;
const Right = styled.div`
	width: 40%;
	@media all and (max-width: 980px) {
		width: 100%;
		margin-top: 50px;
		padding-bottom: 30px;
		display: none;
	}
`;
const PaymentBox = styled.div`
	background-color: #fffdfa;
	border: 1px solid #f2f0ed;
	border-radius: 5px;
	padding: 40px;
	@media all and (max-width: 420px) {
		padding: 15px;
	}
`;
const Box = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 45px;
`;
const PriceBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
		padding-top: 30px;
		border-top: 1px solid #d4d4d4;
		margin-top: 40px;
	}
`;
const Text = styled.h3`
	font-size: 16px;
	font-family: medium;
	color: #219a75;
`;
const FormTitle = styled.h3`
	color: #000000;
	font-size: 20px;
	font-family: "medium";
`;
const IconBox = styled.div`
	width: 20px;
	margin-right: 10px;
`;

// const ModalContainer = styled.div`
// 	width: 100vw;
// 	height: 100vh;
// 	position: fixed;
// 	z-index: 1000;
// 	top: 0;
// 	left: 0;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	transition: 0.2s ease-out;
// 	transform: scale(1.1);
// 	background: transparent;
// 	transform: ${({ isModal }) => (isModal ? "scale(1)" : "scale(0)")};
// `;
// const ModalFade = styled.div`
// 	background: rgba(0, 0, 0, 0.16);
// 	width: 100vw;
// 	height: 100vh;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	display: ${({ isModal }) => (isModal ? "block" : "none")};
// `;
// const ModalBox = styled.div`
// 	background: #fff;
// 	padding: 40px;
// 	border-radius: 5px;
// 	width: 600px;
// 	position: relative;
// 	z-index: 1001;
// 	@media all and (max-width: 768px) {
// 		width: 500px;
// 	}
// 	@media all and (max-width: 640px) {
// 		width: 350px;
// 		padding: 20px;
// 	}
// 	@media all and (max-width: 420px) {
// 		width: 300px;
// 	}
// `;
// const ModalTop = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	width: 100%;
// 	border-bottom: 1px solid #e7e7e7;
// 	padding-bottom: 20px;
// 	margin-bottom: 30px;
// `;
// const ModalTitle = styled.h3`
// 	font-size: 22px;
// 	font-family: medium;
// 	color: #003c3c;
// 	@media all and (max-width: 640px) {
// 		font-size: 18px;
// 	}
// `;
// const ModalClose = styled.div`
// 	width: 15px;
// 	cursor: pointer;
// `;
// const ModalImage = styled.img``;
// const ModalDescription = styled.p`
// 	font-size: 18px;
// 	font-family: regular;
// 	color: #003c3c;
// 	line-height: 25px;
// 	@media all and (max-width: 640px) {
// 		font-size: 15px;
// 	}
// `;

// // cart card bottom
// const Counter = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const Button = styled.button`
// 	height: 28px;
// 	width: 28px;
// 	padding: 5px;
// 	cursor: pointer;
// 	border-radius: 5px;
// 	border: 1px solid #e7e7e7;
// 	&.left {
// 		transform: rotate(180deg);
// 	}
// 	@media all and (max-width: 420px) {
// 		padding: 7px;
// 	}
// `;
// const Number = styled.span`
// 	background: #fffae9;
// 	border-radius: 5px;
// 	border: 1px solid #eab600;
// 	padding: 3px 35px;
// 	font-size: 20px;
// 	color: #eab600;
// 	height: 28px;
// 	margin: 0 10px;
// 	font-family: medium;
// 	@media all and (max-width: 640px) {
// 		padding: 6px 25px;
// 		font-size: 16px;
// 	}
// 	@media all and (max-width: 420px) {
// 		margin: 0 5px;
// 		padding: 6px 18px;
// 		font-size: 12px;
// 	}
// `;

// const QtyTitle = styled.h3`
// 	font-family: regular;
// 	font-size: 18px;
// 	margin-right: 12px;
// 	color: #747474;
// 	@media all and (max-width: 420px) {
// 		font-size: 15px;
// 	}
// `;
// const SeletctSize = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const SizeIconBox = styled.div`
// 	width: 15px;
// `;
// const SizeText = styled.div`
// 	margin-right: 6px;
// 	font-size: 16px;
// 	font-family: medium;
// 	color: 191919;
// 	@media all and (max-width: 420px) {
// 		font-size: 13px;
// 	}
// `;
// const SizeDropDown = styled.div`
// 	position: absolute;
// 	top: 20px;
// 	left: 0;
// 	width: 100%;
// 	/* height: 80px; */
// 	background: #fff;
// 	border-radius: 5px;
// 	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
// `;
// const Qty = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const SizeBox = styled.div`
// 	display: flex;
// 	align-items: center;
// 	position: relative;
// `;

const CartMobileBottom = styled.div`
	width: 100%;
	height: 80px;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: #fffdfa;
	border-top: 1px solid #f2f0ed;
	display: none;
	align-items: center;
	border-bottom: 1px solid #f2f0ed;
	@media all and (max-width: 980px) {
		display: flex;
	}
`;
const MobileLeft = styled.div`
	display: flex;
	flex-direction: column;
`;
const BottomText = styled.span`
	font-family: regular;
	font-size: 18px;
	color: #747474;
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const MobileRight = styled.div`
	font-size: 18px;
	font-family: medium;
	width: 49%;
	color: #747474;
	border-radius: 5px;
	cursor: pointer;
	text-align: center;
	background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
	padding: 15px 0;
	@media all and (max-width: 420px) {
		width: 60%;
	}
`;
