import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../../general/Footer";
import HeaderPush from "../../general/HeaderPush";
import Header from "../../general/Header";

export default function About({ reswidth, searchValue, setSearchValue }) {
  //when onload the page ,scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        reswidth={reswidth}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <HeaderPush />
      <Container>
        <Wrapper>
          <Title>About Us</Title>
          <Content>
            Oneruppee, a gamified multi-vendor e-commerce platform by Orgecom
            Trade Private Limited, offers a unique approach to online shopping.
            This platform enhances the shopping experience by incorporating
            gaming elements, allowing users to play online games, earn rewards,
            and enjoy virtual incentives while they shop. Customers can earn
            loyalty points and discounts, adding excitement to their product
            exploration and purchases. Interactive product discovery through
            mini-games and challenges empowers customers to make informed
            decisions, and a strong community focus lets users compete,
            participate in virtual events, and share their achievements. Vendors
            can also add and sell their products on Oneruppee, creating a
            diverse and engaging marketplace for both buyers and sellers.
            Oneruppee's gamified multi-vendor approach sets it apart from
            traditional e-commerce platforms, offering a fun and interactive way
            to shop online.
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  padding: 3% 0;
  min-height: calc(100vh - 420px);
  @media all and (max-width: 640px) {
    padding: 8% 0;
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
`;
const Title = styled.h3`
  font-size: 22px;
  font-family: medium;
  color: #191919;
  padding-bottom: 20px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 30px;
  text-align: center;
  @media all and (max-width: 640px) {
    font-size: 19px;
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
`;
const Content = styled.p`
  font-size: 18px;
  font-family: regular;
  color: #747474;
  line-height: 1.2em;
  letter-spacing: 0.3px;
  margin-bottom: 35px;
  @media all and (max-width: 640px) {
    font-size: 14px;
    margin-bottom: 25px;
  }
`;
