import React, { useContext, useState } from "react";
import styled from "styled-components";
import { accountConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import ButtonLoader from "../../general/includes/ButtonLoader";

import { UnderLineButton } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";


export default function SetName() {
	const { state, dispatch } = useContext(Context);
	const [isFocus, setFocus] = useState("phone");
	const [name, setName] = useState("");
	const [errMsg, setErrMsg] = useState("");
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	// fetch profile after set name
	function fetchProfile() {
		accountConfig
			.get("profile/", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					dispatch({
						type: "UPDATE_USER_DATA",
						user_data: {
							...state.user_data,
							is_verified: true,
							phone: data.phone,
							name: data.name,
							email: data.email,
							gender: data.gender,
							photo: data.photo,
							user_pk: data.user,
						},
					});

					// redirecting
					if (location.state?.from) {
						navigate(location.state?.from);
					} else {
						navigate("/");
					}
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	}

	// set name
	function handleSubmitName() {
		if (name) {
			setErrMsg("");
			setLoading(true);
			accountConfig
				.post(
					"update-customer-profile/",
					{
						name: name,
					},
					{
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					}
				)
				.then((res) => {
					const { StatusCode, data } = res.data;
					if (StatusCode === 6000) {
						// firebaseAuth();
						fetchProfile();
					} else {
						setLoading(false);
					}
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			setErrMsg("Please enter your full name");
		}
	}

	return (
		<EnterPhoneContainer>
			<Title>Complete your signup with your name</Title>
			<InputContainer>
				<NumberBox
					onClick={() => setFocus("phone")}
					style={{
						borderColor:
							isFocus === "phone" ? "#E9CE22" : "#747474",
					}}
				>
					<NumberTitle
						style={{
							color: isFocus === "phone" ? "#E9CE22" : "#747474",
						}}
					>
						Full name
					</NumberTitle>
					<NumberInput>
						<Input
							type="text"
							autoFocus
							value={name}
							onChange={(e) => (
								setName(e.target.value), setErrMsg("")
							)}
						/>
					</NumberInput>
					<ErrorText>{errMsg}</ErrorText>
				</NumberBox>
			</InputContainer>
			{isLoading ? (
				<Button>
					<ButtonLoader />
				</Button>
			) : (
				<Button onClick={handleSubmitName}>
					<ButtonText>Submit</ButtonText>
				</Button>
			)}
			{/* <AuthFooter>
				<TermsTitle>By continuing, you agree to onerupee’s</TermsTitle>
				<LinkBox>
					<UnderLineButton
						fontSize="15px"
						text="Terms & Conditions"
						margin
					/>
					<Span>&</Span>
					<UnderLineButton
						fontSize="15px"
						text="Privacy Policy"
						margin
					/>
				</LinkBox>
			</AuthFooter> */}
		</EnterPhoneContainer>
	);
}
const EnterPhoneContainer = styled.div``;
const Title = styled.h3`
	font-size: 25px;
	font-family: medium;
	color: #606060;
	margin-bottom: 15%;
	@media all and (max-width: 640px) {
		font-size: 17px;
	}
`;
const InputContainer = styled.div`
	display: flex;
	align-items: flex-start;
`;
// const CountryBox = styled.div`
// 	margin-right: 20px;
// 	border-bottom: 1px solid #747474;
// 	padding-bottom: 3%;
// 	position: relative;
// `;
// const CountryTitle = styled.h3`
// 	font-size: 17px;
// 	margin-bottom: 20px;
// 	font-family: medium;
// 	@media all and (max-width: 640px) {
// 		font-size: 14px;
// 	}
// `;
// const FlagBox = styled.div`
// 	display: flex;
// 	cursor: pointer;
// 	align-items: center;
// `;
// const CountryFlag = styled.div`
// 	width: 30px;
// 	height: 30px;
// 	border-radius: 50%;
// 	margin-right: 10px;
// 	overflow: hidden;
// `;
// const DropIcon = styled.div`
// 	width: 15px;
// 	height: 15px;
// 	margin-left: 10px;
// `;
// const Image = styled.img``;
// const CountryCode = styled.h3`
// 	font-size: 20px;
// 	font-family: medium;
// 	color: #606060;
// 	@media all and (max-width: 640px) {
// 		font-size: 15px;
// 	}
// `;
const NumberBox = styled.div`
	border-bottom: 1px solid #747474;
	padding-bottom: 3%;
	width: 100%;
	position: relative;
`;
const NumberTitle = styled.h3`
	font-size: 17px;
	margin-bottom: 20px;
	font-family: medium;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
const NumberInput = styled.div`
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	/* @media all and (max-width: 640px) {
    height: 25px;
  } */
`;
const Input = styled.input`
	font-size: 20px;
	font-family: regular;
	color: #191919;
	width: 100%;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
const ErrorText = styled.span`
	position: absolute;
	bottom: -35%;
	right: 0;
	font-size: 17px;
	font-family: regular;
	color: #e93a22;
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;
const Button = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 45px;
	background: linear-gradient(255.47deg, #ffdf17 0%, #fff79e 102.09%);
	border-radius: 5px;
	margin-top: 20%;
	cursor: pointer;
	@media all and (max-width: 640px) {
		height: 40px;
	}
`;
const ButtonText = styled.span`
	font-size: 18px;
	font-family: medium;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;

const AuthFooter = styled.div`
	/* width: 50%;
  position: absolute;
  bottom: 0;
  right: 0; */
	height: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* width: 100%; */
`;
const TermsTitle = styled.h3`
	font-size: 15px;
	font-family: regular;
	margin-bottom: 8px;
	color: #191919;
`;
const Span = styled.span`
	font-size: 13px;
	font-family: regular;
	color: #191919;
	margin: 0 5px;
`;
const LinkBox = styled.div`
	display: flex;
	align-items: center;
`;
