import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import HeaderPush from "../general/HeaderPush";
import { useNavigate } from "react-router-dom";
import { bidConfig } from "../../apiConfig";
import PageLoader from "./PageLoader";

//
import Footer from "../general/Footer";
import Toast from "../general/includes/Toast";
import Header from "../general/Header";
import { Context } from "../context/store";
import EmptyPage from "./EmptyPage";

export default function UpcommingBidding({ searchValue, setSearchValue }) {
	const { state } = useContext(Context);
	const [upcomingBiddingList, setUpcomingBiddingList] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isToast, setToast] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		if (state.user_data.name === null || state.user_data.name === "") {
			setToast(true);
			setTimeout(() => {
				navigate("/profile");
			}, 2000);
		}
	}, []);

	useEffect(() => {
		bidConfig
			.get("upcoming/bidding-items/")
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setUpcomingBiddingList(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			<Toast
				text="Complete your profile"
				isToast={isToast}
				setToast={setToast}
			/>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush height="80px" />
			{!isLoading ? (
				upcomingBiddingList.length === 0 && !isLoading ? (
					<EmptyPage text="No items found" />
				) : (
					<BiddingContainer>
						<BiddingBanner>
							<img
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/bidding/upcoming-top-banner.png"
								className="img-class"
								
							/>
						</BiddingBanner>
						<BiddingListContainer>
							<Wrapper className="wrapper">
								{upcomingBiddingList.length > 0 && (
									<BiddingTitle
										style={{
											marginTop: "2%",
										}}
									>
										Upcomming
									</BiddingTitle>
								)}
								<BiddingItems>
									{upcomingBiddingList.map((item, index) => (
										<BiddingCard key={index}>
											<Top>
												<TopLeft>
													<Image
														
														className="img-class"
														alt="Image" src={item.product_image}
													/>
												</TopLeft>
												<TopRight>
													<ProductName>
														{item.product_name}
													</ProductName>
													<ProductDetails>
														{item.title}
													</ProductDetails>
												</TopRight>
											</Top>
											<Bottom>
												<BottomLeft>
													<BiddingTimeLine
														style={{
															display: "flex",
														}}
													>
														<TimeBox
															style={{
																marginRight:
																	"6px",
															}}
														>
															<IconBox>
																<Image
																	
																	className="img-class"
																	alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/calender.svg"
																/>
															</IconBox>
															<Text
																style={{
																	fontSize:
																		"13px",
																	fontFamily:
																		"medium",
																	color: "#191919",
																}}
															>
																{item.start_time_stamp
																	? new Date(
																			item.start_time_stamp
																	  ).toDateString()
																	: "--"}
															</Text>
														</TimeBox>
														<TimeBox>
															<TimeBox>
																<IconBox>
																	<Image
																		
																		className="img-class"
																		alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/clock.svg"
																	/>
																</IconBox>
																<Text
																	style={{
																		fontSize:
																			"13px",
																		fontFamily:
																			"medium",
																		color: "#191919",
																	}}
																>
																	{item.start_time_stamp
																		? new Date(
																				item.start_time_stamp
																		  ).toLocaleTimeString(
																				"en-US",
																				{
																					// timeZone:
																					// 	"UTC",
																					hour12: true,
																					// hour: "numeric",
																					// minute: "numeric",
																				}
																		  )
																		: "--"}
																</Text>
															</TimeBox>
														</TimeBox>
													</BiddingTimeLine>
												</BottomLeft>
											</Bottom>
										</BiddingCard>
									))}
								</BiddingItems>
							</Wrapper>
						</BiddingListContainer>
					</BiddingContainer>
				)
			) : (
				<PageLoader />
			)}
			<Footer />
		</>
	);
}

const BiddingContainer = styled.div`
	padding-bottom: 5%;
`;
const BiddingBanner = styled.div`
	/* padding: 5% 0; */
	@media all and (max-width: 640px) {
		height: 100px;
	}
`;
const Wrapper = styled.div``;
const BiddingListContainer = styled.div`
	margin-top: 7%;
`;
const BiddingTitle = styled.h3`
	font-size: 25px;
	font-family: medium;
	color: #191919;
	margin-bottom: 25px;
	/* @media all and (max-width: 1280px) {
		font-size: 25px;
	} */
	@media all and (max-width: 1080px) {
		font-size: 20px;
	}
	@media all and (max-width: 640px) {
		font-size: 16px;
		margin-bottom: 15px;
	}
`;
const BiddingItems = styled.div`
	padding: 0 3%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;
const BiddingCard = styled.div`
	width: 30%;
	margin-bottom: 5%;
	padding: 2%;
	border-radius: 6px;
	@media all and (max-width: 1280px) {
		width: 48%;
		margin-bottom: 7%;
	}
	@media all and (max-width: 768px) {
		width: 100%;
		margin-bottom: 7%;
	}
	@media all and (max-width: 640px) {
		padding: 4%;
		margin-bottom: 15%;
	}
	transition: 0.3s all ease-in-out;
	cursor: pointer;
	&:hover {
		-webkit-box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
		-moz-box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
		box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
	}
`;
const Top = styled.div`
	display: flex;
`;
const TopLeft = styled.div`
	min-width: 130px;
	max-width: 130px;
	height: 130px;
	margin-right: 20px;
	@media all and (max-width: 640px) {
		min-width: 100px;
		max-width: 100px;
		height: 100px;
		margin-right: 10px;
	}
`;
const Image = styled.img`
	/* &.bid-list-img {
		width: 70%;
		max-height: 90px;
	} */
`;
const TopRight = styled.div`
	text-align: left;
	/* width: 58%; */
`;
const ProductName = styled.h3`
	font-size: 16px;
	font-family: medium;
	color: #191919;
	margin-bottom: 10px;
	@media all and (max-width: 980px) {
		font-size: 14px;
	}
`;
const ProductDetails = styled.h3`
	font-size: 13px;
	font-family: regular;
	color: #747474;
	line-height: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	@media all and (max-width: 640px) {
		font-size: 12px;
	}
`;
const Bottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
`;
const BottomLeft = styled.div``;
const BiddingTimeLine = styled.div``;
const IconBox = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 5px;
`;
const TimeBox = styled.div`
	display: flex;
	align-items: center;
`;
const Text = styled.span``;
// const BottomRight = styled.div`
// 	width: 30%;
// `;
// const BiddingButton = styled(Link)`
// 	position: relative;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	width: 100%;
// 	height: 30px;
// 	cursor: pointer;
// `;
// const BidCover = styled.img`
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	width: 100%;
// 	height: 100%;
// 	z-index: -1;
// `;
// const BiddingText = styled.h3`
// 	font-size: 13px;
// 	font-family: "medium";
// 	color: #747474;
// `;
