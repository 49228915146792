import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/lotties/button-loader.json";

export default function ButtonLoader({ size }) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<div>
			<Lottie
				options={defaultOptions}
				height={size ? size : 50}
				width={size ? size : 50}
			/>
		</div>
	);
}
