import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import HeaderPush from "../general/HeaderPush";
import { useNavigate } from "react-router-dom";
import { bidConfig } from "../../apiConfig";
import PageLoader from "./PageLoader";

//
import Footer from "../general/Footer";
import Toast from "../general/includes/Toast";
import Header from "../general/Header";
import { Context } from "../context/store";
import EmptyPage from "./EmptyPage";

export default function LiveBiddingList({ searchValue, setSearchValue }) {
	const { state } = useContext(Context);

	const [biddingList, setBiddingList] = useState([]);
	const [myBiddingList, setMyBiddingList] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isToast, setToast] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		if (state.user_data.name === null || state.user_data.name === "") {
			setToast(true);
			setTimeout(() => {
				navigate("/profile");
			}, 2000);
		}
	}, []);

	useEffect(() => {
		bidConfig
			.get("bidding-items/")
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setBiddingList(data);
					// setLoading(false);
				} else {
					// setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});

		setLoading(true);

		// my biddings
		bidConfig
			.get("bidding-winners/", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setMyBiddingList(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			<Toast
				text="Complete your profile"
				isToast={isToast}
				setToast={setToast}
			/>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush height="80px" />
			{!isLoading &&
			biddingList.length === 0 &&
			myBiddingList.length === 0 ? (
				<EmptyPage text="No items found" />
			) : (!isLoading && biddingList.length > 0) ||
			  myBiddingList.length > 0 ? (
				<BiddingContainer>
					<BiddingBanner>
						<img
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/bidding/top-banner.png"
							className="img-class"
							
						/>
					</BiddingBanner>
					<BiddingListContainer>
						<Wrapper className="wrapper">
							{biddingList.length > 0 && (
								<BiddingTitle>Live Jackpot Event</BiddingTitle>
							)}
							<BiddingItems>
								{biddingList.map((item, index) => (
									<BiddingCard key={index}>
										<Top>
											<TopLeft>
												<Image
													
													className="img-class bid-list-img"
													alt="Image" src={item.product_image}
												/>
											</TopLeft>
											<TopRight>
												<ProductName>
													{item.product_name}
												</ProductName>
												<ProductDetails>
													{item.title}
												</ProductDetails>
											</TopRight>
										</Top>
										<Bottom>
											<BottomLeft>
												<BiddingTimeLine>
													<TimeBox
														style={{
															marginBottom:
																"10px",
														}}
													>
														<IconBox>
															<Image
																
																className="img-class"
																alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/live.png"
															/>
														</IconBox>
														<Text
															style={{
																fontSize:
																	"13px",
																fontFamily:
																	"medium",
																color: "#191919",
															}}
														>
															Live
														</Text>
													</TimeBox>
													<TimeBox>
														<Text
															style={{
																fontSize:
																	"13px",
																fontFamily:
																	"medium",
																color: "#D4D4D4",
															}}
														>
															Ends in{" "}
															<Text
																style={{
																	color: "#747474",
																}}
															>
																{item.end_time_stamp
																	? new Date(
																			item.end_time_stamp
																	  ).toDateString()
																	: "--"}
															</Text>
														</Text>
													</TimeBox>
												</BiddingTimeLine>
											</BottomLeft>
											<BottomRight>
												<BiddingButton
													// to={`/bidding/live-bidding/${item.id}`}
													onClick={() => {
														navigate(
															`/bidding/live-bidding/${item.id}`,
															{
																state: {
																	bid_date:
																		new Date(
																			item.start_time_stamp
																		)
																			.toJSON()
																			.slice(
																				0,
																				10
																			),
																},
															}
														);
													}}
												>
													<BidCover alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/button-cover.png" />
													<BiddingText>
														Start Now
													</BiddingText>
												</BiddingButton>
											</BottomRight>
										</Bottom>
									</BiddingCard>
								))}
							</BiddingItems>
							{/* Winners */}
							{myBiddingList.length > 0 && (
								<BiddingTitle>Jackpot Event Winners</BiddingTitle>
							)}
							{myBiddingList.length > 0 && (
								<WinnerContainer>
									{myBiddingList.map((item,index) => {
										return (
											<WinnerCard key={index}>
												<Left
													style={{
														backgroundColor:
															"#feda00",
													}}
												>
													{item.user_image ? (
														<img
															
															className="img-class"
															alt="Image" src={
																item.user_image
															}
														/>
													) : (
														<ProfileText>
															{item?.message?.charAt(
																0
															)}
														</ProfileText>
													)}
												</Left>
												<Right>
													<ProductName className="winning">
														{item.message}
													</ProductName>
													<WinProduct>
														<ProductImage>
															<img
																
																className="img-class"
																alt="Image" src={
																	item.product_image
																}
															/>
														</ProductImage>
														<WinProductName>
															{item.product_name}
														</WinProductName>
													</WinProduct>
												</Right>
											</WinnerCard>
										);
									})}
								</WinnerContainer>
							)}
						</Wrapper>
					</BiddingListContainer>
				</BiddingContainer>
			) : (
				<PageLoader />
			)}
			<Footer />
		</>
	);
}

const BiddingContainer = styled.div`
	padding-bottom: 5%;
`;
const BiddingBanner = styled.div`
	@media all and (max-width: 640px) {
		height: 100px;
	}
`;
const Wrapper = styled.div``;

const BiddingListContainer = styled.div`
	margin-top: 7%;
`;
const BiddingTitle = styled.h3`
	font-size: 30px;
	font-family: medium;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 1280px) {
		font-size: 25px;
	}
	@media all and (max-width: 1080px) {
		font-size: 20px;
	}
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
`;
const BiddingItems = styled.div`
	padding: 0 3%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;
const BiddingCard = styled.div`
	width: 30%;
	margin-bottom: 5%;
	border-radius: 6px;
	padding: 2%;
	@media all and (max-width: 1280px) {
		width: 48%;
		margin-bottom: 7%;
	}
	@media all and (max-width: 768px) {
		width: 100%;
		margin-bottom: 7%;
	}
	@media all and (max-width: 640px) {
		padding: 4%;
		margin-bottom: 15%;
	}
	transition: 0.3s all ease-in-out;
	cursor: pointer;
	&:hover {
		-webkit-box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
		-moz-box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
		box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
	}
`;
const Top = styled.div`
	display: flex;
`;
const TopLeft = styled.div`
	min-width: 130px;
	height: 130px;
	margin-right: 20px;
	@media all and (max-width: 640px) {
		min-width: 100px;
		height: 100px;
		margin-right: 10px;
	}
`;
const Image = styled.img`
	/* &.bid-list-img {
		width: 70%;
		max-height: 90px;
	} */
`;
const TopRight = styled.div`
	text-align: left;
	/* width: 58%; */
`;
const ProductName = styled.h3`
	font-size: 16px;
	font-family: medium;
	color: #191919;
	margin-bottom: 4px;
	&.winning {
		margin-bottom: 10px;
		font-size: 15px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	@media all and (max-width: 980px) {
		font-size: 14px;
	}
`;
const ProductDetails = styled.h3`
	font-size: 13px;
	font-family: regular;
	color: #747474;
	line-height: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	@media all and (max-width: 640px) {
		font-size: 12px;
	}
`;
const Bottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
`;
const BottomLeft = styled.div``;
const BiddingTimeLine = styled.div``;
const IconBox = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 5px;
`;
const TimeBox = styled.div`
	display: flex;
	align-items: center;
`;
const Text = styled.span``;
const BottomRight = styled.div`
	width: 30%;
`;
const BiddingButton = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 30px;
	cursor: pointer;
`;
const BidCover = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
`;
const BiddingText = styled.h3`
	font-size: 13px;
	font-family: "medium";
	color: #747474;
`;

// winning

const WinnerContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
`;
const WinnerCard = styled.div`
	border: 1px solid #f3f1da;
	padding: 10px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 24%;
	margin-right: 1.33%;
	margin-bottom: 1.33%;
	&:nth-child(4n) {
		margin-right: 0;
	}
	@media all and (max-width: 1280px) {
		width: 32%;
		margin-right: 2%;
		margin-bottom: 2%;
		&:nth-child(4n) {
			margin-right: 2%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 768px) {
		width: 48%;
		margin-right: 2%;
		margin-bottom: 2%;
		&:nth-child(4n) {
			margin-right: 2%;
		}
		&:nth-child(3n) {
			margin-right: 2%;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 640px) {
		width: 100%;
		margin-right: 0;
		&:nth-child(4n) {
			margin-right: 0%;
		}
		&:nth-child(3n) {
			margin-right: 0%;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
		margin-bottom: 2%;
	}
`;
const Left = styled.div`
	width: 85px;
	height: 85px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	background-color: #efefef;
	border: 2px solid #feda00;
	@media all and (max-width: 1080px) {
		width: 60px;
		height: 60px;
	}
	@media all and (max-width: 769px) {
		width: 80px;
		height: 80px;
	}
	@media all and (max-width: 640px) {
		width: 70px;
		height: 70px;
	}
`;
const Right = styled.div`
	width: 67%;
	@media all and (max-width: 980px) {
		width: 60%;
	}
	@media all and (max-width: 640px) {
		width: 72%;
	}
`;
const WinProduct = styled.div`
	display: flex;
	align-items: center;
`;
const ProductImage = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	img {
		border-radius: 6px;
		background-color: #efefef;
		@media all and (max-width: 980px) {
			border-radius: 5px;
		}
	}
	@media all and (max-width: 1280px) {
		width: 35px;
		height: 35px;
	}
	@media all and (max-width: 980px) {
		border-radius: 5px;
	}
`;
const WinProductName = styled.h3`
	font-size: 13px;
	font-family: "medium";
	color: #747474;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 130px;
	margin-left: 10px;
	@media all and (max-width: 980px) {
		width: 200px;
	}
`;
const ProfileText = styled.span`
	color: #747474;
	font-size: 40px;
	font-family: bold;
	@media all and (max-width: 768px) {
		font-size: 30px;
	}
`;
