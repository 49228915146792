import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import claimBg from "../../../assets/images/bidding/claim-banner.png";
import { shopConfig } from "../../../apiConfig";
import { Context } from "../../context/store";

export default function ClaimBanner() {
  const [claims, setClaims] = useState([]);
  const { state } = useContext(Context);
  useEffect(() => {
    // fecthing claimable rewards
    shopConfig
      .get("products/")
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (StatusCode === 6000) {
          setClaims(data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);

  return (
    claims.length > 0 && (
      <BannerContainer>
        <Wrapper
          className="wrapper"
          style={{
            background: `url(${claimBg})`,
          }}
        >
          <Left>
            <Title>Claim with your</Title>
            <Title className="yellow">Wallet points</Title>
            <Claim to="/bidding/claim-wallet">Claim now</Claim>
          </Left>
          <Right>
            {claims.map((item, index) => {
              return (
                index <= 3 && (
                  <ImageItem key={item.id} to="/bidding/claim-wallet">
                    <Image
                      style={{
                        width: "100%",
                        height: "0",
                        paddingBottom: "100%", // creates the 1:1 ratio
                        position: "relative",
                      }}
                    >
                      <img
                        alt="Image"
                        src={item.featured_image}
                        className="img-class"
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          left: 0,
                          top: 0,
                          borderRadius: "5px",
                        }}
                      />
                    </Image>
                    <ProductName>{item.name}</ProductName>
                  </ImageItem>
                )
              );
            })}
          </Right>
        </Wrapper>
      </BannerContainer>
    )
  );
}

const BannerContainer = styled.div`
  background-color: #fff;
  padding-bottom: 3%;
  @media all and (max-width: 640px) {
    padding-bottom: 7%;
  }
`;
const Wrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 2%;
  @media all and (max-width: 640px) {
    padding: 3%;
  }
`;

const Left = styled.div`
  margin: auto 0;
`;
const Title = styled.h2`
  font-size: 35px;
  color: #fff;
  margin-bottom: 5%;
  font-family: regular;
  &.yellow {
    color: #feda00;
    margin-bottom: 18%;
    @media all and (max-width: 640px) {
      margin-bottom: 12%;
    }
  }
  @media all and (max-width: 980px) {
    font-size: 25px;
  }
  @media all and (max-width: 768px) {
    font-size: 20px;
  }
  @media all and (max-width: 640px) {
    font-size: 15px;
    margin-bottom: 3%;
  }
`;
const Claim = styled(Link)`
  font-size: 20px;
  color: #feda00;
  font-family: regular;
  cursor: pointer;
  text-decoration: underline;
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
  @media all and (max-width: 768px) {
    font-size: 14px;
  }
  @media all and (max-width: 640px) {
    font-size: 11px;
  }
`;
const Right = styled.div`
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media all and (max-width: 640px) {
    width: 58%;
  }
`;
const ImageItem = styled(Link)`
  width: 24%;
  border-radius: 5px;
  padding: 1%;
  background-color: #fff;
  text-align: center;
  @media all and (max-width: 768px) {
    &:nth-child(4) {
      display: none;
    }
    width: 32%;
  }
  @media all and (max-width: 640px) {
    &:nth-child(3) {
      display: none;
    }
    width: 48%;
  }
`;
const Image = styled.div`
  margin-bottom: 5%;
`;
const ProductName = styled.h3`
  font-size: 18px;
  margin: 7% 0 2%;
  font-family: regular;
  color: #191919;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  @media all and (max-width: 980px) {
    font-size: 13px;
  }
  @media all and (max-width: 640px) {
    font-size: 11px;
    width: 80%;
    margin: 0 auto;
  }
`;
