import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { bidConfig } from "../../../apiConfig";

export default function BiddingHomeList() {
  const [biddingList, setBiddingList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    bidConfig
      .get("bidding-items/")
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (StatusCode === 6000) {
          setBiddingList(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      <BiddingHomeListContainer>
        <Wrapper
          className="wrapper"
        >
          <Top>
            <JackpotImage>
              <img
                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/web/Jackpot-logo1.png"
                className="img-class"
                alt="Image"
              />
            </JackpotImage>
          </Top>
          <Bottom>
            <CardImage to="/bidding/live-biddings">
              <img
                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/web/live.png"
                alt="Image"
                className="img-class"
              />
            </CardImage>
            <CardImage to="/bidding/upcoming-biddings" className="middle">
              <img
                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/web/upcoming.png"
                alt="Image"
                className="img-class"
              />
            </CardImage>
            <CardImage to="/bidding/redeem-your-bid">
              <img
                src=" https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/web/redeem.png"
                alt="Image"
                className="img-class"
              />
            </CardImage>
          </Bottom>
        </Wrapper>
      </BiddingHomeListContainer>
    </>
  );
}
const BiddingHomeListContainer = styled.div`
  padding-bottom: 3%;
  @media all and (max-width: 640px) {
    padding-bottom: 4%;
  }
`;
const Wrapper = styled.div`
  border-radius: 6px;
  padding-top: 5%;
  overflow: hidden;
  padding: 4%;
  background: url("https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/web/Jackpot-bg.png");
  background-size: cover;
  @media all and (max-width: 640px) {
    background: url("https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/12-06-2023/mobile/Jackpot-bg.png");
    background-size: contain;
    padding-top: 10%;
    background-repeat: round;
  }
`;

const Top = styled.div`
  width: 100%;
  margin-bottom: 2%;
`;

const JackpotImage = styled.div`
  width: 38%;
  margin: 0 auto;
  @media all and (max-width: 640px) {
	width: 70%;
  }
`;
const Bottom = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const CardImage = styled(Link)`
  width: 32%;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: translateY(-6%);
  }
  @media all and (max-width: 640px) {
    width: 100%;
    &:hover {
      transform: unset;
    }
	&.middle{
		margin: 4% 0;
	}
  }
  img {
    border-radius: 6px;
    overflow: hidden;
  }
`;
