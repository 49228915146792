import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../general/includes/ButtonLoader";
import { Link } from "react-router-dom";

//product price box
export const ProductPriceContainer = ({
	margin,
	actualPrice,
	displayPrice,
}) => {
	return (
		<ProductPriceBox style={{ margin: margin }}>
			<OfferPrice>
				{displayPrice ? `₹${Math.round(displayPrice)}` : "--"}
			</OfferPrice>
			<OldPrice>
				{/* <LineBox>
          <Line alt="Image" src={LineIcon} className="img-class" />
        </LineBox> */}
				<OldPriceText>
					{actualPrice ? `₹${Math.round(actualPrice)}` : "--"}
				</OldPriceText>
			</OldPrice>
			<OfferCount>
				{actualPrice && displayPrice
					? `${Math.round(
							((actualPrice - displayPrice) / actualPrice) * 100
					  )}% off`
					: "--"}
			</OfferCount>
		</ProductPriceBox>
	);
};

const ProductPriceBox = styled.div`
	display: flex;
	align-items: center;
`;
const OfferPrice = styled.h3`
	font-family: medium;
	color: #191919;
	font-size: 17px;
	@media all and (max-width: 1280px) {
		font-size: 15px;
	}
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;
const OldPrice = styled.span`
	margin: 0 10px;
	position: relative;
	&::after {
		content: "";
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		bottom: 50%;
		transform: translateY(-50%);
		left: 0;
		background: #c4c4c4;
	}
`;
const OldPriceText = styled.h3`
	font-family: medium;
	color: #c4c4c4;
	font-size: 17px;
	@media all and (max-width: 1280px) {
		font-size: 15px;
	}
	@media all and (max-width: 420px) {
		font-size: 12px;
	}
`;
const OfferCount = styled.span`
	font-family: medium;
	color: #32c997;
	font-size: 14px;
	@media all and (max-width: 1280px) {
		font-size: 12px;
	}
	@media all and (max-width: 420px) {
		font-size: 9px;
	}
`;

//product rating box
export const GreenRatingBox = ({ noText, text, rating, review }) => {
	return (
		<RatingBox>
			{rating && (
				<RatingLeft>
					<StarIcon>
						<Start
							alt="Image"
							src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/star.svg"
						/>
					</StarIcon>
					<Rating>{rating}</Rating>
				</RatingLeft>
			)}
			<Reviews>{review ? `${review}${" "}Reviews` : ""}</Reviews>
		</RatingBox>
	);
};

const RatingBox = styled.div`
	display: flex;
	align-items: center;
	@media all and (max-width: 640px) {
		margin: 7px 10px;
	}
`;
const RatingLeft = styled.div`
	display: flex;
	align-items: center;
	background-color: #32c997;
	padding: 2px 5px;
	border-radius: 3px;
	margin-right: 7px;
`;
const StarIcon = styled.div`
	width: 12px;
	margin-right: 5px;
`;
const Start = styled.img`
	width: 100%;
	height: 100%;
	display: inline-block;
`;
const Rating = styled.span`
	font-size: 13px;
	font-family: medium;
	color: #fff;
`;
const Reviews = styled.span`
	font-size: 13px;
	font-family: regular;
	color: #c4c4c4;
`;

//button with border
export const Button = ({
	border,
	backgroundColor,
	margin,
	fontColor,
	fontSize,
	fontFamily,
	br,
	text,
	width,
	height,
	onClick,
	buttonData,
}) => {
	const buttonAction = () => {
		buttonData && buttonData.setSizeSelectId(buttonData.id);
	};
	return (
		<ButtonNoBgImg
			onClick={(buttonAction, onClick)}
			style={{
				backgroundColor: backgroundColor,
				border: border,
				borderRadius: br,
				cursor: "pointer",
				margin: margin,
				width: width,
				height: height,
			}}
		>
			<ButtonNoBgImgText
				style={{
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: fontSize,
				}}
			>
				{text}
			</ButtonNoBgImgText>
		</ButtonNoBgImg>
	);
};

const ButtonNoBgImg = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ButtonNoBgImgText = styled.span``;

// yellow gradient button
export const GradientButton = ({
	margin,
	fontColor,
	fontSize,
	fontFamily,
	br,
	text,
	width,
	isLoading,
	height,
	onClick,
	grey,
	noBackground,
}) => {
	const buttonAction = () => {};
	if (grey) {
		return (
			<GradientButtonBoxGrey
				onClick={onClick}
				style={{
					borderRadius: br,
					cursor: "pointer",
					margin: margin,
					width: width,
					height: height,
				}}
			>
				<GradientButtonText
					style={{
						color: fontColor,
						fontFamily: fontFamily,
						fontSize: fontSize,
					}}
				>
					{isLoading ? <ButtonLoader /> : text}
				</GradientButtonText>
			</GradientButtonBoxGrey>
		);
	} else if (noBackground) {
		return (
			<GradientButtonBoxTransparent
				onClick={onClick}
				style={{
					borderRadius: br,
					cursor: "pointer",
					margin: margin,
					width: width,
					height: height,
				}}
			>
				<GradientButtonText
					style={{
						color: fontColor,
						fontFamily: fontFamily,
						fontSize: fontSize,
					}}
				>
					{isLoading ? <ButtonLoader /> : text}
				</GradientButtonText>
			</GradientButtonBoxTransparent>
		);
	} else {
		return (
			<GradientButtonBox
				onClick={onClick}
				style={{
					borderRadius: br,
					cursor: "pointer",
					margin: margin,
					width: width,
					height: height,
				}}
			>
				<GradientButtonText
					style={{
						color: fontColor,
						fontFamily: fontFamily,
						fontSize: fontSize,
					}}
				>
					{isLoading ? <ButtonLoader /> : text}
				</GradientButtonText>
			</GradientButtonBox>
		);
	}
};

const GradientButtonBox = styled.div`
	background: rgb(252, 245, 157);
	background: linear-gradient(
		90deg,
		rgba(252, 245, 157, 1) 11%,
		rgba(251, 219, 3, 1) 100%
	);
	display: flex;
	align-items: center;
	justify-content: center;
`;
const GradientButtonBoxGrey = styled.div`
	background: rgb(252, 245, 157);
	background: linear-gradient(94.63deg, #f4f4f4 0%, #e2dfca 93.02%);
	display: flex;
	align-items: center;
	justify-content: center;
`;
const GradientButtonBoxTransparent = styled.div`
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #747474;
	margin-right: 15px;
`;
const GradientButtonText = styled.span``;

// button with underline

export const UnderLineButton = ({ fontSize, opacity, text, margin, path }) => {
	return (
		<ButtonContainer
			to={path}
			style={{
				fontSize: fontSize,
				opacity: opacity,
				margin: margin,
			}}
		>
			{text}
		</ButtonContainer>
	);
};
const ButtonContainer = styled(Link)`
	color: #eab600;
	font-family: medium;
	border-bottom: 2px solid #eab600;
	cursor: pointer;
	display: inline-block;
`;

export default function useComponentVisible(initialIsVisible) {
	const [isComponentVisible, setIsComponentVisible] =
		useState(initialIsVisible);
	const ref = useRef(null);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	return { ref, isComponentVisible, setIsComponentVisible };
}
