import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../general/Footer";
import Header from "../general/Header";
import HeaderPush from "../general/HeaderPush";
import Cancellation from "../includes/legal/Cancellation";
import ContactUs from "../includes/legal/ContactUs";
import DeliveryAndReturns from "../includes/legal/DeliveryAndReturns";
import Privacypolicy from "../includes/legal/Privacypolicy";
import Shipping from "../includes/legal/Shipping";
import TermsAndConditions from "../includes/legal/TermsAndConditions";

export default function Legal({ reswidth, searchValue, setSearchValue }) {
  return (
    <>
      <Header
        reswidth={reswidth}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <HeaderPush />
      <Routes>
        <Route path="*" element={<Navigate to="/page-not-found" />} />
        {/* <Route element={<ContactUs />} path="contact-us" /> */}
        <Route element={<TermsAndConditions />} path="terms-and-conditions" />
        <Route element={<Shipping />} path="shipping" />
        <Route element={<DeliveryAndReturns />} path="delivery-and-returns" />
        <Route element={<Cancellation />} path="cancellation" />
        <Route element={<Privacypolicy />} path="privacy-policy" />
      </Routes>
      <Footer />
    </>
  );
}
