import React, { useContext, useEffect, useRef, useState } from "react";
import {
  accountConfig,
  adsConfig,
  bidConfig,
  shopConfig,
} from "../../apiConfig";
import { Context } from "../context/store";
import CategoryHeader from "../general/CategoryHeader";
import Footer from "../general/Footer";
import { Helmet } from "react-helmet";
import Header from "../general/Header";
import HeaderPush from "../general/HeaderPush";
import BiddingHomeList from "../includes/bidding/BiddingHomeList";
import ClaimBanner from "../includes/home/ClaimBanner";
import Featured from "../includes/home/Featured";
import WeekTrends from "../includes/home/WeekTrends";
import SpotlightBanner from "../includes/home/SpotlightBanner";
import PageLoader from "./PageLoader";
import Brand from "../includes/home/Brand";
import CollectionSlider from "../includes/home/CollectionSlider";
import BidTimerBanner from "../includes/home/BidTimerBanner";
import SellerRegisterBanner from "../includes/home/SellerRegisterBanner";
import Products from "./Products";

export default function Home({ reswidth, searchValue, setSearchValue }) {
  const [isLoading, setLoading] = useState(true);
  const { state, dispatch } = useContext(Context);

  const [weeklyData, setWeeklyData] = useState([]);
  const [WeekTrendsList, setWeekTrendsList] = useState([]);
  const [ads, setAds] = useState([]);
  const [biddingList, setBiddingList] = useState([]);
  const [upcomingBiddingList, setUpcomingBiddingList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const access_token = state.user_data.access_token;
  const homeRef = useRef();

  const fetchLiveBid = async () => {
    try {
      setLoading(true);

      const res1 = await bidConfig.get("bidding-items/");
      const { StatusCode, data } = res1.data;
      if (StatusCode === 6000) {
        setBiddingList(data);
      }

      const res2 = await bidConfig.get("upcoming/bidding-items/");
      const { StatusCode: statusCode2, data: data2 } = res2.data;
      if (statusCode2 === 6000) {
        setUpcomingBiddingList(data2);
      }

      setLoading(false);
    } catch (error) {
      // Handle error here
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveBid();
  }, []);

  //fetching profile data
  useEffect(() => {
    setLoading(true);
    if (state.user_data.is_verified) {
      accountConfig
        .get("profile/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          const { StatusCode, data } = res.data;
          if (StatusCode === 6000) {
            dispatch({
              type: "UPDATE_USER_DATA",
              user_data: {
                ...state.user_data,
                phone: data.phone,
                name: data.name,
                email: data.email,
                gender: data.gender,
              },
            });
          }
        })
        .catch((e) => {});
    }

    // ads
    setLoading(true);
    adsConfig
      .get("top-brands/")
      .then((res) => {
        setAds(res.data);
      })
      .catch((error) => {});
    fetchLiveBid();
    setLoading(false);
  }, []);

  // const onScroll = () => {
  //   if (homeRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = homeRef.current;
  //     const threshold = 100; // Adjust this threshold as needed

  //     if (scrollHeight - scrollTop - threshold <= clientHeight) {
  //       // Trigger your pagination here.
  //     }
  //   }
  // };

  return !isLoading ? (
    <div
      // ref={homeRef}
      // onScroll={onScroll}
      style={{ overflowY: "auto", maxHeight: "99.99vh" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Oneruppee</title>
      </Helmet>
      <Header searchValue={searchValue} setSearchValue={setSearchValue} />
      <HeaderPush height={true} />
      {reswidth > 640 && <CategoryHeader />}
      <SpotlightBanner />
      {reswidth < 640 && <CategoryHeader />}
      <BidTimerBanner upcomingBiddingList={upcomingBiddingList} />
      <BiddingHomeList
        biddingList={biddingList}
        upcomingBiddingList={upcomingBiddingList}
      />
      <ClaimBanner />
      <CollectionSlider />
      <WeekTrends weeklyData={weeklyData} />
      <Featured WeekTrendsList={WeekTrendsList} />
      <Brand ads={ads} />
      <SellerRegisterBanner />
      {/* <Products
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        componentLoc="home"
        pageNum={pageNum}
        setPageNum={setPageNum}
      /> */}
      <Footer />
    </div>
  ) : (
    <PageLoader />
  );
}
