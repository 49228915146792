import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import Header from "../general/Header";
import HeaderPush from "../general/HeaderPush";
import PageLoader from "../screens/PageLoader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GreenRatingBox } from "../includes/constants";
import {
  ordersConfig,
  ratingConfig,
  shippingsConfig,
  shopConfig,
  wishlistConfig,
} from "../../apiConfig";
import AddToCart from "../includes/modal/cart/AddToCart";
import BuyNow from "../includes/modal/orders/BuyNow";
import { Context } from "../context/store";
import Toast from "../general/includes/Toast";
import RatingViewMore from "../includes/modal/product/RatingViewMore";
import ProductSingleStars from "../includes/product/ProductSingleStars";
import AddresChange from "../includes/modal/product/AddresChange";
import ButtonLoader from "../general/includes/ButtonLoader";
import axios from "axios";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <NavButton onClick={onClick}>
      <Image
        className="img-class"
        alt="Image"
        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/right-arrow.svg"
      />
    </NavButton>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <NavButton onClick={onClick} className="left">
      <Image
        className="img-class"
        alt="Image"
        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/right-arrow.svg"
      />
    </NavButton>
  );
}

export default function ProductSingle({ searchValue, setSearchValue }) {
  const { state } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isModal, setModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [address, setAddress] = useState([]);
  const [fullAddress, setFullAddress] = useState([]);
  const [product, setProduct] = useState([]);
  const [isToast, setToast] = useState(false);
  const [similarProduct, setSimilarProduct] = useState([]);
  // const [activeItem, setactiveItem] = useState(0);
  const [reviewImages, setReviewImages] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isCartModal, setCartModal] = useState(false);
  const [isRefresh, setRefresh] = useState(Math.random());
  const [isBuyNowModal, setBuyNowModal] = useState(false);
  const randomString = Math.random().toString(36).substring(0.2);
  const randomString2 = Math.random().toString(36).substring(0.1);
  const params = useParams();
  const [toastText, setToastText] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  const [counterVal, setCounterVal] = useState(1);
  const [selectedAttr, setSelectedAttr] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // slider config
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const { search } = useLocation();
  const parameters = new URLSearchParams(search);
  const type = parameters.get("type");


  useEffect(() => {
    if (isModal || addressModal || isCartModal || isBuyNowModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isModal, addressModal, isCartModal, isBuyNowModal]);

  //when onload the page ,scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // product details fetching
  useEffect(() => {
    setLoading(true);
    if (location?.state?.url) {
      axios
        .get(location?.state?.url)
        .then((res) => {
          const { StatusCode, data } = res.data;
          if (StatusCode === 6000) {
            let product = {};
            product = data;
            setProduct(product);
            setImageUrl(product.featured_image);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      shopConfig
        .get(`products/${params.product_id}`)
        .then((res) => {
          const { StatusCode, data } = res.data;
          if (StatusCode === 6000) {
            let product = {};
            product = data;
            setProduct(product);
            setImageUrl(product.featured_image);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    // fetching similar product
    shopConfig
      .get(
        `similar-products/${params.product_id}`,
        state.user_data.is_verified && {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        }
      )
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (StatusCode === 6000) {
          setSimilarProduct(data);
        }
      })
      .catch((err) => {});
    // view customer rating images
    ratingConfig
      .get(`view/customer-review-images/${params.product_id}/`)
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (StatusCode === 6000) {
          setReviewImages(data);
        } else {
        }
      })
      .catch((e) => {});

    //customer reviews
    ratingConfig
      .get(`view/${params.product_id}/`)
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (StatusCode === 6000) {
          setReviews(data);
        } else {
        }
      })
      .catch((e) => {});
    //address
    if (state.user_data.is_verified) {
      ordersConfig
        .get("address", {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        })
        .then((res) => {
          const { data, StatusCode } = res.data;
          if (StatusCode === 6000) {
            setFullAddress(data);
            let arr = data.filter((item) => {
              if (item.default === true) {
                return item;
              }
            });
            setAddress(arr);
          }
        })
        .catch((err) => {});
    }
  }, [params]);

  useEffect(() => {
    //address
    if (state.user_data.is_verified) {
      ordersConfig
        .get("address", {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        })
        .then((res) => {
          const { data, StatusCode } = res.data;
          if (StatusCode === 6000) {
            setFullAddress(data);
            let arr = data.filter((item) => {
              if (item.default === true) {
                return item;
              }
            });
            setAddress(arr);
          }
        })
        .catch((err) => {});
    }
  }, [params, addressModal]);

  // checking delivery availability
  function checkPincode() {
    if (pincode.length === 6) {
      setButtonLoader(true);
      shippingsConfig
        .get(`check-delivery-availability/${pincode}/`)
        .then((res) => {
          const { StatusCode } = res.data;
          if (StatusCode === 6000) {
            setPincodeError("Delivery available");
          } else {
            setPincodeError("Delivery not available on this pincode");
          }
          setButtonLoader(false);
        })
        .catch((err) => {
          setButtonLoader(false);
        });
    } else if (!pincode) {
      setPincodeError("Pls Enter Pincode");
    } else {
      setPincodeError("Not a valid pnicode");
    }
  }

  //add to wishlist
  const addToWishList = (product_pk) => {
    if (state.user_data.is_verified) {
      wishlistConfig
        .post(
          `add-to-wishlist/${product_pk}/`,
          { quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.StatusCode === 6000) {
            setRefresh(Math.random());
          }
          setToast(true);
          setToastText(res.data.data.message);
        })
        .catch((err) => {
          setToast(true);
          setToastText(err.message);
        });
    } else {
      navigate("/login");
    }
  };

  // handle attribute maping
  function handleAttr() {
    const data = product?.attributes;
    const attList = data[Object.keys(data)[0]].map((item, index) => {
      return (
        <SizeInd
          key={Math.random()}
          onClick={() => setSelectedAttr(item)}
          style={{
            background: selectedAttr == item ? "#fffae9" : "#fff",
            cursor: "pointer",
          }}
        >
          <SizeTitle
            style={{
              color: selectedAttr == item ? "#eab600" : "#747474",
            }}
          >
            {item}
          </SizeTitle>
        </SizeInd>
      );
    });
    return attList;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product.name}</title>
        <meta property="og:image" content={product.featured_image} />
      </Helmet>
      <Header
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isRefresh={isRefresh}
      />
      <HeaderPush />
      <RatingViewMore
        isModal={isModal}
        setModal={setModal}
        reviewImages={reviewImages}
      />
      <Toast isToast={isToast} setToast={setToast} text={toastText} />
      {!loading ? (
        <Container>
          <AddToCart
            isCartModal={isCartModal}
            setCartModal={setCartModal}
            price={product.display_cost}
            actualPrice={product.actual_cost}
            productId={product.product_code}
            productName={product.name}
            setToastText={setToastText}
            setToast={setToast}
            setCounterVal={setCounterVal}
            selectedAttr={selectedAttr}
            setSelectedAttr={setSelectedAttr}
            counterVal={counterVal}
            setRefresh={setRefresh}
            productAttr={product.attributes}
          />
          <BuyNow
            isBuyNowModal={isBuyNowModal}
            setBuyNowModal={setBuyNowModal}
            price={product.display_cost}
            actualPrice={product.actual}
            productId={product.id}
            productCode={product.id}
            productName={product.name}
            setCounterVal={setCounterVal}
            selectedAttr={selectedAttr}
            setSelectedAttr={setSelectedAttr}
            counterVal={counterVal}
            productAttr={product.attributes}
            type={type}
          />
          <AddresChange
            setAddressModal={setAddressModal}
            addressModal={addressModal}
            fullAddress={fullAddress}
          />
          <Wrapper className="wrapper">
            <Left>
              <ProductImageContainer>
                <MainImage>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ImageBox>
                      <Image
                        className="img-class product-image"
                        alt="Image"
                        src={imageUrl}
                      />
                    </ImageBox>
                  </div>

                  {/* {product?.images?.length !== 0 && ( */}
                  <SliderBox>
                    <Slider {...settings}>
                      <SliderItems
                        imageUrl={imageUrl}
                        x={product.featured_image}
                        onClick={() => setImageUrl(product.featured_image)}
                      >
                        <Image
                          className="img-class product-slider"
                          alt="Image"
                          src={product.featured_image}
                        />
                      </SliderItems>
                      {product?.images?.map((item) => (
                        <SliderItems
                          imageUrl={imageUrl}
                          x={item.product_image}
                          key={item.id}
                          onClick={() => setImageUrl(item.product_image)}
                        >
                          <Image
                            className="img-class product-slider"
                            alt="Image"
                            src={item.product_image}
                          />
                        </SliderItems>
                      ))}
                    </Slider>
                  </SliderBox>
                  {/* )} */}
                </MainImage>
              </ProductImageContainer>

              <ButtonBox>
                <ButtonBoxSub>
                  <Button onClick={() => setCartModal(true)}>
                    Add to Cart
                  </Button>
                  <Button className="buy" onClick={() => setBuyNowModal(true)}>
                    Buy Now
                  </Button>
                </ButtonBoxSub>
              </ButtonBox>
              {similarProduct.length > 0 && (
                <SimilarProducts>
                  <Title>Similar products</Title>
                  <ProductList>
                    {similarProduct.map((item) => {
                      return (
                        product.product_code !== item.product_code && (
                          <ProductItem
                            key={item.id}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            <ProductImageBox>
                              <HeartIconBox
                                onClick={() => addToWishList(item.product_code)}
                              >
                                <Heart
                                  alt="Image"
                                  src={
                                    item.is_wishlisted
                                      ? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/addWishlist.svg"
                                      : "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/heart.svg"
                                  }
                                />
                              </HeartIconBox>
                              <ProductImage
                                onClick={() => {
                                  navigate(
                                    `/product/${item.product_code}?pid=${randomString2}&pn=${item.name}&pb=${item.brand}&pr-nds=${randomString}`
                                  );
                                }}
                                alt="Image"
                                src={item.featured_image}
                              />
                            </ProductImageBox>
                            <Link
                              to={{
                                pathname: `/product/${item.product_code}?pid=${randomString2}&pn=${item.name}&pb=${item.brand}&pr-nds=${randomString}`,
                              }}
                            >
                              <ProductBrand>{item.brand}</ProductBrand>
                              <ProductName>{item.name}</ProductName>
                              <SimilarProductPriceBox>
                                <SimilarOfferPrice>
                                  ₹{item.display_cost}
                                </SimilarOfferPrice>
                                <SimilarOldPrice>
                                  <SimilarOldPriceText>
                                    ₹{item.actual_cost}
                                  </SimilarOldPriceText>
                                </SimilarOldPrice>
                                <SimilarOfferCount>
                                  {item.actual_cost && item.display_cost
                                    ? `${Math.round(
                                        ((item.actual_cost -
                                          item.display_cost) /
                                          item.actual_cost) *
                                          100
                                      )}% off`
                                    : "--"}
                                </SimilarOfferCount>
                              </SimilarProductPriceBox>
                              {item.average_rating > 0 ||
                                (item.count_review > 0 && (
                                  <GreenRatingBox
                                    rating={
                                      item.average_rating > 0
                                        ? item.average_rating
                                        : ""
                                    }
                                    review={
                                      item.count_review > 0
                                        ? `${item.count_review} Reviews`
                                        : ""
                                    }
                                  />
                                ))}
                            </Link>
                          </ProductItem>
                        )
                      );
                    })}
                  </ProductList>
                </SimilarProducts>
              )}
            </Left>
            <Right>
              <Box>
                {product.brand && <Brand>{product.brand}</Brand>}
                <Name>{product.name}</Name>
                <ProductPriceBox>
                  <OfferPrice>₹{product.display_cost}</OfferPrice>
                  <OldPrice>
                    <OldPriceText>₹{product.actual_cost}</OldPriceText>
                  </OldPrice>
                  <OfferCount>
                    {product.actual_cost && product.display_cost
                      ? `${Math.round(
                          ((product.actual_cost - product.display_cost) /
                            product.actual_cost) *
                            100
                        )}% off`
                      : "--"}
                  </OfferCount>
                </ProductPriceBox>
                {/* {product.average_rating > 0 ||
									(product.count_review > 0 && ( */}
                <RatingBox>
                  {product.average_rating > 0 && (
                    <RatingLeft>
                      <StarIcon>
                        <Start
                          alt="Image"
                          src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/star.svg"
                        />
                      </StarIcon>
                      <Rating>{product.average_rating}</Rating>
                    </RatingLeft>
                  )}
                  {product.count_review > 0 && (
                    <Reviews>
                      {product.count_review}{" "}
                      {product.count_review === 1 ? "Review" : "Reviews"}
                    </Reviews>
                  )}
                </RatingBox>
                {/* // ))} */}
              </Box>

              {product.attributes && (
                <Box className="Size">
                  <Title>{Object.keys(product.attributes)}</Title>
                  <SizeContainer>{handleAttr()}</SizeContainer>
                </Box>
              )}
              <Box className="Qty">
                <Title className="QtyText">Select Quantity</Title>
                <Counter>
                  <ButtonQty
                    className="left"
                    onClick={() =>
                      counterVal === 1 ? 1 : setCounterVal(counterVal - 1)
                    }
                  >
                    <Image
                      className="img-class"
                      alt="Image"
                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-left-gold.svg"
                    />
                  </ButtonQty>
                  <Number>{counterVal}</Number>
                  <ButtonQty onClick={() => setCounterVal(counterVal + 1)}>
                    <Image
                      className="img-class"
                      alt="Image"
                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-left-gold.svg"
                    />
                  </ButtonQty>
                </Counter>
              </Box>
              {/* {product?.description && ( */}
              <Box className="details">
                <Title>Product Details</Title>
                {product?.name && <Text>Product Name : {product?.name}</Text>}
                {product?.brand && <Text>Brand : {product?.brand}</Text>}
                {product?.display_cost && (
                  <Text>Description : {product?.description}</Text>
                )}
                {product?.display_cost && (
                  <Text>Price : {product?.display_cost}</Text>
                )}
                {/* <Text>Size : </Text> */}
                {product?.shop_name && (
                  <Text>Shop Name : {product?.shop_name}</Text>
                )}
              </Box>
              {/* pincode check */}
              {address.length === 0 ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "15px",
                  }}
                >
                  <Title>Delivery</Title>
                  <PinCodeRight>
                    <PinCodeIconBox>
                      <Image
                        className="img-class"
                        alt="Image"
                        src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/location-yellow.svg"
                      />
                    </PinCodeIconBox>
                    <input
                      placeholder="Enter delivery pincode"
                      onChange={(e) => setPincode(e.target.value)}
                      value={pincode}
                    />
                    <Check onClick={checkPincode}>
                      {buttonLoader ? <ButtonLoader size={30} /> : "Check"}
                    </Check>
                    {pincodeError && (
                      <Error
                        style={{
                          color:
                            pincodeError === "Delivery available"
                              ? "#32C997"
                              : "#E02B1D",
                        }}
                      >
                        {pincodeError}
                      </Error>
                    )}
                  </PinCodeRight>
                </Box>
              ) : (
                <Box
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <Title>Delivery</Title>
                  <PinCode>
                    {/* {address.map((item) => { */}
                    <Address>
                      <AddressTitleBox>
                        {address[0].customer}, {address[0].pincode}{" "}
                        <Label>{address[0].address_type}</Label>
                      </AddressTitleBox>
                      <AdressSpan>
                        {address[0].address_line1}, ,Near{" "}
                        {address[0].near_landmark},{address[0].city},
                        {address[0].state}
                      </AdressSpan>
                    </Address>
                    <ChangeButton onClick={() => setAddressModal(true)}>
                      <ChangeText>Change</ChangeText>
                    </ChangeButton>
                  </PinCode>
                </Box>
              )}
              {/* <Box>
                <Title>Seller</Title>
                <Middle>
                  <SellerDetails>
                    <IconBox>
                      <Image   className="img-class" />
                    </IconBox>
                    <SellerName>NEW GEN stepping to future</SellerName>
                  </SellerDetails>
                  <ViewShop>View shop</ViewShop>
                  <SellerBottom>
                    <ReviewDetails></ReviewDetails>
                    <ReviewDetails></ReviewDetails>
                    <ReviewDetails></ReviewDetails>
                  </SellerBottom>
                </Middle>
              </Box> */}
              {/* rating and reviews */}
              <ReviewSummury>
                <Title
                  style={{
                    color: "#191919",
                  }}
                >
                  Rating & Reviews
                </Title>
                <SummuryBox>
                  <SummuryLeft>
                    <StarBox>
                      <Star>
                        <Image
                          className="img-class"
                          alt="Image"
                          src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/dark-golden.svg"
                        />
                      </Star>
                      <ReviewText>{product?.average_rating}</ReviewText>
                    </StarBox>
                    <ReiviewCount>
                      {product?.count_review} ratings & reviews
                    </ReiviewCount>
                  </SummuryLeft>
                  <ProductSingleStars ratingData={reviews?.rating_data} />
                </SummuryBox>
              </ReviewSummury>
              {reviewImages?.length > 0 && (
                <Box className="images">
                  <ImagesTop>
                    <Title>
                      Customers Photos(
                      {reviewImages?.length})
                    </Title>
                    <ViewMore onClick={() => setModal(true)}>
                      View More
                    </ViewMore>
                  </ImagesTop>
                  <ReviewImages>
                    {reviewImages?.map((item, index) =>
                      index < 8 ? (
                        <ImageItem key={index}>
                          <Image
                            className="img-class"
                            alt="Image"
                            src={item.image}
                          />
                        </ImageItem>
                      ) : null
                    )}
                  </ReviewImages>
                </Box>
              )}
              {reviews.rating_data?.length > 0 && (
                <Box>
                  <TopHead>
                    <Title
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      Customers Review
                    </Title>
                    <ReivewCountBox>
                      <GreenBox>
                        <GreenStar>
                          <Image
                            className="img-class"
                            alt="Image"
                            src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/star.svg"
                          />
                        </GreenStar>
                        <StarText>{product.average_rating}</StarText>
                      </GreenBox>
                      <RatingText>
                        {product.count_review} ratings & reviews
                      </RatingText>
                    </ReivewCountBox>
                  </TopHead>
                  {reviews.rating_data?.map((item, index) =>
                    index < 7 ? (
                      <ReviewItem key={item.id}>
                        <ReivewCountBox className="review-main">
                          <GreenBox className="review-main">
                            <GreenStar className="review-main">
                              <Image
                                className="img-class"
                                alt="Image"
                                src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/star.svg"
                              />
                            </GreenStar>
                            <StarText className="review-main">
                              {item.stars}
                            </StarText>
                          </GreenBox>
                          <RatingText className="review-main">
                            {item.review}
                          </RatingText>
                        </ReivewCountBox>
                        <ReviewContent>
                          {/* <Content>
											</Content> */}
                          {item.customer_review_images.length > 0 && (
                            <ReviewImages>
                              {item.customer_review_images?.map(
                                (item, index) => (
                                  <ImageItem
                                    className="review-main"
                                    key={index}
                                  >
                                    <Image
                                      className="img-class"
                                      alt="Image"
                                      src={item.image}
                                    />
                                  </ImageItem>
                                )
                              )}
                            </ReviewImages>
                          )}
                        </ReviewContent>
                        <ReviewerName>
                          {item.customer_name}{" "}
                          <Grey>
                            {new Date(item.date_added).toLocaleDateString()}
                          </Grey>
                        </ReviewerName>
                      </ReviewItem>
                    ) : null
                  )}
                </Box>
              )}
              {similarProduct.length > 0 && (
                <SimilarProducts className="mobile">
                  <Title>Similar products</Title>
                  <ProductList>
                    {similarProduct.map((item) => {
                      return (
                        product.product_code !== item.product_code && (
                          <ProductItem
                            key={item.id}
                            to={{
                              pathname: `/product/${item.product_code}`,
                            }}
                          >
                            <ProductImageBox>
                              <HeartIconBox
                                onClick={() => addToWishList(item.product_code)}
                              >
                                <Heart
                                  alt="Image"
                                  src={
                                    item.is_wishlisted
                                      ? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/addWishlist.svg"
                                      : "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/heart.svg"
                                  }
                                />
                              </HeartIconBox>
                              <ProductImage
                                alt="Image"
                                src={item.featured_image}
                              />
                            </ProductImageBox>
                            <Link
                              to={{
                                pathname: `/product/${item.product_code}`,
                              }}
                            >
                              <ProductBrand>{item.brand}</ProductBrand>
                              <ProductName>{item.name}</ProductName>
                              <SimilarProductPriceBox>
                                <SimilarOfferPrice>
                                  ₹{item.display_cost}
                                </SimilarOfferPrice>
                                <SimilarOldPrice>
                                  <SimilarOldPriceText>
                                    ₹{item.actual_cost}
                                  </SimilarOldPriceText>
                                </SimilarOldPrice>
                                <SimilarOfferCount>
                                  {item.actual_cost && item.display_cost
                                    ? `${Math.round(
                                        ((item.actual_cost -
                                          item.display_cost) /
                                          item.actual_cost) *
                                          100
                                      )}% off`
                                    : "--"}
                                </SimilarOfferCount>
                              </SimilarProductPriceBox>
                              {item.average_rating > 0 ||
                                (item.count_review > 0 && (
                                  <GreenRatingBox
                                    rating={
                                      item.average_rating > 0
                                        ? item.average_rating
                                        : ""
                                    }
                                    review={
                                      item.count_review > 0
                                        ? `${item.count_review} Reviews`
                                        : ""
                                    }
                                  />
                                ))}
                            </Link>
                          </ProductItem>
                        )
                      );
                    })}
                  </ProductList>
                </SimilarProducts>
              )}
            </Right>
          </Wrapper>
        </Container>
      ) : (
        <PageLoader />
      )}
      {/* <Footer /> */}
    </>
  );
}

const Container = styled.div`
  padding: 60px 0;
  position: relative;
  @media all and (max-width: 768px) {
    padding: 30px 0;
  }
  @media all and (max-width: 420px) {
    padding-top: 0px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
  @media all and (max-width: 420px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 39%;
  @media all and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const ProductImageContainer = styled.div`
  margin-bottom: 10px;
  @media all and (max-width: 980px) {
    margin-bottom: 8px;
  }
`;
const MainImage = styled.div`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  overflow: hidden;
  @media all and (max-width: 420px) {
    padding: 0px;
    border-radius: 0px;
    border: 0px solid #c4c4c4;
  }
`;
const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  /* margin-bottom: 15px; */
  @media all and (max-width: 980px) {
    margin-bottom: 5px;
  }
  @media all and (max-width: 420px) {
    border-radius: 0px;
  }
`;
//slider
const NavButton = styled.button`
  background-color: #f2f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 30px;
  cursor: pointer;
  height: 70px;
  &.left {
    left: 0;
    transform: rotate(180deg);
  }
`;

const SliderItems = styled.div`
  width: 65px;
  height: 65px;
  padding: 3px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  transition: 0.4s all ease-out;
  border: ${({ imageUrl, x }) =>
    imageUrl === x ? "1px solid #eab600" : "1px solid #c4c4c4"};

  @media all and (max-width: 980px) {
    width: 45px;
    height: 45px;
  }
`;
const Image = styled.img`
  &.product-slider {
    border-radius: 5px;
  }
  &.product-image {
    width: 500px;
    height: 500px;
    min-width: 100%;
    @media all and (max-width: 1440px) {
      width: 450px;
      height: 450px;
    }
    @media all and (max-width: 1280px) {
      width: 365px;
      height: 365px;
    }
    @media all and (max-width: 1080px) {
      width: 330px;
      height: 330px;
    }
    @media all and (max-width: 980px) {
      width: 250px;
      height: 250px;
    }
    @media all and (max-width: 768px) {
      width: 550px;
      height: 550px;
    }
    @media all and (max-width: 640px) {
      width: 415px;
      height: 415px;
    }
    @media all and (max-width: 460px) {
      width: 350px;
      height: 350px;
    }
    /* @media all and (max-width: 420px) {
			width: 300px;
			height: 300px;
		}
		@media all and (max-width: 360px) {
			width: 260px;
			height: 260px;
		} */
  }
  &.similar {
    width: 248px;
    height: 248px;
  }
`;
const SliderBox = styled.div`
  margin-top: 15px;
  .slick-list {
    overflow: hidden;
    width: 84%;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    width: 65px !important;
    height: 65px !important;
    margin-right: 8px;
    border-radius: 5px;
    overflow: hidden !important;
  }
  .slick-active {
    /* transform: scale(1) */
  }
  .slick-dots {
    display: none !important;
  }
  .slick-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media all and (max-width: 1080px) {
    .slick-slide {
      width: 50px !important;
      height: 50px !important;
    }
  }
  @media all and (max-width: 980px) {
    .slick-slide {
      width: 45px !important;
      height: 45px !important;
    }
  }
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 768px) {
    padding: 3% 5%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
  }
`;
const ButtonBoxSub = styled.div`
  /* padding: 10px; */
  background: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.div`
  font-size: 18px;
  font-family: medium;
  width: 49%;
  color: #747474;
  border-radius: 5px;
  border: 1px solid #eab600;
  cursor: pointer;
  text-align: center;
  background: #fff;
  padding: 15px 0;
  &.buy {
    background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
    border: none;
  }
  @media all and (max-width: 980px) {
    padding: 12px 0;
  }
  @media all and (max-width: 640px) {
    font-size: 15px;

    padding: 15px 0;
  }
`;
const Right = styled.div`
  width: 60%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media all and (max-width: 420px) {
    padding: 0px 10px;
  }
`;

const Box = styled.div`
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #c4c4c4;
  &.details {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  &.images {
    margin-bottom: 15px;
  }
  @media all and (max-width: 420px) {
    padding: 10px;
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #c4c4c4;
    &.Size {
      margin: 10px 0;
    }
  }
  &.Qty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  &.Size {
    margin: 10px 0 0;
  }
`;

const Brand = styled.h3`
  font-size: 20px;
  font-family: medium;
  color: #747474;
  @media all and (max-width: 640px) {
    font-size: 18px;
  }
  @media all and (max-width: 360px) {
    font-size: 16px;
  }
`;
const Name = styled.h3`
  font-size: 22px;
  font-family: regular;
  color: #191919;
  margin: 10px 0;
  @media all and (max-width: 640px) {
    font-size: 20px;
  }
  @media all and (max-width: 360px) {
    font-size: 18px;
  }
`;

const ProductPriceBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const OfferPrice = styled.h3`
  font-family: medium;
  color: #191919;
  font-size: 23px;
  @media all and (max-width: 640px) {
    font-size: 22px;
  }
`;
const OldPrice = styled.span`
  margin: 0 10px;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #c4c4c4;
  }
`;
const LineBox = styled.div`
  position: absolute;
  top: -20%;
  opacity: 0.7;
  width: 100%;
  height: 5px;
`;
const Line = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const OldPriceText = styled.h3`
  font-family: medium;
  color: #c4c4c4;
  font-size: 18px;
`;
const OfferCount = styled.span`
  font-family: medium;
  color: #32c997;
  font-size: 14px;
`;
const RatingBox = styled.div`
  display: flex;
  align-items: center;
`;
const RatingLeft = styled.div`
  display: flex;
  align-items: center;
  background-color: #32c997;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 7px;
`;
const StarIcon = styled.div`
  width: 12px;
  margin-right: 5px;
`;
const Start = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const Rating = styled.span`
  font-size: 13px;
  font-family: medium;
  color: #fff;
`;
const Reviews = styled.span`
  font-size: 13px;
  font-family: regular;
  color: #c4c4c4;
`;
const Text = styled.span`
  font-size: 16px;
  font-family: regular;
  color: #191919;
  margin-bottom: 10px;
  @media all and (max-width: 640px) {
    font-size: 14px;
  }
`;
const Title = styled.span`
  font-size: 20px;
  font-family: medium;
  margin-bottom: 15px;
  color: #0a0a0a;
  @media all and (max-width: 640px) {
    font-size: 17px;
  }
  @media all and (max-width: 360px) {
    font-size: 15px;
  }
  &.QtyText {
    margin-bottom: 0px;
  }
`;

// const MoreButton = styled.span`
// 	color: #eab600;
// 	border-bottom: 2px solid #eab600;
// 	/* padding-bottom: 5px; */
// 	font-size: 16px;
// 	font-family: medium;
// 	display: inline-block;
// 	width: max-content;
// 	margin-top: 15px;
// 	cursor: pointer;
// 	@media all and (max-width: 640px) {
// 		font-size: 14px;
// 	}
// 	@media all and (max-width: 360px) {
// 		font-size: 12px;
// 	}
// `;

// product
const SimilarProducts = styled.div`
  margin-top: 30px;
  &.mobile {
    display: none;
    padding-bottom: 10%;
  }
  @media all and (max-width: 980px) {
    margin-top: 20px;
  }
  @media all and (max-width: 768px) {
    display: none;
    &.mobile {
      display: block;
    }
  }
  @media all and (max-width: 420px) {
    padding: 0px 20px;
  }
`;
const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;
const ProductItem = styled.div`
  transition: 0.3s all ease-in-out;
  padding: 5px;
  border-radius: 5px;
  margin-right: 2%;
  width: 49%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 15px;
  &:hover {
    box-shadow: 0px 1px 12px -2px rgb(176 176 176);
  }
  &:nth-child(2n) {
    margin-right: 0;
  }
`;
const ProductImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 248px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 7px;
  @media all and (max-width: 1440px) {
    height: 210px;
  }
  @media all and (max-width: 1280px) {
    height: 180px;
  }
  @media all and (max-width: 1080px) {
    height: 160px;
  }
  @media all and (max-width: 980px) {
    height: 125px;
  }
  @media all and (max-width: 768px) {
    height: 270px;
  }
  @media all and (max-width: 640px) {
    height: 175px;
  }
  @media all and (max-width: 421px) {
    height: 135px;
  }
`;
const HeartIconBox = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
`;
const Heart = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const ProductImage = styled.img`
  width: 248px;
  height: 248px;
  min-width: 100%;
  display: inline-block;
  @media all and (max-width: 1440px) {
    width: 210px;
    height: 210px;
  }
  @media all and (max-width: 1280px) {
    width: 180px;
    height: 180px;
  }
  @media all and (max-width: 1080px) {
    width: 160px;
    height: 160px;
  }
  @media all and (max-width: 980px) {
    width: 125px;
    height: 125px;
  }
  @media all and (max-width: 768px) {
    height: 270px;
    width: 270px;
  }
  @media all and (max-width: 640px) {
    height: 175px;
    width: 175px;
  }
  @media all and (max-width: 421px) {
    width: 135px;
    height: 135px;
  }
`;
const ProductBrand = styled.span`
  font-size: 13px;
  font-family: medium;

  color: #747474;
`;
const ProductName = styled.h3`
  font-size: 14px;
  font-family: regular;
  color: #191919;
  margin: 7px 0;
`;

// review
const ReviewSummury = styled.div`
  margin-top: 20px;
`;
const SummuryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0 30px;
  @media all and (max-width: 640px) {
    margin-top: 15px;
  }
`;
const SummuryLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StarBox = styled.div`
  display: flex;
  /* align-items: center; */
`;
const Star = styled.div`
  width: 40px;
  height: 40px;
  @media all and (max-width: 1280px) {
    width: 20px;
  }
`;
const ReviewText = styled.span`
  font-size: 70px;
  font-family: regular;
  color: #eab600;
  line-height: 50px;
  @media all and (max-width: 1280px) {
    font-size: 50px;
  }
  @media all and (max-width: 980px) {
    font-size: 35px;
  }
  @media all and (max-width: 640px) {
    font-size: 25px;
  }
`;
const ReiviewCount = styled.span`
  font-size: 25px;
  font-family: regular;
  color: #747474;
  margin-top: 20px;
  @media all and (max-width: 1280px) {
    font-size: 18px;
  }
  @media all and (max-width: 980px) {
    font-size: 12px;
    margin-top: 0;
  }
  @media all and (max-width: 640px) {
    font-size: 9px;
  }
`;
const ImagesTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const ViewMore = styled.span`
  color: #eab600;
  border-bottom: 2px solid #eab600;
  font-size: 16px;
  font-family: medium;
  display: inline-block;
  width: max-content;
  margin-bottom: 15px;
  cursor: pointer;
`;
const ReviewImages = styled.div`
  display: flex;
  align-items: center;
`;
const ImageItem = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 5px;
  overflow: hidden;
  :last-child {
    margin-right: 0;
  }
  &.review-main {
    width: 60px;
    height: 60px;
  }
  @media all and (max-width: 640px) {
    height: 50px;
    width: 50px;
  }
`;
const TopHead = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;
  margin-bottom: 15px;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const ReivewCountBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  &.review-main {
    margin-left: 0;
  }
  @media all and (max-width: 640px) {
    margin-top: 10px;
    margin-left: 0;
  }
`;
const GreenBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #32c997;
  padding: 4px 8px;
  border-radius: 3px;
  margin-right: 7px;
  &.review-main {
    padding: 3px 7px;
  }
  @media all and (max-width: 640px) {
    padding: 3px 5px;
  }
`;
const GreenStar = styled.div`
  width: 15px;
  margin-right: 5px;
  @media all and (max-width: 640px) {
    width: 12px;
  }
`;
const StarText = styled.span`
  font-size: 15px;
  font-family: medium;
  color: #fff;
  &.review-main {
    font-size: 14px;
  }
`;
const RatingText = styled.span`
  font-size: 16px;
  font-family: regular;
  color: #c4c4c4;
  @media all and (max-width: 640px) {
    font-size: 13px;
  }
`;
const ReviewItem = styled.div`
  margin-bottom: 20px;
`;
const ReviewerName = styled.h3`
  margin-top: 10px;
  font-size: 16px;
  font-family: medium;
  color: #747474;
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;
const Grey = styled.span`
  font-size: 13px;
  font-family: regular;
  color: #c4c4c4;
  margin-left: 6px;
  @media all and (max-width: 420px) {
    font-size: 12px;
  }
`;
const ReviewContent = styled.div`
  margin-top: 10px;
`;
// const Content = styled.p`
// 	font-size: 13px;
// 	font-family: regular;
// 	color: #c4c4c4;
// 	margin-bottom: 10px;
// `;

// for similar products
const SimilarProductPriceBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const SimilarOfferPrice = styled.h3`
  font-family: medium;
  color: #191919;
  font-size: 18px;
  @media all and (max-width: 1280px) {
    font-size: 15px;
  }
  @media all and (max-width: 1080px) {
    font-size: 13px;
  }
  @media all and (max-width: 980px) {
    font-size: 12px;
  }
`;
const SimilarOldPrice = styled.span`
  margin: 0 10px;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #c4c4c4;
  }
`;
const SimilarLineBox = styled.div`
  position: absolute;
  top: -20%;
  opacity: 0.7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media all and (max-width: 980px) {
    top: -11%;
  }
`;
const SimilarLine = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
const SimilarOldPriceText = styled.h3`
  font-family: medium;
  color: #c4c4c4;
  font-size: 16px;
  @media all and (max-width: 1280px) {
    font-size: 14px;
  }
  @media all and (max-width: 1080px) {
    font-size: 12px;
  }
  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;
const SimilarOfferCount = styled.span`
  font-family: medium;
  color: #32c997;
  font-size: 14px;
  @media all and (max-width: 1280px) {
    font-size: 11px;
  }
  @media all and (max-width: 1080px) {
    font-size: 9px;
  }
`;
const Counter = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonQty = styled.button`
  height: 28px;
  width: 28px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  &.left {
    transform: rotate(180deg);
  }
  @media all and (max-width: 420px) {
    padding: 7px;
  }
`;

const Number = styled.span`
  background: #fffae9;
  border-radius: 5px;
  border: 1px solid #eab600;
  padding: 3px 35px;
  font-size: 20px;
  color: #eab600;
  height: 28px;
  margin: 0 10px;
  font-family: medium;
  @media all and (max-width: 640px) {
    padding: 6px 25px;
    font-size: 16px;
  }
  @media all and (max-width: 420px) {
    margin: 0 5px;
    padding: 6px 18px;
    font-size: 14px;
  }
`;
const SizeInd = styled.div`
  padding: 5px 10px;
  border: 1px solid #eab600;
  border-radius: 5px;
  margin-right: 6px;
  @media all and (max-width: 640px) {
    width: max-content;
    margin-bottom: 6px;
  }
`;
const SizeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  overflow-x: scroll;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SizeTitle = styled.span`
  font-size: 16px;
  font-family: regular;
  margin-bottom: 15px;

  @media all and (max-width: 640px) {
    font-size: 14px;
  }
  @media all and (max-width: 360px) {
    font-size: 12px;
  }
  &.QtyText {
    margin-bottom: 0px;
  }
`;
const PinCodeRight = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9ce22;
  padding-bottom: 10px;
  position: relative;
  margin: 0 15px;
  margin-left: 20px;
  margin-bottom: 20px;
  input {
    margin: 0 8px;
    color: #747474;
    font-size: 14px;
    font-family: regular;
  }
`;
const PinCodeIconBox = styled.div``;
const Check = styled.span`
  font-size: 15px;
  font-family: medium;
  color: #e9ce22;
  margin-left: 40px;
  cursor: pointer;
`;
const PinCode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Address = styled.div``;
const AddressTitleBox = styled.h3`
  display: flex;
  font-size: 16px;
  font-family: medium;
  align-items: center;
  text-transform: capitalize;
  margin: 15px 0 5px;
  color: #0a0a0a;
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;
const Label = styled.div`
  background: #f2f2f2;
  color: #0a0a0a;
  text-transform: capitalize;
  padding: 3px 8px;
  border-radius: 4px;
  margin-left: 8px;
  font-family: medium;
  font-size: 12px;
`;
const AdressSpan = styled.p`
  color: #747474;
  font-size: 13px;
  font-family: regular;
`;
const ChangeButton = styled.div`
  border: 1px solid #eab600;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  cursor: pointer;
  @media all and (max-width: 640px) {
    margin-top: 15px;
  }
`;

const ChangeText = styled.span`
  color: #0a0a0a;
  font-size: 13px;
  font-family: regular;
`;

const Error = styled.span`
  color: #e02b1d;
  font-size: 13px;
  font-family: regular;
  position: absolute;
  bottom: -20px;
  left: 0;
`;
