import React from "react";
import styled from "styled-components";

export default function SellerRegisterBanner() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Left>
          <Title>
            Register now as a <Gold> Seller in Oneruppee!</Gold>
          </Title>
          <Button onClick={()=>window.open('https://seller.oneruppee.com')}>Register Now</Button>
        </Left>
        <RightImage>
          <img
            src={require("../../../assets/images/banner/seller-girl.png")}
            alt="Image"
            className="img-class"
          />
        </RightImage>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 6% 0 10%;
  @media all and (max-width: 768px) {
    padding: 6% 0 20%;
  }
`;
const Wrapper = styled.div`
  position: relative;
  background: linear-gradient(0deg, #f5f5e3, #f5f5e3), url(.jpg), #3f3f3f;
  border-radius: 6px;
  padding: 3%;
  @media all and (max-width: 768px) {
   padding-bottom: 30%;
  }
  @media all and (max-width: 768px) {
   padding: 5% 5% 30% 5%;
  }
`;
const Left = styled.div``;
const Title = styled.h1`
  font-size: 35px;
  color: #191919;
  font-family: medium;
  display: flex;
  align-items: center;
  @media all and (max-width: 1280px) {
    font-size: 25px;
  }
  @media all and (max-width: 980px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media all and (max-width: 768px) {
    font-size: 35px;
  }
  @media all and (max-width: 420px) {
    font-size: 25px;
  }
`;
const Gold = styled.h2`
  color: #feda00;
  font-family: medium;
  font-size: 35px;
  margin-left: 0.5%;
  @media all and (max-width: 1280px) {
    font-size: 25px;
  }
  @media all and (max-width: 768px) {
    font-size: 35px;
  }
  @media all and (max-width: 640px) {
  margin-top : .5%;
  }
  @media all and (max-width: 420px) {
    font-size: 25px;
    margin-top : 1%;
    margin-left: 0;
  }
`;
const Button = styled.span`
  font-size: 14px;
  font-family: medium;
  display: block;
  width: 15%;
  color: #747474;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 2%;
  background: #fff;
  padding: 15px 0;
  background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
  @media all and (max-width: 980px) {
    font-size: 11px;
    width: 17%;
    padding: 9px 0;
  }
  @media all and (max-width: 768px) {
    width: 22%;
  }
  @media all and (max-width: 420px) {
   margin-top: 4%;
   width: 32%;
  }
`;
const RightImage = styled.div`
  position: absolute;
  width: 45%;

  right: -4%;
  bottom: -55%;
  @media all and (max-width: 980px) {
    width: 50%;
    bottom: -25%;
  }
  @media all and (max-width: 768px) {
    width: 75%;
    bottom: -22%;
    right: -6%;
  }
`;
