import React, { useContext, useState } from "react";
import styled from "styled-components";
import { GradientButton } from "../constants";
import { Context } from "../../context/store";
import { ordersConfig } from "../../../apiConfig";
import Toast from "../../general/includes/Toast";

export default function AddressForm({
  setAddress,
  setAddressData,
  formData,
  setFormData,
}) {
  const { state } = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [isToast, setToast] = useState(false);
  const [toastText, setToastText] = useState("");

  // fetch address data
  const fetchAddressApi = () => {
    setLoading(true);
    ordersConfig
      .get("address", {
        headers: {
          Authorization: `Bearer ${state.user_data.access_token}`,
        },
      })
      .then((res) => {
        // const { StatusCode, data } = res.data;
        if (res.status === 200 && res.data.length > 0) {
          setAddressData(res.data);
          setLoading(false);
          setAddress(true);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addToAddress = () => {
    if (
      formData.customer_name &&
      formData.state !== "" &&
      formData.phone_no !== "" &&
      formData.pincode.length === 6 &&
      formData.city !== "" &&
      formData.address_line1 !== "" &&
      formData.address_line2 !== "" &&
      formData.near_landmark !== "" &&
      formData.address_type !== "" &&
      formData.is_default
    ) {
      ordersConfig
        .post("address/", formData, {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        })
        .then((res) => {
          if (res.data.StatusCode === 6000) {
            fetchAddressApi();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
		
      if (formData.customer_name === "") {
        setToastText("Please enter your name");
      } else if (formData.phone_no === "") {
        setToastText("Please enter your phone number correctly");
      } else if (formData.address_line1 === "") {
        setToastText("Please enter your Address line 1");
      } else if (formData.address_line2 === "") {
        setToastText("Please enter your Address line 2");
      } else if (formData.pincode.length !== 6) {
        setToastText("Please enter your pincode correctly");
      } else if (formData.state === "") {
        setToastText("Please enter your state");
      } else if (formData.city === "") {
        setToastText("Please enter your city");
      } else if (formData.near_landmark === "") {
        setToastText("Please enter your near landmark");
      } else if (formData.address_type === "") {
        setToastText("Please enter your Address_type");
      } else {
        setToastText("Please complete your address");
      }
      setToast(true);
    }
  };


  return (
    <>
      <Title>Select Delivery Address</Title>
      <Toast isToast={isToast} text={toastText} setToast={setToast}/>
      <Form>
        <FormTitleBox
          style={{
            marginBottom: "45px",
          }}
        >
          <IconBox>
            <Image
              className="img-class"
              alt="Image"
              src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/location.svg"
            />
          </IconBox>
          <FormTitle>Add Address</FormTitle>
        </FormTitleBox>
        <InputBox>
          <Input
            id="name"
            placeholder=" "
            type="text"
            value={formData.customer_name}
            onChange={(e) =>
              setFormData({ ...formData, customer_name: e.target.value })
            }
          />
          <Label htmlFor="name">Name</Label>
        </InputBox>
        <InputBox
        // style={{
        //   marginBottom: "25px",
        // }}
        >
          <Input
            id="phone"
            placeholder=" "
            type="number"
            value={formData.phone_no}
            onChange={(e) =>
              setFormData({ ...formData, phone_no: e.target.value })
            }
          />
          <Label htmlFor="phone">Phone number</Label>
        </InputBox>
        <AddressFormBox>
          <InputBox>
            <Input
              id="house"
              placeholder=" "
              type="text"
              value={formData.address_line1}
              onChange={(e) =>
                setFormData({ ...formData, address_line1: e.target.value })
              }
            />
            <Label htmlFor="house">Address line 1</Label>
          </InputBox>
          <InputBox>
            <Input
              id="house"
              placeholder=" "
              type="text"
              value={formData.address_line2}
              onChange={(e) =>
                setFormData({ ...formData, address_line2: e.target.value })
              }
            />
            <Label htmlFor="house">Address line 2</Label>
          </InputBox>
          <InputBox>
            <Input
              id="pincode"
              placeholder=" "
              type="number"
              value={formData.pincode}
              onChange={(e) =>
                setFormData({ ...formData, pincode: e.target.value })
              }
            />
            <Label htmlFor="pincode">Pincode</Label>
          </InputBox>
          <FlexBox>
            <InputBox
              style={{
                width: "49%",
              }}
            >
              <Input
                id="city"
                placeholder=" "
                type="text"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
              <Label htmlFor="city">City</Label>
            </InputBox>
            <InputBox
              style={{
                width: "49%",
              }}
            >
              <Input
                id="state"
                placeholder=" "
                type="text"
                value={formData.state}
                onChange={(e) =>
                  setFormData({ ...formData, state: e.target.value })
                }
              />
              <Label htmlFor="state">State</Label>
            </InputBox>
          </FlexBox>
          <InputBox>
            <Input
              id="location"
              placeholder=" "
              type="text"
              value={formData.near_landmark}
              onChange={(e) =>
                setFormData({ ...formData, near_landmark: e.target.value })
              }
            />
            <Label htmlFor="location">NearbyLocation</Label>
          </InputBox>
          {/* address select */}
          <SelectBox>
            <SelectBoxLabel>Type of address</SelectBoxLabel>
            <SelectToolTip>
              <SelectItem
                onClick={() => {
                  setFormData({ ...formData, address_type: "home" });
                }}
              >
                <RadioButton
                  style={{
                    border:
                      formData.address_type === "home"
                        ? "1px solid #f7d320"
                        : "1px solid #d4d4d4",
                  }}
                >
                  {formData.address_type === "home" && (
                    <Radio
                      style={{
                        background:
                          formData.address_type === "home"
                            ? "#f7d320"
                            : "#d4d4d4",
                      }}
                    ></Radio>
                  )}
                </RadioButton>
                <SelectItemName>Home</SelectItemName>
              </SelectItem>
              <SelectItem
                onClick={() => {
                  setFormData({ ...formData, address_type: "office" });
                }}
              >
                <RadioButton
                  style={{
                    border:
                      formData.address_type === "office"
                        ? "1px solid #f7d320"
                        : "1px solid #d4d4d4",
                  }}
                >
                  {formData.address_type === "office" && (
                    <Radio
                      style={{
                        background:
                          formData.address_type === "office"
                            ? "#f7d320"
                            : "#d4d4d4",
                      }}
                    ></Radio>
                  )}
                </RadioButton>
                <SelectItemName>Office</SelectItemName>
              </SelectItem>
            </SelectToolTip>
          </SelectBox>
        </AddressFormBox>
        {/* submit Button */}
        <GradientButton
          margin={"30px 0 0 0"}
          fontColor={"#747474"}
          fontSize={"15px"}
          fontFamily={"medium"}
          br={"5px"}
          text={"Save Address & Continue"}
          width={"100%"}
          height={"50px"}
          isLoading={isLoading}
          onClick={addToAddress}
        />
      </Form>
    </>
  );
}
const Title = styled.h3`
  color: #747474;
  font-size: 22px;
  font-family: "medium";
  margin-bottom: 25px;
  @media all and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 20px;
  }
`;
const Form = styled.form`
  background-color: #fffdfa;
  border: 1px solid #f2f0ed;
  border-radius: 5px;
  padding: 40px;
  @media all and (max-width: 420px) {
    padding: 15px;
  }
`;
const FormTitleBox = styled.div`
  display: flex;
  align-items: center;
`;
const IconBox = styled.div`
  width: 20px;
  margin-right: 10px;
`;
const Image = styled.img``;
const FormTitle = styled.h3`
  color: #000000;
  font-size: 20px;
  font-family: "medium";
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`;
const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
  position: relative;
  width: 100%;
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.label`
  color: #d4d4d4;
  font-size: 14px;
  font-family: "regular";
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.2s all ease-in;
`;
const Input = styled.input`
  color: #191919;
  font-size: 17px;
  font-family: "regular";
  border-bottom: 1px solid #d4d4d4;
  &:focus ~ label {
    top: -20px;
    font-size: 14px;
    color: #e9ce22;
    transition: 0.3s;
  }
  &:focus {
    border-bottom: 1px solid #e9ce22;
  }
  &:not(:placeholder-shown) ~ label {
    border-color: green;
    top: -20px;
    font-size: 14px;
    color: #e9ce22;
    transition: 0.3s;
  }
`;
const AddressFormBox = styled.div``;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
  position: relative;
  width: 100%;
  padding-bottom: 2%;
  /* :hover{
	border-bottom: 1px solid #d4d4d4;
  } */
`;

const SelectBoxLabel = styled.span`
  color: #d4d4d4;
  font-size: 14px;
  font-family: "regular";
  margin-bottom: 3%;
`;

const SelectToolTip = styled.div`
  display: flex;
  align-items: center;
`;
const SelectItem = styled.div`
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  cursor: pointer;
  &:first-child {
    margin-right: 10%;
  }
`;
const RadioButton = styled.div`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
`;
const Radio = styled.div`
  border-radius: 50%;
  width: 11px;
  height: 11px;
  /* background-color: #d4d4d4; */
`;
const SelectItemName = styled.span`
  color: #d4d4d4;
  font-size: 15px;
  font-family: "medium";
`;
