import React, { useContext, useEffect, useState } from "react";
import Header from "../../general/Header";
import HeaderPush from "../../general/HeaderPush";
import Footer from "../../general/Footer";
import styled from "styled-components";
import { paymentsConfig, shopConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import EmptyPage from "../../screens/EmptyPage";
import PageLoader from "../../screens/PageLoader";
import { Link, useNavigate } from "react-router-dom";
import bg from "../../../assets/images/bidding/claim-banner.png";

export default function ClaimRewards({ searchValue, setSearchValue }) {
  const { state } = useContext(Context);
  const [claims, setClaims] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [wallet, setWallet] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (state.user_data.is_verified) {
      // fecthing claimable rewards
      shopConfig
        .get("products/", {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        })
        .then((res) => {
          const { StatusCode, data } = res.data;
          if (StatusCode === 6000) {
            setClaims(data);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
      // fetching wallet balance
      paymentsConfig
        .get("view-balance", {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        })
        .then((res) => {
          if (res.data.StatusCode === 6000) {
            setWallet(res.data.data[0].wallet_balance);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Header searchValue={searchValue} setSearchValue={setSearchValue} />
      <HeaderPush />
      {!isLoading && claims.length > 0 ? (
        <Container>
          <TopBg
            style={{
              background: `url(${bg})`,
            }}
          >
            <Wrapper className="wrapper">
              <IconBox>
                <img
                  className="img-class"
                  alt="Image"
                  src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/no-events.svg"
                />
              </IconBox>
              <Title>
                You have{" "}
                <span
                  style={{
                    color: "#feda00",
                    margin: "0 0 0 1%",
                  }}
                >
                  {wallet} wallet points
                </span>
              </Title>
              <Description>
                You can use the wallet points to deduct the product amount
              </Description>
            </Wrapper>
          </TopBg>
          <Wrapper className=" wrapper bottom">
            {claims.map((item) => {
              return (
                <ProductItem
                  key={item.id}
                  to={`/product/${item.product_code}?type=claim_your_product`}
                >
                  <Left>
                    <Image>
                      {item.featured_image && (
                        <img
                          alt="Image"
                          src={item.featured_image}
                          // alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/bidding/watch.png"
                          className="img-class"
                        />
                      )}
                    </Image>
                  </Left>
                  <Right>
                    <Name>{item.name}</Name>
                    <Details>
                      {/* (8 GB/512 GB SSD/Mac OS Big Sur) MGN73
										HN/A (13.3 inch, Space Grey, 1.29 kg) */}
                    </Details>
                    <PriceBox>
                      <New>₹{item.display_cost}</New>
                      <Old>₹{item.actual_cost}</Old>
                      <Discount>
                        -{wallet}
                      </Discount>
                    </PriceBox>
                    {/* <Button>Buy Now</Button> */}
                  </Right>
                </ProductItem>
              );
            })}
          </Wrapper>
        </Container>
      ) : !isLoading && claims.length === 0 ? (
        <EmptyPage text="No items found" />
      ) : (
        <PageLoader />
      )}
      <Footer />
    </>
  );
}

const Container = styled.div``;
const TopBg = styled.div`
  padding: 4% 0;
  @media all and (max-width: 640px) {
    padding: 7% 0;
  }
`;
const Wrapper = styled.div`
  &.bottom {
    display: flex;
    align-items: flex-start;
    padding: 4% 0 4%;
    flex-wrap: wrap;
  }
`;
const IconBox = styled.div`
  width: 40px;
  @media all and (max-width: 768px) {
    width: 30px;
  }
  @media all and (max-width: 640px) {
    width: 25px;
  }
`;
const Title = styled.h3`
  font-size: 30px;
  color: #fff;
  font-family: regular;
  margin: 3% 0 1%;
  display: flex;
  align-items: center;
  @media all and (max-width: 768px) {
    font-size: 25px;
  }
  @media all and (max-width: 640px) {
    font-size: 20px;
    margin: 4% 0 2%;
  }
`;
const Description = styled.p`
  font-size: 22px;
  color: #fff;
  font-family: regular;
  @media all and (max-width: 768px) {
    font-size: 18px;
  }
  @media all and (max-width: 640px) {
    font-size: 13px;
  }
`;
const ProductItem = styled(Link)`
  width: 31%;
  display: flex;
  justify-content: space-between;
  padding: 2%;
  transition: 0.3s all ease-in-out;
  cursor: pointer;

  border-radius: 6px;
  margin-right: 3.5%;
  margin-bottom: 3.5%;
  &:hover {
    -webkit-box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
    -moz-box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
    box-shadow: 0px 2px 10px 3px rgba(207, 196, 207, 1);
  }
  &:nth-child(3n) {
    margin-right: 0;
  }

  @media all and (max-width: 1280px) {
    width: 48%;
    &:nth-child(3n) {
      margin-right: 3.5%;
    }
    &:nth-child(2n) {
      margin-right: 0%;
    }
  }
  @media all and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media all and (max-width: 640px) {
    padding: 4.5%;
  }
`;
const Left = styled.div`
  width: 28%;
`;
const Image = styled.div`
  width: 100%;
  height: 100%;
  background: #f7f7f7;
`;
const Right = styled.div`
  width: 67%;
`;
const Name = styled.h3`
  font-size: 18px;
  color: #191919;
  font-family: medium;
  @media all and (max-width: 640px) {
    font-size: 14px;
  }
`;
const Details = styled.p`
  font-size: 14px;
  color: #747474;
  font-family: regular;
  margin: 5% 0 6%;
  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;
const PriceBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5%;
`;
const New = styled.span`
  font-size: 20px;
  color: #191919;
  font-family: medium;
  @media all and (max-width: 640px) {
    font-size: 14px;
  }
`;
const Old = styled.span`
  font-size: 16px;
  color: #c4c4c4;
  font-family: regular;
  margin: 0 4%;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 2px;
    background: #c4c4c4;
  }
  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;
const Discount = styled.span`
  font-size: 14px;
  color: #32c997;
  font-family: regular;
  @media all and (max-width: 640px) {
    font-size: 10px;
  }
`;
// const Button = styled.span`
// 	background: rgb(252, 245, 157);
// 	background: linear-gradient(
// 		90deg,
// 		rgba(252, 245, 157, 1) 11%,
// 		rgba(251, 219, 3, 1) 100%
// 	);
// 	align-items: center;
// 	display: inline-block;
// 	border-radius: 6px;
// 	padding: 10px 14px;
// 	justify-content: center;
// 	font-size: 13px;
// 	color: #747474;
// 	cursor: pointer;
// 	font-family: medium;
// 	@media all and (max-width: 640px) {
// 		font-size: 10px;
// 	}
// `;
