import React, { useContext, useState } from "react";
import styled from "styled-components";
import { GradientButton } from "../../constants";
import { ordersConfig } from "../../../../apiConfig";
import { Context } from "../../../context/store";
import Toast from "../../../general/includes/Toast";

export default function AddEditModal({
  isModal,
  setModal,
  modalType,
  addId,
  setAddressData,
  setRefresh,
  formData,
  setFormData,
}) {
  const { state } = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [isToast, setToast] = useState(false);
  const [toastText, setToastText] = useState("");

  const fetchAddressApi = () => {
    setLoading(true);
    ordersConfig
      .get("address", {
        headers: {
          Authorization: `Bearer ${state.user_data.access_token}`,
        },
      })
      .then((res) => {
        // const { StatusCode, data } = res.data;
        if (res.status === 200) {
          setAddressData(res.data);
          setLoading(false);
          setModal(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setModal(false);
      });
  };

  function addEditAddress() {
    if (
      formData.customer_name &&
      formData.state !== "" &&
      formData.phone_no !== "" &&
      formData.pincode.toString().length === 6 &&
      formData.city !== "" &&
      formData.address_line1 !== "" &&
      formData.address_line2 !== "" &&
      formData.near_landmark !== "" &&
      formData.address_type !== "" &&
      formData.is_default
    ) {
      setLoading(true);
      const createOrUpdateAddress = (
        modalType,
        formData,
        state,
        setLoading,
        setModal,
        fetchAddressApi
      ) => {
        const headers = {
          Authorization: `Bearer ${state.user_data.access_token}`,
        };

        const url =
          modalType === "add" ? "address/" : `address/${formData.edit_id}/`;
        const data = formData

        ordersConfig
          .request({
            url,
            method: modalType === "add" ? "post" : "patch",
            data,
            headers,
          })
          .then((res) => {
            if (res.status === 200 || res.data.StatusCode === 6000) {
              fetchAddressApi();
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
            setModal(false);
          });
      };

      // Usage example:
      if (modalType === "add" || modalType === "edit") {
        createOrUpdateAddress(
          modalType,
          formData,
          state,
          setLoading,
          setModal,
          fetchAddressApi
        );
      }

      setRefresh(Math.random());
    } else {
      if (formData.customer_name === "") {
        setToastText("Please enter your name");
      } else if (formData.phone_no === "") {
        setToastText("Please enter your phone number correctly");
      } else if (formData.address_line1 === "") {
        setToastText("Please enter your Address line 1");
      } else if (formData.address_line2 === "") {
        setToastText("Please enter your Address line 2");
      } else if (formData.pincode.toString().length !== 6) {
        setToastText("Please enter your pincode correctly");
      } else if (formData.state === "") {
        setToastText("Please enter your state");
      } else if (formData.city === "") {
        setToastText("Please enter your city");
      } else if (formData.near_landmark === "") {
        setToastText("Please enter your near landmark");
      } else if (formData.address_type === "") {
        setToastText("Please enter your Address_type");
      } else {
        setToastText("Please complete your address");
      }
      setToast(true);
    }
  }

  return (
    <ModalContainer isModal={isModal}>
      <Toast isToast={isToast} text={toastText} setToast={setToast} />
      <ModalFade isModal={isModal} onClick={() => setModal(false)}></ModalFade>
      <ModalBox>
        <Form>
          <InputBox>
            <Input
              onChange={(e) =>
                setFormData({ ...formData, customer_name: e.target.value })
              }
              id="name"
              placeholder=" "
              type="text"
              value={formData?.customer_name}
            />
            <Label htmlFor="name">Name</Label>
          </InputBox>
          <InputBox>
            <Input
              onChange={(e) =>
                setFormData({ ...formData, phone_no: e.target.value })
              }
              id="phone"
              placeholder=" "
              type="number"
              value={formData?.phone_no}
            />
            <Label htmlFor="phone">Phone number</Label>
          </InputBox>
          <InputBox>
            <Input
              id="add1"
              placeholder=" "
              type="text"
              value={formData?.address_line1}
              onChange={(e) =>
                setFormData({ ...formData, address_line1: e.target.value })
              }
            />
            <Label htmlFor="add1">Address line 1</Label>
          </InputBox>
          <InputBox>
            <Input
              id="add2"
              placeholder=" "
              type="text"
              value={formData?.address_line2}
              onChange={(e) =>
                setFormData({ ...formData, address_line2: e.target.value })
              }
            />
            <Label htmlFor="add2">Address line 2</Label>
          </InputBox>
          <InputBox>
            <Input
              id="pincode"
              placeholder=" "
              type="number"
              value={formData?.pincode}
              onChange={(e) =>
                setFormData({ ...formData, pincode: e.target.value })
              }
            />
            <Label htmlFor="pincode">Pincode</Label>
          </InputBox>
          <FlexBox>
            <InputBox
              style={{
                width: "49%",
              }}
            >
              <Input
                id="city"
                placeholder=" "
                type="text"
                value={formData?.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
              <Label htmlFor="city">City</Label>
            </InputBox>
            <InputBox
              style={{
                width: "49%",
              }}
            >
              <Input
                id="state"
                placeholder=" "
                type="text"
                value={formData?.state}
                onChange={(e) =>
                  setFormData({ ...formData, state: e.target.value })
                }
              />
              <Label htmlFor="state">State</Label>
            </InputBox>
          </FlexBox>
          <InputBox>
            <Input
              id="location"
              placeholder=" "
              type="text"
              value={formData?.near_landmark}
              onChange={(e) =>
                setFormData({ ...formData, near_landmark: e.target.value })
              }
            />
            <Label htmlFor="location">NearbyLocation</Label>
          </InputBox>
          <SelectBox>
            <SelectBoxLabel>Type of address</SelectBoxLabel>
            <SelectToolTip>
              <SelectItem
                onClick={(e) => {
                  setFormData({ ...formData, address_type: "home" });
                }}
              >
                <RadioButton
                  style={{
                    border:
                      formData?.address_type === "home"
                        ? "1px solid #f7d320"
                        : "1px solid #d4d4d4",
                  }}
                >
                  {formData?.address_type === "home" && (
                    <Radio
                      style={{
                        background:
                          formData?.address_type === "home"
                            ? "#f7d320"
                            : "#d4d4d4",
                      }}
                    ></Radio>
                  )}
                </RadioButton>
                <SelectItemName>Home</SelectItemName>
              </SelectItem>
              <SelectItem
                onClick={(e) => {
                  setFormData({ ...formData, address_type: "office" });
                }}
              >
                <RadioButton
                  style={{
                    border:
                      formData?.address_type === "office"
                        ? "1px solid #f7d320"
                        : "1px solid #d4d4d4",
                  }}
                >
                  {formData?.address_type === "office" && (
                    <Radio
                      style={{
                        background:
                          formData?.address_type === "office"
                            ? "#f7d320"
                            : "#d4d4d4",
                      }}
                    ></Radio>
                  )}
                </RadioButton>
                <SelectItemName>Office</SelectItemName>
              </SelectItem>
            </SelectToolTip>
          </SelectBox>
          <GradientButton
            margin={"30px 0 0 0"}
            fontColor={"#747474"}
            fontSize={"15px"}
            isLoading={isLoading}
            fontFamily={"medium"}
            br={"5px"}
            text={"Save Address & Continue"}
            width={"100%"}
            height={"50px"}
            onClick={addEditAddress}
          />
        </Form>
      </ModalBox>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out;
  transform: scale(1.1);
  background: transparent;
  transform: ${({ isModal }) => (isModal ? "scale(1)" : "scale(0)")};
`;
const ModalFade = styled.div`
  background: rgba(0, 0, 0, 0.16);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ isModal }) => (isModal ? "block" : "none")};
`;
const ModalBox = styled.div`
  background: #fff;
  /* padding: 40px; */
  border-radius: 5px;
  /* width: 600px; */
  position: relative;
  z-index: 1001;
  @media all and (max-width: 640px) {
    width: 80%;
  }
`;

// modal content

const Form = styled.form`
  background-color: #fffdfa;
  border: 1px solid #f2f0ed;
  border-radius: 5px;
  padding: 40px;
  @media all and (max-width: 421px) {
    padding: 15px;
  }
`;
const FormTitleBox = styled.div`
  display: flex;
  align-items: center;
`;
const IconBox = styled.div`
  width: 20px;
  margin-right: 10px;
`;
const Image = styled.img``;
const FormTitle = styled.h3`
  color: #000000;
  font-size: 20px;
  font-family: "medium";
  @media all and (max-width: 640px) {
    font-size: 18px;
  }
`;
const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
  position: relative;
  width: 100%;
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.label`
  color: #d4d4d4;
  font-size: 14px;
  font-family: "regular";
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.2s all ease-in;
`;
const Input = styled.input`
  color: #191919;
  font-size: 17px;
  font-family: "regular";
  border-bottom: 1px solid #d4d4d4;
  &:focus ~ label {
    top: -20px;
    font-size: 14px;
    color: #e9ce22;
    transition: 0.3s;
  }
  &:focus {
    border-bottom: 1px solid #e9ce22;
  }
  &:not(:placeholder-shown) ~ label {
    border-color: green;
    top: -20px;
    font-size: 14px;
    color: #e9ce22;
    transition: 0.3s;
  }
`;
const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
  position: relative;
  width: 100%;
  /* padding-bottom: 2%; */
  /* :hover{
	border-bottom: 1px solid #d4d4d4;
  } */
`;

const SelectBoxLabel = styled.span`
  color: #d4d4d4;
  font-size: 14px;
  font-family: "regular";
  margin-bottom: 3%;
`;

const SelectToolTip = styled.div`
  display: flex;
  align-items: center;
`;
const SelectItem = styled.div`
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  cursor: pointer;
  &:first-child {
    margin-right: 10%;
  }
`;
const RadioButton = styled.div`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
`;
const Radio = styled.div`
  border-radius: 50%;
  width: 11px;
  height: 11px;
  /* background-color: #d4d4d4; */
`;
const SelectItemName = styled.span`
  color: #d4d4d4;
  font-size: 15px;
  font-family: "medium";
`;
