import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../../general/Header";
import HeaderPush from "../../general/HeaderPush";
import Footer from "../../general/Footer";

export default function ContactUs({ reswidth, searchValue, setSearchValue }) {
  //when onload the page ,scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        reswidth={reswidth}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <HeaderPush />
      <Container>
        <Wrapper>
          <Title>Contact us</Title>
          <Content>You may contact us using the information below:</Content>
          <SubTitle>
            Merchant Legal entity name: ORGECOM TRADE PRIVATE LIMITED
          </SubTitle>
          <SubTitle>
            Registered Address: 28/569, THAKIDIYIL BUILDING, AISWARYA JUNCTION,
            KATTAPPANA Idukki KERALA 685508
          </SubTitle>
          <SubTitle>
            Operational Address: 28/569, THAKIDIYIL BUILDING, AISWARYA JUNCTION,
            KATTAPPANA Idukki KERALA 685508
          </SubTitle>
          <SubTitle>Telephone No: 9995917827</SubTitle>
          <SubTitle>E-Mail ID: hello@oneruppee.com</SubTitle>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  padding: 3% 0;
  min-height: calc(100vh - 420px);
  @media all and (max-width: 640px) {
    padding: 8% 0;
  }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
`;
const Title = styled.h3`
  font-size: 22px;
  font-family: medium;
  color: #191919;
  padding-bottom: 20px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 30px;
  text-align: center;
  @media all and (max-width: 640px) {
    font-size: 19px;
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
`;
const Content = styled.p`
  font-size: 18px;
  font-family: regular;
  color: #747474;
  line-height: 1.2em;
  letter-spacing: 0.3px;
  margin-bottom: 35px;
  @media all and (max-width: 640px) {
    font-size: 14px;
    margin-bottom: 25px;
  }
`;
const SubTitle = styled.h3`
  font-size: 16px;
  font-family: regular;
  color: #747474;
  line-height: 1.2em;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
  @media all and (max-width: 640px) {
    font-size: 13px;
    margin-bottom: 10px;
  }
`;
