import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/lotties/button-loader.json";
import styled from "styled-components";

export default function PaginationLoader({ size }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Container>
      <Lottie options={defaultOptions} height={70} width={70} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  @media all and (max-width: 1440px) {
    bottom: 34%;
}
  @media all and (max-width: 1280px) {
    bottom: 36%;
  }
  @media all and (max-width: 1080px) {
	bottom: 32%;
  }
  @media all and (max-width: 980px) {
	bottom: 30%;
  }
  @media all and (max-width: 768px) {
	bottom: 56%;
  }
  @media all and (max-width: 640px) {
	bottom: 58%;
  }
  @media all and (max-width: 520px) {
	bottom: 64%;
  }
  @media all and (max-width: 420px) {
	/* bottom: 77%; */
  }
  @media all and (max-width: 360px) {
	bottom: 75%;
  }
`;
