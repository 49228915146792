import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { GreenRatingBox } from "../includes/constants";
import Header from "../general/Header";
import Footer from "../general/Footer";
import HeaderPush from "../general/HeaderPush";
import { wishlistConfig } from "../../apiConfig";
import { Context } from "../context/store";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import PageLoader from "./PageLoader";
import Toast from "../general/includes/Toast";
import EmptyPage from "./EmptyPage";

export default function WishList({ searchValue, setSearchValue }) {
	const { state } = useContext(Context);
	const [wishListData, setWishListData] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isToast, setToast] = useState(false);
	const navigate = useNavigate();

	const wishlistLoadData = () => {
		wishlistConfig
			.get("view-wishlist/", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setWishListData(data);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		wishlistLoadData();
	}, []);

	const removeWishList = (product_pk) => {
		wishlistConfig
			.post(`remove-from-wishlist/${product_pk}/`, null, {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode } = res.data;
				if (StatusCode === 6000) {
					wishlistLoadData();
					setToast(true);
				}
			})
			.catch((err) => {});
	};

	// const addToCart = () => {};

	function renderWishList() {
		return wishListData.map((item) => {
			return (
				<ListItem key={item.id}>
					<ImageBox>
						<Image
							onClick={()=>navigate(`/product/${item.product_data.product_code}`)}
							className="img-class"
							alt="Image" src={item.product_data.featured_image}
						/>
						<Close
							onClick={() =>
								removeWishList(item.product_data.product_code)
							}
						>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
								style={{
									width: "80%",
									height: "80%",
								}}
							/>
						</Close>
					</ImageBox>
					<Link to={`/product/${item.product_data.product_code}`}>
						{item.product_data.brand && <Brand>Rolex</Brand>}
						<Name>{item.product_data.name}</Name>
						<ProductPrice>
							{item.product_data.actual_cost}
						</ProductPrice>
						<GreenRatingBox rating="3.4" review="1240" />
					</Link>
					{/* <CartButton onClick={() => addToCart()}>
						Add to Cart{" "}
						<IconBox>
							<Image   className="img-class" alt="Image" src={goldArrow} />
						</IconBox>
					</CartButton> */}
				</ListItem>
			);
		});
	}
	return !isLoading ? (
		<>
			<Header searchValue={searchValue} setSearchValue={setSearchValue} />
			<HeaderPush />

			<Container>
				<Toast
					isToast={isToast}
					setToast={setToast}
					text="Removed from wishlist"
				/>
				<Wrapper className="wrapper">
					{wishListData.length !== 0 ? (
						<Title>
							My Wishlist <Grey>8 items</Grey>
						</Title>
					) : (
						<EmptyPage
							text="Your wishlist is empty"
							buttonText="Shop now"
							onClick={() => navigate("/products/all-products")}
						/>
					)}
					<ListContainer>{renderWishList()}</ListContainer>
				</Wrapper>
			</Container>
			<Footer />
		</>
	) : (
		<PageLoader />
	);
}

const Container = styled.div`
	padding: 4% 0;
`;
const Wrapper = styled.div``;
const Title = styled.h3`
	font-family: medium;
	font-size: 24px;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
	@media all and (max-width: 420px) {
		font-size: 18px;
	}
`;
const Grey = styled.span`
	font-size: 18px;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
const ListContainer = styled.div`
	margin: 0 auto;
`;
const ListItem = styled.div`
	width: 19.2%;
	display: inline-block;
	margin-right: 1%;
	margin-bottom: 1%;
	border: 1px solid #d4d4d4;
	padding: 10px;
	border-radius: 5px;
	&:nth-child(5n) {
		margin-right: 0;
	}
	@media all and (max-width: 1280px) {
		width: 24.2%;
		&:nth-child(5n) {
			margin-right: 1%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 980px) {
		width: 32.2%;
		&:nth-child(4n) {
			margin-right: 1%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 768px) {
		width: 48.2%;
		margin-bottom: 2%;
		margin-right: 2%;
		&:nth-child(3n) {
			margin-right: 2%;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media all and (max-width: 420px) {
		padding: 3px;
	}
`;
const ImageBox = styled.div`
	width: 100%;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	height: 220px;
	@media all and (max-width: 640px) {
		height: 230px;
	}
	@media all and (max-width: 500px) {
		height: 170px;
	}
	@media all and (max-width: 400px) {
		height: 150px;
	}
`;
const Close = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	border: 3px solid #efefef;
	top: 3%;
	right: 3%;
	background: #efefef;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	@media all and (max-width: 420px) {
		width: 14px;
		height: 14px;
	}
`;
const Image = styled.img`
cursor: pointer;`;
const Brand = styled.span`
	font-size: 13px;
	font-family: medium;
	color: #101010;
`;
const Name = styled.h3`
	font-size: 14px;
	font-family: regular;
	color: #747474;
	margin: 7px 0;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
// const CartButton = styled.span`
// 	color: #ffb400;
// 	font-size: 16px;
// 	display: flex;
// 	align-items: center;
// 	margin-top: 20px;
// 	font-family: medium;
// 	cursor: pointer;
// 	@media all and (max-width: 420px) {
// 		margin-top: 10px;
// 		font-size: 12px;
// 	}
// `;
// const IconBox = styled.div`
// 	width: 20px;
// 	height: 20px;
// 	margin-left: 10px;
// 	@media all and (max-width: 420px) {
// 		width: 15px;
// 		height: 15px;
// 	}
// `;
const ProductPrice = styled.h3`
	font-family: medium;
	color: #191919;
	font-size: 17px;
	margin-bottom: 10px;
	@media all and (max-width: 1280px) {
		font-size: 15px;
	}
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;
