import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GradientButton } from "../../constants";


export default function FilterModal({
	isModal,
	setModal,
	// categories,
	setRatingFilterId,
	setPriceFilterId,
	priceList,
	ratingList,
	priceFilterId,
	ratingFilterId,
	setIsFilterApply,
	subCategory,
	outerSubCategory,
	subCategoryId,
	setSubCategory,
	setSubCategoryId,
	nestedCategoryId,
	setNestedCategoryId,
}) {
	const [category, setCategory] = useState(true);
	const [pricing, setPricing] = useState(false);
	const [rating, setRating] = useState(false);

	useEffect(() => {
		if (isModal) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "scroll";
		}
	}, [isModal]);

	return (
		<ModalContainer
			style={{ height: isModal ? "100vh" : "0", overflow: "hidden" }}
		>
			<ModalFade
				onClick={() => setModal(false)}
				isModal={isModal}
			></ModalFade>
			<ModalBox>
				<Top>
					<MainFilterButton>
						<FilterIcon>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/filter.svg" />
						</FilterIcon>
						<FilterButtonText>FILTER</FilterButtonText>
					</MainFilterButton>
					<Close onClick={() => setModal(false)}>
						<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg" />
					</Close>
				</Top>
				<Middle>
					<ContainerFilter>
						<FilterBoxLeft>
							{category ? (
								<FilterLeftButtonActive
									onClick={() => {
										setCategory(true);
										setPricing(false);
										setRating(false);
									}}
								>
									<FilterTitleActive>
										Category
									</FilterTitleActive>
								</FilterLeftButtonActive>
							) : (
								<FilterLeftButton
									onClick={() => {
										setCategory(true);
										setPricing(false);
										setRating(false);
									}}
								>
									<FilterTitle>Category</FilterTitle>
								</FilterLeftButton>
							)}
							{pricing ? (
								<FilterLeftButtonActive
									onClick={() => {
										setCategory(false);
										setPricing(true);
										setRating(false);
									}}
								>
									<FilterTitleActive>
										Pricing
									</FilterTitleActive>
								</FilterLeftButtonActive>
							) : (
								<FilterLeftButton
									onClick={() => {
										setCategory(false);
										setPricing(true);
										setRating(false);
									}}
								>
									<FilterTitle>Pricing</FilterTitle>
								</FilterLeftButton>
							)}
							{rating ? (
								<FilterLeftButtonActive
									onClick={() => {
										setCategory(false);
										setPricing(false);
										setRating(true);
									}}
								>
									<FilterTitleActive>
										Rating
									</FilterTitleActive>
								</FilterLeftButtonActive>
							) : (
								<FilterLeftButton
									onClick={() => {
										setCategory(false);
										setPricing(false);
										setRating(true);
									}}
								>
									<FilterTitle>Rating</FilterTitle>
								</FilterLeftButton>
							)}
						</FilterBoxLeft>
						<FilterBoxRight>
							{category ? (
								<RightFilterBox>
									<FilterTop>
										<FilterTitle className="colorTitle">
											Category
										</FilterTitle>
									</FilterTop>
									<FilterItemBox>
										{outerSubCategory.map((item, index) => {
											return item.parent === null ? (
												<FilterItem
													className="sub-cat"
													key={index}
												>
													<div className="sub-inner">
														<AddIconBox
															onClick={() => {
																setSubCategory(
																	subCategory
																		? ""
																		: item.slug
																);
																setNestedCategoryId(
																	subCategory
																		? ""
																		: subCategoryId
																);
																setSubCategoryId(
																	subCategoryId
																		? ""
																		: item.slug
																);
															}}
														>
															{item.slug !==
																subCategoryId &&
																item.id !==
																	nestedCategoryId && (
																	<AddIcon>
																		<img
																			alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/vertical-line.svg"
																			className="img-class"
																		/>
																	</AddIcon>
																)}
															<AddIcon>
																<img
																	alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/horizontal-line.svg"
																	className="img-class"
																/>
															</AddIcon>
														</AddIconBox>
														<CheckText
															onClick={() => {
																setSubCategory(
																	subCategory
																		? ""
																		: item.title
																);
																setNestedCategoryId(
																	subCategory
																		? ""
																		: nestedCategoryId
																);
																setSubCategoryId(
																	subCategory
																		? ""
																		: item.id
																);
															}}
														>
															{item.title}
														</CheckText>
													</div>
													{subCategory && (
														<SubCategoryBox>
															{outerSubCategory.map(
																(
																	sub_item,
																	index
																) => {
																	return (
																		subCategoryId ===
																			sub_item.parent &&
																		item.id ===
																			sub_item.parent && (
																			<SubCategoryItem
																				key={
																					index
																				}
																				onClick={() => {
																					setSubCategory(
																						subCategory ===
																							item.title
																							? sub_item.title
																							: item.title
																					);
																					setNestedCategoryId(
																						sub_item.id
																					);
																				}}
																			>
																				{nestedCategoryId ===
																				sub_item.id ? (
																					<Check
																						style={{
																							border: "none",
																						}}
																					>
																						<Image
																							
																							className="img-class"
																							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
																						/>
																					</Check>
																				) : (
																					<Check></Check>
																				)}

																				<CheckText className="sub-cat">
																					{
																						sub_item.title
																					}
																				</CheckText>
																			</SubCategoryItem>
																		)
																	);
																}
															)}
														</SubCategoryBox>
													)}
												</FilterItem>
											) : null;
										})}
									</FilterItemBox>
									{/* {categories.length > 5 && (
											<MoreButton>+22 more</MoreButton>
										)} */}
								</RightFilterBox>
							) : null}
							{pricing ? (
								<RightFilterBox>
									<FilterTop>
										<FilterTitle className="colorTitle">
											Price
										</FilterTitle>
									</FilterTop>
									<FilterItemBox>
										{priceList.map((item) => (
											<FilterItem
												key={item.id}
												onClick={() =>
													setPriceFilterId(item.id)
												}
											>
												{item.id === priceFilterId ? (
													<Check
														style={{
															border: "none",
														}}
													>
														<Image
															
															className="img-class"
															alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
														/>
													</Check>
												) : (
													<Check></Check>
												)}
												<CheckText htmlFor="Topwear">
													{item.text}
												</CheckText>
											</FilterItem>
										))}
									</FilterItemBox>
								</RightFilterBox>
							) : null}
							{rating ? (
								<RightFilterBox>
									<FilterTop>
										<FilterTitle className="colorTitle">
											Rating
										</FilterTitle>
									</FilterTop>
									<FilterItemBox>
										{ratingList.map((item) => (
											<FilterItem
												key={item.id}
												onClick={() =>
													setRatingFilterId(item.id)
												}
											>
												{item.id === ratingFilterId ? (
													<Check
														style={{
															border: "none",
														}}
													>
														<Image
															
															className="img-class"
															alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/golden-tick.svg"
														/>
													</Check>
												) : (
													<Check></Check>
												)}
												<CheckText htmlFor="Topwear">
													{item.text}
												</CheckText>
											</FilterItem>
										))}
									</FilterItemBox>
								</RightFilterBox>
							) : null}
						</FilterBoxRight>
					</ContainerFilter>
				</Middle>
				<Bottom>
					<GradientButton
						onClick={() => (
							// setCategoryFilterId(""),
							setRatingFilterId(null),
							setPriceFilterId(null),
							setSubCategory(""),
							setSubCategoryId(""),
							setNestedCategoryId(""),
							setModal(false)
						)}
						fontColor={"#747474"}
						fontSize={"18px"}
						fontFamily={"medium"}
						br={"5px"}
						text="Clear Filters"
						width={"230px"}
						height={"45px"}
						noBackground={true}
					/>
					<GradientButton
						onClick={() => (
							setModal(false), setIsFilterApply(Math.random())
						)}
						fontColor={"#747474"}
						fontSize={"18px"}
						fontFamily={"medium"}
						br={"5px"}
						text="Apply"
						width={"230px"}
						height={"45px"}
					/>
				</Bottom>
			</ModalBox>
		</ModalContainer>
	);
}

const ModalContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100vw;
	right: 0;
	transition: 0.2s ease-out;
	z-index: 999;
`;
const ModalFade = styled.div`
	background: rgba(0, 0, 0, 0.16);
	width: 100vw;
	height: 100vh;
	position: relative;
	top: 0;
	left: 0;
	display: ${({ isModal }) => (isModal ? "flex" : "none")};
	justify-content: flex-end;
`;
const ModalBox = styled.div`
	background: #fff;
	position: absolute;
	z-index: 9999;
	border-radius: 5px;
	align-self: flex-end;
	width: 100vw;
	padding: 20px 20px 100px 20px;
	bottom: -15vh;
	height: 60vh;
	margin-bottom: 10vh;
`;
const Middle = styled.div`
	overflow-y: scroll;

	height: 70%;
	margin: 15px 0;

	border-top: 1px solid #e7e7e7;
	border-bottom: 1px solid #e7e7e7;
`;
const Bottom = styled.div`
	height: 10%;
	margin: 15px 0;
	display: flex;
	justify-content: center;
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;
// const Title = styled.h3`
// 	font-size: 22px;
// 	font-family: medium;
// 	color: #003c3c;
// 	@media all and (max-width: 640px) {
// 		font-size: 18px;
// 	}
// `;
const Close = styled.div`
	width: 15px;
	cursor: pointer;
`;
const Image = styled.img``;
// const Description = styled.p`
// 	font-size: 18px;
// 	font-family: regular;
// 	color: #003c3c;
// 	line-height: 25px;
// 	@media all and (max-width: 640px) {
// 		font-size: 15px;
// 	}
// `;
const FilterIcon = styled.div`
	width: 17px;
	margin-right: 6px;
	@media all and (max-width: 420px) {
		width: 12px;
	}
`;
const FilterButtonText = styled.span`
	font-size: 18px;
	font-family: medium;
	color: #191919;
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const MainFilterButton = styled.div`
	display: flex;
	align-items: center;
`;

const FilterItemBox = styled.div``;
const FilterItem = styled.div`
	display: flex;
	cursor: pointer;
	align-items: center;
	margin-bottom: 13px;
	&.sub-cat {
		flex-direction: column;
		align-items: flex-start;
	}
	.sub-inner {
		display: flex;
		align-items: baseline;
	}
`;

const Check = styled.div`
	background: #fff;
	border: 1px solid #adadad;
	overflow: hidden;
	border-radius: 4px;
	width: 18px;
	height: 18px;
	margin-right: 10px;
	@media all and (max-width: 980px) {
		font-size: 16px;
		width: 15px;
		height: 15px;
	}
	@media all and (max-width: 420px) {
		font-size: 14px;

		margin-right: 8px;
		width: 14px;
		height: 14px;
	}
`;
const CheckText = styled.span`
	font-family: regular;
	color: #191919;
	font-size: 18px;
	@media all and (max-width: 980px) {
		font-size: 16px;
	}
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
// const MoreButton = styled.span`
// 	font-family: medium;
// 	color: #f5b528;
// 	font-size: 14px;
// 	border-bottom: 2px solid #f5b528;
// 	margin: 20px 0 0px 30px;
// 	text-align: center;
// 	display: inline-block;
// 	@media all and (max-width: 980px) {
// 		font-size: 13px;
// 	}
// `;
const RightFilterBox = styled.div`
	margin-bottom: 35px;
`;
const FilterTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const FilterTitle = styled.h3`
	font-family: regular;
	font-size: 18px;
	color: #adadad;

	font-family: medium;
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
	&.colorTitle {
		color: #191919;
	}
`;
const FilterTitleActive = styled.h3`
	font-family: medium;
	font-size: 18px;
	color: #747474;
	@media all and (max-width: 420px) {
		font-size: 15px;
	}
`;
const ContainerFilter = styled.div`
	display: flex;
	height: 100%;
`;
const FilterBoxLeft = styled.div`
	background-color: #fdfbf5;
	width: 25vw;
	height: 100%;
`;

const FilterBoxRight = styled.div`
	width: 75%;
	padding: 15px;
`;
const FilterLeftButton = styled.div`
	display: flex;
	align-item: center;
	justify-content: center;
	padding: 15px;

	cursor: pointer;
`;
const FilterLeftButtonActive = styled.div`
	display: flex;
	align-item: center;
	justify-content: center;
	padding: 15px;
	background: linear-gradient(
		90deg,
		rgba(252, 245, 157, 1) 11%,
		rgba(251, 219, 3, 1) 100%
	);

	cursor: pointer;
`;
const SubCategoryBox = styled.div`
	padding-left: 25px;
	margin-top: 10px;
	transition: all 0.3s ease-in;
`;
const SubCategoryItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 6px;
`;
const AddIconBox = styled.div`
	position: relative;
	height: 18px;
	width: 18px;
	margin-right: 7px;
	@media all and (max-width: 420px) {
		height: 12px;
		width: 12px;
		margin-right: 3px;
	}
`;
const AddIcon = styled.div`
	height: 18px;
	width: 18px;
	position: absolute;
	@media all and (max-width: 420px) {
		height: 12px;
		width: 12px;
	}
`;
