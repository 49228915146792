import React from "react";
import styled from "styled-components";

export default function HeaderPush({ height }) {
	return (
		<HeaderPushContainer style={{ height: "80px" }}></HeaderPushContainer>
	);
}

const HeaderPushContainer = styled.section`
	background: transparent;
	width: 100%;
	@media all and (max-width: 420px) {
		height: 60px !important;
	}
`;
