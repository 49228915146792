import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PaymentStatus from "../../includes/checkout/PaymentStatus";
import Checkout from "../../screens/Checkout";
// import PaymentRouter from "./PaymentRouter";

export default function CheckoutRouter({ reswidth }) {
	return (
		<>
			<Routes>
				<Route
					path="*"
					element={<Navigate to="/page-not-found" replace />}
				/>
				<Route
					element={<Checkout reswidth={reswidth} />}
					path="/:product_id"
				/>
				<Route
					element={<PaymentStatus />}
					path="purchases/"
				/>
			</Routes>
		</>
	);
}
