import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import styled from "styled-components";
import {
  bidConfig,
  ordersConfig,
  paymentsConfig,
  shippingsConfig,
  shopConfig,
} from "../../apiConfig";
import { Context } from "../context/store";
// import Footer from "../general/Footer";
import HeaderPush from "../general/HeaderPush";
import Address from "../includes/checkout/Address";
import CheckoutContainerHeader from "../includes/checkout/CheckoutContainerHeader";
import PageLoader from "./PageLoader";

export default function Checkout({ reswidth }) {
  const { state } = useContext(Context);
  const [productData, setProductData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [addressData, setAddressData] = useState([]);
  const [isAddress, setAddress] = useState(false);
  const [isPayment, setPayment] = useState(false);
  const [isCheckout, setCheckout] = useState(false);
  const [isAddressConfirm, setAddressConfirm] = useState(false);
  const [selectAddId, setSizeSelectId] = useState({});
  const [shippingDetails, setShippingDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isRefresh, setRefresh] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [selectedPincode, setSelectedPincode] = useState("");
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const params = useParams();
  const { search } = useLocation();
  const parameters = new URLSearchParams(search);
  const type = parameters.get("type");
  const location = useLocation();

  //when onload the page ,scroll top
  useEffect(() => {
    document.body.style.overflowY = "scroll";
    window.scrollTo(0, 0);
  }, []);

  //  fetch address
  const fetchAddressApi = () => {
    setLoading(true);
    ordersConfig
      .get("address", {
        headers: {
          Authorization: `Bearer ${state.user_data.access_token}`,
        },
      })
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (res.status === 200) {
          setAddressData(res.data);
          // fetchShippingApi(res.data[0].pincode);
          setSelectedPincode(res.data[0].pincode);
          setAddress(true);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchAddressApi();
  }, []);

  // fetch shipping details
  const fetchShippingApi = () => {
    console.log("SHIPPIN PARAMETREES", selectedPincode, productData?.id);
    setLoading(true);
    shippingsConfig
      .get(
        `shipping-charge/?pincode=${selectedPincode}&product_id=${
          type === "cart_products" && productData?.cart_items
            ? productData?.cart_items[0]?.id
            : productData?.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        }
      )
      .then((res) => {
        const { StatusCode, data } = res.data;
        if (StatusCode === 6000) {
          setLoading(false);
          setShippingDetails(data);
        } else {
          setShippingDetails([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setShippingDetails([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // product fetching
        let fetchProduct = null;
        if (type === "cart_products") {
          fetchProduct = ordersConfig("cart/", {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          });
        } else if (type === "bid_redeem") {
          fetchProduct = bidConfig.get("my-biddings/", {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          });
        } else {
          fetchProduct = shopConfig.get(`products/${params.product_id}`);
        }

        // address fetching
        const fetchAddress = ordersConfig.get("address", {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        });

        // wallet balance fetching
        const fetchWalletBalance = paymentsConfig.get("view-balance", {
          headers: {
            Authorization: `Bearer ${state.user_data.access_token}`,
          },
        });

        // shiping details fetching
        const fetchShipping = shippingsConfig.get(
          `shipping-charge/?pincode=${
            selectedPincode
              ? selectedPincode
              : addressData[addressData.length - 1]?.pincode
          }&product_id=${productData?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state.user_data.access_token}`,
            },
          }
        );

        // waiting for all api calls
        const [
          fetchProductData,
          fetchAddressData,
          fetchWalletBalanceData,
          fetchShippingData,
        ] = await Promise.all([
          fetchProduct,
          fetchAddress,
          fetchWalletBalance,
          fetchShipping,
        ]);

        // assinging data into variables from response

        // wallet
        if (fetchWalletBalanceData.data.StatusCode === 6000) {
          setWalletBalance(fetchWalletBalanceData.data.data[0].wallet_balance);
        }

        // product
        const { StatusCode, data } = fetchProductData.data;
        if (type === "cart_products") {
          if (StatusCode === 6000) {
            setProductData(data);
            let price = 0;
            let shippingCost = 0;
            // let actual = 0;
            productData?.cart_items?.forEach((x) => {
              price += parseInt(x.product_cost);
              shippingCost += x.product_shipping_cost;
            });
            setTotalPrice(price);
            setTotalShippingCost(productData?.pice_data.total_shipping_cost);
            fetchShippingApi();
          }
        } else if (type === "bid_redeem") {
          if (StatusCode === 6000) {
            data.bidding_data.filter((item) => {
              if (
                item.has_claimed &&
                item.product_has_converted_to_order === false
              ) {
                setProductData(item);
              }
            });
            fetchShippingApi();
          }
        } else {
          if (StatusCode === 6000) {
            setProductData(data);
            fetchShippingApi();
          }
        }

        // address
        if (fetchAddressData.data.StatusCode === 6000) {
          setAddressData(fetchAddressData.data.data);
          setSelectedPincode(fetchAddressData.data[0].pincode);
          setAddress(true);
        }

        // wallet
        if (fetchWalletBalanceData.data.StatusCode === 6000) {
          setWalletBalance(fetchWalletBalanceData.data.data[0].wallet_balance);
        }

        // shipping
        if (fetchShippingData.data.StatusCode === 6000) {
          setShippingDetails(fetchShippingData.data.data);
        }

        // All API calls completed, set isLoader to false
        // setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (productData && selectedPincode) {
      fetchShippingApi();
    }
  }, [addressData, selectAddId, isRefresh, productData]);

  const renderCheckoutPage = () => {
    if (isLoading) {
      return <PageLoader />;
    } else {
      return (
        <Address
          isAddress={isAddress}
          isPayment={isPayment}
          isCheckout={isCheckout}
          isAddressConfirm={isAddressConfirm}
          setAddress={setAddress}
          setPayment={setPayment}
          setCheckout={setCheckout}
          setAddressConfirm={setAddressConfirm}
          addressData={addressData}
          productData={productData}
          setSizeSelectId={setSizeSelectId}
          selectAddId={selectAddId}
          totalPrice={totalPrice}
          type={type}
          quantity={location.state?.quantity}
          attr={location.state?.attr}
          setRefresh={setRefresh}
          walletBalance={walletBalance}
          shippingDetails={shippingDetails}
          setAddressData={setAddressData}
          setSelectedPincode={setSelectedPincode}
          totalShippingCost={totalShippingCost}
        />
      );
    }
  };

  return (
    <>
      <CheckoutContainerHeader
        isAddress={isAddress}
        isPayment={isPayment}
        isCheckout={isCheckout}
        reswidth={reswidth}
        cart={type === "cart_products" ? true : false}
        isAddressConfirm={isAddressConfirm}
      />
      <HeaderPush />
      {renderCheckoutPage()}
    </>
  );
}
