import { useState, useEffect } from "react";

export const useAudio = () => {
	const [audio] = useState(
		new Audio(
			"https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/audio/audio-1.mpeg"
		)
	);
	const [audio_2] = useState(
		new Audio(
			"https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/audio/audio-2.mpeg"
		)
	);
	const [playing, setPlaying] = useState(false);
	const [playing_2, setPlaying_2] = useState(false);

	const toggle = () => setPlaying(!playing);
	const toggle_2 = () => setPlaying_2(!playing_2);

	useEffect(() => {
		playing ? audio?.play() : audio?.pause();
		playing_2 ? audio_2?.play() : audio_2?.pause();
	}, [playing, playing_2]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		audio_2.addEventListener("ended", () => setPlaying_2(false));
		return () => {
			audio.removeEventListener("ended", () => setPlaying(false));
			audio_2.removeEventListener("ended", () => setPlaying_2(false));
		};
	}, []);

	return [playing, toggle, toggle_2];
};
