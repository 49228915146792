import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function CheckoutContainerHeader({
	isAddress,
	isPayment,
	isCheckout,
	isAddressConfirm,
	cart,
	reswidth,
}) {
	const navigate = useNavigate();
	const [per, setPer] = useState(10);
	useEffect(() => {
		handlePercentage();
	}, [isAddress, isPayment, isCheckout, isAddressConfirm]);
	function handlePercentage() {
		if (isAddress && !isAddressConfirm) {
			if (cart) {
				setPer(35);
			} else {
				setPer(10);
			}
		} else if (isAddress && isAddressConfirm && !isPayment) {
			setPer(30);
		} else if (isAddress && isAddressConfirm && isPayment && !isCheckout) {
			if (cart) {
				setPer(70);
			} else {
				setPer(50);
			}
		} else if (isAddress && isAddressConfirm && isPayment && isCheckout) {
			setPer(100);
		} else {
			setPer(0);
		}
	}

	return (
		<HeaderContainer>
			<Wrapper className="wrapper">
				<LogoBox to="/">
					<Image
						
						alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/logo.svg"
						className="img-class"
					/>
				</LogoBox>
				<MobileHeader
					onClick={() => navigate("/products/all-products")}
				>
					<Image
						
						alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-left.svg"
						className="img-class arrow"
					/>
					<MobileHeaderText>
						{isAddress ? "Add Address" : "My cart"}
					</MobileHeaderText>
				</MobileHeader>
				<PRight reswidth={reswidth} cart={cart}>
					{cart ? (
						<ProgressBar
							// percent={
							// 	isAddress
							// 		? 20
							// 		: isPayment
							// 		? 70
							// 		: isCheckout
							// 		? 100
							// 		: 0
							// }
							percent={per}
							// percent={handlePercentage()}
							filledBackground="#F7D320"
							height={2}
						>
							<Step
								transition="scale"
								style={{
									flexDirection: "column",
									display: cart ? "flex" : "none",
								}}
							>
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: "1px solid #F7D320",
												background: "#F7D320",
											}}
										>
											{isAddress ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color: isAddress
															? "#F7D320"
															: "#191919",
													}}
												>
													1
												</RingText>
											)}
										</Ring>
										<StatusTitle>cart</StatusTitle>
									</>
								)}
							</Step>
							<Step
								transition="scale"
								style={{ flexDirection: "column" }}
							>
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: isAddress
													? "1px solid #F7D320"
													: "1px solid #2d2d2d",
												background:
													isAddress &&
													isAddressConfirm
														? "#F7D320"
														: "#fff",
											}}
										>
											{isAddress && isAddressConfirm ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color:
															isAddress &&
															isAddressConfirm
																? "#F7D320"
																: "#191919",
													}}
												>
													1
												</RingText>
											)}
										</Ring>
										<StatusTitle>Address</StatusTitle>
									</>
								)}
							</Step>
							<Step transition="scale">
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: isPayment
													? "1px solid #F7D320"
													: "1px solid #2d2d2d",
												background: isPayment
													? "#F7D320"
													: "#fff",
											}}
										>
											{isPayment ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color: isPayment
															? "#F7D320"
															: "#191919",
													}}
												>
													2
												</RingText>
											)}
										</Ring>
										<StatusTitle>Summary</StatusTitle>
									</>
								)}
							</Step>
							<Step transition="scale">
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: isCheckout
													? "1px solid #F7D320"
													: "1px solid #2d2d2d",
												background: isCheckout
													? "#F7D320"
													: "#fff",
											}}
										>
											{isCheckout ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color: isCheckout
															? "#F7D320"
															: "#191919",
													}}
												>
													3
												</RingText>
											)}
										</Ring>
										<StatusTitle>Payment</StatusTitle>
									</>
								)}
							</Step>
						</ProgressBar>
					) : (
						<ProgressBar
							// percent={
							// 	isAddress
							// 		? 20
							// 		: isPayment
							// 		? 70
							// 		: isCheckout
							// 		? 100
							// 		: 0
							// }
							percent={per}
							// percent={handlePercentage()}
							filledBackground="#F7D320"
							height={2}
						>
							<Step
								transition="scale"
								style={{ flexDirection: "column" }}
							>
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: isAddress
													? "1px solid #F7D320"
													: "1px solid #2d2d2d",
												background: isAddress
													? "#F7D320"
													: "#fff",
											}}
										>
											{isAddress ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color: isAddress
															? "#F7D320"
															: "#191919",
													}}
												>
													1
												</RingText>
											)}
										</Ring>
										<StatusTitle>Address</StatusTitle>
									</>
								)}
							</Step>
							<Step transition="scale">
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: isPayment
													? "1px solid #F7D320"
													: "1px solid #2d2d2d",
												background: isPayment
													? "#F7D320"
													: "#fff",
											}}
										>
											{isPayment ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color: isPayment
															? "#F7D320"
															: "#191919",
													}}
												>
													2
												</RingText>
											)}
										</Ring>
										<StatusTitle>Summary</StatusTitle>
									</>
								)}
							</Step>
							<Step transition="scale">
								{({ accomplished }) => (
									<>
										<Ring
											style={{
												border: isCheckout
													? "1px solid #F7D320"
													: "1px solid #2d2d2d",
												background: isCheckout
													? "#F7D320"
													: "#fff",
											}}
										>
											{isCheckout ? (
												<img
													
													alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/tick.svg"
												/>
											) : (
												<RingText
													style={{
														color: isCheckout
															? "#F7D320"
															: "#191919",
													}}
												>
													3
												</RingText>
											)}
										</Ring>
										<StatusTitle>Payment</StatusTitle>
									</>
								)}
							</Step>
						</ProgressBar>
					)}
				</PRight>
			</Wrapper>
		</HeaderContainer>
	);
}

const HeaderContainer = styled.div`
	width: 100%;
	z-index: 999;
	background: #fff;
	border-bottom: 1px solid #c4c4c4;
	@media all and (max-width: 980px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
	}
`;
const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	height: 80px;
	position: relative;
	align-items: center;
	@media all and (max-width: 980px) {
		justify-content: flex-start;
		/* justify-content: center; */
		align-items: baseline;
		flex-direction: column;
		height: 150px;
	}
`;
const LogoBox = styled(Link)`
	width: 55px;
	position: absolute;
	left: 0;

	@media all and (max-width: 980px) {
		display: none;
	}
	@media all and (max-width: 640px) {
		width: 40px;
	}
`;
const Image = styled.img`
	&.arrow {
		width: 20px;
		height: 20px;
	}
`;
// const HedearStatus = styled.div`
// 	margin: 0 auto;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	position: relative;
// 	z-index: 2;
// 	width: 35%;
// 	&::after {
// 		content: "";
// 		display: block;
// 		position: absolute;
// 		height: 2px;
// 		width: 100%;
// 		background: #747474;
// 		z-index: -1;
// 	}
// 	&.cart {
// 		width: 45%;
// 	}
// 	@media all and (max-width: 980px) {
// 		display: none;
// 	}
// 	@media all and (max-width: 640px) {
// 		margin: unset;
// 		width: 60%;
// 	}
// `;
// const StatusBox = styled.div`
// 	width: 40px;
// 	height: 40px;
// 	border-radius: 50%;
// 	border: 2px solid #747474;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	background: #fff;
// 	@media all and (max-width: 768px) {
// 		width: 35px;
// 		height: 35px;
// 	}
// 	@media all and (max-width: 640px) {
// 		width: 30px;
// 		height: 30px;
// 	}
// `;
// const Text = styled.h3`
// 	font-family: medium;
// 	font-size: 18px;
// 	color: #747474;
// 	@media all and (max-width: 768px) {
// 		font-size: 14px;
// 	}
// `;

// const Tick = styled.div`
// 	width: 40%;
// 	/* height: 10px; */
// `;

const MobileHeader = styled.div`
	display: none;
	@media all and (max-width: 980px) {
		display: flex;
		margin-bottom: 50px;
		margin-top: 20px;
	}
`;
const MobileHeaderText = styled.span`
	font-family: medium;
	font-size: 18px;
	color: #191919;
	margin-left: 10px;
`;

const PRight = styled.div`
	/* width: 60%;	 */

	@media all and (max-width: 980px) {
		/* display: none; */
		padding-left: 20px;
	}
	.RSPBstep {
		flex-direction: column;
		white-space: nowrap;
		margin-top: 4%;
		/* &::first-child {
			display: ${({ cart }) => (cart ? "flex" : "none")};
		} */
		@media all and (max-width: 640px) {
			margin-top: 7%;
		}
	}
	.RSPBprogressBar {
		/* transform: translateX(8%); */
		transform: translateY(-7px);
		width: 500px;

		@media all and (max-width: 980px) {
			width: ${({ reswidth }) => `${reswidth - 140}px`};
		}
		@media all and (max-width: 640px) {
			width: ${({ reswidth }) => `${reswidth - 100}px`};
		}
		@media all and (max-width: 420px) {
			width: ${({ reswidth }) => `${reswidth - 80}px`};
		}
		/*
		@media all and (max-width: 360px) {
			width: 250px;
		}  */
	}
`;
const StatusTitle = styled.h3`
	color: #191919;
	font-size: 14px;
	font-family: "regular";
	margin-top: 10px;
	@media all and (max-width: 1080px) {
		font-size: 15px;
	}
	@media all and (max-width: 980px) {
		font-size: 12px;
	}
`;
const Ring = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #2d2d2d;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	@media all and (max-width: 980px) {
		width: 25px;
		height: 25px;
	}
`;
const RingText = styled.span`
	color: #191919;
	font-size: 17px;
	font-family: sans-serif;
	@media all and (max-width: 980px) {
		font-size: 12px;
	}
`;
