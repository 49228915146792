import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../../general/Header";
import HeaderPush from "../../general/HeaderPush";

export default function CoinPurchaseStatus() {
	return (
		<>
			<Header />
			<HeaderPush />
			<Container>
				<Wrapper>
					<Top>
						<Title>Thank you!</Title>
						<Span>Your order successfully created</Span>
					</Top>
				</Wrapper>
				<Button to="/profile/coins">
					<ButtonText>Purchase Coin</ButtonText>
				</Button>
			</Container>
		</>
	);
}

const Container = styled.div`
	padding: 5% 0;
	height: calc(100vh - 80px);
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const Wrapper = styled.div`
	width: 60%;
	margin: 0 auto;
	max-width: 1350px;
	background: #fff7ea;
	border-radius: 6px;
	padding: 50px;
	margin-bottom: 40px;
	@media all and (max-width: 1280px) {
		width: 75%;
	}
	@media all and (max-width: 420px) {
		width: 90%;
		margin-bottom: 20px;
	}
`;

const Top = styled.div`
	text-align: center;
`;
// const Coin = styled.div`
// 	width: 70px;
// 	height: 70px;
// 	margin: 0 auto;
// `;
const Title = styled.h3`
	font-size: 20px;
	font-family: medium;
	color: #191919;
	margin-bottom: 10px;
`;
const Span = styled.span`
	font-size: 16px;
	font-family: regular;
	color: #747474;
`;
// const Bottom = styled.div`
// 	display: flex;
// 	align-items: center;
// 	flex-direction: column;
// 	margin-top: 60px;
// `;
// const CoinCount = styled.input`
// 	min-width: 15px;
// 	max-width: 150px;
// 	color: #191919;
// 	font-size: 35px;
// 	font-family: "medium";
// 	text-align: center;
// `;
// const InputBox = styled.div`
// 	display: flex;
// 	border: 1px solid #feda00;
// 	align-items: center;
// `;
// const IconBox = styled.div`
// 	margin-right: 10px;
// 	padding: 10px;
// 	background: #feda00;
// 	@media all and (max-width: 640px) {
// 		padding: 7px;
// 	}
// `;
// const Input = styled.input`
// 	/* text-transform: uppercase; */
// `;
const Button = styled(Link)`
	height: 40px;
	width: 13%;
	background: #feda00;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	@media all and (max-width: 980px) {
		width: 20%;
	}
	@media all and (max-width: 640px) {
		width: 35%;
	}
`;
const ButtonText = styled.div`
	font-size: 16px;
	font-family: regular;
	color: #191919;
	@media all and (max-width: 980px) {
		font-size: 14px;
	}
`;
