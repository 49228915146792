import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InsufficientCoins from "../modal/bidding/InsufficientCoins";
import {
	ref,
	onValue,
	update,
	serverTimestamp,
	push,
	child,
} from "firebase/database";
import { rtdb } from "../../../../src/config/firebase";
import { accountConfig, bidConfig, paymentsConfig } from "../../../apiConfig";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/store";
import PageLoader from "../../screens/PageLoader";
import { BiddingMainCountDown } from "./BiddingMainCountDown";
import { useAudio } from "../useAudio";
import ButtonLoader from "../../general/includes/ButtonLoader";
import EmptyPage from "../../screens/EmptyPage";
import WinnerModal from "../modal/bidding/WinnerModal";
import Toast from "../../general/includes/Toast";

export default function LiveBidding() {
	const [isModal, setModal] = useState(false);
	const [winningModal, setWinningModal] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const { state, dispatch } = useContext(Context);
	const [toastText, setToastText] = useState("");
	const [isToast, setToast] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [liveBid, setLiveBid] = useState([]);
	const [timeEnd, setTimeEnd] = useState(false);
	const params = useParams();
	const [firebaseBidProductData, setFirebaseBidProductData] = useState([]);
	const [ownBidData, setOwnBidData] = useState([]);
	const [firebaseBidUsersData, setFirebaseBidUsersData] = useState({});
	const [firebaseLiveBidData, setFirebaseLiveBidData] = useState({});
	const [lastKey, setLastKey] = useState("");
	const divRef = useRef(null);
	const newElementRef = useRef(null);
	const [timesBidden, setTimesBidden] = useState(0);
	const [coinBalance, setCoinBalance] = useState("");
	const user_pk = state.user_data.user_pk;
	const firebaseBidUsersDataLength = firebaseBidUsersData
		? Object.keys(firebaseBidUsersData).length
		: 0;
	const firebaseLiveBidDataLength = firebaseLiveBidData
		? Object.keys(firebaseLiveBidData).length
		: 0;

	const [playing, toggle, toggle_2] = useAudio();
	const [userTimer, setUserTimer] = useState(liveBid?.bid_time);

	const bid_date_year = new Date(liveBid.start_time_stamp).getFullYear();
	const bid_date_month = new Date(liveBid.start_time_stamp).getMonth() + 1;
	const bid_date_day = new Date(liveBid.start_time_stamp).getDate();
	const bid_id = params.bid_id;

	function bidDateString() {
		let date = `${bid_date_year}-${
			bid_date_month < 10 ? `0${bid_date_month}` : bid_date_month
		}-${bid_date_day < 10 ? `0${bid_date_day}` : bid_date_day}`;
		return date;
	}

	//for scroll bar hidden ,when modal activated
	useEffect(() => {
		if (isModal) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "scroll";
		}
	}, [isModal]);

	// call winner modal
	useEffect(() => {
		if (timeEnd) {
			setWinningModal(true);
		}
	}, [timeEnd]);

	// bid main timer managment end manage bid status
	useEffect(() => {
		if (
			(firebaseLiveBidDataLength > 0 && !buttonLoader,
			firebaseLiveBidData?.bid_status === "ongoing")
		) {
			handleScrollToBottom();
			const interval = setInterval(() => {
				const date = new Date();
				const startDate = firebaseLiveBidData.startAt;
				const distance = date - startDate;
				if (liveBid?.bid_time - Math.floor(distance / 1000) > 0) {
					setUserTimer(
						liveBid.bid_time - Math.floor(distance / 1000)
					);
				} else {
					setUserTimer(0);
					const timerData = {
						startAt: firebaseLiveBidData.startAt,
						user_pk: firebaseLiveBidData.user_pk,
						bid_status: "ended",
					};

					// post current user and timer update
					const timerUpdates = {};
					timerUpdates[
						`biddings/${bidDateString()}/${bid_id}/live_bid_data`
					] = timerData;
					update(ref(rtdb), timerUpdates);
					handleEndBid();
					// setWinningModal(true);
					clearInterval(interval);
				}
			}, 1000);
			// ========================================================================

			if (firebaseBidUsersDataLength > 0) {
				let bottomMostMatch = null;
				const keys = Object.keys(firebaseBidUsersData);
				for (let i = keys.length - 1; i >= 0; i--) {
					const key = keys[i];
					const object = firebaseBidUsersData[key];

					if (object.user_pk === 672) {
						bottomMostMatch = object;
						break; // Stop the loop after finding the bottom-most match
					}
				}
				setOwnBidData(bottomMostMatch);
			}

			return () => clearInterval(interval);
		}
	}, [firebaseBidUsersData, firebaseLiveBidData, buttonLoader]);

	// how many time ago
	function timeSince(date) {
		var seconds = Math.floor((new Date() - date) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + " years";
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + " months";
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + " days";
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + " hours";
		}
		interval = seconds / 60;
		if (interval > 1) {
			return Math.floor(interval) + " minutes";
		}
		// return Math.floor(seconds) + " seconds";
		return "Few seconds ";
	}

	// //for firebase realtime update.this function gonna call after single bid req if code===6000
	// const getFireBaseRealTimeData = () => {
	// 	//firebase get data req..realtime

	// 	if (liveBid) {
	// 		const productref = ref(
	// 			rtdb,
	// 			`biddings/${bidDateString()}/${bid_id}`
	// 		);
	// 		onValue(productref, (snapshot) => {
	// 			const fire_data = snapshot.val();
	// 			setFirebaseBidProductData(fire_data.product_data);
	// 			setFirebaseBidUsersData(fire_data.participants);
	// 			setFirebaseLiveBidData(fire_data.live_bid_data);

	// 			if (fire_data?.live_bid_data?.user_pk !== user_pk) {
	// 				toggle_2();
	// 			}
	// 			if (firebaseBidUsersDataLength === 1) {
	// 				setLastKey(Object.keys(firebaseBidUsersData).slice(-1)[0]);
	// 			}

	// 			setLoading(false);
	// 			handleScrollToBottom();
	// 		});
	// 	}
	// };

	// useEffect(() => {
	// 	getFireBaseRealTimeData();
	// }, [liveBid]);

	// Single bid API request
	useEffect(() => {
		// Function to fetch user profile data
		const fetchUserProfileData = async () => {
			try {
				const res = await accountConfig.get("profile/", {
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				});
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					dispatch({
						type: "UPDATE_USER_DATA",
						user_data: {
							...state.user_data,
							phone: data.phone,
							name: data.name,
							email: data.email,
							gender: data.gender,
							photo: data.photo,
							user_pk: data.user,
						},
					});
				}
			} catch (error) {
				setLoading(false);
			}
		};

		// Function to fetch coin balance
		const fetchCoinBalance = async () => {
			try {
				const res = await paymentsConfig.get("view-balance", {
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				});
				if (res.data.StatusCode === 6000) {
					setCoinBalance(res.data.data[0].coin_balance);
				}
			} catch (error) {}
		};

		// Function to make the single bid API request
		const fetchSingleBid = async () => {
			try {
				const res = await bidConfig.get(
					`bidding-item/${params.bid_id}`
				);
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setLiveBid(data);
					setUserTimer(data?.bid_time);
					getFireBaseRealTimeData();
				} else {
					setLoading(false);
				}
			} catch (error) {
				setLoading(false);
			}
		};

		// Execute the required functions
		document.activeElement.blur(); // Hide unwanted keyboard popup
		fetchSingleBid(); // Fetch single bid details

		if (state.user_data.is_verified) {
			fetchUserProfileData(); // Fetch user profile data if verified
		}

		fetchCoinBalance(); // Fetch coin balance
		handleScrollToBottom();
	}, []);

	// Function to get Firebase real-time data
	const getFireBaseRealTimeData = () => {
		if (liveBid) {
			const productref = ref(
				rtdb,
				`biddings/${bidDateString()}/${bid_id}`
			);
			onValue(productref, (snapshot) => {
				const fire_data = snapshot.val();
				if (fire_data) {
					setFirebaseBidProductData(fire_data?.product_data);
					setFirebaseBidUsersData(fire_data?.participants);
					setFirebaseLiveBidData(fire_data?.live_bid_data);

					if (fire_data.live_bid_data?.user_pk !== user_pk) {
						toggle_2();
					}
					if (firebaseBidUsersDataLength === 1) {
						setLastKey(
							Object.keys(firebaseBidUsersData).slice(-1)[0]
						);
					}
				}

				setLoading(false);
				// handleScrollToBottom();
			});
		}
	};

	useEffect(() => {
		if (liveBid) {
			getFireBaseRealTimeData();
		}
	}, [liveBid]);

	// end bidding
	const handleEndBid = () => {
		if (firebaseLiveBidData?.user_pk === user_pk) {
			bidConfig
				.post(
					`end-bidding/${bid_id}/`,
					{
						total_bid_amount:
							firebaseBidUsersData[lastKey]?.coins_used + 1,
					},
					{
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					}
				)
				.then((res) => {})
				.catch((err) => {});
		}
	};

	//when click main bid button(after deduct coin === 6000)
	const postBid = () => {
		// postuser data updation

		if (!timeEnd) {
			if (
				(firebaseLiveBidData?.bid_status === "ongoing" ||
					firebaseBidUsersDataLength === 0) &&
				firebaseLiveBidData?.user_pk !== user_pk &&
				!buttonLoader &&
				user_pk
			) {
				setButtonLoader(true);
				setTimesBidden(timesBidden + 1);

				setUserTimer(liveBid?.bid_time);

				//for now timer
				var xtoday = new Date();
				var time = xtoday.getTime();

				//main participant data
				const postData = {
					participation_status: "ongoing",
					coins_used: timesBidden,
					coins_refunded: 0,
					time_stamp: time,
					times_bidden: timesBidden,
					user_name: state.user_data.name,
					user_pic: state.user_data.photo,
					bidding_lasted_for: 0,
					user_pk: user_pk,
					startAt: time,
				};

				// timer data
				const timerData = {
					startAt: serverTimestamp(),
					user_pk: state.user_data.user_pk,
					bid_status: "ongoing",
				};

				// post current user and timer update
				const timerUpdates = {};
				const updates = {};

				//for generating key for each post
				const eachUserKey = push(
					child(ref(rtdb), "live_participants")
				).key;

				updates[
					`biddings/${bidDateString()}/${bid_id}/participants/${eachUserKey}`
				] = postData;

				// live bid data
				timerUpdates[
					`biddings/${bidDateString()}/${bid_id}/live_bid_data`
				] = timerData;

				//counter and whos the current bid
				update(ref(rtdb), timerUpdates)
					.then(() => {
						update(ref(rtdb), updates)
							.then(() => {
								// setTimeout(() => {
								setButtonLoader(false);
								// handleScrollToBottom();
								toggle();
								// }, 1000);
							})
							.catch(() => {
								setButtonLoader(false);
							});
					})
					.catch(() => {
						setButtonLoader(false);
					});
				setCoinBalance(coinBalance - 1);
			}
		}
	};

	// deduct coin
	const handleDeductCoin = () => {
		if (
			(firebaseLiveBidData?.bid_status === "ongoing" ||
				firebaseBidUsersDataLength === 0) &&
			firebaseLiveBidData?.user_pk !== user_pk &&
			user_pk
		) {
			// setButtonLoader(true);
			paymentsConfig
				.post(
					"deduct-coin/",
					{
						bidding_pk: bid_id,
					},
					{
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					}
				)
				.then((res) => {
					const { StatusCode, data } = res.data;
					if (StatusCode === 6000) {
						// postBid();
					} else {
						setToastText(data.message);
						setToast(true);
						// setButtonLoader(false);
					}
				})
				.catch((err) => {
					setToastText(
						"You can't bid right now.Somthing went wrong."
					);
					setToast(true);
					// setButtonLoader(false);
				});
		} else {
			if (!user_pk) {
				setToastText("Check your profile again");
				setToast(true);
			}
		}
	};

	// when the 'Bid now' button is clicked
	const handleBid = () => {
		if (coinBalance > 0) {
			handleDeductCoin();
			postBid();
		} else {
			setModal(true);
		}
	};

	// for automatic scroll bottom when user click a bid
	const handleScrollToBottom = () => {
		if (newElementRef.current !== null) {
			newElementRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	};

	// winner details
	function handleWinnerDetails() {
		let name = "";
		let profileImage = "";
		if (firebaseLiveBidData && firebaseBidUsersDataLength > 0) {
			Object.keys(firebaseBidUsersData).filter(function (key, value) {
				if (
					firebaseLiveBidData.user_pk ===
					firebaseBidUsersData[key].user_pk
				) {
					name = firebaseBidUsersData[key].user_name;
					profileImage = firebaseBidUsersData[key].user_pic;
				}
			});
			return {
				name: name,
				profileImage: profileImage,
				productName: firebaseBidProductData?.name,
				productImage: liveBid?.product_image,
				userPk: firebaseLiveBidData.user_pk,
			};
		}
		return null;
	}
	let winnerDetails = handleWinnerDetails();

	return (
		<>
			<InsufficientCoins
				isModal={isModal}
				setModal={setModal}
				// setCoinModal={setCoinModal}
			/>
			{/* <PurchaseCoin isModal={coinModal} setModal={setCoinModal} /> */}
			{!isLoading && Object.keys(liveBid).length > 0 ? (
				<LiveBiddingContainer>
					<Toast text={toastText} isToast={isToast} />
					<WinnerModal
						winnerDetails={winnerDetails}
						winningModal={winningModal}
						setWinningModal={setWinningModal}
					/>
					<BiddingTop>
						<NewLeft to="/bidding/live-biddings/">
							<CloseIconBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/close.svg"
								/>
							</CloseIconBox>
							<CloseText>Exit</CloseText>
						</NewLeft>
						<NewRight>
							<CoinBalance>
								<BalanceCoin>
									<Image
										
										className="img-class"
										alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
									/>
								</BalanceCoin>
								<BalanceText>
									{coinBalance ? coinBalance : "0"}
								</BalanceText>
							</CoinBalance>
						</NewRight>
					</BiddingTop>
					<Wrapper
						className="wrapper"
						style={{
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}
					>
						<Container>
							<Top>
								<TopLeft>
									<ProductBox>
										{liveBid.length !== 0 && (
											<ProductImage>
												<Image
													
													className="img-class"
													alt="Image" src={liveBid.product_image}
													// alt="Image" src={
													// 	firebaseBidProductData?.image
													// }
												/>
											</ProductImage>
										)}
										<ProdcutTextBox>
											{liveBid.length !== 0 && (
												<ProductName>
													{/* {liveBid.product_name} */}
													{
														firebaseBidProductData?.name
													}
												</ProductName>
											)}
											{liveBid.length !== 0 && (
												<ProductPrice>
													Price
													<Text className="product-name">
														{/* {liveBid.actual_cost} */}
														{
															firebaseBidProductData?.display_cost
														}
													</Text>
												</ProductPrice>
											)}
										</ProdcutTextBox>
									</ProductBox>
									<TimeBox>
										{!timeEnd && (
											<Text className="ends-in">
												Ends in
											</Text>
										)}
										{liveBid?.end_time_stamp && (
											<BiddingMainCountDown
												initialTime={
													liveBid.end_time_stamp
												}
												timeEnd={timeEnd}
												setTimeEnd={setTimeEnd}
												bidStatus={
													firebaseLiveBidData?.bid_status
												}
												handleEndBid={handleEndBid}
											/>
										)}
									</TimeBox>
								</TopLeft>
								<TopRight>
									<UsersList ref={divRef}>
										{firebaseBidUsersDataLength > 0 &&
											Object.keys(
												firebaseBidUsersData
											).map(function (key, value) {
												return (
													<UserListItem
														key={key}
														ref={newElementRef}
														onLoad={() =>
															setLastKey(key)
														}
													>
														<UserLeft>
															<ProfilePic
																style={{
																	border:
																		value ===
																		firebaseBidUsersDataLength -
																			1
																			? "2px solid #FFDF17"
																			: "none",
																	backgroundColor:
																		"#FFDF17",
																}}
															>
																{firebaseBidUsersData[
																	key
																].user_pic ? (
																	<Image
																		
																		className="img-class"
																		alt="Image" src={
																			firebaseBidUsersData[
																				key
																			]
																				.user_pic
																		}
																	/>
																) : (
																	<ProfileLetter>
																		{firebaseBidUsersData[
																			key
																		].user_name?.charAt(
																			0
																		)}
																	</ProfileLetter>
																)}
															</ProfilePic>
															<ProfileNameBox>
																<Text className="profile-name">
																	{firebaseBidUsersData[
																		key
																	]
																		?.user_pk ===
																	user_pk
																		? "You"
																		: firebaseBidUsersData[
																				key
																		  ]
																				.user_name}
																</Text>
																<Text className="last-bid-time">
																	{timeSince(
																		new Date(
																			firebaseBidUsersData[
																				key
																			].time_stamp
																		)
																	)}{" "}
																	{/* {timeSince(
																		new Date(
																			Date.now() -
																				aDay
																		)
																	)} */}
																	ago
																</Text>
															</ProfileNameBox>
														</UserLeft>
														<UserRight>
															<CoinBox>
																<Image
																	
																	className="img-class"
																	alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
																/>
															</CoinBox>
															<Text className="coin-count">
																{/* {
																firebaseBidUsersData[
																	fireBaseLiveParticipants[
																		key
																	].pk
																].coins_used
															} */}
																1
															</Text>
															{/* {item.time && ( */}

															{firebaseLiveBidData?.bid_status ===
																"ongoing" &&
																!timeEnd &&
																value ===
																	firebaseBidUsersDataLength -
																		1 && (
																	<Text className="bid-remaining">
																		{
																			userTimer
																		}
																		<Text className="bid-remaining-time">
																			sec
																		</Text>
																	</Text>
																)}

															{(firebaseLiveBidData?.bid_status ===
																"ended" ||
																timeEnd) &&
																value ===
																	firebaseBidUsersDataLength -
																		1 && (
																	<Text className="bid-remaining">
																		Winner
																	</Text>
																)}

															{/* )} */}
														</UserRight>
														{value ===
															firebaseBidUsersDataLength -
																1 && (
															<Triangle className="left">
																<img
																	className="img-class"
																	
																	alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/triangle.svg"
																/>
															</Triangle>
														)}
														{value ===
															firebaseBidUsersDataLength -
																1 && (
															<Triangle className="right">
																<img
																	className="img-class"
																	
																	alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/triangle.svg"
																/>
															</Triangle>
														)}
													</UserListItem>
												);
											})}
									</UsersList>
								</TopRight>
							</Top>
							<Bottom>
								<ButtonBox>
									<Button
										style={{
											background: "#252525",
											marginRight: "10px",
										}}
										className="small-button"
									>
										<IconBox className="small">
											<Image
												
												className="img-class"
												alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
											/>
										</IconBox>
										<Text className="button-text">
											{/* {Object.values(
												firebaseBidUsersData
											)[firebaseBidUsersDataLength - 1]
												.user_pk === user_pk
												? Object.values(
														firebaseBidUsersData
												  )[
														firebaseBidUsersDataLength -
															1
												  ].coins_used
												: Object.values(
														firebaseBidUsersData
												  )[
														firebaseBidUsersDataLength -
															2
												  ].coins_used} */}
											1
										</Text>
									</Button>
									<Button onClick={handleBid}>
										<IconBox>
											<Image
												
												className="img-class"
												alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
											/>
										</IconBox>
										{buttonLoader ? (
											<ButtonLoader />
										) : (
											<Text
												className="button-text"
												style={{
													color: "#747474",
												}}
											>
												1
											</Text>
										)}
									</Button>
								</ButtonBox>
								{/* <Text className="bidd-status-text">
									You have bidden{" "}
									<Text
										style={{
											color: "rgb(255, 223, 23)",
											// fontSize: "15px",
											marginLeft: "5px",
										}}
									>
										{ownBidData?.user_pk === user_pk &&
											ownBidData.coins_used + 1}{" "}
										times
									</Text>
								</Text> */}
							</Bottom>
						</Container>
					</Wrapper>
				</LiveBiddingContainer>
			) : !isLoading && Object.keys(liveBid).length === 0 ? (
				<EmptyPage text="No Bids taking at this time" />
			) : (
				<PageLoader />
			)}
		</>
	);
}

const LiveBiddingContainer = styled.div`
	padding: 3% 0;
	height: 100vh;
	position: relative;
	@media all and (max-width: 768px) {
		background: #26221f;
	}
`;
const Wrapper = styled.div`
	padding: 4% 5%;
	overflow: hidden;
	border-radius: 5px;
	background: url("https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/images/01-03-2023/bidding/live-bidding-bg.png");
	@media all and (max-width: 768px) {
		background: #26221f;
		padding: 0;
	}
`;
const Container = styled.div``;
const Top = styled.div`
	display: flex;
	justify-content: space-between;
	@media all and (max-width: 768px) {
		flex-direction: column;
	}
`;
const TopLeft = styled.div`
	width: 25%;
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const ProductBox = styled.div`
	background: #fff;
	border-radius: 5px;
	padding: 15% 14%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 8%;
	text-align: center;

	@media all and (max-width: 768px) {
		padding: 3%;
		box-sizing: border-box;
		margin-bottom: 3%;
		flex-direction: row;
	}
`;
const ProductImage = styled.div`
	width: 50%;
	@media all and (max-width: 768px) {
		width: 40%;
	}
`;
const Image = styled.img``;
const ProdcutTextBox = styled.div`
	@media all and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		margin-left: 3%;
	}
`;
const ProductName = styled.h3`
	color: #747474;
	font-size: 18px;
	font-family: medium;
	margin: 10% 0;
	@media all and (max-width: 1080px) {
		font-size: 15px;
	}
	@media all and (max-width: 980px) {
		font-size: 13px;
	}
	@media all and (max-width: 768px) {
		margin: 3% 0;
		font-size: 20px;
		margin-bottom: 5%;
	}
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;
const ProductPrice = styled.div`
	display: flex;
	color: #747474;
	font-size: 16px;
	font-family: regular;
	@media all and (max-width: 1080px) {
		font-size: 13px;
	}
	@media all and (max-width: 768px) {
		font-size: 18px;
	}
	@media all and (max-width: 640px) {
		font-size: 13px;
	}
	@media all and (max-width: 420px) {
		font-size: 12px;
	}
`;
const Text = styled.span`
	font-family: medium;
	&.product-name {
		color: #191919;
		margin-left: 5px;
	}
	&.ends-in {
		color: #efefef;
		font-family: medium;
		font-size: 22px;
		@media all and (max-width: 1080px) {
			font-size: 18px;
		}
		@media all and (max-width: 420px) {
			font-size: 13px;
		}
	}
	&.bid-time {
		color: #ffdf17;
		font-family: bold;
		font-size: 35px;
		margin-bottom: 5px;
		@media all and (max-width: 1080px) {
			font-size: 28px;
		}
		@media all and (max-width: 768px) {
			font-size: 20px;
			margin-bottom: 0;
		}
		@media all and (max-width: 420px) {
			font-size: 17px;
		}
	}
	&.bid-time-type {
		color: #efefef;
		font-family: medium;
		font-size: 20px;
		@media all and (max-width: 1080px) {
			font-size: 16px;
		}
		@media all and (max-width: 768px) {
			display: none;
		}
	}
	&.profile-name {
		font-size: 18px;
		font-family: medium;
		color: #fff;
		margin-bottom: 5px;
		@media all and (max-width: 1080px) {
			font-size: 16px;
		}
		@media all and (max-width: 420px) {
			font-size: 14px;
			/* white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 130px; */
		}
	}
	&.last-bid-time {
		font-size: 15px;
		font-family: regular;
		color: #fff;
		@media all and (max-width: 1080px) {
			font-size: 13px;
		}
		@media all and (max-width: 420px) {
			font-size: 11px;
		}
	}
	&.coin-count {
		color: #ffdf17;
		font-family: medium;
		font-size: 20px;
		@media all and (max-width: 1080px) {
			font-size: 16px;
		}
		@media all and (max-width: 420px) {
			font-size: 14px;
		}
	}
	&.bid-remaining {
		color: #ffdf17;
		font-family: medium;
		font-size: 20px;
		display: flex;
		margin-left: 20px;
		align-items: center;
		@media all and (max-width: 768px) {
			font-size: 16px;
		}
	}
	&.bid-remaining-time {
		margin-left: 5px;
		font-family: regular;
		font-size: 17px;
		color: #efefef;
	}
	&.button-text {
		color: rgb(255, 223, 23);
		font-size: 15px;
	}
	&.bidd-status-text {
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 15px;
		@media all and (max-width: 420px) {
			font-size: 12px;
		}
	}
`;
const TimeBox = styled.div`
	background: rgba(255, 255, 255, 0.06);
	border-radius: 5px;
	padding: 10%;
	text-align: left;
	@media all and (max-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media all and (max-width: 768px) {
		padding: 3%;
	}
`;
// const TimeStatusBox = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	margin-top: 7%;
// 	@media all and (max-width: 768px) {
// 		margin-top: 0;
// 		width: 23%;
// 		margin-left: 5%;
// 	}
// 	@media all and (max-width: 640px) {
// 		width: 30%;
// 	}
// 	@media all and (max-width: 420px) {
// 		width: 35%;
// 	}
// `;
// const Box = styled.div`
// 	display: flex;
// 	flex-direction: column;
// `;
const TopRight = styled.div`
	background: rgba(255, 255, 255, 0.1);
	/* position: relative; */
	border-radius: 5px;
	width: 73%;
	/* padding: 2%; */
	@media all and (max-width: 768px) {
		width: 100%;
		margin-top: 3%;
		height: 180px;
	}
	@media all and (max-width: 640px) {
		height: 250px;
	}
	@media all and (max-width: 550px) {
		height: 290px;
	}
	@media all and (max-width: 480px) {
		height: 320px;
	}
	@media all and (max-width: 420px) {
		height: 360px;
	}
	@media all and (max-width: 360px) {
		height: 380px;
	}
`;
const UsersList = styled.div`
	max-height: 390px;
	overflow-y: scroll;
	height: 100%;
	/* position: relative; */

	&::-webkit-scrollbar {
		display: none;
	}
	@media all and (max-width: 1080px) {
		max-height: 285px;
	}
	@media all and (max-width: 980px) {
		max-height: 245px;
	}
	@media all and (max-width: 768px) {
		max-height: 340px;
		min-height: -webkit-fill-available;
	}
`;
const UserListItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 2%;
	margin-bottom: 3%;
	&:last-child {
		margin-bottom: 0;
		padding: 2% 4%;
		@media all and (max-width: 420px) {
			padding: 2% 6%;
		}
	}
	@media all and (max-width: 420px) {
		margin-bottom: 7%;
	}
`;

const UserLeft = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ProfilePic = styled.div`
	width: 45px;
	overflow: hidden;
	border-radius: 50%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	@media all and (max-width: 1080px) {
		width: 40px;
		height: 40px;
	}
`;
const ProfileNameBox = styled.div`
	display: flex;
	flex-direction: column;
`;
const UserRight = styled.div`
	display: flex;
	align-items: center;
`;
const CoinBox = styled.div`
	width: 25px;
	height: 25px;
	margin-right: 5px;
	@media all and (max-width: 1080px) {
		width: 20px;
		height: 20px;
	}
`;
const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	@media all and (max-width: 1080px) {
		margin-top: 35px;
	}
	@media all and (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		background: #26221f;
		padding: 15px 15px;
	}
`;
const ButtonBox = styled.div`
	display: flex;
	margin-bottom: 20px;
`;
const Button = styled.div`
	background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 180px;
	cursor: pointer;
	height: 50px;
	border-radius: 5px;
	@media all and (max-width: 980px) {
		width: 140px;
		height: 40px;
	}
	@media all and (max-width: 420px) {
		width: 120px;
		height: 35px;
	}
	&.small-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 58px;
		height: 50px;
		border-radius: 5px;
		border: 1px solid #fff79e;
		@media all and (max-width: 980px) {
			height: 40px;
		}
		@media all and (max-width: 420px) {
			height: 35px;
		}
	}
`;
const IconBox = styled.div`
	width: 10%;
	margin-right: 5px;
	&.small {
		/* width: 23px; */
		width: 30%;
		/* height: 23px; */
		/* @media all and (max-width: 420px) {
			height: 18px;
			width: 18px;
		} */
	}
`;

const BiddingTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
	max-width: 1350px;
	margin-bottom: 10px;
`;
const NewLeft = styled(Link)`
	display: flex;
	align-items: center;
`;
const CloseIconBox = styled.div`
	width: 25px;
	/* height: 20px; */
	padding: 1px 6px;
	margin-right: 10px;
	border: 2px solid #c4c4c4;
	border-radius: 5px;
	cursor: pointer;
`;
const CloseText = styled.span`
	font-size: 17px;
	font-family: medium;
	color: #c4c4c4;
`;
const NewRight = styled.div``;
const CoinBalance = styled.div`
	display: flex;
	align-items: center;
	border-radius: 50px;
	border: 1px solid #fff79e;
	background: #252525;
	padding: 5px 7px;
	cursor: pointer;
`;
const BalanceCoin = styled.div`
	width: 20px;
	height: 20px;
	@media all and (max-width: 768px) {
		width: 15px;
		height: 15px;
	}
`;
const BalanceText = styled.span`
	font-size: 18px;
	font-family: medium;
	color: #f4f4f4;
	@media all and (max-width: 768px) {
		font-size: 13px;
		margin-left: 5px;
	}
`;

const Triangle = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 50%;
	top: 50%;
	transform: translateY(-50%);

	z-index: 1;
	&.left {
		left: 1%;
		img {
			transform: rotate(180deg);
		}
	}
	&.right {
		right: 1%;
	}
	@media all and (max-width: 1080px) {
		width: 15px;
		height: 15px;
	}
	@media all and (max-width: 640px) {
		width: 10px;
		height: 10px;
	}
`;
const ProfileLetter = styled.span`
	color: #747474;
	font-size: 20px;
	font-family: bold;
	@media all and (max-width: 768px) {
		font-size: 15px;
	}
`;
