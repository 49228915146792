import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/404-error.json";

export default function PageNotFound(
	{
		// text,
		// inside,
		// buttonText,
		// onClick,
		// size,
	}
) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<Container>
			<Content>
				<Lottie options={defaultOptions} height={250} width={250} />
				<IconBox to="/">
					<img
						
						className="img-class"
						alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/404.svg"
					/>
				</IconBox>
			</Content>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	/* background-color: red; */
	align-items: center;
	justify-content: center;
	height: calc(100vh - 80px);
`;

const Content = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;
const IconBox = styled(Link)`
	width: 80%;
	/* cursor: pointer; */
`;
