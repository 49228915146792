import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ordersConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import Toast from "../../general/includes/Toast";

export default function OrderCancelletion() {
	const { state } = useContext(Context);
	const [reasonId, setReasonId] = useState("");
	const [modeId, setModeId] = useState("first");
	const [isDropdown, setDropdown] = useState(false);
	const [reasonText, setReasonText] = useState("");
	const [toastText, setToastText] = useState("");
	const [isToast, setToast] = useState(false);
	const location = useLocation();
	const params = useParams();
	const [reasons, setReasons] = useState([]);
	const [reason, setReason] = useState("");
	// const navigate = useNavigate();

	useEffect(() => {
		ordersConfig
			.get("order-cancellation-reason", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				if (res.data.StatusCode === 6000) {
					setReasons(res.data.data);
				}
			})
			.catch((err) => {
			});
	}, []);

	// for cancellation request
	function cancellOrder() {
		if (reasonText) {
			ordersConfig
				.post(
					`cancel-order/${location.search.slice(1)}/`,
					{ cancellation_reason: reason },
					{
						headers: {
							Authorization: `Bearer ${state.user_data.access_token}`,
						},
					}
				)
				.then((res) => {
					setToast(true);
					setToastText(res.data.data.message);
					// navigate("/orders");
				})
				.catch((err) => {
					setToast(true);
					setToastText(err.message);
					// navigate("/orders");
				});
		}
	}

	return (
		<>
			<Toast isToast={isToast} setToast={setToast} text={toastText} />
			<Title>
				{/* Addidas,  */}
				<Grey>{params.product_details}</Grey>
			</Title>
			<ReasonBox>
				<ReasonTitle>Reason for cancellation*</ReasonTitle>
				<DropDownBox onClick={() => setDropdown(!isDropdown)}>
					<SelectText>{reason ? reason : "Select Reason"}</SelectText>
					<IconBox>
						<Image   className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-down.svg" />
					</IconBox>
					{isDropdown && (
						<DropDown>
							{reasons?.map((item) => (
								<DropDownItem
									onClick={() => (
										setDropdown(!isDropdown),
										setReason(item.reason)
									)}
								>
									<Radio>
										{item.reason === reason && <Dot></Dot>}
									</Radio>
									<DropText>{item.reason}</DropText>
								</DropDownItem>
							))}
						</DropDown>
					)}
				</DropDownBox>
				<CommentBox>
					<SelectText>Comments*</SelectText>
					<TextArea
						placeholder="Write here"
						rows={10}
						value={reasonText}
						onChange={(e) => setReasonText(e.target.value)}
					/>
				</CommentBox>
			</ReasonBox>
			{/* <ModeBox>
        <ReasonTitle>Mode of Refund*</ReasonTitle>
        <ModeItem>
          <Radio>
            <Dot></Dot>
          </Radio>
          <Text>
            Original Payment Method <br />
            <GreyText>Refund will be processed within 8 days</GreyText>
          </Text>
        </ModeItem>
        <ModeItem>
          <Radio>
            <Dot></Dot>
          </Radio>
          <Text>
            Original Payment Method <br />
            <GreyText>Refund will be processed within 8 days</GreyText>
          </Text>
        </ModeItem>
      </ModeBox> */}
			<Button onClick={cancellOrder}>Request Cancelletion</Button>
		</>
	);
}

const Title = styled.h3`
	font-family: medium;
	font-size: 22px;
	color: #747474;
	margin-bottom: 20px;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
	@media all and (max-width: 420px) {
		font-size: 18px;
	}
`;
const Grey = styled.span`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;

const Button = styled.span`
	display: flex;
	align-items: center;
	background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
	padding: 15px 20px;
	border-radius: 5px;
	cursor: pointer;
	width: max-content;
	font-family: medium;
	font-size: 18px;
	text-align: center;
	margin-top: 10px;
	margin-left: auto;
	@media all and (max-width: 420px) {
		padding: 10px 20px;
		font-size: 14px;
	}
`;

const ReasonBox = styled.div`
	padding: 30px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	background: #fffdfa;
	margin-bottom: 20px;
	@media all and (max-width: 420px) {
		padding: 15px;
	}
`;
const ReasonTitle = styled.h3`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	margin-bottom: 20px;
`;
const DropDownBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	position: relative;
	margin-bottom: 30px;
	border-bottom: 1px solid #adadad;
	padding-bottom: 15px;
`;
const SelectText = styled.span`
	font-size: 16px;
	font-family: regular;
	color: #747474;
	text-transform: capitalize;
`;
const IconBox = styled.div`
	width: 20px;
	height: 20px;
	@media all and (max-width: 420px) {
		width: 15px;
		height: 15px;
	}
`;
const Image = styled.img``;
const CommentBox = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
`;
const TextArea = styled.textarea`
	border: 1px solid #e4e4e4;
	padding: 10px;
	margin-top: 10px;
	resize: vertical;
	color: #747474;
	font-size: 18px;
	font-family: regular;
	border-radius: 5px;
	@media all and (max-width: 420px) {
		font-size: 16px;
	}
`;
// const ModeBox = styled.div`
// 	padding: 30px;
// 	border: 1px solid #e4e4e4;
// 	border-radius: 5px;
// 	@media all and (max-width: 420px) {
// 		padding: 15px;
// 	}
// `;
// const ModeItem = styled.div`
// 	display: flex;
// 	align-items: center;
// 	margin-bottom: 20px;
// 	cursor: pointer;
// `;
const Radio = styled.div`
	border-radius: 50%;
	border: 1px solid #fedc0c;
	background: #fffdfa;
	height: 17px;
	width: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
`;
const Dot = styled.div`
	width: 11px;
	height: 11px;
	background: #fedc0c;
	border-radius: 50%;
`;
// const Text = styled.span`
// 	font-size: 16px;
// 	font-family: regular;
// 	color: #191919;
// 	line-height: 25px;
// 	@media all and (max-width: 420px) {
// 		font-size: 14px;
// 	}
// `;
// const GreyText = styled.span`
// 	font-size: 16px;
// 	font-family: regular;
// 	color: #747474;
// 	@media all and (max-width: 420px) {
// 		font-size: 13px;
// 	}
// `;

const DropDown = styled.div`
	position: absolute;
	top: 40px;
	left: 0;
	width: 100%;
	background: #fff;
	box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.04);
	border-radius: 0px 0px 6px 6px;
	padding: 20px;
`;
const DropDownItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
`;
const DropText = styled.div`
	font-size: 16px;
	font-family: regular;
	color: #191919;
	text-transform: capitalize;
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;
