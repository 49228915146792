import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import HomeLoader from "../content-loader/collection-banner/HomeLoader";
import { adsConfig } from "../../../apiConfig";
import { useNavigate } from "react-router-dom";

export default function CollectionSlider() {
  // const [spotlightSlider, setSpotlightSlider] = useState([]);
  const [collectionSlider, setCollectionSlider] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate()
  const settings = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };


  useEffect(() => {
    adsConfig
      .get("sliders/")
      .then((res) => {
        if (res.data.length > 0) {
          res.data.filter((item) => {
            if (item.slider_order_id === 1) {
              // setSpotlightSlider(item.slider_items);
            } else if (item.slider_order_id === 3) {
              setCollectionSlider(item.slider_items);
            }
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);


  const handleUrl = (url, id) => {
    if (url.includes("products")) {
      navigate("/products/all-products", {
        state: {
          url: url,
        },
      });
    } else if (
      url.includes("products") === false &&
      url.includes("product") === false
    ) {
      window.open(`${url}`, "_self");
    } else {
      navigate(`/product/${id}`, {
        state: {
          url: url,
        },
      });
    }
  };


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={(className, "button-next")}
        style={{ ...style, position: "absolute", zIndex: 10 }}
        onClick={onClick}
      >
        <img
          alt="Image"
          src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/left-arrow-grey.svg"
          className="img-class"
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={(className, "button-prev")}
        style={{ ...style, position: "absolute", zIndex: 10 }}
        onClick={onClick}
      >
        <img
          alt="Image"
          src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/left-arrow-grey.svg"
          className="img-class"
        />
      </div>
    );
  }

  return (
    <Wrapper className="wrapper">
      {!isLoading && collectionSlider.length > 0 ? (
        <BannerContainer>
          <Slider {...settings}>
            {collectionSlider.map((item, index) => {
              return (
                <img
                  fetchpriority="high"
				  
                  key={index}
                  onClick={() => {
                    // window.open(`${item.url}`, "_self");
                    handleUrl(item.url, item.id);
                  }}
                  className="img-class slider-img"
                  alt="Image"
                  src={item.item_image}
                />
              );
            })}
          </Slider>
        </BannerContainer>
      ) : (
        <HomeLoader />
      )}
    </Wrapper>
  );
}
const Wrapper = styled.section`
  padding-bottom: 3%;
  @media all and (max-width: 640px) {
    padding-bottom: 4%;
  }
`;
const BannerContainer = styled.section`
  overflow: hidden;
  border-radius: 5px;
  .slick-track {
    display: flex;
    /* width: 100vw !important; */
  }
  .slick-slide {
	margin-left: .5%;
    height: 350px;
    div {
      height: 100%;
    }
    @media all and (max-width: 1080px) {
      height: 280px;
    }
    @media all and (max-width: 980px) {
      height: 220px;
    }
    @media all and (max-width: 768px) {
      height: 200px;
    }
    @media all and (max-width: 640px) {
      height: 140px;
    }
    @media all and (max-width: 420px) {
      height: 110px;
    }
  }
  .slick-slider {
    position: relative;
    .button-prev {
      display: block;
      height: 50px;
      cursor: pointer;
      background: #fff;
      width: 50px;
      border-radius: 50%;
      top: 50%;
      bottom: 50%;
      padding: 15px;
      transform: translateY(-50%);
      left: 10px;
      @media all and (max-width: 768px) {
        height: 30px;
        width: 30px;
        padding: 7px;
      }
      @media all and (max-width: 640px) {
        height: 20px;
        width: 20px;
        padding: unset;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
      img {
		
        @media all and (max-width: 640px) {
          height: 10px;
          width: 10px;
        }
      }
    }
    .button-next {
      display: block;
      cursor: pointer;
      height: 50px;
      background: #fff;
      width: 50px;
      border-radius: 50%;
      top: 50%;
      padding: 15px;
      bottom: 50%;
      transform: translateY(-50%);
      right: 10px;
      @media all and (max-width: 768px) {
        height: 30px;
        width: 30px;
        padding: 7px;
      }
      @media all and (max-width: 640px) {
        height: 24px;
        width: 24px;
        padding: unset;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
      img {
        @media all and (max-width: 640px) {
          height: 10px;
          width: 10px;
        }
      }
    }
  }
  .slick-arrow {
    /* display: none !important; */
  }
  
`;
