import React from "react";
import styled from "styled-components";

export default function EmptyPage({ text }) {
	return (
		<Container>
			<Content>
				<IconBox>
					<img
						
						className="img-class"
						alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/no-events.gif"
					/>
				</IconBox>
				<EmptyTitle>{text}</EmptyTitle>
			</Content>
		</Container>
	);
}

const EmptyTitle = styled.h3`
	color: #000;
	text-align: center;
	margin-bottom: 20px;
	font-size: 18px;
	font-family: "medium";
	@media all and (max-width: 768px) {
		/* margin-top: 15px; */
		font-size: 20px;
	}
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 80px);
	width: 100%;
`;

const Content = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const IconBox = styled.div`
	width: 200px;
`;
// const Button = styled.div`
// 	background: linear-gradient(
// 		90deg,
// 		rgba(252, 245, 157, 1) 11%,
// 		rgba(251, 219, 3, 1) 100%
// 	);
// 	padding: 12px 35px;
// 	border-radius: 5px;
// 	cursor: pointer;
// 	display: inline-block;
// 	font-family: medium;
// 	font-size: 14px;
// 	color: #747474;
// `;
