import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Brand({ ads }) {
  const navigate = useNavigate();

  const handleUrl = (url, id) => {
    if (url.includes("products")) {
      navigate("/products/all-products", {
        state: {
          url: url,
        },
      });
    } else if (
      url.includes("products") === false &&
      url.includes("product") === false
    ) {
      window.open(`${url}`, "_self");
    } else {
      navigate(`/product/${id}`, {
        state: {
          url: url,
        },
      });
    }
  };

  return (
    ads.length > 0 && (
      <Container>
        <Wrapper className="wrapper">
          <Title>Top Categories</Title>
          <BrandContainer>
            {ads.map((item, index) => {
              return (
                <BrandBox
                  key={index}
                  onClick={() => {
                    // window.open(`${item.url}`, "_self");
                    handleUrl(item.url, item.id);
                  }}
                >
                  <Top>
                    <img
                      className="img-class"
                      alt="Image"
                      src={item.item_image}
                    />
                  </Top>
                  <Bottom>
                    <Name>{item.title}</Name>
                    <Off>{item.description}</Off>
                  </Bottom>
                </BrandBox>
              );
            })}
          </BrandContainer>
        </Wrapper>
      </Container>
    )
  );
}

const Name = styled.h3`
  font-family: medium;
  font-size: 22px;
  color: #ff9239;
  /* padding: 15px 0; */
  @media all and (max-width: 1280px) {
    font-size: 18px;
    padding: 5px 0;
  }
  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;
const Off = styled.h2`
  font-family: regular;
  font-size: 18px;
  color: #3f414d;
  @media all and (max-width: 1280px) {
    font-size: 15px;
  }
  @media all and (max-width: 640px) {
    font-size: 11px;
  }
`;

//

const Container = styled.div`
  /* margin-top: 20px; */
  padding-bottom: 3%;
  @media all and (max-width: 640px) {
    padding-bottom: 4%;
  }
`;
const Wrapper = styled.div``;
const Title = styled.h3`
  font-family: medium;
  font-size: 24px;
  color: #191919;
  margin-bottom: 15px;
  @media all and (max-width: 980px) {
    /* font-size: 18px; */
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) {
    font-size: 18px;
  }
  @media all and (max-width: 420px) {
    font-size: 15px;
    margin-bottom: 5px;
  }
`;
const BrandContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const BrandBox = styled.div`
  width: 32%;
  padding: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 25px;
  @media all and (max-width: 768px) {
    margin-bottom: 15px;
    width: 100%;
  }
`;
const Top = styled.div``;
const Bottom = styled.div`
  text-align: center;
`;
