import React from "react";
import { Route, Routes } from "react-router-dom";
import CoinPurchaseStatus from "../../includes/bidding/CoinPurchaseStatus";
import PurchaseCoin from "../../includes/bidding/PurchaseCoin";
import TransferToCoin from "../../includes/bidding/TransferToCoin";
import PageNotFound from "../../screens/PageNotFound";

export default function CoinRouter() {
	return (
		<Routes>
			<Route path="*" element={<PageNotFound />} />
			<Route element={<PurchaseCoin />} path="/purchase-coins" />
			<Route element={<CoinPurchaseStatus />} path="/purchases/coins/" />
			<Route element={<TransferToCoin />} path="/transfer-to-wallet" />
		</Routes>
	);
}
