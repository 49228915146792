// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCp_1Yr_c7So_TgSPojtCPdyJKR1WTD4vI",
	authDomain: "oneruppee.firebaseapp.com",
	projectId: "oneruppee",
	databaseURL: "https://oneruppee-default-rtdb.firebaseio.com/",
	storageBucket: "oneruppee.approductot.com",
	messagingSenderId: "264773847099",
	appId: "1:264773847099:web:201e55b0ab36bf47bd741e",
	measurementId: "G-J3L2H6KKZ6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);

export { auth, db, rtdb, app };
