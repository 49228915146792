import React, { useEffect } from "react";
import styled from "styled-components";

export default function Privacypolicy() {
	//when onload the page ,scroll top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Wrapper>
				<Title>Privacy Policy</Title>
				<Content>
					This privacy policy sets out how ONERUPPEE from ORGECOM
					TRADE PRIVATE LIMITED uses and protects any information that
					you give ORGECOM TRADE PRIVATE LIMITED when you use this
					website.
				</Content>
				<Content>
					ONERUPPEE from ORGECOM TRADE PRIVATE LIMITED is committed to
					ensuring that your privacy is protected. Should we ask you
					to provide certain information by which you can be
					identified when using this website, and then you can be
					assured that it will only be used in accordance with this
					privacy statement.
				</Content>
				<Content>
					ONERUPPEE from ORGECOM TRADE PRIVATE LIMITED may change this
					policy from time to time by updating this page. You should
					check this page from time to time to ensure that you are
					happy with any changes.
				</Content>
				<SubTitle>We may collect the following information:</SubTitle>
				<List>
					<ListItem>Name and job title</ListItem>
					<ListItem>
						Contact information including email address
					</ListItem>
					<ListItem>
						Demographic information such as postcode, preferences
						and interests
					</ListItem>
					<ListItem>
						Other information relevant to customer surveys and/or
						offers
					</ListItem>
				</List>
				<SubTitle>What we do with the information we gather</SubTitle>
				<Content>
					We require this information to understand your needs and
					provide you with a better service, and in particular for the
					following reasons:
				</Content>
				<List>
					<ListItem>Internal record keeping.</ListItem>
					<ListItem>
						We may use the information to improve our products and
						services.
					</ListItem>
					<ListItem>
						We may periodically send promotional emails about new
						products, special offers or other information which we
						think you may find interesting using the email address
						which you have provided.
					</ListItem>
					<ListItem>
						From time to time, we may also use your information to
						contact you for market research purposes. We may contact
						you by email, phone, fax or mail. We may use the
						information to customise the website according to your
						interests.
					</ListItem>
				</List>
				<Content>
					We are committed to ensuring that your information is
					secure. In order to prevent unauthorised access or
					disclosure we have put in suitable measures.
				</Content>
				<SubTitle>How we use cookies</SubTitle>
				<Content>
					A cookie is a small file which asks permission to be placed
					on your computer's hard drive. Once you agree, the file is
					added and the cookie helps analyses web traffic or lets you
					know when you visit a particular site. Cookies allow web
					applications to respond to you as an individual. The
					webapplication can tailor its operations to your needs,
					likes and dislikes by gathering and remembering information
					about your preferences.
				</Content>
				<Content>
					We use traffic log cookies to identify which pages are being
					used. This helps us analyses data about webpage traffic and
					improve our website in order to tailor it to customer needs.
					We only use this information for statistical analysis
					purposes and then the data is removed from the system.
				</Content>
				<Content>
					Overall, cookies help us provide you with a better website,
					by enabling us to monitor which pages you find useful and
					which you do not. A cookie in no way gives us access to your
					computer or any information about you, other than the data
					you choose to share with us.
				</Content>
				<Content>
					You can choose to accept or decline cookies. Most web
					browsers automatically accept cookies, but you can usually
					modify your browser setting to decline cookies if you
					prefer. This may prevent you from taking full advantage of
					the website.
				</Content>
				<SubTitle>Controlling your personal information</SubTitle>
				<Content>
					You may choose to restrict the collection or use of your
					personal information in the following ways:
				</Content>
				<List>
					<ListItem>
						whenever you are asked to fill in a form on the website,
						look for the box that you can click to indicate that you
						do not want the information to be used by anybody for
						direct marketing purposes
					</ListItem>
					<ListItem>
						if you have previously agreed to us using your personal
						information for direct marketing purposes, you may
						change your mind at any time by writing to or emailing
						us at hello@oneruppee.com
					</ListItem>
				</List>
				<Content>
					We will not sell, distribute or lease your personal
					information to third parties unless we have your permission
					or are required by law to do so. We may use your personal
					information to send you promotional information about third
					parties which we think you may find interesting if you tell
					us that you wish this to happen.
				</Content>
				<Content>
					If you believe that any information we are holding on you is
					incorrect or incomplete, please write to or email us as soon
					as possible, at the above address. We will promptly correct
					any information found to be incorrect.
				</Content>
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	padding: 3% 0;
	min-height: calc(100vh - 420px);
	@media all and (max-width: 640px) {
		padding: 8% 0;
	}
`;
const Wrapper = styled.div`
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	text-align: left;
`;
const Title = styled.h3`
	font-size: 22px;
	font-family: medium;
	color: #191919;
	padding-bottom: 20px;
	border-bottom: 1px solid #c4c4c4;
	margin-bottom: 30px;
	text-align: center;
	@media all and (max-width: 640px) {
		font-size: 19px;
		margin-bottom: 25px;
		padding-bottom: 15px;
	}
`;
const Content = styled.p`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	line-height: 1.2em;
	letter-spacing: 0.3px;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 25px;
	}
`;
const SubTitle = styled.h3`
	font-size: 20px;
	font-family: regular;
	color: #191919;
	margin-bottom: 15px;
	@media all and (max-width: 640px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;
const List = styled.ul`
	padding-left: 3%;
	margin-bottom: 35px;
	@media all and (max-width: 640px) {
		margin-bottom: 25px;
	}
`;
const ListItem = styled.li`
	font-size: 18px;
	font-family: regular;
	color: #747474;
	list-style: disc;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	@media all and (max-width: 640px) {
		font-size: 14px;
		margin-bottom: 15px;
	}
`;
