import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../context/store";
import { paymentsConfig } from "../../../apiConfig";
import PageLoader from "../../screens/PageLoader";
import { useNavigate } from "react-router-dom";

export default function Wallet({}) {
	const { state } = useContext(Context);
	// const [coinBalance, setCoinBalance] = useState("");
	const [walletBalance, setWalletBalance] = useState("");
	const [transaction, setTransaction] = useState([]);
	const [isLoading, setLoading] = useState(true);
	// const location = useLocation();
	

	useEffect(() => {
		// coin and wallet ballence
		paymentsConfig
			.get("view-balance", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				if (res.data.StatusCode === 6000) {
					setWalletBalance(res.data.data[0].wallet_balance);
				}
			})
			.catch((err) => {});

		// for view transaction history
		paymentsConfig
			.get(
				"view-transaction-history/?transaction_type=coin_transaction",
				{
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				}
			)
			.then((res) => {
				const { data, StatusCode } = res.data;

				if (StatusCode === 6000) {
					setTransaction(data.wallet_transaction_data);
				
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [state.user_data.access_token]);

	return !isLoading ? (
		<Container>
			<Top>
				<Title>My Wallet</Title>
				<CoinCount>₹{walletBalance ? walletBalance : "0.00"}</CoinCount>
				<Descritpion>Purchase products from wallet amount</Descritpion>
			</Top>
			<Bottom>
				<BottomTitle>Transactions</BottomTitle>
				{transaction.length > 0 ? (
					<BottomList>
						{transaction.map((item, index) => {
							return (
								item.payment_type !== "credited_to_wallet" && (
									<ListItem key={index}>
										<Left>
											<BankImg>
												<Image
													
													className="img-class"
													// alt="Image" src={item.bank_logo}
													alt="Image" src={
														item.payment_type ===
															"bidding_refund" ||
														item.payment_type ===
															"credited_to_wallet" ||
														item.payment_type ===
															"debited_from_coins"
															? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-arrow.svg"
															: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/red-arrow.svg"
													}
												/>
											</BankImg>
											<Box>
												<Name>
													{item.payment_type ===
														"bidding_refund" ||
													item.payment_type ===
														"credited_to_wallet" ||
													item.payment_type ===
														"debited_from_coins"
														? "Credited"
														: "Debited"}
												</Name>
												<Time>
													{new Date(item.date_added)
														.toUTCString()
														.slice(0, -4)}
												</Time>
											</Box>
										</Left>
										<Right>
											<CoinBox>
												<Count>
													<CoinIconBox>
														<Image
															
															className="img-class"
															alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg"
														/>
													</CoinIconBox>
													<Text
														className="coin"
														style={{
															color:
																item.payment_type ===
																"bidding_deduction"
																	? "#E02B1D"
																	: "#009262",
														}}
													>
														{item.payment_type ===
														"bidding_deduction"
															? item.transfer_amount.slice(
																	0,
																	1
															  )
															: item.transfer_amount}
													</Text>
												</Count>
												{/* <Status> */}
												{/* <IconBox>
													<Image
														
														className="img-class"
														alt="Image" src={
															item.payment_type ===
															"bidding_deduction"
																? red
																: green
														}
													/>
												</IconBox> */}
												{/* <SpendText>
													{item.payment_type ===
													"bidding_deduction"
														? "Bidding"
														: "Purchase"}
												</SpendText> */}
												{/* </Status> */}
											</CoinBox>
										</Right>
									</ListItem>
								)
							);
						})}
					</BottomList>
				) : (
					<NoTransaction>
						<NoIcon>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/no-events.gif"
							/>
						</NoIcon>
						<NoText>
							Sorry, You have <br />
							no transaction found
						</NoText>
					</NoTransaction>
				)}
			</Bottom>
		</Container>
	) : (
		<PageLoader />
	);
}

const Container = styled.div`
	padding: 3% 0;
`;
const Top = styled.div`
	background: #167dff;
	border-radius: 5px;
	margin-bottom: 30px;
	padding: 70px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	@media all and (max-width: 640px) {
		padding: 40px 30px;
	}
`;
const Title = styled.h3`
	font-family: bold;
	font-size: 20px;
	color: #fff;
`;
const CoinCount = styled.h3`
	font-family: bold;
	margin: 30px 0;
	font-size: 70px;
	color: #fff;
	@media all and (max-width: 640px) {
		font-size: 50px;
		margin: 20px 0;
	}
`;
const Descritpion = styled.p`
	font-family: regular;
	font-size: 18px;
	color: #fff;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
const Bottom = styled.div``;
const BottomTitle = styled.h3`
	font-size: 22px;
	font-family: regular;
	color: #191919;
	margin-bottom: 20px;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
`;
const BottomList = styled.div``;
const ListItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
`;
const BankImg = styled.div`
	width: 50px;
	height: 50px;
	margin-right: 15px;
	border-radius: 50%;
	background: #f7f7f7;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	img {
		width: 40%;
		height: 40%;
	}
	@media all and (max-width: 640px) {
		width: 40px;
		height: 40px;
	}
`;
const Image = styled.img``;
const Box = styled.div``;
const Name = styled.h3`
	font-size: 20px;
	font-family: medium;
	color: #191919;
	margin-bottom: 5px;
	@media all and (max-width: 640px) {
		margin-bottom: 2px;
		font-size: 16px;
	}
`;
const Time = styled.span`
	font-size: 16px;
	font-family: regular;
	color: #747474;
	margin-top: 5px;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
const Right = styled.div``;
const CoinBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;
const Count = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
`;
// const IconBox = styled.div`
// 	width: 13px;
// 	margin-right: 8px;
// 	@media all and (max-width: 640px) {
// 		width: 10px;
// 		margin-right: 5px;
// 	}
// `;
const CoinIconBox = styled.div`
	width: 25px;
	margin-right: 8px;
	@media all and (max-width: 640px) {
		margin-right: 5px;
		width: 20px;
	}
`;
const Text = styled.span`
	font-family: medium;
	font-size: 18px;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
`;
// const Status = styled.div`
// 	display: flex;
// 	align-items: center;
// `;
// const SpendText = styled.span`
// 	font-family: regular;
// 	font-size: 16px;
// 	color: #747474;
// 	@media all and (max-width: 640px) {
// 		font-size: 14px;
// 	}
// `;

const NoTransaction = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: 60px 20px;
`;
const NoIcon = styled.div`
	width: 40%;
	/* margin-bottom: 20px; */
	/* @media all and (max-width: 640px) {
		width: 13%;
	} */
`;
const NoText = styled.p`
	font-size: 20px;
	font-family: "medium";
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
`;
