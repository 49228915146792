import React, { useContext} from "react";
import styled from "styled-components";
import { Context } from "../context/store";
import { Link, useNavigate } from "react-router-dom";

export default function MobileMenu({ isMenu, setMenu }) {
	const { state, dispatch } = useContext(Context);

	const navigate = useNavigate();


	const handleLogin = () => {
		if (state.user_data.is_verified) {
			dispatch({
				type: "UPDATE_USER_DATA",
				user_data: {
					...state.user_data,
					is_verified: false,
					access_token: "",
					phone: "",
					country: "",
					name: "",
					email: "",
					gender: "",
					user_pk: null,
				},
			});
			setMenu(false);
		} else {
			navigate(`/login`);
			setMenu(false);
		}
	};
	return (
		<>
			<Overlay
				onClick={() => setMenu(false)}
				style={{
					display: isMenu ? "block" : "none",
					opacity: isMenu ? 1 : 0,
				}}
			></Overlay>
			<Container isMenu={isMenu}>
				<Top>
					<Logo>
					
					</Logo>
					<LogoTitle>
						<Image
							
							className="img-class"
							alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/mobile-header.svg"
						/>
					</LogoTitle>
				</Top>
				<Middle>
					<ButtonBox>
						<TabTextBox
							to="/profile"
							onClick={() => setMenu(false)}
						>
							<IconBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/user.svg"
								/>
							</IconBox>
							<TabText>Profile</TabText>
						</TabTextBox>
						<LoginButton onClick={handleLogin}>
							<ButtonText>
								{state.user_data.is_verified
									? "Logout"
									: "Sign Up"}
							</ButtonText>
						</LoginButton>
					</ButtonBox>
					<TabTextBoxLink to="/orders" onClick={() => setMenu(false)}>
						<IconBox>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/orders.svg" />
						</IconBox>
						<TabText>My Orders</TabText>
					</TabTextBoxLink>
					<TabTextBoxLink
						to="/wishlist"
						onClick={() => setMenu(false)}
					>
						<IconBox>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/black-heart.svg" />
						</IconBox>
						<TabText>Wishlist</TabText>
					</TabTextBoxLink>
					<TabTextBoxLink
						to="/profile/wallet"
						onClick={() => setMenu(false)}
					>
						<IconBox>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/wallet.svg" />
						</IconBox>
						<TabText>Wallet</TabText>
					</TabTextBoxLink>
					<TabTextBoxLink
						to="/profile/coins"
						onClick={() => setMenu(false)}
					>
						<IconBox>
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/coin.svg" />
						</IconBox>
						<TabText>Coin</TabText>
					</TabTextBoxLink>
				</Middle>
			</Container>
		</>
	);
}

const Container = styled.div`
	height: 100vh;
	width: 85%;
	position: fixed;
	top: 0;
	background: #fff;
	transition: 0.4s ease;
	left: ${({ isMenu }) => (isMenu ? 0 : "-5990px")};
	z-index: 1000;
	padding: 25px 20px;
`;

const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	transition: 0.2s ease-in;
	top: 0;
	left: 0;
	z-index: 999;
	transition: 0.3s ease-in;
`;

const Top = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 40px;
`;
const Logo = styled.h1`
	width: 10%;
	margin-right: 3%;
	@media all and (max-width: 640px) {
		width: 17%;
	}
`;
const Image = styled.img``;
const LogoTitle = styled.h1`
	width: 45%;
	@media all and (max-width: 640px) {
		width: 55%;
	}
`;
const Middle = styled.div`
	margin-bottom: 40px;
`;
const ButtonBox = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 22px;
`;
const TabTextBox = styled(Link)`
	display: flex;
	align-items: center;
	/* &:last-child { */
	/* margin-top: 22px;
  } */
`;
const TabTextBoxLink = styled(Link)`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;
const IconBox = styled.div`
	width: 20px;
	margin-right: 15px;
`;
const TabText = styled.span`
	font-size: 18px;
	font-family: medium;
	cursor: pointer;
	color: #0a0a0a;
	@media all and (max-width: 640px) {
		font-size: 15px;
	}
`;
const LoginButton = styled.div`
	background: linear-gradient(
		90deg,
		rgba(252, 245, 157, 1) 11%,
		rgba(251, 219, 3, 1) 100%
	);
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	font-family: medium;
	font-size: 14px;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 13px;
	}
`;
const ButtonText = styled.h3``;



