import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";

export default function Footer() {
  return (
    <>
      <FooterContainer
      // ref={ref}
      >
        <Wrapper className="wrapper">
          <Top>
            <FooterBox>
              <FooterBoxTitle>Customer Policies</FooterBoxTitle>
              <FlexBox>
                <FooterLink to="/about">About Us</FooterLink>
                <FooterLink to="/contact">Contact Us</FooterLink>
                <FooterLink to="/legal-aspects/terms-and-conditions">
                  Terms & Conditions
                </FooterLink>
                <FooterLink to="/legal-aspects/privacy-policy">
                  Privacy Policy
                </FooterLink>
                <FooterLink to="/legal-aspects/shipping">
                  Shipping & Delivery Policy
                </FooterLink>
                {/* <FooterLink to="/legal-aspects/delivery-and-returns">
									Delivery & Return
								</FooterLink> */}
                <FooterLink to="/legal-aspects/cancellation">
                  Cancellation & Refund Policy
                </FooterLink>
              </FlexBox>
            </FooterBox>
            <FooterBox>
              <FooterBoxTitle>Follow us on</FooterBoxTitle>
              <IconContainer className="social">
                <IconBox>
                  <img className="img-class" alt="Image" src={facebook} />
                </IconBox>
                <IconBox>
                  <img className="img-class" alt="Image" src={instagram} />
                </IconBox>
                <IconBox>
                  <img className="img-class" alt="Image" src={twitter} />
                </IconBox>
                <IconBox>
                  <img className="img-class" alt="Image" src={linkedin} />
                </IconBox>
              </IconContainer>
            </FooterBox>
            <FooterBox>
              <FooterBoxTitle>Download the app</FooterBoxTitle>
              <IconContainer className="app">
                <AppBox>
                  <AppIconBox className="app">
                    <img
                      className="img-class"
                      alt="Image"
                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/appstore.svg"
                    />
                  </AppIconBox>
                  <TextBox>
                    <Small>GET IT ON</Small>
                    <Big>App Store</Big>
                  </TextBox>
                </AppBox>
                <AppBox>
                  <AppIconBox className="app">
                    <img
                      className="img-class"
                      alt="Image"
                      src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/playstore.svg"
                    />
                  </AppIconBox>
                  <TextBox>
                    <Small>GET IT ON</Small>
                    <Big>App Store</Big>
                  </TextBox>
                </AppBox>
              </IconContainer>
            </FooterBox>
            <FooterBox>
              <FooterBoxTitle>Office Address</FooterBoxTitle>
              <Address>28/569, THAKIDIYIL BUILDING,</Address>
              <Address>AISWARYA JUNCTION,</Address>
              <Address>KATTAPPANA, IDUKKI, KERALA - 685508</Address>
            </FooterBox>
          </Top>
          <Bottom>
            <Span>&#169; 2023 Oneruppee. all rights reserved.</Span>
          </Bottom>
        </Wrapper>
      </FooterContainer>
    </>
  );
}

const FooterContainer = styled.footer`
  padding: 3% 0 2%;
  background-color: #1e1e1e;
  /* position: absolute;
	width: 100%;
	left: 0;
	bottom: 0; */
  @media all and (max-width: 768px) {
    padding: 6% 0 4%;
  }
`;
const Wrapper = styled.div``;
const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const FooterBoxTitle = styled.h3`
  font-size: 23px;
  font-family: medium;
  color: #f7f7f7;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    font-size: 20px;
  }
  @media all and (max-width: 980px) {
    font-size: 18px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 15px;
  }
  @media all and (max-width: 420px) {
    font-size: 16px;
  }
`;
const FooterLink = styled(Link)`
  font-size: 18px;
  font-family: regular;
  color: #f7f7f7;
  /* margin-left: 15px; */
  margin-bottom: 17px;
  cursor: pointer;
  &:last-child {
    padding-right: 0;
    border: none;
  }
  @media all and (max-width: 1080px) {
    font-size: 18px;
  }
  @media all and (max-width: 980px) {
    font-size: 15px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 12px;
    margin-left: 12px;
    border-right: 1px solid #f7f7f7;
    padding-right: 10px;
  }
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;
const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    align-items: flex-end;
    text-align: right;
    @media all and (max-width: 768px) {
      align-items: unset;
      text-align: unset;
    }
  }
  @media all and (max-width: 768px) {
    margin-bottom: 30px;
  }
  &:nth-child(2) {
    text-align: center;

    @media all and (max-width: 768px) {
      text-align: left;
    }
  }
`;
const IconBox = styled.div`
  background-color: #1e1e1e;
  border-radius: 50%;
  padding: 10px;
  height: 50px;
  border: 1px solid #adadad;
  width: 50px;
  cursor: pointer;
  &:first-child {
    margin-right: 20px;
    @media all and (max-width: 1080px) {
      margin-right: 10px;
    }
  }
  @media all and (max-width: 1080px) {
    height: 40px;
    width: 40px;
  }
  @media all and (max-width: 980px) {
    height: 30px;
    width: 30px;
    padding: 5px;
  }
`;
const Bottom = styled.div`
  text-align: center;
  margin-top: 30px;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.social {
    div {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      @media all and (max-width: 1280px) {
        flex-wrap: wrap;
      }
      @media all and (max-width: 1080px) {
        margin-right: 14px;
      }
    }
  }
  &.app {
    @media all and (max-width: 1080px) {
      flex-direction: column;
    }
    @media all and (max-width: 768px) {
      flex-direction: row;
    }
  }
  @media all and (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 12px;
  }
`;

const Address = styled.span`
  font-size: 14px;
  font-family: regular;
  color: #f7f7f7;
  width: 100%;
  letter-spacing: 0.7px;
  /* max-width: 150px; */
  line-height: 1.4em;
  @media all and (max-width: 768px) {
    margin-left: 12px;
  }
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;
const Span = styled.span`
  font-size: 15px;
  font-family: regular;
  color: #f7f7f7;
  letter-spacing: 1px;
  @media all and (max-width: 420px) {
    font-size: 13px;
    letter-spacing: 0.5px;
  }
`;

const FooterPush = styled.div`
  width: 100%;
  background: transparent;
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

//
const AppBox = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 7px;
  border: 1px solid #f7f7f7;
  padding: 10px;
  cursor: pointer;
  &:first-child {
    margin-right: 10px;
  }
  @media all and (max-width: 1080px) {
    &:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  @media all and (max-width: 768px) {
    &:first-child {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
`;
const AppIconBox = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 10px;
  @media all and (max-width: 980px) {
    height: 30px;
    width: 30px;
  }
  @media all and (max-width: 420px) {
    height: 25px;
    width: 25px;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const Small = styled.div`
  font-size: 13px;
  font-family: regular;
  color: #f7f7f7;
  @media all and (max-width: 980px) {
    font-size: 11px;
  }
  @media all and (max-width: 420px) {
    font-size: 9px;
  }
`;
const Big = styled.div`
  font-size: 17px;
  font-family: medium;
  color: #f7f7f7;
  margin-top: 5px;
  @media all and (max-width: 980px) {
    font-size: 13px;
  }
`;
