import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Context } from "../../context/store";
import { accountConfig } from "../../../apiConfig";
import Toast from "../../general/includes/Toast";
import ButtonLoader from "../../general/includes/ButtonLoader";

export default function UserProfile() {
	const { state, dispatch } = useContext(Context);
	const [isToast, setToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [gender, setGender] = useState("male");
	const [phoneNumber, setPhoneNumber] = useState(
		state.user_data.phone ? state.user_data.phone : ""
	);
	const [emailId, setEmailId] = useState("");
	// state.user_data.email ? state.user_data.email : ""
	const [isEdit, setEdit] = useState(false);
	const [firstName, setFirstName] = useState("");
	// state.user_data.name ? state.user_data.name : ""
	// const [lastName, setLastName] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [profilePhoto, setProfilePhoto] = useState("");
	const [photoUrl, setPhotoUrl] = useState(
		state.user_data.photo ? state.user_data.photo : null
	);
	const access_token = state.user_data.access_token;
	const emailRef = useRef(null);

	const profileFecthing = () => {
		accountConfig
			.get("profile/", {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setProfilePhoto(data.photo);
					setGender(data.gender);
					dispatch({
						type: "UPDATE_USER_DATA",
						user_data: {
							...state.user_data,
							phone: data.phone,
							name: data.name,
							email: data.email,
							gender: data.gender,
							photo: data.photo,
							user_pk: data.user,
						},
					});
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	useEffect(() => {
		profileFecthing();
	}, [isToast]);

	function handleformData() {
		//
		const formData = new FormData();
		if (typeof photoUrl !== "object") {
			formData.append("name", firstName);
			formData.append("phone", phoneNumber);
			formData.append("email", emailId);
			formData.append("gender", gender);
		} else {
			formData.append("name", firstName);
			formData.append("phone", phoneNumber);
			formData.append("email", emailId);
			formData.append("gender", gender);
			formData.append("photo", photoUrl);
		}
		return formData;
	}

	const handleProfileUpdate = (e) => {
		e.preventDefault();
		if (
			// /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailId) &&
			gender &&
			phoneNumber &&
			emailId &&
			(firstName || state.user_data.name) &&
			(photoUrl || profilePhoto)
		) {
			setLoading(true);
			accountConfig
				.post("update-customer-profile/", handleformData(), {
					headers: {
						Authorization: `Bearer ${state.user_data.access_token}`,
					},
				})
				.then((res) => {
					const { StatusCode, data } = res.data;
					if (StatusCode === 6000) {
						profileFecthing();
						setEdit(false);
						setLoading(false);
						setToastText(data.message);
						setToast(true);
					} else {
						setToastText(res.data.message);
						setToast(true);
						// setEdit(false);
						setLoading(false);
					}
				})
				.catch((e) => {
					setToastText("Network error");
					setToast(true);
					setLoading(false);
				});
		} else {
			setProfilePhoto(photoUrl ? URL.createObjectURL(photoUrl) : "");

			setToast(true);
			if (!photoUrl) {
				setToastText("Please upload your photo");
			} else if (!emailId) {
				setToastText("Please enter your email");
			} else if (!gender) {
				setToastText("Please select your gender");
			}
		}
	};

	return (
		<UserProfileContainer>
			<Toast isToast={isToast} setToast={setToast} text={toastText} />
			<Head>
				<HeadLeft>
					{profilePhoto || photoUrl ? (
						<Image
							
							className="img-class"
							style={{
								borderRadius: "50%",
							}}
							alt="Image" src={
								state.user_data.photo
									? state.user_data.photo
									: URL.createObjectURL(photoUrl)
							}
						/>
					) : (
						<ProfileLetter>
							{state?.user_data?.name?.charAt(0)}
						</ProfileLetter>
					)}
					{isEdit && (
						<CameraIcon htmlFor="image">
							<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/camera.svg" />
							<input
								accept="image/*"
								type="file"
								id="image"
								// onChange={(e) => handleProfileImageUpload(e)}
								onChange={(e) => (
									setPhotoUrl(e.target.files[0]),
									setProfilePhoto(
										URL.createObjectURL(e.target.files[0])
									)
								)}
							/>
						</CameraIcon>
					)}
				</HeadLeft>
				<HeadRight>
					<Name>
						{state.user_data.name ? state.user_data.name : "Name"}
					</Name>
					<Bottom>
						<Contact>
							<IconBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/messages.svg"
								/>
							</IconBox>
							<ContactText
								style={{
									color: isEdit ? "#191919" : "#ADADAD",
								}}
							>
								{state.user_data.email
									? state.user_data.email
									: "Email"}
							</ContactText>
						</Contact>

						<Contact>
							<IconBox>
								<Image
									
									className="img-class"
									alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/phone.svg"
								/>
							</IconBox>
							<ContactText
								style={{
									color: isEdit ? "#191919" : "#ADADAD",
								}}
							>
								{state.user_data.phone
									? state.user_data.phone
									: "Phone No"}
							</ContactText>
						</Contact>
					</Bottom>
				</HeadRight>
				{!isEdit && (
					<EditBox
						onClick={() => (
							setEdit(true),
							setFirstName(state.user_data.name),
							setEmailId(state.user_data.email)
						)}
					>
						<Image  className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/edit.svg" />
					</EditBox>
				)}
			</Head>
			<FormSection>
				<Form onSubmit={handleProfileUpdate}>
					<FlexBox>
						<InputBox
							style={{
								width: "100%",
							}}
						>
							<Input
								style={{
									color: isEdit ? "#191919" : "#ADADAD",
								}}
								id="f-name"
								placeholder=" "
								// required
								disabled={isEdit ? false : true}
								type="text"
								value={
									isEdit ? firstName : state.user_data.name
								}
								// value={firstName || state.user_data.name}
								onChange={(e) => setFirstName(e.target.value)}
							/>
							<Label htmlFor="f-name">Full Name</Label>
						</InputBox>
						{/* <InputBox
							style={{
								width: "49%",
							}}
						>
							<Input
								style={{
									color: isEdit ? "#191919" : "#ADADAD",
								}}
								id="l-name"
								placeholder=" "
								disabled={isEdit ? false : true}
								type="text"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
							<Label htmlFor="l-name">Last Name</Label>
						</InputBox> */}
					</FlexBox>
					<GenderBox>
						<GenderTitle>Your gender</GenderTitle>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<RadioBox
								onClick={() =>
									isEdit ? setGender("male") : null
								}
							>
								<div>
									<Radio
										className="radio"
										style={{
											background:
												gender === "male"
													? "#adadad"
													: "#fff",
										}}
									></Radio>
								</div>
								<Text
									style={{
										color: isEdit ? "#191919" : "#adadad",
									}}
								>
									Male
								</Text>
							</RadioBox>
							<RadioBox
								onClick={() =>
									isEdit ? setGender("female") : null
								}
							>
								<div>
									<Radio
										className="radio"
										style={{
											background:
												gender === "female"
													? "#adadad"
													: "#fff",
										}}
									></Radio>
								</div>
								<Text
									style={{
										color: isEdit ? "#191919" : "#adadad",
									}}
								>
									Female
								</Text>
							</RadioBox>
						</div>
					</GenderBox>

					<AddressFormBox>
						<InputBox
							style={{
								marginBottom: "70px",
							}}
						>
							<Input
								ref={emailRef}
								style={{
									color: isEdit ? "#191919" : "#ADADAD",
								}}
								id="email"
								placeholder=" "
								disabled={isEdit ? false : true}
								type="email"
								required
								value={
									isEdit
										? emailId
										: state.user_data.email || emailId
								}
								onChange={(e) => setEmailId(e.target.value)}
							/>
							<Label htmlFor="email">Email Address</Label>
						</InputBox>
						<InputBox>
							<Input
								style={{
									color: isEdit ? "#ADADAD" : "#ADADAD",
								}}
								id="phone"
								placeholder=" "
								disabled={true}
								type="number"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
							<Label htmlFor="phone">Phone Number</Label>
						</InputBox>
					</AddressFormBox>
					{isEdit && (
						<SubmitButton type="submit">
							{isLoading ? (
								<ButtonLoader />
							) : (
								<ButtonText>Verify</ButtonText>
							)}
						</SubmitButton>
					)}
				</Form>
			</FormSection>
		</UserProfileContainer>
	);
}

const UserProfileContainer = styled.div`
	background: #fffdfa;
	padding: 20px;
	border-radius: 5px;
`;
const Head = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 70px;
	position: relative;
	@media all and (max-width: 640px) {
		flex-direction: column;
	}
`;
const HeadLeft = styled.div`
	width: 85px;
	height: 85px;
	border: 1px solid #ffdd0d;
	border-radius: 50%;
	/* overflow: hidden; */
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 5px;
	margin-right: 25px;
	background: linear-gradient(
		90deg,
		rgba(252, 245, 157, 1) 11%,
		rgba(251, 219, 3, 1) 100%
	);

	@media all and (max-width: 640px) {
		margin-right: 0;
		margin-bottom: 25px;
	}
`;
const HeadRight = styled.div`
	@media all and (max-width: 640px) {
		/* display: none; */
		text-align: center;
	}
`;
const Name = styled.div`
	font-size: 25px;
	font-family: medium;
	color: #191919;
	margin-bottom: 10px;
	@media all and (max-width: 640px) {
		font-size: 18px;
	}
`;
const Bottom = styled.div`
	display: flex;
	align-items: center;
	@media all and (max-width: 640px) {
		/* flex-direction: column; */
		display: none;
	}
`;
const Contact = styled.div`
	display: flex;
	align-items: center;
	margin-right: 20px;
	/* cursor: pointer; */
`;
// const IconBox = styled.div``;
const ContactText = styled.div`
	font-size: 14px;
	font-family: regular;
	color: #747474;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 170px;
`;
const FormSection = styled.div`
	margin-top: 30px;
`;

// const TitleBox = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	width: 100%;
// 	margin-bottom: 45px;
// `;
const EditBox = styled.div`
	width: 20px;
	height: 20px;
	border: 1px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 0;
`;
// const Title = styled.h3`
// 	color: #747474;
// 	font-size: 22px;
// 	font-family: "medium";
// 	@media all and (max-width: 420px) {
// 		font-size: 20px;
// 	}
// `;
const Form = styled.form`
	background-color: #fffdfa;
	/* padding: 40px; */
	/* @media all and (max-width: 420px) {
    padding: 15px;
  } */
`;

const IconBox = styled.div`
	width: 20px;
	margin-right: 10px;
	@media all and (max-width: 420px) {
		font-size: 16px;
	}
`;
const Image = styled.img``;

const InputBox = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
`;
const FlexBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
`;
const Label = styled.label`
	color: #747474;
	font-size: 14px;
	font-family: "regular";
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.2s all ease-in;
`;
const Input = styled.input`
	font-size: 18px;
	font-family: medium;
	padding-bottom: 10px;
	border-bottom: 1px solid #d4d4d4;
	&:focus ~ label {
		top: -25px;
		font-size: 14px;
		color: #e9ce22;
		transition: 0.3s;
	}
	&:focus {
		border-bottom: 1px solid #e9ce22;
	}
	&:not(:placeholder-shown) ~ label {
		border-color: green;
		top: -25px;
		font-size: 14px;
		color: #adadad;
		transition: 0.3s;
	}
	@media all and (max-width: 420px) {
		font-size: 16px;
	}
`;
const AddressFormBox = styled.div``;

//gender

const GenderBox = styled.div`
	margin: 50px 0 70px;
`;
const GenderTitle = styled.div`
	color: #adadad;
	font-size: 14px;
	font-family: "regular";
	margin-bottom: 20px;
`;
const RadioBox = styled.div`
	display: flex;
	align-items: center;
	&:first-child {
		margin-right: 20px;
	}
	div {
		background: #fff;
		border: 1px solid #adadad;
		padding: 3px;
		border-radius: 50%;
	}
`;
const Radio = styled.div`
	width: 15px;
	height: 15px;
	border-radius: 50%;
	&.radio {
		/* background: #747474; */
		border: none;
	}
`;
const Text = styled.span`
	color: #747474;
	font-size: 16px;
	font-family: "medium";
	margin-left: 10px;
`;

const CameraIcon = styled.label`
	width: 20px;
	height: 20px;
	padding: 3px;
	position: absolute;
	bottom: 0;
	right: 0;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0px 2.94118px 8.08823px rgba(0, 0, 0, 0.09);
	cursor: pointer;
	input {
		display: none;
	}
`;
const ProfileLetter = styled.span`
	color: #747474;
	font-size: 55px;
	font-family: bold;
	@media all and (max-width: 768px) {
		font-size: 45px;
	}
`;

const SubmitButton = styled.button`
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	/* padding: 15px; */
	height: 50px;
	font-size: 18px;
	font-family: medium;
	color: #191919;
	margin-top: 5%;
	display: inline-block;
	width: 100%;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	&.transfer {
		margin-bottom: 30px;
		background: #fffcf3;
		color: #191919;
		margin-top: 2%;
		border: 1px solid #feda00;
	}
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
const ButtonText = styled.span`
	font-size: 18px;
	font-family: medium;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
