import axios from "axios";

// ***************************************DEVELOPER SERVER CONFIG *************************************** //

// #### LIVE SERVER CONFIGS #### //

// generelConfig
export const generelConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/general/",
});

//accountConfig
export const accountConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/users/",
});

// shopConfig
export const shopConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/shops/",
});

// bidConfig
export const bidConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/bidpot/",
});

// ratingConfig
export const ratingConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/ratings/",
});

// ordersConfig
export const ordersConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/orders/",
});

// wishlistConfig
export const wishlistConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/wishlists/",
});

// shippingsConfig
export const shippingsConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/shippings/",
});

// paymentsConfig
export const paymentsConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/payments/",
});

// adsConfig
export const adsConfig = axios.create({
	baseURL: "https://api.oneruppee.com/api/v1/ads/",
});

// ***************************************DEVELOPER SERVER CONFIG *************************************** //

// // #### DEVELOPER SERVER CONFIGS #### //

// generelConfig;
// export const generelConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/general/",
// });

// //
// export const accountConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/users/",
// });

// // shopConfig
// export const shopConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/shops/",
// });

// // bidConfig
// export const bidConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/bidpot/",
// });

// // ratingConfig
// export const ratingConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/ratings/",
// });

// // ordersConfig
// export const ordersConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/orders/",
// });

// // wishlistConfig
// export const wishlistConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/wishlists/",
// });

// // shippingsConfig
// export const shippingsConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/shippings/",
// });

// paymentsConfig
// export const paymentsConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/payments/",
// });

// // adsConfig
// export const adsConfig = axios.create({
// 	baseURL: "https://developers-api.oneruppee.com/api/v1/ads/",
// });

// ================================================================

// ngork configuration

// export const generelConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/general/",
// });

// //
// export const accountConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/users/",
// });

// // shopConfig
// export const shopConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/shops/",
// });

// // bidConfig
// export const bidConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/bidpot/",
// });

// // ratingConfig
// export const ratingConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/ratings/",
// });

// // ordersConfig
// export const ordersConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/orders/",
// });

// // wishlistConfig
// export const wishlistConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/wishlists/",
// });

// // shippingsConfig
// export const shippingsConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/shippings/",
// });

// // paymentsConfig
// export const paymentsConfig = axios.create({
// 	baseURL: "http://139.59.19.225:8000/api/v1/payments/",
// });
