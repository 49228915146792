import React, { useState } from "react";
import styled from "styled-components";

export default function OrderReturn() {
  // const [reasonId, setReasonId] = useState("");
  // const [modeId, setModeId] = useState("first");
  const [isDropdown, setDropdown] = useState(false);
  return (
    <>
      <Title>
        Addidas, <Grey>KL-6A Analog Watch- Order Canellation </Grey>
      </Title>
      <ReasonBox>
        <ReasonTitle>Reason for Return*</ReasonTitle>
        <DropDownBox onClick={() => setDropdown(!isDropdown)}>
          <SelectText>Select Reason</SelectText>
          <IconBox>
            <Image   className="img-class" alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/arrow-down.svg" />
          </IconBox>
          {isDropdown && (
            <DropDown>
              <DropDownItem onClick={() => setDropdown(!isDropdown)}>
                <Radio>
                  <Dot></Dot>
                </Radio>
                <DropText>I have changed my mind </DropText>
              </DropDownItem>
            </DropDown>
          )}
        </DropDownBox>
        <CommentBox>
          <SelectText>Comments*</SelectText>
          <TextArea placeholder="Write here" rows={10} />
        </CommentBox>
      </ReasonBox>
      <ModeBox>
        <ReasonTitle>Reason for cancellation*</ReasonTitle>
        <ModeItem>
          <Radio>
            <Dot></Dot>
          </Radio>
          <Text>
            Original Payment Method <br />
            <GreyText>Refund will be processed within 8 days</GreyText>
          </Text>
        </ModeItem>
        <ModeItem>
          <Radio>
            <Dot></Dot>
          </Radio>
          <Text>
            Original Payment Method <br />
            <GreyText>Refund will be processed within 8 days</GreyText>
          </Text>
        </ModeItem>
      </ModeBox>
      <Button>Request Cancelletion</Button>
    </>
  );
}

const Title = styled.h3`
  font-family: medium;
  font-size: 22px;
  color: #747474;
  margin-bottom: 20px;
  @media all and (max-width: 640px) {
    font-size: 20px;
  }
  @media all and (max-width: 420px) {
    font-size: 18px;
  }
`;
const Grey = styled.span`
  font-size: 18px;
  font-family: regular;
  color: #747474;
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
  @media all and (max-width: 420px) {
    font-size: 14px;
  }
`;

const Button = styled.span`
  display: flex;
  align-items: center;
  background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: max-content;
  font-family: medium;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
`;

const ReasonBox = styled.div`
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background: #fffdfa;
  margin-bottom: 20px;
`;
const ReasonTitle = styled.h3`
  font-size: 18px;
  font-family: regular;
  color: #747474;
  margin-bottom: 20px;
`;
const DropDownBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  margin-bottom: 30px;
  border-bottom: 1px solid #adadad;
  padding-bottom: 15px;
`;
const SelectText = styled.span`
  font-size: 16px;
  font-family: regular;
  color: #747474;
`;
const IconBox = styled.div`
  width: 20px;
  height: 20px;
`;
const Image = styled.img``;
const CommentBox = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;
const TextArea = styled.textarea`
  border: 1px solid #e4e4e4;
  padding: 10px;
  margin-top: 10px;
  resize: vertical;
  color: #747474;
  font-size: 18px;
  font-family: regular;
  border-radius: 5px;
`;
const ModeBox = styled.div`
  padding: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
`;
const ModeItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;
const Radio = styled.div`
  border-radius: 50%;
  border: 1px solid #fedc0c;
  background: #fffdfa;
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
const Dot = styled.div`
  width: 11px;
  height: 11px;
  background: #fedc0c;
  border-radius: 50%;
`;
const Text = styled.span`
  font-size: 16px;
  font-family: regular;
  color: #191919;
  line-height: 25px;
`;
const GreyText = styled.span`
  font-size: 16px;
  font-family: regular;
  color: #747474;
`;

const DropDown = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 6px 6px;
  padding: 20px;
`;
const DropDownItem = styled.div`
  display: flex;
  align-items: center;
`;
const DropText = styled.div`
  font-size: 16px;
  font-family: regular;
  color: #191919;
`;
