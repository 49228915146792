import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import { accountConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonLoader from "../../general/includes/ButtonLoader";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../../src/config/firebase";

export default function EnterOtp({ reswidth, isLogin, setOtpMode, setIsName }) {
    const { state, dispatch } = useContext(Context);
    const [seconds, setSeconds] = useState(30);
    const [resend, setResend] = useState(true);
    const [otp, setOtp] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    function handleFirebaseAuth(token, profileName) {
        accountConfig
            .get("firebase/auth/login/", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { StatusCode, data } = res.data;
                if (StatusCode === 6000) {
                    const token = data.token;
                    signInWithCustomToken(auth, token)
                        .then((userCredential) => {
                            const user = userCredential.user;
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                user_data: {
                                    uid: user.uid,
                                },
                            });
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                        });
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
        if (isLogin) {
            if (profileName) {
                if (location.state?.from) {
                    navigate(location.state?.from);
                } else {
                    navigate("/");
                }
            } else {
                setIsName(true);
            }
        } else {
            setIsName(true);
        }
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setResend(true);
                setSeconds(seconds - 1);
            } else {
                setResend(false);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    // get from OTP cells
    const handleChange = (otpData) => {
        setOtp(otpData);
    };
    useEffect(() => {
        if (otp.length === 4) {
            handleOtp();
        }
    }, [otp]);

    // handle OTP
    function handleOtp() {
        if (isLogin) {
            //handle login otp
            if (otp.length === 4) {
                setLoading(true);
                setErrMsg("");
                const data = {
                    country: state.user_data.country,
                    phone: state.user_data.phone,
                    otp: otp,
                };
                accountConfig
                    .post("login/verify/otp/", data)
                    .then((res) => {
                        const { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setErrMsg("");
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                user_data: {
                                    ...state.user_data,
                                    is_verified: true,
                                    access_token: data.response.access,
                                    name: data.name,
                                    user_pk: data.user,
                                },
                            });

                            // after login / sign in ,create a user in firebase auth for realtime database
                            handleFirebaseAuth(data.response.access, data.name);
                        } else {
                            setErrMsg(data.message);
                            setLoading(false);
                        }
                    })
                    .catch((e) => {
                        setErrMsg(e.message);
                        setLoading(false);
                    });
            } else {
                setErrMsg("Enter Valid OTP");
            }
        } else {
            //handle sign in otp
            if (otp.length === 4) {
                setLoading(true);
                const data = {
                    country: state.user_data.country,
                    phone: state.user_data.phone,
                    otp: otp,
                };
                accountConfig
                    .post("signup/verify/phone/", data)
                    .then((res) => {
                        const { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setLoading(true);
                            setErrMsg("");
                            // setIsName(true);
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                user_data: {
                                    ...state.user_data,
                                    is_verified: false,
                                    access_token: data.response.access,
                                    name: data.name,
                                    user_pk: data.user,
                                },
                            });
                            // after login / sign in ,create a user in firebase auth for realtime database
                            handleFirebaseAuth(data.response.access, data.name);
                        } else {
                            setErrMsg(data.message);
                            setLoading(false);
                        }
                    })
                    .catch((e) => {
                        setErrMsg(e.message);
                        setLoading(false);
                    });
            } else {
                setErrMsg("Enter Valid OTP");
            }
        }
    }

    function handleResendOtp() {
        if (isLogin) {
            // login resend OTP
            accountConfig
                .post("login/resend/otp/", {
                    country: state.user_data.country,
                    phone: state.user_data.phone,
                })
                .then((res) => {
                    const { StatusCode, data } = res.data;
                    if (StatusCode == 6001) {
                        setErrMsg(data.message);
                    }
                })
                .catch((e) => {});
        } else {
            // signin resend OTP
            accountConfig
                .post("signup/resend/otp/", {
                    country: state.user_data.country,
                    phone: state.user_data.phone,
                })
                .then((res) => {
                    const { StatusCode, data } = res.data;
                    if (StatusCode == 6001) {
                        setErrMsg(data.message);
                    }
                })
                .catch((e) => {});
        }
    }

    return (
        <EnterOtpContainer>
            <Title>Enter OTP</Title>
            <Description>
                We have send you an OTP code <br />
                to{" "}
                <GoldText>
                    {state.user_data.country} {state.user_data.phone}
                </GoldText>
            </Description>
            <ChangeNumber onClick={() => setOtpMode(false)}>
                CHANGE NUMBER?
            </ChangeNumber>
            <OtpInputContainer>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum
                    inputType="number"
                    separator={
                        <span
                            style={{
                                width: "30px",
                                height: "20px",
                            }}
                        ></span>
                    }
                    // inputStyle={{
                    //     fontSize: reswidth < 640 ? "25px" : "35px",
                    //     fontFamily: "medium",
                    //     width: "100%",
                    //     height: "100%",
                    //     color: "#455A64",
                    //     textAlign: "center",
                    //     borderBottom: "2px solid #C6C6C6",
                    //     paddingBottom: "5%",
                    // }}
                    focusStyle={{
                        borderBottom: "2px solid #e9ce22",
                    }}
                    renderInput={(props) => (
                        <input
                            type="number"
                            {...props}
                            style={{
                                fontSize: reswidth < 640 ? "25px" : "35px",
                                fontFamily: "medium",
                                width: "100%",
                                height: "100%",
                                color: "#455A64",
                                textAlign: "center",
                                borderBottom: "2px solid #C6C6C6",
                                paddingBottom: "5%",
                                marginRight: "20px",
                            }}
                        />
                    )}
                />
            </OtpInputContainer>
            <ErrorBox>
                <OtpText
                    style={{
                        color: "#E93A22",
                    }}
                >
                    {errMsg}
                </OtpText>
                {resend ? (
                    <OtpText
                        style={{
                            marginLeft: "auto",
                            cursor: "pointer",
                        }}
                    >
                        Resend OTP in{" "}
                        <TimerText
                            style={{
                                color: "#E9CE22",
                            }}
                        >
                            {seconds}s
                        </TimerText>
                    </OtpText>
                ) : (
                    <OtpText
                        style={{
                            marginLeft: "auto",
                            cursor: "pointer",
                        }}
                        onClick={() => (setSeconds(5), handleResendOtp())}
                    >
                        Resend OTP?
                    </OtpText>
                )}
            </ErrorBox>
            <VerifyButton onClick={handleOtp}>
                {isLoading ? <ButtonLoader /> : <ButtonText>Verify</ButtonText>}
            </VerifyButton>
        </EnterOtpContainer>
    );
}

const EnterOtpContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media all and (max-width: 640px) {
        transform: translateY(-70px);
    }
`;
const Title = styled.h3`
    font-size: 25px;
    font-family: medium;
    color: #606060;
    margin-bottom: 10%;
    @media all and (max-width: 1080px) {
        font-size: 20px;
    }
    @media all and (max-width: 640px) {
        font-size: 17px;
    }
`;
const Description = styled.p`
    font-size: 18px;
    font-family: regular;
    color: #606060;
    line-height: 25px;
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 420px) {
        font-size: 14px;
    }
`;
const GoldText = styled.span`
    font-size: 17px;
    font-family: regular;
    color: #e9ce22;
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 420px) {
        font-size: 14px;
    }
`;
const ChangeNumber = styled.span`
    margin-top: 4%;
    font-size: 17px;
    font-family: regular;
    color: #e9ce22;
    cursor: pointer;
    @media all and (max-width: 420px) {
        font-size: 15px;
    }
`;
const OtpInputContainer = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10%;
`;
const ErrorBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3%;
`;
const OtpText = styled.span`
    font-size: 14px;
    font-family: regular;
    color: #455a64;
    @media all and (max-width: 420px) {
        font-size: 12px;
    }
`;
const TimerText = styled.span``;
const VerifyButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 40px;
    background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5%;
    margin-left: auto;
    @media all and (max-width: 640px) {
        height: 35px;
    }
`;
const ButtonText = styled.h3`
    font-size: 16px;
    font-family: medium;
    color: #747474;
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;
