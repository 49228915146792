import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Link } from "react-router-dom";
import { ordersConfig } from "../../../apiConfig";
import { Context } from "../../context/store";
import PageLoader from "../../screens/PageLoader";
import EmptyPage from "../../screens/EmptyPage";

export default function OrderList() {
	const { state } = useContext(Context);
	const [orderData, setOrderData] = useState([]);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		ordersConfig
			.get("view-orders-of-user/", {
				headers: {
					Authorization: `Bearer ${state.user_data.access_token}`,
				},
			})
			.then((res) => {
				const { StatusCode, data } = res.data;
				if (StatusCode === 6000) {
					setOrderData(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	return !isLoading && orderData?.length > 0 ? (
		<>
			<Title>
				My Orders <Grey>{orderData?.length} items</Grey>
			</Title>
			{orderData?.length > 0 && (
				<SearchBox>
					<Input placeholder="Search your orders" />
					<SearchButton>
						<IconBox>
							<Image
								
								className="img-class"
								alt="Image" src="https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/search.svg"
							/>
						</IconBox>
						<Text>Search Orders</Text>
					</SearchButton>
				</SearchBox>
			)}
			<ListBox>
				{orderData?.map((item, index) => {
					return (
						<ListItem to={`/orders/order/${item.pk}`} key={index}>
							<Left>
								<ImageBox>
									{item.order_details && (
										<Image
											
											className="img-class"
											alt="Image" src={
												item.order_details[0]
													.featured_image ||
												item.order_details[0]
													.bid_product_image
											}
										/>
									)}
								</ImageBox>
								<Details>
									{item.order_details &&
										item.order_details[0].product_brand && (
											<Brand>
												{
													item.order_details[0]
														.product_brand
												}
											</Brand>
										)}
									{item.order_details && (
										<Name>
											{item.order_details[0]
												.product_name ||
												item.order_details[0]
													.bid_product_name}
										</Name>
									)}
									{item.order_details && (
										<Price>
											₹{item.order_details[0].total_cost}
										</Price>
									)}
									<DeliveryStatus>
										Delivery Status :{" "}
										<GreenStatus>
											{item.status === "payment_failed"
												? "Failed"
												: item.status}
										</GreenStatus>
									</DeliveryStatus>
								</Details>
							</Left>
							{/* <Right>
								<OrderDetails>
									<StatusBox>
										<IconBox>
											<Image  
												className="img-class"
												alt="Image" src={box}
											/>
										</IconBox>
										<StatusText>
											Delivered on 12 Sep 2022
										</StatusText>
									</StatusBox>
									<Descritpion>
										Product has been delivered
									</Descritpion>
									<RateProduct>
										<IconBox
											style={{
												marginRight: "7px",
											}}
										>
											<Image  
												className="img-class"
												alt="Image" src={goldStart}
											/>
										</IconBox>
										Rate & Review product
									</RateProduct>
								</OrderDetails>
							</Right> */}
							{item.status === "payment_failed" ||
							item.status === "cancelled" ? (
								<PRight>
									<ProgressBar
										percent={100}
										filledBackground="linear-gradient(90deg, #009262 2.2%, #E02B1D 100%)"
										height={5}
									>
										<Step
											transition="scale"
											style={{ flexDirection: "column" }}
										>
											{({ accomplished }) => (
												<>
													<StatusTitle>
														Ordered
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>

										<Step transition="scale">
											{({ accomplished }) => (
												<>
													<StatusTitle>
														{item.status ===
														"cancelled"
															? item.status
															: "Failed"}
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>
									</ProgressBar>
								</PRight>
							) : (
								<PRight>
									<ProgressBar
										percent={
											item.status === "pending"
												? 0
												: item.status === "ordered"
												? 25
												: item.status === "packed"
												? 50
												: item.status === "shipped"
												? 75
												: item.status === "delivered"
												? 100
												: 0
										}
										filledBackground="#009262"
										height={5}
									>
										<Step
											transition="scale"
											style={{ flexDirection: "column" }}
										>
											{({ accomplished }) => (
												<>
													<StatusTitle>
														Pending
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>

										<Step
											transition="scale"
											style={{ flexDirection: "column" }}
										>
											{({ accomplished }) => (
												<>
													<StatusTitle>
														Ordered
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>
										<Step
											transition="scale"
											style={{ flexDirection: "column" }}
										>
											{({ accomplished }) => (
												<>
													<StatusTitle>
														Packed
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>
										<Step transition="scale">
											{({ accomplished }) => (
												<>
													<StatusTitle>
														Shipped
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>
										<Step transition="scale">
											{({ accomplished }) => (
												<>
													<StatusTitle>
														Delivered
													</StatusTitle>
													<img
														className="pImg"
														width="20"
														alt="Image" src={
															accomplished
																? "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/green-bg-tick.svg"
																: "https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/icons/01-03-2023/grey-ring.svg"
														}
													/>
													<StatusDate>
														{/* {item.date_added
														? new Date(
																item.date_added
														  ).toDateString()
														: "--"} */}
													</StatusDate>
												</>
											)}
										</Step>
									</ProgressBar>
								</PRight>
							)}
						</ListItem>
					);
				})}
			</ListBox>
		</>
	) : orderData?.length === 0 && !isLoading ? (
		<EmptyPage text="No items found" />
	) : (
		<PageLoader />
	);
}

const Title = styled.h3`
	font-family: medium;
	font-size: 24px;
	color: #191919;
	@media all and (max-width: 640px) {
		font-size: 20px;
	}
	@media all and (max-width: 420px) {
		font-size: 16px;
	}
`;
const Grey = styled.span`
	font-size: 18px;
	color: #747474;
	@media all and (max-width: 640px) {
		font-size: 16px;
	}
	@media all and (max-width: 420px) {
		font-size: 13px;
	}
`;
const SearchBox = styled.div`
	padding: 5px;
	padding-left: 10px;
	border: 1px solid #e4e4e4;
	display: flex;
	border-radius: 5px;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	@media all and (max-width: 420px) {
		margin-top: 10px;
	}
`;
const Input = styled.input`
	font-size: 20px;
	width: 50%;
	font-family: regular;
	color: #747474;
	overflow: hidden;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
`;
const SearchButton = styled.div`
	display: flex;
	align-items: center;
	background: linear-gradient(94.63deg, #fef7a3 0%, #feda00 100%);
	padding: 15px 20px;
	border-radius: 5px;
	cursor: pointer;
	@media all and (max-width: 640px) {
		padding: 9px 14px;
	}
`;
const IconBox = styled.div`
	width: 15px;
`;
const Image = styled.img``;
const Text = styled.div`
	font-size: 18px;
	font-family: medium;
	color: #191919;
	margin-left: 7px;
	@media all and (max-width: 640px) {
		font-size: 14px;
	}
	@media all and (max-width: 640px) {
		font-size: 12px;
	}
`;
const ListBox = styled.div`
	margin-top: 20px;
`;
const ListItem = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	margin-bottom: 20px;
	background: #fffdfa;
	cursor: pointer;
	@media all and (max-width: 1080px) {
		flex-direction: column;
	}
	@media all and (max-width: 420px) {
		align-items: flex-start;
	}
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	width: 30%;
	@media all and (max-width: 1080px) {
		width: 100%;
	}
`;
const ImageBox = styled.div`
	width: 100px;
	height: 100px;
	margin-right: 20px;
	border-radius: 5px;
	overflow: hidden;
	@media all and (max-width: 1080px) {
		/* width: 10%; */
	}
	@media all and (max-width: 768px) {
		/* width: 20%; */
	}
	@media all and (max-width: 420px) {
		/* width: 30%; */
		margin-right: 10px;
	}
`;
const Details = styled.div``;
const Brand = styled.h3`
	font-size: 20px;
	font-family: "medium";
	color: #191919;
	@media all and (max-width: 420px) {
		font-size: 16px;
	}
`;
const Name = styled.h3`
	color: #747474;
	font-size: 18px;
	font-family: "regular";
	margin: 10px 0;
	@media all and (max-width: 420px) {
		font-size: 15px;
		margin: 3px 0 7px;
	}
`;
const Price = styled.span`
	color: #191919;
	font-family: "medium";
	font-size: 16px;
	@media all and (max-width: 420px) {
		font-size: 14px;
	}
`;
// const Right = styled.div`
// 	@media all and (max-width: 1080px) {
// 		width: 100%;
// 		margin-top: 20px;
// 	}
// `;
// const OrderDetails = styled.div`
// 	text-align: right;
// 	@media all and (max-width: 1080px) {
// 		text-align: left;
// 	}
// `;
// const StatusBox = styled.div`
// 	display: flex;
// 	align-items: center;
// 	margin-bottom: 10px;
// 	@media all and (max-width: 420px) {
// 		margin: 0;
// 	}
// `;
// const StatusText = styled.h3`
// 	color: #009262;
// 	font-family: "regular";
// 	font-size: 20px;
// 	margin-left: 10px;
// 	@media all and (max-width: 420px) {
// 		font-size: 16px;
// 	}
// `;
// const Descritpion = styled.span`
// 	color: #747474;
// 	font-family: "regular";
// 	font-size: 18px;
// 	@media all and (max-width: 420px) {
// 		font-size: 14px;
// 	}
// `;
// const RateProduct = styled.span`
// 	display: flex;
// 	align-items: center;
// 	text-align: right;
// 	color: #747474;
// 	font-family: "regular";
// 	font-size: 18px;
// 	justify-content: end;
// 	margin-top: 10px;
// 	text-decoration: underline;
// 	color: #feda00;
// 	cursor: pointer;
// 	@media all and (max-width: 1080px) {
// 		justify-content: start;
// 	}
// 	@media all and (max-width: 420px) {
// 		margin-top: 5px;
// 		font-size: 14px;
// 	}
// `;

// // green bar

// const StatusBar = styled.div`
// 	width: 50%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	position: relative;
// 	overflow: hidden;
// 	z-index: 2;
// 	::after {
// 		display: block;
// 		content: "";
// 		width: 75%;
// 		height: 3px;
// 		background: #d4d4d4;
// 		position: absolute;
// 		top: 51%;
// 		left: 50%;
// 		right: 50%;
// 		bottom: 50%;
// 		transform: translate(-50%, -50%);
// 		z-index: -2;
// 		@media all and (max-width: 1080px) {
// 			width: 85%;
// 		}
// 		@media all and (max-width: 768px) {
// 			height: 2px;
// 			width: 77%;
// 		}
// 		@media all and (max-width: 520px) {
// 			width: 65%;
// 		}
// 	}
// 	::before {
// 		display: block;
// 		transition: 0.3s ease-out;
// 		content: "";
// 		width: 40%;
// 		height: 3px;
// 		background: #009262;
// 		position: absolute;
// 		top: 51%;
// 		left: 13%;
// 		bottom: 50%;
// 		transform: translateY(-50%);
// 		z-index: -1;
// 		@media all and (max-width: 1080px) {
// 			width: 43%;
// 			left: 8%;
// 		}
// 		@media all and (max-width: 768px) {
// 			height: 2px;
// 			width: 38%;
// 			left: 12%;
// 		}
// 		@media all and (max-width: 520px) {
// 			left: 18%;
// 		}
// 	}
// 	@media all and (max-width: 1080px) {
// 		width: 100%;
// 		margin-top: 20px;
// 	}
// 	@media all and (max-width: 420px) {
// 		display: none;
// 	}
// `;
// const StatusItem = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// `;
const StatusTitle = styled.h3`
	color: #191919;
	font-size: 17px;
	font-family: "regular";
	@media all and (max-width: 1080px) {
		font-size: 15px;
	}
`;
// const TickBox = styled.div`
// 	background: #d4d4d4;
// 	width: 20px;
// 	height: 20px;
// 	border-radius: 50%;
// 	margin: 15px 0;
// 	@media all and (max-width: 768px) {
// 		width: 15px;
// 		height: 15px;
// 		margin: 10px 0;
// 	}
// `;
// const Tick = styled.div``;
const StatusDate = styled.span`
	color: #747474;
	font-size: 16px;
	font-family: "regular";
	@media all and (max-width: 1080px) {
		font-size: 13px;
	}
`;

const DeliveryStatus = styled.h3`
	color: #747474;
	font-size: 15px;
	font-family: "regular";
	margin-top: 20px;
	display: none;
	@media all and (max-width: 420px) {
		display: block;
		font-size: 13px;
	}
`;
const GreenStatus = styled.span`
	color: #009262;
	font-size: 12px;
	font-family: "medium";
	text-transform: capitalize;
`;
const PRight = styled.div`
	.pImg {
		margin: 10px 0;
	}
	.RSPBstep {
		flex-direction: column;
		white-space: nowrap;
		margin-bottom: 15px;
	}
	.RSPBprogressBar {
		transform: translateX(-8%);
		width: 500px;
		@media all and (max-width: 1080px) {
			/* transform: unset; */
			margin-bottom: 40px;
			transform: translateX(8%);
		}
		@media all and (max-width: 768px) {
			width: 400px;
		}
		@media all and (max-width: 640px) {
			display: none;
		}
	}
	@media all and (max-width: 1080px) {
		transform: unset;
		width: 100%;
		margin-top: 70px;
	}
	@media all and (max-width: 640px) {
		margin-top: 20px;
	}
	@media all and (max-width: 640px) {
		display: none;
	}
`;
